import { useCallback, useMemo } from "react";
import generateContext from "../../utils/generate-context";
import { useAsync } from "@react-org/hooks";
import * as TaskApi from "../../apis/tasks.api";
import { useNavigate } from "react-router-dom";

function useMyTaskPage(props) {
  const navigate = useNavigate();
  const getMyTaskDetailsQuery = useAsync(
    useCallback(
      (payload) => TaskApi.getMyTaskDetails({ limit: 100, ...payload }),
      []
    ),
    {
      select: (res) => {
        return { data: res.data.data, paginate: res.data.paging };
      },
      immediate: true,
    }
  );
  const handleViewTaskDetails = useCallback(
    (taskId, groupId) => () => {
      navigate(`/groups/${groupId}/tasks/${taskId}`);
    },
    [navigate]
  );

  return useMemo(() => {
    return {
      getMyTaskDetailsQuery,
      handleViewTaskDetails,
    };
  }, [getMyTaskDetailsQuery, handleViewTaskDetails]);
}
export const [MyTaskPageProvider, useMyTaskPageContext] =
  generateContext(useMyTaskPage);
