import { Form, Formik } from "formik";
import Button from "../../../../components/button";
import Drawer from "../../../../components/drawer";
import { useMembersPageContext } from "../../provider";
import FormControl from "../../../../components/form-control";
import InputLabel from "../../../../components/input-label";
import { FormikInput } from "../../../../components/input";
import { Enums, FN } from "./form-helper";
import { FormikTextarea } from "../../../../components/textarea";
import { FormikSelect } from "../../../../components/select";
import { Close2Icon } from "../../../../components/vectors/close2-icon";
import constants from "../../../../constants";

export const RegisterMemberDrawer = () => {
  const {
    initialValues,
    isRegisterMemberDrawerOpen,
    closeRegisterMemberDrawer,
    mainFormRef,
    getValidationSchema,
    handleRegisterMember,
    handleUpdateMember,
  } = useMembersPageContext();
  const hasMemberId = !!initialValues?.id;
  const handleSubmit = hasMemberId
    ? handleUpdateMember(initialValues?.id)
    : handleRegisterMember;
  return (
    <Drawer
      open={isRegisterMemberDrawerOpen}
      onOverlayClick={closeRegisterMemberDrawer}
      classNames={{
        content:
          "w-[600px] flex overflow-hidden flex-col h-full tab-xs:w-[300px] drop-shadow",
      }}
    >
      <div className="bg-primary-text">
        <div className="w-full flex justify-end">
          <button className="mt-5 mr-5" onClick={closeRegisterMemberDrawer}>
            <Close2Icon className="h-5 w-5 text-secondary-0" />
          </button>
        </div>
        <div className="w-full">
          <div className="text-base m-5 font-medium leading-6 font-sans text-secondary-0">
            Member
          </div>
        </div>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={getValidationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => {
          mainFormRef.current = formik;
          return (
            <Form className="flex flex-1 flex-col overflow-y-auto justify-between">
              <div className="flex-col p-4 gap-2">
                <div className="grid grid-cols-2 gap-2">
                  <FormControl>
                    <InputLabel
                      className="flex-1"
                      required
                      htmlFor={FN.FIRST_NAME}
                    >
                      First Name
                    </InputLabel>
                    <FormikInput
                      classNames={{ root: "grid-cols-2 gap-x-4 mb-1" }}
                      id={FN.FIRST_NAME}
                      name={FN.FIRST_NAME}
                    />
                  </FormControl>
                  <FormControl>
                    <InputLabel
                      className="flex-1"
                      required
                      htmlFor={FN.LAST_NAME}
                    >
                      Last Name
                    </InputLabel>
                    <FormikInput
                      classNames={{ root: "grid-cols-2 gap-x-4 mb-1" }}
                      id={FN.LAST_NAME}
                      name={FN.LAST_NAME}
                    />
                  </FormControl>
                </div>
                <div className="grid grid-cols-2 gap-2">
                  <FormControl>
                    <InputLabel
                      className="flex-1"
                      required
                      htmlFor={FN.CONTACT_NUMBER}
                    >
                      Contact Number
                    </InputLabel>
                    <FormikInput
                      classNames={{ root: "grid-cols-2 gap-x-4 mb-1" }}
                      id={FN.CONTACT_NUMBER}
                      name={FN.CONTACT_NUMBER}
                    />
                  </FormControl>
                  <FormControl>
                    <InputLabel className="flex-1" required htmlFor={FN.EMAIL}>
                      Email
                    </InputLabel>
                    <FormikInput
                      classNames={{ root: "grid-cols-2 gap-x-4 mb-1" }}
                      id={FN.EMAIL}
                      name={FN.EMAIL}
                      disabled={hasMemberId}
                    />
                  </FormControl>
                </div>
                <div className="grid grid-cols-2 gap-2">
                  <FormControl>
                    <InputLabel
                      className="flex-1"
                      required
                      htmlFor={FN.REPRESENTATIVE_OF}
                    >
                      Representative of
                    </InputLabel>
                    <FormikSelect
                      data={Enums.representativeOf}
                      classNames={{ root: "grid-cols-2 gap-x-4 mb-1" }}
                      lableString="label"
                      valueString="value"
                      passValueInt={true}
                      virtualize={Enums.representativeOf?.length >= constants.VIRTUALIZED_ENABLE_LIMIT}
                      id={FN.REPRESENTATIVE_OF}
                      name={FN.REPRESENTATIVE_OF}
                    />
                  </FormControl>
                  <FormControl>
                    <InputLabel className="flex-1" htmlFor={FN.ORGANISATION}>
                      Organisation
                    </InputLabel>
                    <FormikInput
                      classNames={{ root: "grid-cols-2 gap-x-4 mb-1" }}
                      id={FN.ORGANISATION}
                      name={FN.ORGANISATION}
                    />
                  </FormControl>
                </div>
                <FormControl>
                  <InputLabel className="flex-1" htmlFor={FN.ADDRESS}>
                    Address
                  </InputLabel>
                  <FormikTextarea
                    id={FN.ADDRESS}
                    name={FN.ADDRESS}
                    minRows={3}
                    classNames={{ root: "grid-cols-2 gap-x-4 mb-1" }}
                  />
                </FormControl>
              </div>
              <div className="flex items-end px-8 justify-end w-full my-4">
                <Button
                  variant="outline"
                  color="primary"
                  className="py-2 px-3.5 flex items-center text-xs font-semibold ml-5 text-primary-button"
                  onClick={closeRegisterMemberDrawer}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="solid"
                  color="primary"
                  className="py-2 px-3.5 flex items-center text-xs font-semibold ml-5 action-auditor-save"
                >
                  {hasMemberId ? "Update" : "Register"}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Drawer>
  );
};
export default RegisterMemberDrawer;
