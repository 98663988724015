import {
  getDateFromValue,
  getFormattedDate,
} from "../../../utils/date-and-time";
import { generateFullName } from "../../../utils/generate-names";
import * as Yup from "yup";
import * as validation from "../../../utils/validation";

export const FN = {
  FROM_MEETING_DATE: "from_meeting_date",
  TO_MEETING_DATE: "to_meeting_date",
  NATURE_OF_MEETING: "nature_of_meeting",
  LIST_OF_INDIAN_DELEGATION: "list_of_indian_delegation",
  TOTAL_PAPER_SUBMISSION: "total_paper_submission",
  TOTAL_MEMBER_ATTENDED: "total_member_attended",
  TOTAL_MEMBER_ATTENDED_VIRTUALLY: "total_member_attended_virtually",
  TOTAL_PAPER_UNDER_SUBMISSION: "total_paper_under_submission",
  GROUP_SET_UP: "group_set_up",
  NUMBER_OF_WORKING_GROUP_SET_UP: "number_of_working_group_setup",
  AGENDA_DETAILS: {
    AGENDAS: "agendas",
    TITLE: "title",
    TOTAL_PAPER_UNDER_CONSIDERATION: "total_paper_under_submission",
    TOTAL_PAPER_SUBMISSION_BY_INDIA: "total_paper_submission_by_india",
    TOTAL_PAPER_FROM_PAST_INDIA: "total_paper_from_past_india",
    AGENDA_ID: "agenda_id",
  },
  AGENDA_MEMBER_DETAILS: {
    AGENDA_MEMBERS: "agenda_members",
    AGENDA_TITLE: "agenda_title",
    NOMINATED_MEMEBERS: "nomiated_members",
    HEAD_OF_AGENDA: "head_of_agenda",
    TITLE: "title",
  },
  GROUPS: {
    NAME: "name",
    GROUP: "groups",
    ISSUE: "tasks",
    DESCRIPTION: "description ",
    PAPER_UNDER_CONSIDERATION: "paper_under_consideration",
    INDIAS_SUBMISSION: "india_submissions",
    INDIAS_CO_SPONSERED_PAPERS: "india_cosponsored_papers",
    REVIEW_OF_SUBMISSION: "review_of_submissions",
    RECOMMENDED_INDIAS_STAND_TO_BE_TAKEN: "recommended_india_stand",
    COMMITTEE_APPROVED_STAND: "committee_approved_stand",
    DECISSION_OF_COMMITTEE: "decision_of_committee",
    AREAS_OF_DISAGRREMENT_OF_INDIA: "areas_of_disagreement_of_india",
    ACHIEVEMENT_OF_INDIA: "achievement_of_india",
    TO_DO_LIST: "to_do_list",
    DETAILS: "details",
    PAPERS: "papers",
    TASK_ID: "task_id",
  },
  FULL_NAME: "full_name",
};

export const getAgendaDetailsRow = () => {
  return {
    [FN.AGENDA_DETAILS.TITLE]: "",
    [FN.AGENDA_DETAILS.AGENDA_ID]: "",
    [FN.AGENDA_DETAILS.TOTAL_PAPER_UNDER_CONSIDERATION]: 0,
    [FN.AGENDA_DETAILS.TOTAL_PAPER_SUBMISSION_BY_INDIA]: "",
    [FN.AGENDA_DETAILS.TOTAL_PAPER_FROM_PAST_INDIA]: "",
  };
};

export const getAgendaMemberRow = () => {
  return {
    [FN.AGENDA_MEMBER_DETAILS.AGENDA_TITLE]: [],
    [FN.AGENDA_MEMBER_DETAILS.NOMINATED_MEMEBERS]: [],
    [FN.AGENDA_MEMBER_DETAILS.HEAD_OF_AGENDA]: [],
  };
};

export const getIssuesRow = () => {
  return [
    {
      [FN.GROUPS.DESCRIPTION]: "",
      [FN.GROUPS.PAPER_UNDER_CONSIDERATION]: "",
      [FN.GROUPS.INDIAS_SUBMISSION]: "",
      [FN.GROUPS.INDIAS_CO_SPONSERED_PAPERS]: "",
      [FN.GROUPS.REVIEW_OF_SUBMISSION]: "",
      [FN.GROUPS.RECOMMENDED_INDIAS_STAND_TO_BE_TAKEN]: "",
      [FN.GROUPS.COMMITTEE_APPROVED_STAND]: "",
      [FN.GROUPS.DECISSION_OF_COMMITTEE]: "",
      [FN.GROUPS.AREAS_OF_DISAGRREMENT_OF_INDIA]: "",
      [FN.GROUPS.ACHIEVEMENT_OF_INDIA]: "",
      [FN.GROUPS.TO_DO_LIST]: "",
    },
  ];
};

export const getFormInitialValues = (data = {}, agendaList = []) => {
  const {
    commitee,
    indian_delegation_members,
    from_meeting_date,
    to_meeting_date,
    nature_of_meeting,
    total_paper_submission,
    total_member_attended,
    total_member_attended_virtually,
    total_paper_under_submission,
    group_set_up,
    post_meeting_agendas,
  } = data;

  const totalPaperUnderSubmission = [];

  // Function to aggregate agendas and papers
  function aggregateAgendasAndPapers(groupsData = []) {
    const agendasMap = new Map();

    // Iterate through each group and its tasks
    groupsData
      .flatMap((group) => group.agendas || [])
      .forEach((agenda) => {
        // If the agenda is not yet in the map, add it
        if (!agendasMap.has(agenda.id)) {
          agendasMap.set(agenda.id, {
            ...agenda,
            papers: [],
          });
        }
      });

    groupsData
      .flatMap((group) =>
        (group.tasks || []).flatMap((task) => task.papers || [])
      )
      .forEach((paper) => {
        totalPaperUnderSubmission.push(paper);
        const agenda = agendasMap.get(paper.agenda.id);
        if (agenda && !agenda.papers.some((p) => p.id === paper.id)) {
          agenda.papers.push(paper);
        }
      });

    // Convert the map back to an array of agendas
    const aggregatedAgendas = Array.from(agendasMap.values());

    return aggregatedAgendas;
  }
  // Call the function and get the output
  const agendasAndPapersConsidered = aggregateAgendasAndPapers(
    commitee?.groups
  );

  const agendaIdToSubmissionData = post_meeting_agendas?.reduce(
    (map, agenda) => {
      map[agenda.agenda_id] = {
        total_paper_submission_by_india:
          agenda.total_paper_submission_by_india || "",
        total_paper_from_past_india: agenda.total_paper_from_past_india || "",
      };
      return map;
    },
    {}
  );

  const agendaDetailsRow = agendasAndPapersConsidered?.length
    ? agendasAndPapersConsidered?.map((agenda) => {
        const submissionData = agendaIdToSubmissionData[agenda.id] || {};
        return {
          [FN.AGENDA_DETAILS.AGENDA_ID]: agenda?.id || "",
          [FN.AGENDA_DETAILS.TITLE]: agenda?.title || "",
          [FN.AGENDA_DETAILS.TOTAL_PAPER_UNDER_CONSIDERATION]:
            agenda.papers.length,
          [FN.AGENDA_DETAILS.TOTAL_PAPER_SUBMISSION_BY_INDIA]:
            submissionData.total_paper_submission_by_india || "",
          [FN.AGENDA_DETAILS.TOTAL_PAPER_FROM_PAST_INDIA]:
            submissionData.total_paper_from_past_india || "",
        };
      })
    : [getAgendaMemberRow()];

  const memberDetailRow = commitee?.groups?.length
    ? commitee.groups?.map((item) => {
        const headOfAgenda = item?.members?.filter((member) => {
          member.full_name = generateFullName(member);
          return member?.group_members?.is_leader === true;
        });

        const nomiatedMembers = item.members?.map((member) => {
          const full_name = generateFullName(member);
          return { full_name, ...member };
        });

        return {
          [FN.AGENDA_MEMBER_DETAILS.AGENDA_TITLE]: item.agendas || [],
          [FN.AGENDA_MEMBER_DETAILS.NOMINATED_MEMEBERS]: nomiatedMembers || [],
          [FN.AGENDA_MEMBER_DETAILS.HEAD_OF_AGENDA]: headOfAgenda || [],
        };
      })
    : [getAgendaMemberRow()];

  const indianDelegations =
    indian_delegation_members?.map((e) => e.member_id) || [];

  const groups = commitee?.groups?.length
    ? commitee?.groups?.map((group) => group)
    : getIssuesRow();

  const taskFields = commitee?.groups?.flatMap((group) => {
    return group?.tasks?.map((task) => {
      return {
        [FN.GROUPS.DETAILS]: task?.details || "",
        [FN.GROUPS.INDIAS_SUBMISSION]: task?.india_submissions || "",
        [FN.GROUPS.INDIAS_CO_SPONSERED_PAPERS]:
          task?.india_cosponsored_papers || "",
        [FN.GROUPS.REVIEW_OF_SUBMISSION]: task?.review_of_submissions || "",
        [FN.GROUPS.RECOMMENDED_INDIAS_STAND_TO_BE_TAKEN]:
          task?.recommended_india_stand || "",
        [FN.GROUPS.COMMITTEE_APPROVED_STAND]:
          task?.committee_approved_stand || "",
        [FN.GROUPS.DECISSION_OF_COMMITTEE]: task?.decision_of_committee || "",
        [FN.GROUPS.AREAS_OF_DISAGRREMENT_OF_INDIA]:
          task?.areas_of_disagreement_of_india || "",
        [FN.GROUPS.ACHIEVEMENT_OF_INDIA]: task?.achievement_of_india || "",
        [FN.GROUPS.PAPERS]: task?.papers || [],
        [FN.GROUPS.TO_DO_LIST]: task?.to_do_list || "",
      };
    });
  });

  return {
    [FN.FROM_MEETING_DATE]: getDateFromValue(from_meeting_date) || "",
    [FN.TO_MEETING_DATE]: getDateFromValue(to_meeting_date) || "",
    [FN.NATURE_OF_MEETING]: nature_of_meeting || "",
    [FN.LIST_OF_INDIAN_DELEGATION]: indianDelegations,
    [FN.TOTAL_PAPER_SUBMISSION]:
      total_paper_submission || totalPaperUnderSubmission.length,
    [FN.TOTAL_MEMBER_ATTENDED]:
      total_member_attended || commitee?.commitee_members?.length,
    [FN.TOTAL_MEMBER_ATTENDED_VIRTUALLY]: total_member_attended_virtually || "",
    [FN.TOTAL_PAPER_UNDER_SUBMISSION]:
      total_paper_under_submission || totalPaperUnderSubmission.length,
    [FN.GROUP_SET_UP]: group_set_up || commitee?.groups?.length,
    [FN.AGENDA_DETAILS.AGENDAS]: agendaDetailsRow || [],
    [FN.AGENDA_MEMBER_DETAILS.AGENDA_MEMBERS]: memberDetailRow || [],

    [FN.GROUPS.GROUP]: groups || [],
    [FN.GROUPS.ISSUE]: taskFields || [],
  };
};

export const generatePayload = (inputData) => {
  const {
    agendas,
    from_meeting_date,
    to_meeting_date,
    group_set_up,
    list_of_indian_delegation,
    nature_of_meeting,
    total_member_attended,
    total_member_attended_virtually,
    total_paper_submission,
    total_paper_under_submission,
    groups,
  } = inputData;

  const agendaListPayload = agendas?.map((agenda, i) => {
    return {
      agenda_id: parseInt(agenda?.agenda_id) ,
      total_paper_from_past_india: agenda?.total_paper_from_past_india.toString() || "",
      total_paper_submission_by_india: agenda?.total_paper_submission_by_india.toString() || "",
      total_paper_under_submission: agenda?.total_paper_under_submission.toString() || "",
      title: agenda?.title?.toString() || "",
    };
  });

  const tasksPayload = [];
  (groups || [])
    ?.filter((group) => group?.tasks)
    ?.flatMap((group) => group?.tasks)
    .forEach((task) => {
      const payload = {
        task_id: task.id,
        india_submissions: task.india_submissions || "",
        india_cosponsored_papers: task.india_cosponsored_papers || "",
        review_of_submissions: task.review_of_submissions || "",
        recommended_india_stand: task.recommended_india_stand || "",
        committee_approved_stand: task.committee_approved_stand || "",
        decision_of_committee: task.decision_of_committee || "",
        areas_of_disagreement_of_india: task.areas_of_disagreement_of_india || "",
        achievement_of_india: task.achievement_of_india || "",
        to_do_list: task.to_do_list || "",
      };
      tasksPayload.push(payload);
    });

  const payload = {
    members: list_of_indian_delegation,
    from_meeting_date: getFormattedDate(from_meeting_date, "YYYY-MM-DD") || [],
    to_meeting_date: getFormattedDate(to_meeting_date, "YYYY-MM-DD") || [],
    nature_of_meeting: nature_of_meeting,
    total_paper_submission: total_paper_submission.toString() || "",
    total_member_attended: total_member_attended.toString() || "",
    total_member_attended_virtually: total_member_attended_virtually.toString() || "",
    total_paper_under_submission: total_paper_under_submission.toString() || "",
    group_set_up: group_set_up.toString() || "",
    agendas: agendaListPayload,
    tasks: tasksPayload,
  };
  return payload;
};

export const Enums = {
  NatureOfMeeting: [
    { nature_of_meeting: "Hybrid", label: "Hybrid" },
    { nature_of_meeting: "InPerson", label: "In Person" },
    { nature_of_meeting: "Virtual", label: "Virtual" },
  ],
};

const taskSchema = Yup.object().shape({
  [FN.GROUPS.DETAILS]: validation.isRequiredString({withRequired:false}),
  [FN.GROUPS.INDIAS_SUBMISSION]: validation.isRequiredString({withRequired:false}),
  [FN.GROUPS.INDIAS_CO_SPONSERED_PAPERS]: validation.isRequiredString({withRequired:false}),
  [FN.GROUPS.REVIEW_OF_SUBMISSION]: validation.isRequiredString({withRequired:false}),
  [FN.GROUPS.RECOMMENDED_INDIAS_STAND_TO_BE_TAKEN]:
    validation.isRequiredString({withRequired:false}),
  [FN.GROUPS.COMMITTEE_APPROVED_STAND]: validation.isRequiredString({withRequired:false}),
  [FN.GROUPS.DECISSION_OF_COMMITTEE]: validation.isRequiredString({withRequired:false}),
  [FN.GROUPS.AREAS_OF_DISAGRREMENT_OF_INDIA]: validation.isRequiredString({withRequired:false}),
  [FN.GROUPS.ACHIEVEMENT_OF_INDIA]: validation.isRequiredString({withRequired:false}),
  [FN.GROUPS.TO_DO_LIST]: validation.isRequiredString({withRequired:false}),
});

export const getReportValidationSchema = (withRequired = true) => {
  return Yup.lazy(() => {
    return Yup.object().shape({
      [FN.FROM_MEETING_DATE]: validation.isRequiredDate(),
      [FN.TO_MEETING_DATE]: validation.isRequiredDate(),
      [FN.NATURE_OF_MEETING]: validation.isRequiredString(),
      [FN.LIST_OF_INDIAN_DELEGATION]: validation.isArrayObject({
        withRequired,
      }),
      [FN.TOTAL_PAPER_SUBMISSION]: validation.isRequiredNumber(),
      [FN.TOTAL_MEMBER_ATTENDED]: validation.isRequiredNumber(),
      [FN.TOTAL_MEMBER_ATTENDED_VIRTUALLY]: validation.isRequiredNumber(),
      [FN.TOTAL_PAPER_UNDER_SUBMISSION]: validation.isRequiredString(),
      [FN.GROUP_SET_UP]: validation.isRequiredNumber(),
      [FN.AGENDA_DETAILS.AGENDAS]: Yup.array().of(
        Yup.object().shape({
          [FN.AGENDA_DETAILS.TOTAL_PAPER_SUBMISSION_BY_INDIA]:
            validation.isRequiredNumber(),
          [FN.AGENDA_DETAILS.TOTAL_PAPER_FROM_PAST_INDIA]:
            validation.isRequiredNumber(),
        })
      ),
      [FN.GROUPS.GROUP]: Yup.array().of(
        Yup.object().shape({
          [FN.GROUPS.ISSUE]: Yup.array().of(taskSchema),
        })
      ),
    });
  });
};
