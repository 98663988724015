import React from "react";

import cx from "../../utils/class-names";
import CheckboxInnerIcon from "../vectors/checkbox-inner-icon";
import CheckboxOuterIcon from "../vectors/checkbox-outer-icon-2";
import PropTypes from "prop-types";
import { useField, useFormikContext } from "formik";
import FormError from "../form-error";

function useCheckboxClasses(props) {
  const { classNames, checked, readOnly, disabled } = props;
  const rootClasses = cx(
    "checkbox inline-flex items-center content-center relative box-border bg-transparent outline-0 border-0 m-0 cursor-pointer select-none align-middle appearance-none p-1.5 rounded-[50%] ",
    classNames.root
  );
  let inputClasses = cx(
    "cursor-[inherit] absolute opacity-0 w-full h-full top-0 left-0 m-0 p-0 z-[1] bottom-0",
    classNames.input
  );
  const iconWrapperClasses = cx(
    "checkbox-input relative flex",
    classNames.iconWrapper
  );
  const iconOuterClasses = cx(
    "checkbox-outer-icon select-none w-[10px] h-[10px] inline-block  shrink-0 text-[1.5rem] scale-100",
    (readOnly || disabled) && "opacity-50",
    classNames.iconOuter
  );
  const iconInnerClasses = cx(
    "checkbox-inner-icon select-none w-[10px] h-[10px] inline-block  shrink-0 text-[1.5rem] scale-100 absolute left-0",
    classNames.iconInner,
    (readOnly || disabled) && "opacity-50",
    checked ? cx("scale-100 text-black-900", classNames.checked) : "scale-0"
  );
  return {
    rootClasses,
    inputClasses,
    iconWrapperClasses,
    iconInnerClasses,
    iconOuterClasses,
  };
}
const Checkbox = React.forwardRef((props, ref) => {
  const {
    checked,
    value,
    onChange,
    classNames,
    readOnly,
    disabled,
    ...restProps
  } = props;

  const {
    rootClasses,
    inputClasses,
    iconWrapperClasses,
    iconInnerClasses,
    iconOuterClasses,
  } = useCheckboxClasses({
    classNames,
    checked,
    readOnly,
    disabled,
  });

  const handleOnChange = (e) => {
    !(readOnly || disabled) && onChange && onChange(value, e);
  };

  return (
    <span ref={ref} className={rootClasses} tabIndex="0">
      <span className={iconWrapperClasses}>
        <input
          type="checkbox"
          className={inputClasses}
          checked={checked}
          onChange={handleOnChange}
          tabIndex={-1}
          readOnly={readOnly}
          disabled={disabled}
          {...restProps}
        />
        <CheckboxOuterIcon className={iconOuterClasses} />
        <CheckboxInnerIcon className={iconInnerClasses} />
      </span>
    </span>
  );
});
export function FormikCheckbox(props) {
  const { name, classNames = {}, onChange } = props;
  const [field, meta, { setValue }] = useField(name);
  const { value } = field;

  const { submitCount } = useFormikContext();
  const isError = submitCount > 0 && !!meta.error;
  const helperText = (submitCount > 0 && meta.error) || "";

  const handleChange = (value) => {
    setValue(!value);
    onChange && onChange(value);
  };
  return (
    <>
      <Checkbox
        {...field}
        classNames={classNames}
        {...props}
        checked={value}
        onChange={handleChange}
      />
      <FormError
        show={isError}
        message={helperText}
        className={classNames.error}
      />
    </>
  );
}

Checkbox.defaultProps = {
  checked: false,
  classNames: {
    root: "",
    input: "",
    iconWrapper: "",
    iconInner: "",
    iconOuter: "",
    checked: "",
  },
  disabled: false,
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  classNames: PropTypes.shape({
    root: PropTypes.string,
    input: PropTypes.string,
    iconWrapper: PropTypes.string,
    iconInner: PropTypes.string,
    iconOuter: PropTypes.string,
    checked: PropTypes.string,
  }),
  disabled: PropTypes.bool,
};

export default Checkbox;
