import Button from "../../../components/button";
import ReviewCard from "../../../components/review-card";
import cx from "../../../utils/class-names";
import SearchInput from "../../../components/search-input";
import CloseIcon from "../../../components/vectors/close-icon";
import { useManageAgendaPageContext } from "../provider";
import { FormikSelect } from "../../../components/select";
import constants from "../../../constants";
import { Form, Formik } from "formik";
import FormControl from "../../../components/form-control";

export default function HeaderSection() {
  const {
    handleOpenAddAgendaDrawer,
    isAdmin,
    searchText,
    handleSearchChange,
    handleEnterSearch,
    handleClearSearchFilter,
    getMeetingDetailsQuery,
    handleMeetingChange,
  } = useManageAgendaPageContext();
  return (
    <ReviewCard
      className={cx(
        "p-2 mb-0 shadow-none"
      )}
    >
      <Formik
        initialValues={{}}
      >
        {(formik) => {
          return (
            <Form className="flex flex-wrap gap-2 p-2 justify-between items-center w-full">
              <div className="mt-2 mb-2">
                <SearchInput
                  name="search"
                  value={searchText}
                  onChange={(e) => handleSearchChange(e)}
                  classNames={{ root: "min-w-[350px]", input: "w-[350px]" }}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") handleEnterSearch(event.target.value);
                  }}
                  placeholder="Search in List"
                  iconRight={
                    searchText !== "" ? (
                      <div className="cursor-pointer" onClick={handleClearSearchFilter}>
                        <CloseIcon className="w-3 h-3" />
                      </div>
                    ) : null
                  }
                />
              </div>
              <div className="flex items-center gap-x-2">
                <FormControl className="flex-1 m-0">
                  <FormikSelect
                    data={getMeetingDetailsQuery?.data || []}
                    id="meeting"
                    name="meeting"
                    valueString="id"
                    lableString="name"
                    onChange={handleMeetingChange}
                    placeholder="Select Meeting"
                    virtualize={getMeetingDetailsQuery?.data?.length >= constants.VIRTUALIZED_ENABLE_LIMIT}
                    passValueInt={true}
                    classNames={{
                      root: "grid-cols-2 gap-x-4 mb-1 min-w-[300px]",
                      input: "ring-black-100 ring-[0.5px] truncate pr-16 ",
                    }}
                  />
                </FormControl>
                {isAdmin && (
                  <Button
                    variant="solid"
                    color="primary"
                    className="h-10"
                    icon={"+"}
                    onClick={handleOpenAddAgendaDrawer}
                  >
                    Add Agenda
                  </Button>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>

    </ReviewCard>
  );
}
