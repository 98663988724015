import { useState, useMemo, useCallback, useRef, useEffect } from "react";

import useKeyPress from "../../hooks/use-key-press";
import usePrevious from "../../hooks/use-previous";

import { scrollIntoView } from "../../utils/scroll-into-view";
import is from "../../utils/is";

export default function useTypeAheadInput(props) {
  const {
    openOnFocus = false,
    data = [],
    value = "",
    lableString,
    valueString,
    onChange,
    closeOnSelect = true,
    filter = [],
    onFocus,
    onBlur,
    onSearch,
    multiSelect,
    readOnlyInput,
    addNew,
    onAddNewClick,
    searchOnFocus,
    passValueInt,
  } = props;
  const inputRef = useRef(null);
  const isinputvalueChangedRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const prevIsOpen = usePrevious(isOpen);
  const [showReadonlyInput, setShowReadonlyInput] = useState(true);
  const [inputValue, setInputValue] = useState("");

  const selectedOption = multiSelect
    ? data?.filter?.((dataItem) => {
        let optionValue = valueString ? dataItem?.[valueString] : dataItem;
        return value.includes(optionValue);
      })
    : data?.find?.((dataItem) => {
        let optionValue = valueString ? dataItem?.[valueString] : dataItem;
        return String(value).trim() === String(optionValue).trim();
      });
  const filteredData = useMemo(() => {
    if (!inputValue) {
      return data;
    }
    if (!filter) {
      return data;
    }
    if (!isinputvalueChangedRef.current) {
      return data;
    }
    if (is.array(filter)) {
      let _filter = [];
      if (!filter.length) {
        _filter = [lableString];
      } else {
        _filter = filter;
      }
      return data.filter((dataItem) => {
        let itemsToCompare = _filter.reduce((prev, current) => {
          let _value = dataItem?.[current] || "";
          _value = _value?.trim?.()?.toLowerCase?.();
          if (_value) {
            return [...prev, _value];
          }
          return prev;
        }, []);
        let valueToCompare = multiSelect ? inputValue : inputValue?.trim?.()?.toLowerCase?.();
        return itemsToCompare.filter((item) => item.includes(valueToCompare)).length;
      });
    } else if (is.function(filter)) {
      return data.filter((dataItem) => filter({ value: inputValue, dataItem, data }));
    }
    return data;
  }, [data, filter, inputValue, lableString, multiSelect]);

  const lastSelectedValueRef = useRef();

  const [focusedOptionIndex, setfocusedOptionIndex] = useState(0);
  const containerRef = useRef();
  const menuListRef = useRef();
  const virtualizeListRef = useRef();
  const focusedOptionRef = useRef();

  const handleFocus = (e) => {
    setShowReadonlyInput(false);
    inputRef?.current?.focus?.();
    let _value = selectedOption?.[lableString] || value || "";
    !readOnlyInput && !multiSelect && setInputValue(_value);
    if (openOnFocus) {
      setIsOpen(true);
      if (searchOnFocus) {
        onSearch && onSearch(_value);
      }
    }
    if (!lastSelectedValueRef.current) {
      lastSelectedValueRef.current = _value;
    }
    onFocus && onFocus(e);
  };
  const handleBlur = (e) => {
    onBlur && onBlur(e);
    setShowReadonlyInput(true);
  };
  const handleClear = () => {
    if (multiSelect) {
      setInputValue([]);
      onChange && onChange([]);
    } else {
      setInputValue("");
      onChange && onChange("");
    }
    onSearch && onSearch("");
  };

  const handleInputChange = (e) => {
    isinputvalueChangedRef.current = true;
    setInputValue(e.target.value);
    onSearch && onSearch(e.target.value);
    if (e.target.value === "") {
      handleClear();
    }
  };

  const handleOnSelect = useCallback(
    (currentValue, label) => (e) => {
      if (multiSelect) {
        let updatedValue = value;
        let optionValue = null;
        if (passValueInt) {
          optionValue = parseInt(currentValue?.[valueString]) || parseInt(currentValue);
        } else {
          optionValue = currentValue?.[valueString] || currentValue;
        }
        if (updatedValue.includes(optionValue)) {
          updatedValue = value && value?.filter((value) => value !== optionValue);
        } else {
          updatedValue = [...value, optionValue];
        }
        onChange && onChange(updatedValue);
      } else {
        onChange && onChange(currentValue);
        if (closeOnSelect) {
          setInputValue("");
          setIsOpen(false);
        } else {
          setInputValue(label);
        }
      }
      lastSelectedValueRef.current = label;
      isinputvalueChangedRef.current = false;
    },
    [closeOnSelect, multiSelect, onChange, passValueInt, value, valueString]
  );

  const handleAddNewClick = () => {
    if (addNew && onAddNewClick) {
      onAddNewClick(inputValue);
    }
    onChange && onChange(inputValue);
    setInputValue("");
    setIsOpen(false);
  };

  const handleOutSideClick = () => {
    if (value && data.length === 0 && lastSelectedValueRef.current) {
      onSearch && onSearch(lastSelectedValueRef.current);
    }
    setInputValue("");
    setIsOpen(false);
  };

  const handleSetIsOpen = () => {
    setIsOpen((value) => !value);
    let inputEle = inputRef?.current;
    let activeEle = document.activeElement;
    if (inputEle !== activeEle) {
      inputRef?.current?.focus?.();
    }
  };

  const handleEscapeClick = () => {
    if (value && data.length === 0 && lastSelectedValueRef.current) {
      onSearch && onSearch(lastSelectedValueRef.current);
    }
    setInputValue("");
    setIsOpen(false);
  };

  useKeyPress(
    containerRef?.current,
    ["ArrowDown"],
    useCallback(
      (event) => {
        event.preventDefault();
        event.stopPropagation();
        setfocusedOptionIndex((p) => Math.min(p + 1, filteredData.length - 1));
        return;
      },
      [filteredData.length]
    )
  );
  useKeyPress(
    containerRef?.current,
    "ArrowUp",
    useCallback((event) => {
      event.preventDefault();
      event.stopPropagation();
      setfocusedOptionIndex((p) => Math.max(p - 1, 0));
      return;
    }, [])
  );
  useKeyPress(
    containerRef?.current,
    "Enter",
    useCallback(
      (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (isOpen) {
          let dataItem = filteredData.find((_, index) => index === focusedOptionIndex);
          if (dataItem) {
            let optionLabel = valueString ? dataItem?.[lableString] : dataItem;
            let optionValue = valueString ? dataItem?.[valueString] : dataItem;
            handleOnSelect(optionValue, optionLabel, dataItem)(event);
          }
        } else {
          setIsOpen(true);
        }
      },
      [filteredData, focusedOptionIndex, handleOnSelect, isOpen, lableString, valueString]
    )
  );

  useEffect(() => {
    if (focusedOptionIndex && menuListRef.current && focusedOptionRef.current) {
      scrollIntoView(menuListRef.current, focusedOptionRef.current);
    }
  }, [focusedOptionIndex]);

  useEffect(() => {
    if (prevIsOpen !== isOpen && isOpen) {
      let selectedOptionIndex = filteredData.findIndex((val, index) => {
        let optionValue = valueString ? val?.[valueString] : val;
        return optionValue === parseInt(value);
      });
      setfocusedOptionIndex(selectedOptionIndex || 0);
      if (virtualizeListRef?.current?.scrollToRow) {
        virtualizeListRef.current.scrollToRow(focusedOptionIndex);
      }
    }
  }, [filteredData, focusedOptionIndex, isOpen, prevIsOpen, value, valueString]);

  return {
    isOpen,
    setIsOpen,
    filteredData,
    focusedOptionIndex,
    setfocusedOptionIndex,
    containerRef,
    menuListRef,
    focusedOptionRef,
    handleFocus,
    handleOnSelect,
    inputRef,
    showReadonlyInput,
    inputValue,
    handleInputChange,
    handleBlur,
    handleOutSideClick,
    handleEscapeClick,
    handleAddNewClick,
    selectedOption,
    handleClear,
    handleSetIsOpen,
    virtualizeListRef,
  };
}
