import Button from "../../../components/button";
import ReviewCard from "../../../components/review-card";
import cx from "../../../utils/class-names";

export default function HeaderSection(props) {
  const { RegulationCount, handleAddNewRegulations } = props;
  return (
    <ReviewCard
      className={cx(
        "p-2 mb-0 shadow-none flex justify-between items-center tab-sm:flex tab-xs:flex-wrap"
      )}
    >
      <div className="flex">
        <div className="font-semibold text-primary-text mr-2">Regulations</div>
        {!!RegulationCount ?
          <div className="font-semibold text-primary-text">
            ({RegulationCount})
          </div>
          : null}
      </div>
      <Button
        variant="solid"
        color="primary"
        className="h-10 text-sm"
        onClick={handleAddNewRegulations}
      >
        + Add New
      </Button>
    </ReviewCard>
  );
}
