import { forwardRef } from "react";

import { useField, useFormikContext } from "formik";
import TextareaAutosize from "react-textarea-autosize";
import cx from "../../utils/class-names";
import FormError from "../form-error";
import PropTypes from "prop-types";

export const Textarea = forwardRef((props, ref) => {
  const { className, error, readOnly, disabled, ...restProps } = props;
  return (
    <TextareaAutosize
      ref={ref}
      readOnly={readOnly}
      disabled={disabled}
      className={cx(
        "textarea border-0 outline-0 ring-1 ring-secondary-400 w-full border-secondary-200 bg-secondary-0 text-xs font-medium text-primary-text px-[10px] py-[6px] rounded-[5px] resize-none",
        className,
        (readOnly || disabled) && "bg-secondary-250"
      )}
      {...restProps}
    />
  );
});

export function FormikTextarea(props) {
  const { name, classNames = {} } = props;
  const [field, meta] = useField(name);

  const { submitCount } = useFormikContext();
  const isError = submitCount > 0 && !!meta.error;
  const helperText = (submitCount > 0 && meta.error) || "";

  return (
    <>
      <Textarea error={isError} {...field} {...props} />
      <FormError show={isError} message={helperText} className={classNames.error} />
    </>
  );
}

Textarea.defaultProps = {
  className: "",
  disabled: false,
  cacheMeasurements: false,
};

Textarea.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  onHeightChange: PropTypes.func,
  cacheMeasurements: PropTypes.bool,
};

export default Textarea;
