import React from "react";
import FormControl from "../../../components/form-control";
import InputLabel from "../../../components/input-label";
import { FormikTextarea } from "../../../components/textarea";
import { FormikSelect } from "../../../components/select";
import { SelectedOptions } from "../../../components/selected-option.js";
import Accordion from "../../../components/accordion";
import cx from "../../../utils/class-names";
import ArrowIcon from "../../../components/vectors/arrow-icon";
import { FN } from "./form-helper";
import { useCreatePostMeetingPageContext } from "../provider";
import constants from "../../../constants";

const Group = (props) => {
  const { initialValues } =
    useCreatePostMeetingPageContext();
  const { itemIndex, group, isDisabledTask } = props;
  const firstOpen = itemIndex === 0 && true;

  return (
    <section className="mx-2 mb-2">
      <div className="flex items-center bg-primary-text gap-2 mx-2">
        <p className="font-poppins text-xs font-medium leading-4 tracking-normal text-left mx-2 text-white p-2 rounded mb-0">
          {initialValues[FN.GROUPS.GROUP][itemIndex][FN.GROUPS.NAME]}
        </p>
      </div>
      {group?.tasks?.map((tasks, taskIndex) => {
        const papers = tasks?.papers;

        return (
          <Accordion
            key={taskIndex}
            defaultValue={firstOpen}
            className="w-full"
          >
            {({ expanded }) => {
              return (
                <>
                  <Accordion.Header
                    className={cx(
                      "flex items-center bg-blue-800 p-2 border border-primary-button rounded overflow-hidden m-2 mb-[-9px] mt-0"
                    )}
                  >
                    <p className="font-poppins text-xs font-medium leading-4 tracking-normal text-left w-[100%]">
                      {`Task ${taskIndex + 1} - ${tasks?.details}`}
                    </p>
                    <span className="w-[2%]">
                      <ArrowIcon
                        className={cx(
                          "h-2 w-3 text-primary-brand",
                          expanded && "rotate-180"
                        )}
                      />
                    </span>
                  </Accordion.Header>
                  <Accordion.Content
                    className={cx(
                      "overflow-hidden h-0 m-2 pr-3 pl-3  border border-primary-button rounded-md",
                      expanded && "h-auto"
                    )}
                  >
                    <FormControl>
                      <InputLabel
                        className="flex-1 text-black-100 mt-2"
                        htmlFor={`${FN.GROUPS.GROUP}[${itemIndex}].${FN.GROUPS.ISSUE}[${taskIndex}].${FN.GROUPS.DETAILS}`}
                      >
                        Background
                      </InputLabel>
                      <FormikTextarea
                        id={`${FN.GROUPS.GROUP}[${itemIndex}].${FN.GROUPS.ISSUE}[${taskIndex}].${FN.GROUPS.DETAILS}`}
                        name={`${FN.GROUPS.GROUP}[${itemIndex}].${FN.GROUPS.ISSUE}[${taskIndex}].${FN.GROUPS.DETAILS}`}
                        minRows={3}
                        disabled={isDisabledTask}
                        className="ring-black-50"
                      />
                    </FormControl>
                    <FormControl>
                      <InputLabel
                        className="flex-1 text-black-100"
                        htmlFor={`${FN.GROUPS.GROUP}[${itemIndex}].${FN.GROUPS.ISSUE}[${taskIndex}].${FN.GROUPS.PAPERS}`}
                      >
                        Paper under consideration
                      </InputLabel>
                      <FormikSelect
                        data={papers}
                        id={`${FN.GROUPS.GROUP}[${itemIndex}].${FN.GROUPS.ISSUE}[${taskIndex}].${FN.GROUPS.PAPERS}`}
                        name={`${FN.GROUPS.GROUP}[${itemIndex}].${FN.GROUPS.ISSUE}[${taskIndex}].${FN.GROUPS.PAPERS}`}
                        lableString="description"
                        valueString="id"
                        placeholder="Select"
                        multiSelect
                        passValueInt={true}
                        showSelectedLength={true}
                        virtualize={papers?.length >= constants.VIRTUALIZED_ENABLE_LIMIT}
                        classNames={{
                          root: "grid-cols-2 gap-x-4 mb-1 hidden",
                          input: "ring-black-50 h-8 hidden",
                        }}
                        disabled={isDisabledTask}
                      />
                    </FormControl>
                    <div className="flex gap-2.5 flex-wrap">
                      {papers?.map((member) => (
                        <SelectedOptions
                          key={member?.id}
                          label={member?.description}
                          classNames={{
                            icon: "ml-2.5 hidden",
                            label: "max-w-[100%]",
                          }}
                        />
                      ))}
                    </div>
                    <FormControl>
                      <InputLabel
                        className="flex-1 text-black-100"
                        htmlFor={`${FN.GROUPS.GROUP}[${itemIndex}].${FN.GROUPS.ISSUE}[${taskIndex}].${FN.GROUPS.INDIAS_SUBMISSION}`}
                      >
                        India’s Submissions
                      </InputLabel>
                      <FormikTextarea
                        id={`${FN.GROUPS.GROUP}[${itemIndex}].${FN.GROUPS.ISSUE}[${taskIndex}].${FN.GROUPS.INDIAS_SUBMISSION}`}
                        name={`${FN.GROUPS.GROUP}[${itemIndex}].${FN.GROUPS.ISSUE}[${taskIndex}].${FN.GROUPS.INDIAS_SUBMISSION}`}
                        minRows={3}
                        className="ring-black-50"
                        disabled={isDisabledTask}
                      />
                    </FormControl>
                    <FormControl>
                      <InputLabel
                        className="flex-1 text-black-100"
                        htmlFor={`${FN.GROUPS.GROUP}[${itemIndex}].${FN.GROUPS.ISSUE}[${taskIndex}].${FN.GROUPS.INDIAS_CO_SPONSERED_PAPERS}`}
                      >
                        India’s co-sponsored papers
                      </InputLabel>
                      <FormikTextarea
                        id={`${FN.GROUPS.GROUP}[${itemIndex}].${FN.GROUPS.ISSUE}[${taskIndex}].${FN.GROUPS.INDIAS_CO_SPONSERED_PAPERS}`}
                        name={`${FN.GROUPS.GROUP}[${itemIndex}].${FN.GROUPS.ISSUE}[${taskIndex}].${FN.GROUPS.INDIAS_CO_SPONSERED_PAPERS}`}
                        minRows={3}
                        className="ring-black-50"
                        disabled={isDisabledTask}
                      />
                    </FormControl>
                    <FormControl>
                      <InputLabel
                        className="flex-1 text-black-100"
                        htmlFor={`${FN.GROUPS.GROUP}[${itemIndex}].${FN.GROUPS.ISSUE}[${taskIndex}].${FN.GROUPS.REVIEW_OF_SUBMISSION}`}
                      >
                        Review of submissions
                      </InputLabel>
                      <FormikTextarea
                        id={`${FN.GROUPS.GROUP}[${itemIndex}].${FN.GROUPS.ISSUE}[${taskIndex}].${FN.GROUPS.REVIEW_OF_SUBMISSION}`}
                        name={`${FN.GROUPS.GROUP}[${itemIndex}].${FN.GROUPS.ISSUE}[${taskIndex}].${FN.GROUPS.REVIEW_OF_SUBMISSION}`}
                        minRows={3}
                        className="ring-black-50"
                        disabled={isDisabledTask}
                      />
                    </FormControl>

                    <FormControl>
                      <InputLabel
                        className="flex-1 text-black-100"
                        htmlFor={`${FN.GROUPS.GROUP}[${itemIndex}].${FN.GROUPS.ISSUE}[${taskIndex}].${FN.GROUPS.RECOMMENDED_INDIAS_STAND_TO_BE_TAKEN}`}
                      >
                        Recommended India’s stand to be taken
                      </InputLabel>
                      <FormikTextarea
                        id={`${FN.GROUPS.GROUP}[${itemIndex}].${FN.GROUPS.ISSUE}[${taskIndex}].${FN.GROUPS.RECOMMENDED_INDIAS_STAND_TO_BE_TAKEN}`}
                        name={`${FN.GROUPS.GROUP}[${itemIndex}].${FN.GROUPS.ISSUE}[${taskIndex}].${FN.GROUPS.RECOMMENDED_INDIAS_STAND_TO_BE_TAKEN}`}
                        minRows={3}
                        className="ring-black-50"
                        disabled={isDisabledTask}
                      />
                    </FormControl>

                    <FormControl>
                      <InputLabel
                        className="flex-1 text-black-100"
                        htmlFor={`${FN.GROUPS.GROUP}[${itemIndex}].${FN.GROUPS.ISSUE}[${taskIndex}].${FN.GROUPS.COMMITTEE_APPROVED_STAND}`}
                      >
                        Committee approved stand
                      </InputLabel>
                      <FormikTextarea
                        id={`${FN.GROUPS.GROUP}[${itemIndex}].${FN.GROUPS.ISSUE}[${taskIndex}].${FN.GROUPS.COMMITTEE_APPROVED_STAND}`}
                        name={`${FN.GROUPS.GROUP}[${itemIndex}].${FN.GROUPS.ISSUE}[${taskIndex}].${FN.GROUPS.COMMITTEE_APPROVED_STAND}`}
                        minRows={3}
                        className="ring-black-50"
                        disabled={isDisabledTask}
                      />
                    </FormControl>

                    <FormControl>
                      <InputLabel
                        className="flex-1 text-black-100"
                        htmlFor={`${FN.GROUPS.GROUP}[${itemIndex}].${FN.GROUPS.ISSUE}[${taskIndex}].${FN.GROUPS.DECISSION_OF_COMMITTEE}`}
                      >
                        Decision of committee
                      </InputLabel>
                      <FormikTextarea
                        id={`${FN.GROUPS.GROUP}[${itemIndex}].${FN.GROUPS.ISSUE}[${taskIndex}].${FN.GROUPS.DECISSION_OF_COMMITTEE}`}
                        name={`${FN.GROUPS.GROUP}[${itemIndex}].${FN.GROUPS.ISSUE}[${taskIndex}].${FN.GROUPS.DECISSION_OF_COMMITTEE}`}
                        minRows={3}
                        className="ring-black-50"
                        disabled={isDisabledTask}
                      />
                    </FormControl>

                    <FormControl>
                      <InputLabel
                        className="flex-1 text-black-100"
                        htmlFor={`${FN.GROUPS.GROUP}[${itemIndex}].${FN.GROUPS.ISSUE}[${taskIndex}].${FN.GROUPS.AREAS_OF_DISAGRREMENT_OF_INDIA}`}
                      >
                        Areas of dis-agreement of India by decision of committee
                      </InputLabel>
                      <FormikTextarea
                        id={`${FN.GROUPS.GROUP}[${itemIndex}].${FN.GROUPS.ISSUE}[${taskIndex}].${FN.GROUPS.AREAS_OF_DISAGRREMENT_OF_INDIA}`}
                        name={`${FN.GROUPS.GROUP}[${itemIndex}].${FN.GROUPS.ISSUE}[${taskIndex}].${FN.GROUPS.AREAS_OF_DISAGRREMENT_OF_INDIA}`}
                        minRows={3}
                        className="ring-black-50"
                        disabled={isDisabledTask}
                      />
                    </FormControl>

                    <FormControl>
                      <InputLabel
                        className="flex-1 text-black-100"
                        htmlFor={`${FN.GROUPS.GROUP}[${itemIndex}].${FN.GROUPS.ISSUE}[${taskIndex}].${FN.GROUPS.ACHIEVEMENT_OF_INDIA}`}
                      >
                        Achievement of India under this issue
                      </InputLabel>
                      <FormikTextarea
                        id={`${FN.GROUPS.GROUP}[${itemIndex}].${FN.GROUPS.ISSUE}[${taskIndex}].${FN.GROUPS.ACHIEVEMENT_OF_INDIA}`}
                        name={`${FN.GROUPS.GROUP}[${itemIndex}].${FN.GROUPS.ISSUE}[${taskIndex}].${FN.GROUPS.ACHIEVEMENT_OF_INDIA}`}
                        minRows={3}
                        className="ring-black-50"
                        disabled={isDisabledTask}
                      />
                    </FormControl>

                    <FormControl>
                      <InputLabel
                        className="flex-1 text-black-100"
                        htmlFor={`${FN.GROUPS.GROUP}[${itemIndex}].${FN.GROUPS.ISSUE}[${taskIndex}].${FN.GROUPS.TO_DO_LIST}`}
                      >
                        To do list
                      </InputLabel>
                      <FormikTextarea
                        id={`${FN.GROUPS.GROUP}[${itemIndex}].${FN.GROUPS.ISSUE}[${taskIndex}].${FN.GROUPS.TO_DO_LIST}`}
                        name={`${FN.GROUPS.GROUP}[${itemIndex}].${FN.GROUPS.ISSUE}[${taskIndex}].${FN.GROUPS.TO_DO_LIST}`}
                        minRows={3}
                        className="ring-black-50"
                        disabled={isDisabledTask}
                      />
                    </FormControl>
                  </Accordion.Content>
                </>
              );
            }}
          </Accordion>
        );
      })}
    </section>
  );
};

export default Group;
