import { Form, Formik } from "formik";
import Button from "../../../../components/button";
import FormControl from "../../../../components/form-control";
import InputLabel from "../../../../components/input-label";
import { FormikSelect } from "../../../../components/select";
import {
  Enums,
  FN,
  generateInitialValues,
  generatePayload,
  generateValidationSchema,
} from "./form-helper";
import { FormikRichTextEditor } from "../../../../components/rich-text-editor";
import { useEffect, useMemo } from "react";
import DocumentUploader from "../../../../components/document-uploder";
import { useGroupFeedbackPageContext } from "../../provider";
import constants from "../../../../constants";

function FeedbackForm(props) {
  const { onSubmit, onCancel, data = {}, uploadedDocument, isEditMode } = props;
  const { documents, setDocuments, handleEditDeleteDocument, newFeedback } =
    useGroupFeedbackPageContext();
  const intitialValues = useMemo(() => generateInitialValues(data), [data]);
  const validationSchema = useMemo(() => generateValidationSchema({}), []);

  const handleOnSubmit = (formData, formikContext) => {
    const payload = generatePayload(formData);
    const isEditMode = Object.keys(data).length > 0;
    onSubmit &&
      onSubmit({ payload, isEditMode, feedback: data, formikContext });
  };

  const handleCancelClick = (id) => {
    onCancel && onCancel(id);
  };

  useEffect(() => {
    if (isEditMode && uploadedDocument) {
      const documents = uploadedDocument?.filter(
        (document) => document?.group_feedback_id === data?.id
      );
      setDocuments(documents);
    }
  }, [data.id, isEditMode, setDocuments, uploadedDocument]);

  return (
    <div className="bg-blue-800 border mt-2 border-primary-button p-2 rounded">
      <Formik
        initialValues={intitialValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        {(formik) => {
          return (
            <Form className="flex flex-1 flex-col justify-between p-[1px]">
              <div className="flex items-center gap-2.5">
                <FormControl className="flex-1">
                  <FormikSelect
                    data={Enums.feedback}
                    id={FN.RESPONSE_TYPE}
                    name={FN.RESPONSE_TYPE}
                    lableString="label"
                    valueString="value"
                    placeholder="Select Response Type"
                    virtualize={Enums.feedback?.length >= constants.VIRTUALIZED_ENABLE_LIMIT}
                    classNames={{
                      root: "grid-cols-2 gap-x-4 mb-1",
                      input: "ring-black-100 ring-[0.5px] text-black-100",
                    }}
                  />
                </FormControl>
              </div>

              <div className="grid grid-cols-1 bg-grey-100 mb-3 rounded">
                <InputLabel
                  className="text-sm font-semibold text-black-100"
                  htmlFor={`${FN.RESPONSE}`}
                >
                  Add Comment
                </InputLabel>
                <FormikRichTextEditor
                  id={`${FN.RESPONSE}`}
                  name={`${FN.RESPONSE}`}
                  classNames="text-black-100"
                />
              </div>
              <div className="grid grid-cols-1 bg-grey-100 mb-3 rounded">
                <DocumentUploader
                  documents={documents}
                  setDocuments={setDocuments}
                  onDelete={newFeedback ? null : handleEditDeleteDocument}
                />
              </div>
              <div className="flex justify-end w-full mt-auto">
                <Button
                  type="button"
                  variant="outline"
                  color="primary"
                  className="py-2 px-3.5 ml-2 min-w-[100px] bg-white text-xs font-semibold action-auditor-save"
                  onClick={() => handleCancelClick(data?.id)}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="solid"
                  color="primary"
                  className="py-2 px-3.5 ml-2 min-w-[100px] flex items-center justify-center text-xs font-semibold action-auditor-save"
                >
                  Submit
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default FeedbackForm;
