import cx from "../../../utils/class-names";
import {} from "../../../utils/generate-names";

function HeaderSection() {
  return (
    <div
      className={cx(
        "px-2 py-0 shadow-none flex justify-between items-center tab-sm:flex tab-xs:block mt-3"
      )}
    >
      <div className="grid grid-cols-10 space-x-4 w-full items-center mb-3 text-sm bg-primary-text p-2">
        <div className="flex flex-col col-span-1">
          <label className="text-sm font-medium font-sans text-secondary-0 leading-[18px]">
            Paper
          </label>
        </div>
        <div className="flex flex-col col-span-6">
          <label className="text-sm font-medium font-sans text-secondary-0 leading-[18px]">
            Papers Description
          </label>
        </div>
        <div className="flex flex-col col-span-1">
          <label className="text-sm font-medium font-sans text-secondary-0 leading-[18px]">
            Status
          </label>
        </div>
        <div className="flex justify-end col-span-2">
          <label className="text-sm font-medium font-sans text-secondary-0 leading-[18px] mr-3">
            Prepare Feedback
          </label>
        </div>
      </div>
    </div>
  );
}
export default HeaderSection;
