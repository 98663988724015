import * as Yup from "yup";
import * as validation from "../../../utils/validation";

export const FN = {
  AGENDA: "agenda",
  PAPER: "paper",
};

export const getFormInitialValues = (data = {}) => {
  return {
    [FN.AGENDA]: "",
    [FN.PAPER]: "",
  };
};

export const getFormValidationSchema = () => {
  return Yup.object().shape({
    [FN.AGENDA]: validation.isRequiredString({ withRequired: false }),
    [FN.PAPER]: validation.isRequiredString({ withRequired: false }),
  });
};
