import cx from "../../utils/class-names";

const PenGreenIcon = (props) => {
  const { className, ...restProps } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={28}
      fill="none"
      viewBox="0 0 11 11"
      className={cx("text-primary-button", className)}
      {...restProps}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.2}
        d="M1.333 9.667h2.333l6.125-6.125a1.65 1.65 0 1 0-2.333-2.334L1.333 7.333v2.334Z"
      />
    </svg>
  )
}
export default PenGreenIcon