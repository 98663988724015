import EmptyListMessage from "../../../components/empty-list-message";
import List from "../../../components/list";
import ReviewCard from "../../../components/review-card";
import MESSAGES from "../../../constants/messages";
import { useMembersPageContext } from "../provider";
import MemberItem from "./member-item";
import { MembersSkeleton } from "./members-skeleton";

export const Members = (props) => {
  const { getMembersQuery, loadingRowCount, isHasMorePages, handleLoadMore } =
    useMembersPageContext();
  const RenderRequestRow = (props) => {
    const { row, index, style, rowCount } = props;
    return (
      <MemberItem
        row={row}
        index={index}
        key={row.id}
        style={style}
        rowCount={rowCount}
      />
    );
  };
  const RenderSkeletonRow = (props) => {
    const { key, style } = props;
    return (
      <div style={style} key={key}>
        <MembersSkeleton classNames={{ root: "p-2 m-0" }} />
      </div>
    );
  };
  const ShowEmptyMessage = () => {
    return (
      <EmptyListMessage title={MESSAGES.NO_RECORDS_FOUND} className="mt-6" />
    );
  };

  return (
    <ReviewCard className="flex flex-col p-0 mb-4 cursor-default">
      <div className="grid grid-cols-9 space-x-4 w-full items-center mb-3 text-sm bg-primary-text p-2">
        <div className="flex flex-col col-span-2">
          <label className="text-sm font-medium font-sans text-secondary-0 leading-[18px]">
            Name
          </label>
        </div>
        <div className="flex flex-col col-span-2">
          <label className="text-sm font-medium font-sans text-secondary-0 leading-[18px]">
            Email
          </label>
        </div>
        <div className="flex flex-col col-span-2">
          <label className="text-sm font-medium font-sans text-secondary-0 leading-[18px]">
            Representative of
          </label>
        </div>
        <div className="flex flex-col col-span-2">
          <label className="text-sm font-medium font-sans text-secondary-0 leading-[18px]">
            Committee Name
          </label>
        </div>
        <div className="flex flex-col items-end mt-2 col-span-1"></div>
      </div>
      <div className="flex h-[70vh]">
        <List
          rows={getMembersQuery?.data || []}
          renderRow={RenderRequestRow}
          renderSkeletonRow={RenderSkeletonRow}
          isLoading={getMembersQuery?.isPending}
          isLoadMoreDirty={!getMembersQuery?.isIdle}
          loadingRowCount={loadingRowCount}
          renderNoRows={ShowEmptyMessage}
          hasMore={isHasMorePages}
          loadMore={handleLoadMore}
          {...props}
        />
      </div>
    </ReviewCard>
  );
};
