import PdfViewIcon from "../../../components/vectors/pdf-view-icon";
import cx from "../../../utils/class-names";

export default function DocumentCard(props) {
  const {active, title, onClick} = props;
  return (
    <div className={cx("document-card mb-3 p-1", active && "bg-gray-150")} onClick={onClick}>
      <PdfViewIcon className="document-card_img w-full" />
      <div className="document-card_name text-[10px] font-semibold text-center mt-[2px]">{title}</div>
    </div>
  );
}