export default function NotFoundIcon(props) {
  return (
    <svg width={143} height={148} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 153" {...props}>
      <rect x={11.23} y={16.501} width={101.077} height={130.45} rx={5.185} fill="#97B3D7" />
      <rect x={0.864} y={1.815} width={103.668} height={137.36} rx={4.321} fill="#fff" stroke="#97B2D7" strokeWidth={1.728} />
      <path
        d="M48.338 96.241v-.294c.033-3.127.36-5.615.983-7.464.621-1.85 1.505-3.347 2.651-4.493 1.146-1.145 2.52-2.201 4.125-3.167a12.897 12.897 0 0 0 2.602-2.087 9.283 9.283 0 0 0 1.817-2.823c.458-1.064.687-2.242.687-3.535 0-1.605-.376-2.996-1.13-4.174a7.583 7.583 0 0 0-3.019-2.725c-1.26-.639-2.66-.958-4.198-.958a9.4 9.4 0 0 0-3.88.835c-1.243.556-2.282 1.432-3.117 2.627-.835 1.195-1.318 2.758-1.449 4.69h-6.187c.131-2.783.852-5.165 2.16-7.145a13.01 13.01 0 0 1 5.23-4.542c2.177-1.048 4.591-1.571 7.243-1.571 2.88 0 5.385.572 7.512 1.718 2.145 1.146 3.798 2.717 4.96 4.714 1.178 1.997 1.767 4.272 1.767 6.825 0 1.8-.278 3.43-.834 4.886a12.037 12.037 0 0 1-2.357 3.903c-1.015 1.146-2.243 2.16-3.683 3.045-1.44.9-2.594 1.85-3.462 2.848-.867.982-1.497 2.152-1.89 3.51-.393 1.359-.606 3.053-.638 5.082v.295h-5.893Zm3.143 14.534c-1.211 0-2.25-.433-3.118-1.301-.867-.867-1.301-1.907-1.301-3.118 0-1.211.434-2.25 1.301-3.118.867-.867 1.907-1.301 3.118-1.301 1.211 0 2.25.434 3.118 1.301.867.868 1.301 1.907 1.301 3.118 0 .802-.204 1.539-.614 2.21a4.583 4.583 0 0 1-1.595 1.62c-.655.393-1.392.589-2.21.589Z"
        fill="#97B2D7"
      />
      <path d="M20 16.069h65.657M20.432 26.868h65.656M20.432 38.099h65.656" stroke="#97B2D7" strokeWidth={2.593} strokeLinecap="round" />
      <circle cx={110.581} cy={84.749} r={24.621} fill="#fff" stroke="#004676" strokeWidth={2.593} />
      <path d="m115.765 74.383-9.503 20.733M99.947 80.524l21.572 8.374" stroke="#004676" strokeWidth={3.457} strokeLinecap="round" />
      <path d="m121 107.5 20.5 39.5" stroke="#004676" strokeWidth={2.593} />
    </svg>
  );
}
