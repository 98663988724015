import cx from "../../utils/class-names";

export default function HeaderTitle(props) {
  const { children, className, ...restprops } = props;
  return (
    <span
      className={cx(
        "header-title text-xl tab-sm:text-base leading-none font-inter font-medium text-primary-text",
        className
      )}
      {...restprops}
    >
      {children}
    </span>
  );
}
