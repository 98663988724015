import { Form, Formik } from "formik";
import { useCommitteeFeedbackListingPageContext } from "../provider";
import { FN } from "./formhelper";
import FormControl from "../../../components/form-control";
import { FormikSelect } from "../../../components/select";
import constants from "../../../constants";

export const Filter = () => {
  const {
    initialValues,
    handleSubmit,
    mainFormRef,
    getAgendaQuery,
    handleAgendaChange,
    handlePaperChange,
  } = useCommitteeFeedbackListingPageContext();
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {(formik) => {
        mainFormRef.current = formik;
        const selectedAgenda = formik.values[FN.AGENDA] || "";
        const papers = getAgendaQuery?.data?.data
          .filter((item) => item.id === parseInt(selectedAgenda))
          .flatMap((agenda) => agenda.papers || []);
        return (
          <Form className="flex gap-2 p-2">
            <FormControl className="flex-1 m-0">
              <FormikSelect
                data={getAgendaQuery?.data?.data || []}
                id={FN.AGENDA}
                name={FN.AGENDA}
                valueString="id"
                lableString="title"
                onChange={handleAgendaChange}
                placeholder="Select Agenda"
                virtualize={getAgendaQuery?.data?.data?.length >= constants.VIRTUALIZED_ENABLE_LIMIT}
                passValueInt={true}
                classNames={{
                  root: "grid-cols-2 gap-x-4 mb-1",
                  input: "ring-black-100 ring-[0.5px]",
                }}
              />
            </FormControl>
            <FormControl className="flex-1 m-0">
              <FormikSelect
                data={papers}
                id={FN.PAPER}
                name={FN.PAPER}
                lableString="symbol"
                valueString="id"
                placeholder="Select Paper"
                virtualize={papers?.length >= constants.VIRTUALIZED_ENABLE_LIMIT}
                onChange={handlePaperChange}
                classNames={{
                  root: "grid-cols-2 gap-x-4 mb-1",
                  input: "ring-black-100 ring-[0.5px]",
                }}
              />
            </FormControl>
          </Form>
        );
      }}
    </Formik>
  );
};
