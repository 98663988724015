import Label from "../../components/label";
import Const from "../../constants";
import cx from "../../utils/class-names";

function ReviewCardLabel(props) {
  const { label, value, classNames = {} } = props;
  return (
    <div className={cx("review-card_label flex mb-[3px]", classNames.root)}>
      <Label className={cx("font-normal text-xs  text-primary-text font-inter min-w-sm", classNames.label)}>{label}</Label>
      <Label className={cx("font-normal text-xs text-primary-text font-inter mx-2", classNames.dot)}>{Const.DOT_TEXT}</Label>
      <Label className={cx("text-sm font-semibold text-primary-text", classNames.value)}>{value || "-"}</Label>
    </div>
  );
}

export default ReviewCardLabel;
