import Button from "../../../components/button";
import ReviewCard from "../../../components/review-card";
import cx from "../../../utils/class-names";
import { useMembersPageContext } from "../provider";

export default function HeaderSection(props) {
  const { isAdmin } = props;
  const { handleOpenRegisterMemberDrawer, getMembersQuery } =
    useMembersPageContext();
  const totalMembers = getMembersQuery?.paginate?.total;
  return (
    <ReviewCard
      className={cx(
        "p-2 mb-0 shadow-none flex flex-wrap justify-between items-center"
      )}
    >
      <div className="flex">
        <div className="font-semibold text-primary-text mr-2">
          {isAdmin ? "Manage Members" : "Members Details"}
        </div>
        <div className="font-semibold text-primary-text">({totalMembers})</div>
      </div>
      {isAdmin && (
        <Button
          variant="solid"
          color="primary"
          className="h-10"
          onClick={handleOpenRegisterMemberDrawer}
        >
          Register Member
        </Button>
      )}
    </ReviewCard>
  );
}
