import { useEffect } from "react";

import { useWizard } from ".";
import cx from "../../utils/class-names";

export default function WizardNavItem(props) {
  const { children, className, id, activeBgClass, ...restProps } = props;
  const { handleWizardNavItemClick, isActive, registerId } = useWizard();

  const handleCLick = (e) => {
    handleWizardNavItemClick(id);
  };

  useEffect(() => {
    registerId(id);
  }, [id, registerId]);

  return (
    <div
      data-wizard-navitem-id={id}
      className={cx(
        "wizard_nav-item bg-secondary-0 hover:bg-cyan-100 hover:text-primary-brand text-primary-text text-xs font-medium p-[10px] rounded-[5px] cursor-pointer",
        className,
        isActive(id) && !activeBgClass && "bg-cyan-300 text-primary-brand",
        isActive(id) && activeBgClass && activeBgClass
      )}
      onClick={handleCLick}
      {...restProps}
    >
      {children}
    </div>
  );
}
