import Skeleton from "../skeleton";

import cx from "../../utils/class-names";
import ReviewCardSection from "./review-card-section";
import Divider from "../divider";
import ReviewCardLabel from "./review-card-label";

export function ReviewCardSkeleton(props) {
  const { renderThirdColumn, classNames = {} } = props;
  return (
    <ReviewCard
      className={cx(
        "review-card_skeleton hover:shadow-[-2px_2px_5px_1px_#e3e3e3,_2px_-2px_5px_1px_#e3e3e3,_2px_2px_5px_1px_#e3e3e3,_-2px_-2px_5px_1px_#e3e3e3] p-4",
        classNames.root
      )}
    >
      <ReviewCard.Section className={cx("flex flex-col tab-sm:flex-1 pr-2.5 flex-[4_4_0%] justify-between tab-sm:mb-2", classNames.firstContainer)}>
        <div className="flex items-center h-6">
          <Skeleton className="w-16 mr-2" />
          <Skeleton className="w-80 tab-sm-min:w-64" />
        </div>
        <div className="flex items-center mt-2">
          <Skeleton className="w-4 p-3 mr-4" variant="circular" />
          <Skeleton className="w-40" />
        </div>
      </ReviewCard.Section>
      <Divider className="tab-sm:w-full tab-sm:h-[1px] w-[1px] h-auto" />
      <div className={cx("flex tab-sm:flex-1 pt-2.5 tab-sm:pl-0 pl-2.5 tab-sm:justify-between flex-[6_6_0%]", classNames.secondContainer)}>
        <ReviewCard.Section className="flex flex-col justify-center tab-sm:flex-1 flex-[4_4_0%]">
          <Skeleton className={cx("w-[60%] tab-sm:w-[55%] mb-1", renderThirdColumn && "w-[90%]")} />
          <Skeleton className={cx("w-[40%] tab-sm:w-[30%]", renderThirdColumn && "w-[50%]")} />
        </ReviewCard.Section>
        {renderThirdColumn ? (
          <>
            <div className={cx("bg-secondary-400 tab-sm:hidden w-[1px] h-auto", classNames.thirdContainer)} />
            <ReviewCard.Section className="flex flex-col tab-sm:flex-none pl-2.5 w-[265px] tab-sm:w-[126px] tab-sm-min:-mt-0.5 pb-2">
              <Skeleton className="w-[4rem]" />
              <Skeleton className="w-[7rem]" />
            </ReviewCard.Section>
          </>
        ) : null}
      </div>
    </ReviewCard>
  );
}

const ReviewCard = (props) => {
  const { className, children, ...restProps } = props;
  return (
    <div
      className={cx(
        "review-card transition-[shadow] flex tab-sm:block shadow-[0px_2px_8px_#0000000f] mr-7 bg-secondary-0 ml-4 mt-2 tab-sm:ml-4 tab-sm:mr-6 p-4 cursor-default",
        className
      )}
      {...restProps}
    >
      {children}
    </div>
  );
};

ReviewCard.Section = ReviewCardSection;
ReviewCard.Label = ReviewCardLabel;

export default ReviewCard;
