import { useCallback, useMemo } from "react";
import generateContext from "../../utils/generate-context";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { useState } from "react";
import {
  getFormInitialValues,
  getFormValidationSchema,
} from "./components/register-member-drawer/form-helper";
import { useNotificationModalContext } from "../../components/notification-modal/provider";
import MESSAGES from "../../constants/messages";
import { useAsync } from "@react-org/hooks";
import * as MembersApi from "../../apis/members.api";
import Const from "../../constants";

function useMembersPage(props) {
  const navigate = useNavigate();
  const mainFormRef = useRef(null);
  const notificationModal = useNotificationModalContext();
  const [membersInitialValue, setMembersInitialValue] = useState({});

  const [showAction, setShowAction] = useState(null);

  const [isRegisterMemberDrawerOpen, setIsRegisterMemberDrawerOpen] =
    useState(false);

  const registerMemberQuery = useAsync(MembersApi.registerMember, {
    immediate: false,
  });
  const updateMemberQuery = useAsync(MembersApi.updateMember, {
    immediate: false,
  });
  const deleteMemberQuery = useAsync(MembersApi.deleteMember, {
    immediate: false,
  });
  const resetPasswordQuery = useAsync(MembersApi.resetPassword, {
    immediate: false,
  });

  const getMembersQuery = useAsync(
    useCallback((payload) => MembersApi.getMembers({ ...payload }), []),
    {
      initialState: { data: [] },
      infinite: true,
      select: (res) => {
        return { data: res.data.data, paginate: res.data.paging };
      },
      immediate: true,
    }
  );
  const isHasMorePages = useMemo(() => {
    return (
      getMembersQuery?.paginate?.page_no *
        getMembersQuery?.paginate?.page_limit <
      getMembersQuery?.paginate?.total
    );
  }, [
    getMembersQuery?.paginate?.page_limit,
    getMembersQuery?.paginate?.page_no,
    getMembersQuery?.paginate?.total,
  ]);

  const loadingRowCount = useMemo(() => {
    if (getMembersQuery?.isIdle || getMembersQuery?.data?.length === 0) {
      return 5;
    }
    return isHasMorePages
      ? Const.SKELETON_LOADING_ROWS.PAGING
      : Const.SKELETON_LOADING_ROWS.NO_ROWS;
  }, [getMembersQuery?.data?.length, isHasMorePages, getMembersQuery?.isIdle]);

  const handleLoadMore = useCallback(() => {
    if (
      getMembersQuery?.isPending ||
      getMembersQuery?.isIdle ||
      !isHasMorePages
    )
      return;
    let page = (getMembersQuery?.paginate?.page_no || 0) + 1;
    let data = { page: page };
    let getMembersQueryExecute = getMembersQuery?.execute;
    getMembersQueryExecute(data);
  }, [getMembersQuery, isHasMorePages]);

  const initialValues = useMemo(() => {
    return getFormInitialValues(membersInitialValue);
  }, [membersInitialValue]);

  const getValidationSchema = useCallback(() => {
    return getFormValidationSchema();
  }, []);

  const handleMenuClose = useCallback(() => {
    setShowAction(false);
  }, [setShowAction]);

  const handleMenuOpen = useCallback(
    (id) => (e) => {
      e.stopPropagation();
      setShowAction(id);
    },
    [setShowAction]
  );

  const handleEdit = useCallback(
    (id) => (e) => {
      e.stopPropagation();
      const memberDetails = (getMembersQuery?.data || []).filter(
        (item) => item.id === id
      );
      setMembersInitialValue(memberDetails[0]);
      setIsRegisterMemberDrawerOpen(true);
    },
    [getMembersQuery?.data]
  );

  const handleChangePassword = useCallback(
    (id) => (e) => {
      e.stopPropagation();
      notificationModal.warning({
        title: MESSAGES.ARE_YOU_SURE,
        heading: MESSAGES.MEMBER_PASSWORD_RESET,
        onConfirm: () => {
          const resetPasswordQueryExecute = resetPasswordQuery.execute;
          const getMembersQueryExecute = getMembersQuery.execute;
          const getMembersQuerySetData = getMembersQuery.setQueryData;
          notificationModal.progress({
            heading: MESSAGES.INPROGRESS_MESSAGE,
          });
          resetPasswordQueryExecute(
            { id },
            {
              onSuccess: () => {
                getMembersQuerySetData([]);
                getMembersQueryExecute();
                notificationModal.success({
                  heading: MESSAGES.PASSWORD_RESET_SUCCESSFUL,
                });
              },
              onError: () => {
                notificationModal.error({
                  heading: MESSAGES.PASSWORD_RESET_FAILED,
                });
              },
            }
          );
        },
      });
    },
    [
      getMembersQuery.execute,
      getMembersQuery.setQueryData,
      notificationModal,
      resetPasswordQuery,
    ]
  );

  const handleDelete = useCallback(
    (id) => (e) => {
      e.stopPropagation();
      notificationModal.warning({
        title: MESSAGES.ARE_YOU_SURE,
        heading: MESSAGES.MEMBER_WILL_BE_DELETED,
        onConfirm: () => {
          const deleteMemberQueryExecute = deleteMemberQuery.execute;
          const getMembersQueryExecute = getMembersQuery.execute;
          const getMembersQuerySetData = getMembersQuery.setQueryData;
          notificationModal.progress({
            heading: MESSAGES.INPROGRESS_MESSAGE,
          });
          deleteMemberQueryExecute(
            { id },
            {
              onSuccess: () => {
                getMembersQuerySetData([]);
                getMembersQueryExecute();
                notificationModal.success({
                  heading: MESSAGES.MEMBER_DELETED_SUCCESSFULLY,
                });
              },
              onError: () => {
                notificationModal.error({
                  heading: MESSAGES.FAILED_TO_DELETE_MEMBER,
                });
              },
            }
          );
        },
      });
    },
    [
      deleteMemberQuery.execute,
      getMembersQuery.execute,
      getMembersQuery.setQueryData,
      notificationModal,
    ]
  );

  const handleViewDetails = useCallback(
    (tabId) => {
      navigate("/manage-committee-details");
    },
    [navigate]
  );

  const closeRegisterMemberDrawer = useCallback(() => {
    setIsRegisterMemberDrawerOpen(false);
  }, []);

  const handleOpenRegisterMemberDrawer = useCallback(() => {
    setMembersInitialValue({});
    setIsRegisterMemberDrawerOpen(true);
  }, []);

  const handleRegisterMember = useCallback(
    (formData) => {
      let payload = {
        first_name: formData.first_name,
        last_name: formData.last_name,
        contact_no: formData.contact_no,
        email: formData.email,
        address: formData.address,
        representative_of: formData.representative_of,
        organization: formData.organization,
      };
      const registerMemberQueryExecute = registerMemberQuery.execute;
      const getMembersQueryExecute = getMembersQuery.execute;
      const getMembersQuerySetData = getMembersQuery.setQueryData;
      notificationModal.progress({
        heading: MESSAGES.INPROGRESS_MESSAGE,
      });
      registerMemberQueryExecute(payload, {
        onSuccess: () => {
          getMembersQuerySetData([]);
          getMembersQueryExecute();
          notificationModal.success({
            heading: MESSAGES.MEMBER_REGISTERED_SUCCESSFULLY,
          });
          setIsRegisterMemberDrawerOpen(false);
        },
        onError: () => {
          notificationModal.error({
            heading: MESSAGES.FAILED_TO_REGISTER_MEMBER,
          });
        },
      });
    },
    [
      getMembersQuery.execute,
      getMembersQuery.setQueryData,
      notificationModal,
      registerMemberQuery.execute,
    ]
  );
  const handleUpdateMember = useCallback(
    (id) => (formData) => {
      let payload = {
        first_name: formData.first_name,
        last_name: formData.last_name,
        contact_no: formData.contact_no,
        address: formData.address,
        representative_of: formData.representative_of,
        organization: formData.organization,
        id: id,
      };
      const updateMemberQueryExecute = updateMemberQuery.execute;
      const getMembersQueryExecute = getMembersQuery.execute;
      const getMembersQuerySetData = getMembersQuery.setQueryData;
      notificationModal.progress({
        heading: MESSAGES.INPROGRESS_MESSAGE,
      });
      updateMemberQueryExecute(payload, {
        onSuccess: () => {
          getMembersQuerySetData([]);
          getMembersQueryExecute();
          notificationModal.success({
            heading: MESSAGES.MEMBER_UPDATED_SUCCESSFULLY,
          });
          setIsRegisterMemberDrawerOpen(false);
        },
        onError: () => {
          notificationModal.error({
            heading: MESSAGES.FAILED_TO_UPDATE_MEMBER,
          });
        },
      });
    },
    [
      getMembersQuery.execute,
      getMembersQuery.setQueryData,
      notificationModal,
      updateMemberQuery.execute,
    ]
  );

  return useMemo(() => {
    return {
      handleViewDetails,
      mainFormRef,
      isRegisterMemberDrawerOpen,
      handleOpenRegisterMemberDrawer,
      closeRegisterMemberDrawer,
      initialValues,
      getValidationSchema,
      handleRegisterMember,
      getMembersQuery,
      loadingRowCount,
      isHasMorePages,
      handleLoadMore,
      showAction,
      setShowAction,
      handleMenuClose,
      handleMenuOpen,
      handleEdit,
      handleChangePassword,
      handleDelete,
      handleUpdateMember,
    };
  }, [
    closeRegisterMemberDrawer,
    getMembersQuery,
    getValidationSchema,
    handleChangePassword,
    handleDelete,
    handleEdit,
    handleLoadMore,
    handleMenuClose,
    handleMenuOpen,
    handleOpenRegisterMemberDrawer,
    handleRegisterMember,
    handleUpdateMember,
    handleViewDetails,
    initialValues,
    isHasMorePages,
    isRegisterMemberDrawerOpen,
    loadingRowCount,
    showAction,
  ]);
}
export const [MembersPageProvider, useMembersPageContext] =
  generateContext(useMembersPage);
