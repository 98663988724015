import withHOC from "../../utils/with-hoc";
import {
  ReferenceDocumentsListingPageProvider,
  useReferenceDocumentsLisitingPageContext,
} from "./provider";
import { HeaderRight } from "../../layouts/dashboard/header";
import Button from "../../components/button";
import Arrow2Icon from "../../components/vectors/arrow2-icon";
import HeaderTitle from "../../components/header-title";
import Filter from "./components/filters";
import DocRow from "./components/DocRow";
import EmptyListMessage from "../../components/empty-list-message";
import MESSAGES from "../../constants/messages";
import List from "../../components/list";
import ReferenceDocumentsSkeleton from "./components/Reference-documents-skeleton";

const ReferenceDocumentsListingPage = (props) => {
  const {
    goBack,
    getReferenceDocumentsListingQuery,
    loadingRowCount,
    isHasMorePages,
    handleLoadMore,
    listRef,
    handlePreviewReference,
  } = useReferenceDocumentsLisitingPageContext();

  const RenderRequestRow = (props) => {
    const { row, index, style, rowCount } = props;
    return (
      <DocRow
        onClick={handlePreviewReference(row?.id)}
        item={row}
        index={index}
        key={row.id}
        style={style}
        rowCount={rowCount}
      />
    );
  };

  const ShowEmptyMessage = () => {
    return (
      <EmptyListMessage title={MESSAGES.NO_RECORDS_FOUND} className="mt-6" />
    );
  };

  const RenderSkeletonRow = (props) => {
    const { key, style } = props;
    return (
      <div style={style} key={key}>
        <ReferenceDocumentsSkeleton classNames={{ root: "p-2 m-0" }} />
      </div>
    );
  };
  return (
    <>
      <div className="flex flex-1 flex-col">
        <HeaderRight>
          <Button
            variant="outline"
            color="primary"
            className="py-2 px-3.5 mr-2 flex items-center text-xs font-semibold text-primary-button hover:bg-primary-button hover:text-secondary-0"
            icon={<Arrow2Icon className="rotate-180" />}
            onClick={goBack}
          >
            Back
          </Button>
          <HeaderTitle>Reference Documents</HeaderTitle>
        </HeaderRight>
        <Filter />
        <div className="h-[100vh]">
          <List
            ref={listRef}
            rows={getReferenceDocumentsListingQuery.data || []}
            renderRow={RenderRequestRow}
            renderSkeletonRow={RenderSkeletonRow}
            hasMore={isHasMorePages}
            loadMore={handleLoadMore}
            isLoading={getReferenceDocumentsListingQuery?.isPending}
            loadingRowCount={loadingRowCount}
            isLoadMoreDirty={!getReferenceDocumentsListingQuery?.isIdle}
            renderNoRows={ShowEmptyMessage}
          />
        </div>
      </div>
    </>
  );
};

export default withHOC(
  ReferenceDocumentsListingPageProvider,
  ReferenceDocumentsListingPage
);
