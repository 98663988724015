import { withConfig, getApi } from "./index";
const api = getApi("/tasks");

export const getMyTaskDetails = withConfig((data, conf) => {
  const config = conf({
    url: ``,
    method: "GET",
    params: data,
  });
  return api(config);
});

export const getTaskFeedback = withConfig((data, conf) => {
  const { taskId, ...params } = data;
  const config = conf({
    url: `${taskId}/feedback`,
    method: "GET",
    params: params,
  });
  return api(config);
});
export const getStatus = withConfig((data, conf) => {
  const { taskId, ...params } = data;
  const config = conf({
    url: `${taskId}/status`,
    method: "GET",
    params: params,
  });
  return api(config);
});

export const addTaskFeedback = withConfig((data, conf) => {
  const { taskId, ...payload } = data;
  const config = conf({
    url: `${taskId}/feedback`,
    method: "POST",
    data: payload,
  });
  return api(config);
});

export const updateTask = withConfig((data, conf) => {
  const { taskId, ...payload } = data;
  const config = conf({
    url: `${taskId}`,
    method: "PUT",
    data: payload,
  });
  return api(config);
});

export const deleteTask = withConfig((data, conf) => {
  const { taskId, ...params } = data;
  const config = conf({
    url: `${taskId}`,
    method: "DELETE",
    params: params,
  });
  return api(config);
});

export const updateTaskStatus = withConfig((data, conf) => {
  const { taskId, ...payload } = data;
  const config = conf({
    url: `${taskId}/status`,
    method: "PATCH",
    data: payload,
  });
  return api(config);
});

export const getTaskSuggestion = withConfig((data, conf) => {
  const { taskId, ...params } = data;
  const config = conf({
    url: `${taskId}/suggestions`,
    method: "GET",
    params: params,
  });
  return api(config);
});

export const saveTaskSuggestion = withConfig((data, conf) => {
  const { taskId, ...payload } = data;
  const config = conf({
    url: `${taskId}/suggestions`,
    method: "POST",
    data: payload,
  });
  return api(config);
});

export const updateTaskSuggestion = withConfig((data, conf) => {
  const { id, taskId, ...payload } = data;
  const config = conf({
    url: `${taskId}/suggestions/${id}`,
    method: "PUT",
    data: payload,
  });
  return api(config);
});

export const uploadTaskDocuments = withConfig((data, conf) => {
  const { taskId, files } = data;
  const formData = new FormData();
  files.forEach((item) => {
    formData.append("files", item);
  });
  const config = conf({
    url: `${taskId}/documents`,
    method: "POST",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return api(config);
});

export const getTaskDocuments = withConfig((data, conf) => {
  const { taskId, ...params } = data;
  const config = conf({
    url: `${taskId}/documents`,
    method: "GET",
    params: params,
  });
  return api(config);
});
export const deleteTaskDocument = withConfig((data, conf) => {
  const { documentId, ...params } = data;
  const config = conf({
    url: `/documents/${documentId}`,
    method: "DELETE",
    params: params,
  });
  return api(config);
});
export const downloadTaskDocument = withConfig((data, conf) => {
  const { documentId, ...params } = data;
  const config = conf({
    url: `/documents/${documentId}/preview`,
    method: "GET",
    params: params,
    responseType: "blob",
  });
  return api(config);
});