import { HeaderRight } from "../../layouts/dashboard/header";
import withHOC from "../../utils/with-hoc";
import HeaderTitle from "../../components/header-title";
import {
  UpcomingRegulationPageProvider,
  useUpcomingRegulationPageContext,
} from "./provider";
import HeaderSection from "./components/header-section";
import { UpcomingRegulationItem } from "./components/upcoming-regulation-item";
import EmptyListMessage from "../../components/empty-list-message";
import MESSAGES from "../../constants/messages";
import AddRegulationDrawer from "./components/add-regulation-drawer";

function UpcomingRegulationPage() {
  const { handleAddNewRegulations, getUpcomingRegulationsQuery, handleRowEdit, handleDeleteRow } =
    useUpcomingRegulationPageContext();

  const RegulationCount = getUpcomingRegulationsQuery?.data?.length;
  return (
    <>
      <div className="flex flex-1 flex-col overflow-hidden">
        <HeaderRight>
          <HeaderTitle>Upcoming Regulations</HeaderTitle>
        </HeaderRight>
        <HeaderSection
          RegulationCount={RegulationCount}
          handleAddNewRegulations={handleAddNewRegulations}
        />
        <div className="p-3 overflow-scroll">
          {getUpcomingRegulationsQuery?.data?.length ? (
            <table
              className="table-auto w-full h-full"
            >
              <thead>
                <tr className="mb-1 bg-primary-text items-start">
                  <th className="px-2 py-1 text-sm font-medium font-sans text-secondary-0 leading-[18px] text-start">
                    Committee Session
                  </th>
                  <th className="px-2 py-1 text-sm font-medium font-sans text-secondary-0 leading-[18px] text-start">
                    IMO Resolution regarding Approval of amendments
                  </th>
                  <th className="px-2 py-1 text-sm font-medium font-sans text-secondary-0 leading-[18px] text-start">
                    Convention
                  </th>

                  <th className="px-2 py-1 text-sm font-medium font-sans text-secondary-0 leading-[18px] text-start">
                    Entry into Force
                  </th>
                  <th className="px-2 py-1 text-sm font-medium font-sans text-secondary-0 leading-[18px] text-start">
                    Wing/ Branch of DGS to issue Circular / MSN / Order
                  </th>
                  <th className="px-2 py-1 text-sm font-medium font-sans text-secondary-0 leading-[18px] text-start">
                    Target Date
                  </th>
                  <th className="px-2 py-1 text-sm font-medium font-sans text-secondary-0 leading-[18px] text-start">
                    DGS Circular/ MSN / Order Issued
                  </th>
                  <th className="px-2 py-1 text-sm font-medium font-sans text-secondary-0 leading-[18px] text-start">
                    
                  </th>
                </tr>
              </thead>
              <tbody>
                {(getUpcomingRegulationsQuery?.data || []).map(
                  (item, index) => {
                    return <UpcomingRegulationItem row={item} key={index} handleRowEdit={handleRowEdit} handleDeleteRow={handleDeleteRow}/>;
                  }
                )}
              </tbody>
            </table>
          ) : (
            <EmptyListMessage
              title={MESSAGES.NO_RECORDS_FOUND}
              className="mt-6"
            />
          )}
        </div>
        <AddRegulationDrawer />
      </div>
    </>
  );
}

export default withHOC(UpcomingRegulationPageProvider, UpcomingRegulationPage);
