import { getDateFromValue } from "../../../../utils/date-and-time";
import { FN } from "./core";

export const getListOfIndianDelegationRow = () => {
  return {
    [FN.LIST_OF_INDIAN_DELEGATION.NAME]: "",
    [FN.LIST_OF_INDIAN_DELEGATION.ORGANISATION]: "",
  };
};
export const getNominatedMemberRow = () => {
  return {
    [FN.AGENDA_MEMBER_DETAILS.NOMINATED_MEMBERS.NAME]: "",
    [FN.AGENDA_MEMBER_DETAILS.NOMINATED_MEMBERS.ORGANISATION]: "",
  };
};
export const getAgendaDetailsRow = () => {
  return {
    [FN.AGENDA_DETAILS.TITLE]: "",
    [FN.AGENDA_DETAILS.AGENDA_ID]: "",
    [FN.AGENDA_DETAILS.TOTAL_PAPER_UNDER_CONSIDERATION]: "",
    [FN.AGENDA_DETAILS.TOTAL_PAPER_SUBMISSION_BY_INDIA]: "",
    [FN.AGENDA_DETAILS.TOTAL_PAPER_FROM_PAST_INDIA]: "",
  };
};

export const getAgendaMemberRow = () => {
  return {
    [FN.AGENDA_MEMBER_DETAILS.AGENDA_TITLE]: "",
    [FN.AGENDA_MEMBER_DETAILS.NOMINATED_MEMBERS.ROOT]: [
      getNominatedMemberRow(),
    ],
    [FN.AGENDA_MEMBER_DETAILS.TASKS.ROOT]: [getIssuesRow()],
    [FN.AGENDA_MEMBER_DETAILS.HEAD_OF_AGENDA.ROOT]: {
      [FN.AGENDA_MEMBER_DETAILS.HEAD_OF_AGENDA.HEAD_OF_AGENDA_NAME]: "",
      [FN.AGENDA_MEMBER_DETAILS.HEAD_OF_AGENDA.HEAD_OF_AGENDA_ORGANISATION]: "",
    },
  };
};

export const getIssuesRow = () => {
  return {
    [FN.AGENDA_MEMBER_DETAILS.TASKS.BACKGROUND]: "",
    [FN.AGENDA_MEMBER_DETAILS.TASKS.PAPER_UNDER_CONSIDERATION]: "",
    [FN.AGENDA_MEMBER_DETAILS.TASKS.INDIA_SUBMISSION]: "",
    [FN.AGENDA_MEMBER_DETAILS.TASKS.INDIA_COSPONSORED_PAPERS]: "",
    [FN.AGENDA_MEMBER_DETAILS.TASKS.REVIEW_OF_SUBMISSION]: "",
    [FN.AGENDA_MEMBER_DETAILS.TASKS.RECOMMENDED_INDIA_STAND_TO_BE_TAKEN]: "",
    [FN.AGENDA_MEMBER_DETAILS.TASKS.SHADOW_COMMITTEE_APPROVED_STAND]: "",
    [FN.AGENDA_MEMBER_DETAILS.TASKS.DECISION_OF_IMO_COMMITTEE]: "",
    [FN.AGENDA_MEMBER_DETAILS.TASKS.AREAS_OF_DISAGREEMENT_OF_INDIA]: "",
    [FN.AGENDA_MEMBER_DETAILS.TASKS.ACHIEVEMENT_OF_INDIA]: "",
    [FN.AGENDA_MEMBER_DETAILS.TASKS.TO_DO_LIST]: "",
  };
};

export const getFormInitialValues = (data = {}, agendaList = []) => {
  const {
    indian_delegation_other_members,
    from_meeting_date,
    to_meeting_date,
    nature_of_meeting,
    total_paper_submission,
    total_member_attended,
    total_member_attended_virtually,
    total_paper_under_submission,
    group_set_up,
    post_meeting_agendas,
    post_meeting_nominated_agendas,
  } = data;

  const agendaUnderConsiderationRow = post_meeting_agendas?.length
    ? post_meeting_agendas?.map((agenda) => {
        const agendaRow = {
          [FN.AGENDA_DETAILS.TITLE]: agenda?.agenda_name || "",
          [FN.AGENDA_DETAILS.TOTAL_PAPER_UNDER_CONSIDERATION]:
            agenda?.total_paper_under_submission,
          [FN.AGENDA_DETAILS.TOTAL_PAPER_SUBMISSION_BY_INDIA]:
            agenda?.total_paper_submission_by_india || "",
          [FN.AGENDA_DETAILS.TOTAL_PAPER_FROM_PAST_INDIA]:
            agenda?.total_paper_from_past_india || "",
        };
        if (agenda.id) {
          agendaRow.id = agenda.id;
        }
        return agendaRow;
      })
    : [getAgendaDetailsRow()];

  const agendaTasksDetails = (data) => {
    const tasks = data?.length
      ? data.map((task) => {
          const taskRow = {
            [FN.AGENDA_MEMBER_DETAILS.TASKS.BACKGROUND]: task.background,
            [FN.AGENDA_MEMBER_DETAILS.TASKS.PAPER_UNDER_CONSIDERATION]:
              task.paper_under_consideration,
            [FN.AGENDA_MEMBER_DETAILS.TASKS.INDIA_SUBMISSION]:
              task.india_submissions,
            [FN.AGENDA_MEMBER_DETAILS.TASKS.INDIA_COSPONSORED_PAPERS]:
              task.india_cosponsored_papers,
            [FN.AGENDA_MEMBER_DETAILS.TASKS.REVIEW_OF_SUBMISSION]:
              task.review_of_submissions,
            [FN.AGENDA_MEMBER_DETAILS.TASKS
              .RECOMMENDED_INDIA_STAND_TO_BE_TAKEN]:
              task.recommended_india_stand,
            [FN.AGENDA_MEMBER_DETAILS.TASKS.SHADOW_COMMITTEE_APPROVED_STAND]:
              task.committee_approved_stand,
            [FN.AGENDA_MEMBER_DETAILS.TASKS.DECISION_OF_IMO_COMMITTEE]:
              task.decision_of_committee,
            [FN.AGENDA_MEMBER_DETAILS.TASKS.AREAS_OF_DISAGREEMENT_OF_INDIA]:
              task.areas_of_disagreement_of_india,
            [FN.AGENDA_MEMBER_DETAILS.TASKS.ACHIEVEMENT_OF_INDIA]:
              task.achievement_of_india,
            [FN.AGENDA_MEMBER_DETAILS.TASKS.TO_DO_LIST]: task.to_do_list,
          };
          if (task.id) {
            taskRow.id = task.id;
          }
          return taskRow;
        })
      : [getIssuesRow()];

    return tasks;
  };

  const nominatedMembersRow = (data) => {
    const members = data?.length
      ? data?.filter((e) => !e.is_head).map((member) => {
          const memberRow = {
            id: member.id,
            [FN.AGENDA_MEMBER_DETAILS.NOMINATED_MEMBERS.NAME]:
              member?.name || "",
            [FN.AGENDA_MEMBER_DETAILS.NOMINATED_MEMBERS.ORGANISATION]:
              member?.organization || "",
          };
          if (member.id) {
            memberRow.id = member.id;
          }
          return memberRow;
        })
      : [getIssuesRow()];

    return members;
  };

  const agendaMemberDetailRow = post_meeting_nominated_agendas?.length
    ? post_meeting_nominated_agendas?.map((item) => {
        const agendaHead = item?.post_meeting_nominated_members?.find(
          (member) => member?.is_head
        );
        const agendaMemberRow = {
          [FN.AGENDA_MEMBER_DETAILS.AGENDA_TITLE]: item.name || "",
          [FN.AGENDA_MEMBER_DETAILS.NOMINATED_MEMBERS.ROOT]:
            nominatedMembersRow(item?.post_meeting_nominated_members),
          [FN.AGENDA_MEMBER_DETAILS.TASKS.ROOT]: agendaTasksDetails(
            item?.post_meeting_tasks
          ),
          [FN.AGENDA_MEMBER_DETAILS.HEAD_OF_AGENDA.ROOT]: {
            id: agendaHead?.id,
            [FN.AGENDA_MEMBER_DETAILS.HEAD_OF_AGENDA.HEAD_OF_AGENDA_NAME]:
              agendaHead?.name || "",
            [FN.AGENDA_MEMBER_DETAILS.HEAD_OF_AGENDA
              .HEAD_OF_AGENDA_ORGANISATION]: agendaHead?.organization || "",
          },
        };
        if (item.id) {
          agendaMemberRow.id = item.id;
        }
        return agendaMemberRow;
      })
    : [getAgendaMemberRow()];

  const listOfIndianDelegation = indian_delegation_other_members?.length
    ? indian_delegation_other_members?.map((member) => {
        const indianDelegationRow = {
          [FN.LIST_OF_INDIAN_DELEGATION.NAME]: member?.name,
          [FN.LIST_OF_INDIAN_DELEGATION.ORGANISATION]: member?.organization,
        };
        if (member.id) {
          indianDelegationRow.id = member.id;
        }
        return indianDelegationRow;
      })
    : [getListOfIndianDelegationRow()];

  return {
    [FN.FROM_MEETING_DATE]: getDateFromValue(from_meeting_date) || "",
    [FN.TO_MEETING_DATE]: getDateFromValue(to_meeting_date) || "",
    [FN.NATURE_OF_MEETING]: nature_of_meeting || "",
    [FN.LIST_OF_INDIAN_DELEGATION.ROOT]: listOfIndianDelegation,
    [FN.TOTAL_PAPER_SUBMISSION]: total_paper_submission,
    [FN.TOTAL_MEMBER_ATTENDED]: total_member_attended,
    [FN.TOTAL_MEMBER_ATTENDED_VIRTUALLY]: total_member_attended_virtually || "",
    [FN.TOTAL_PAPER_UNDER_SUBMISSION]: total_paper_under_submission,
    [FN.GROUP_SET_UP]: group_set_up,
    [FN.AGENDA_DETAILS.AGENDAS]: agendaUnderConsiderationRow || [],
    [FN.AGENDA_MEMBER_DETAILS.ROOT]: agendaMemberDetailRow || [],
  };
};
