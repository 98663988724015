import { useField, useFormikContext } from "formik";

import FormError from "../form-error";
import TypeAheadInput from "../type-ahead-input";
import PropTypes from "prop-types";
import cx from "../../utils/class-names";

const Select = (props) => {
  const { className, ...restProps } = props;
  return <TypeAheadInput openOnFocus className={cx("select", className)} filter={false} iconLeft={null} passValueInt={true} {...restProps} readOnlyInput />;
};

Select.defaultProps = {
  openOnFocus: true,
  filter: [],
  iconLeft: null,
  passValueInt: true,
  readOnlyInput: true,
};

Select.propTypes = {
  openOnFocus: PropTypes.bool,
  filter: PropTypes.array,
  iconLeft: PropTypes.element,
  passValueInt: PropTypes.bool,
  readOnlyInput: PropTypes.bool,
};

export function FormikSelect(props) {
  const { name, classNames = {}, onChange } = props;
  const [field, meta, { setValue }] = useField(name);

  const { submitCount } = useFormikContext();
  const isError = submitCount > 0 && !!meta.error;
  const helperText = (submitCount > 0 && meta.error) || "";
  const handleChange = (value) => {
    setValue(value);
    onChange && onChange(value);
  };

  return (
    <>
      <Select {...field} {...props} onChange={handleChange} />
      <FormError show={isError} message={helperText} className={classNames.error} />
    </>
  );
}

export default Select;
