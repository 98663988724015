export const generateNames = (firstName, lastName) => {
  const getShortName = (name) => name.substr(0, 2).toUpperCase();
  const capitalize = (name) => name.charAt(0).toUpperCase() + name.slice(1);

  const shortName =
    firstName && lastName
      ? `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase()
      : firstName
        ? getShortName(firstName)
        : lastName
          ? getShortName(lastName)
          : "";

  const fullName =
    firstName && lastName
      ? `${capitalize(firstName)} ${capitalize(lastName)}`
      : firstName
        ? capitalize(firstName)
        : lastName
          ? capitalize(lastName)
          : "";

  return [shortName, fullName];
};

export const capitalize = (name) => {
  return name.charAt(0).toUpperCase() + name.slice(1);
}

export const generateFullName = (member = {}) => {
  const { first_name, last_name } = member;
  return [first_name, last_name].filter(i => i).map(i => capitalize(i)).join(" ");
};

export const generateShortName = (member) => {
  const getShortName = (name) => name.substr(0, 2).toUpperCase();
  if (member) {
    const { first_name, last_name } = member;
    const shortName =
      first_name && last_name
        ? `${first_name.charAt(0)}${last_name.charAt(0)}`.toUpperCase()
        : first_name
          ? getShortName(first_name)
          : last_name
            ? getShortName(last_name)
            : "";
    return shortName;
  }
  return null;
};

export function findLeader(members) {
  return (members || []).find((item) => item.is_leader)?.member;
}
export function findCoordinator(members) {
  return (members || []).find((item) => item.is_coordinator)?.member;
}

export function getDesignation(memberObject) {
  if (memberObject.is_leader === true) {
    return "Leader of Delegation";
  } else if (memberObject.is_coordinator === true) {
    return "Coordinator of Committee";
  } else {
    return "Member";
  }
}