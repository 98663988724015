import React, { useCallback, useMemo } from "react";
import cx from "../../../../utils/class-names";
import PenGreenIcon from "../../../../components/vectors/pen-green-icon";
import { useAsync } from "@react-org/hooks";
import { useParams } from "react-router-dom";
import * as GroupsApi from "../../../../apis/groups.api";
import StatusBadges from "../../../../components/statusBadge";
import Const from "../../../../constants";

export const FeedbackRow = ({ item, handlePrepareFeedbackClick }) => {
  const description = item?.description;
  const paper = item?.paper;
  const { groupId } = useParams();
  const getGroupPaperFeedbackQuery = useAsync(
    useCallback(
      (payload) => {
        return GroupsApi.getGroupPaperFeedback({
          groupId,
          paperId: item?.paper_id,
          ...payload,
        });
      },
      [groupId, item?.paper_id]
    ),
    {
      immediate: true,
    }
  );
  const status = useMemo(() => {
    if (
      getGroupPaperFeedbackQuery?.data?.data?.status === Const.STATUS.COMPLETED
    ) {
      return Const.STATUS.COMPLETED;
    } else {
      return Const.STATUS.PENDING;
    }
  }, [getGroupPaperFeedbackQuery?.data?.data?.status]);

  return (
    <div
      className={cx(
        "px-2 py-0  m-0 shadow-none flex justify-between items-center tab-sm:flex tab-xs:block"
      )}
    >
      <div className="grid grid-cols-10 space-x-4 w-full items-center mb-2 text-sm p-2 drop-shadow-sm rounded-md bg-gray-50 hover:bg-secondary-0 hover:border hover:border-primary-button">
        <div className="flex flex-col col-span-1">
          <label className="text-sm font-medium font-sans leading-[18px]">
            {paper}
          </label>
        </div>
        <div className="flex flex-col col-span-6">
          <label className="text-sm font-medium font-sans leading-[18px]">
            {description}
          </label>
        </div>
        <div className="flex flex-col col-span-1">
          <StatusBadges variant={status} />
        </div>
        <div className="flex col-span-2 justify-center">
          <PenGreenIcon
            width={18}
            height={18}
            onClick={handlePrepareFeedbackClick(item.agenda_id,item.paper_id)}
            className="cursor-pointer ml-10"
          />
        </div>
      </div>
    </div>
  );
};
