import { useCommitteeFeedbackPageContext } from "../../provider";
import { TaskResponse } from "./task-response";

export const ConsolidatedTaskDetails = () => {
  const { feedbackDetails } = useCommitteeFeedbackPageContext();
  return (
    <>
      {feedbackDetails?.map((item) => {
        return <TaskResponse key={item.id} item={item} />;
      })}
    </>
  );
};
