import getFileExtension from "./get-file-extension";

export const downloadOrPreviewFileUrl = (url, fileName, config = {}) => {
  if (canPreviewTheFileUrl(url, config.type)) {
    previewFile(url, fileName);
  } else {
    downloadFile(url, fileName);
  }

  const timeout = setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(url);
    clearTimeout(timeout);
  }, 100);
};

function canPreviewTheFileUrl(url = "", type = "") {
  if (type) {
    if (type.startsWith("image/") || type === "application/pdf") {
      return true;
    }
    return false;
  } else {
    const extension = getFileExtension(url);
    if (
      extension === "jpg" ||
      extension === "jpeg" ||
      extension === "png" ||
      extension === "gif" ||
      extension === "bmp" ||
      extension === "webp" ||
      extension === "svg" ||
      extension === "pdf"
    ) {
      return true;
    }
    return false;
  }
}

function downloadFile(url, fileName) {
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
}

function previewFile(url, fileName) {
  const newTab = window.open(url, "_blank");
  const timeout = setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    newTab.document.title = fileName;
    clearTimeout(timeout);
  }, 100);
}
