import PropTypes from "prop-types";
import cx from "../../utils/class-names";

const FormControl = (props) => {
  const { children, className, ...restProps } = props;
  return (
    <div className={cx("form-control mb-2 leading-[0px]", className)} {...restProps}>
      {children}
    </div>
  );
};

FormControl.defaultProps = { className: "" };
FormControl.propTypes = { className: PropTypes.string };

export default FormControl;
