import Button from "../../components/button";
import HeaderTitle from "../../components/header-title";
import ToggleButton from "../../components/toggle-button";
import Arrow2Icon from "../../components/vectors/arrow2-icon";
import { HeaderLeft, HeaderRight } from "../../layouts/dashboard/header";
import withHOC from "../../utils/with-hoc";
import { ConsolidatedTaskDetails } from "./components/consolidated-task-details";
import { PrepareFeedback } from "./components/prepare-feedback";
import {
  CommitteeFeedbackPageProvider,
  useCommitteeFeedbackPageContext,
} from "./provider";

function CommitteeFeedbackPage() {
  const { getPaperDetailsQuery, handleCompleteMark, goBack, status } =
    useCommitteeFeedbackPageContext();
  const paperDetails = getPaperDetailsQuery?.data?.data;
  const pageTitle = `${paperDetails?.agenda?.title} - ${paperDetails?.symbol}`;

  return (
    <div className="w-full gap-4 flex">
      <HeaderRight>
        <Button
          variant="outline"
          color="primary"
          className="py-2 px-3.5 mr-2 flex items-center text-xs font-semibold text-primary-button hover:bg-primary-button hover:text-secondary-0"
          icon={<Arrow2Icon className="rotate-180" />}
          onClick={goBack}
        >
          Back
        </Button>
        <HeaderTitle>{pageTitle}</HeaderTitle>
      </HeaderRight>
      <HeaderLeft className="ml-16">
        <ToggleButton onChange={handleCompleteMark} checked={status}>
          Mark as complete
        </ToggleButton>
      </HeaderLeft>
      <div className="w-[60%] py-2 px-3 overflow-y-scroll">
        <ConsolidatedTaskDetails />
      </div>
      <div className="w-[40%] py-2 px-3 overflow-hidden">
        <PrepareFeedback />
      </div>
    </div>
  );
}

export default withHOC(CommitteeFeedbackPageProvider, CommitteeFeedbackPage);
