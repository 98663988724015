export default function SearchIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M6.667 11.333a4.667 4.667 0 100-9.333 4.667 4.667 0 000 9.333zM14 14l-4-4"
        stroke="currentcolor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
