import useCallbackRef from "./use-callback-ref";
import { useState, useCallback } from "react";
import { runIfFn } from "../utils/run-if-fn";

//React hook for using controlling component state
export function useControllableState(props) {
  const { value: valueProp, defaultValue, onChange, shouldUpdate = (prev, next) => prev !== next } = props;
  const onChangeProp = useCallbackRef(onChange);
  const shouldUpdateProp = useCallbackRef(shouldUpdate);

  const [valueState, setValue] = useState(defaultValue);

  const isControlled = valueProp !== undefined;
  const value = isControlled ? valueProp : valueState;

  const updateValue = useCallback(
    (next) => {
      const nextValue = runIfFn(next, value);

      if (!shouldUpdateProp(value, nextValue)) {
        return;
      }

      if (!isControlled) {
        setValue(nextValue);
      }

      onChangeProp(nextValue);
    },
    [isControlled, onChangeProp, value, shouldUpdateProp]
  );

  return [value, updateValue];
}
