import { useAsync } from "@react-org/hooks";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import generateContext from "../../utils/generate-context";
import * as GroupsApi from "../../apis/groups.api";
import * as TasksApi from "../../apis/tasks.api";
import * as PapersApi from "../../apis/papers.api";
import * as TaskFeedbackApi from "../../apis/task-feedback.api";
import { useParams } from "react-router-dom";
import { useNotificationModalContext } from "../../components/notification-modal/provider";
import Const from "../../constants";
import useCustomNavigate from "../../hooks/use-custom-navigate";
import MESSAGES from "../../constants/messages";
import { downloadOrPreviewFileUrl } from "../../utils/download-or-preview-file-url";
import generateFileUrl from "../../utils/generate-file-url";

function useMembersFeedbackPage(props) {
  const [newFeedback, setNewFeedback] = useState(null);
  const { groupId, taskId } = useParams();
  const [feedbackList, setFeedbackList] = useState([]);
  const [isEditMode, setIsEditMode] = useState([]);
  const [status, setStatus] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [uploadedDocList, setUplodedDocList] = useState([]);
  const [activeDocIndex, setActiveDocIndex] = useState(0);

  const notificationModal = useNotificationModalContext();
  const mainFormRef = useRef(null);
  const { goBack } = useCustomNavigate();
  const getTaskQuery = useAsync(GroupsApi.getTask, { immediate: false });
  const getTaskFeedbackQuery = useAsync(
    useCallback(
      (payload) =>
        TasksApi.getTaskFeedback({ ...payload, taskId: taskId, limit: 50 }),
      [taskId]
    )
  );
  const getPaperDocumentQuery = useAsync(PapersApi.getPaperDocument, {
    immediate: false,
  });
  const saveTaskFeedbackQuery = useAsync(TasksApi.addTaskFeedback, {
    immediate: false,
  });
  const updateFeedbackQuery = useAsync(TaskFeedbackApi.updateFeedback, {
    immediate: false,
  });
  const updateTaskStatusQuery = useAsync(TasksApi.updateTaskStatus, {
    immediate: false,
  });
  const uploadFeedbackDocumentsQuery = useAsync(
    TaskFeedbackApi.uploaodFeedbackDocumments,
    { immediate: false }
  );
  const getDocumentListQuery = useAsync(
    TaskFeedbackApi.uploaodFeedbackDocummentsList,
    {
      immediate: true,
    }
  );
  const downloadDocumetQuery = useAsync(
    TaskFeedbackApi.feedbackDocummentDocuments,
    { immediate: false }
  );

  const deleteCommitteeDocumentQuery = useAsync(
    TaskFeedbackApi.feedbackDocummentDelete,
    {
      immediate: false,
    }
  );

  const Edit = useCallback(() => {
    setNewFeedback(false);
  }, []);

  const handleCancel = useCallback(
    (id) => {
      const newState = isEditMode?.map((e) => {
        if (e.feedbackId === id) {
          return { ...e, showModel: false };
        } else {
          return { ...e, showModel: false };
        }
      });
      if (!id) {
        setNewFeedback(false);
      }
      setIsEditMode(newState);
    },
    [isEditMode]
  );

  const handleEdit = useCallback(
    (id) => () => {
      setNewFeedback(false);
      const newState = isEditMode?.map((e) => {
        if (e.feedbackId === id) {
          return { ...e, showModel: true };
        } else {
          return { ...e, showModel: false };
        }
      });
      setIsEditMode(newState);
    },
    [isEditMode]
  );

  const handleFeedbackSave = useCallback(
    (data) => {
      notificationModal.progress({
        heading: MESSAGES.INPROGRESS_MESSAGE,
      });

      const saveTaskFeedbackQueryExecute = saveTaskFeedbackQuery.execute;
      const uploadFeedbackDocumentsQueryExecute =
        uploadFeedbackDocumentsQuery.execute;
      const getDocumentListQueryExecute = getDocumentListQuery.debounceExecute;

      saveTaskFeedbackQueryExecute(
        {
          taskId,
          ...data,
        },
        {
          onSuccess: (res) => {
            const getTaskFeedbackQueryExecute = getTaskFeedbackQuery.execute;
            if (documents?.length) {
              const feedbackId = res?.data?.id;
              const updatedDocList = uploadedDocList.filter(
                (doc) => doc?.feedback_id !== feedbackId
              );
              const payload = {
                files: documents,
                feedbackId: feedbackId,
              };
              uploadFeedbackDocumentsQueryExecute(payload, {
                onSuccess: (res) => {
                  setDocuments([]);
                  getDocumentListQueryExecute(
                    { feedbackId: feedbackId },
                    {
                      onSuccess: (res) => {
                        const updatedDocs = res?.data?.data;
                        updatedDocList.push(...updatedDocs);
                        setUplodedDocList(updatedDocList);
                      },
                    }
                  );
                  notificationModal.success({
                    heading: MESSAGES.MEMBER_RESPONSE_CREATED,
                  });
                  getTaskFeedbackQueryExecute();
                },
                onError: () => {
                  notificationModal.error({
                    heading: MESSAGES.FAILED_TO_UPDATE_MEMBER_RESPONSE,
                  });
                },
              });
            }
            Edit();
            getTaskFeedbackQueryExecute({});

            notificationModal.close();
          },
          onError: (err) => {
            notificationModal.error({
              heading: "Failed to add Feedback",
            });
          },
        }
      );
    },
    [
      Edit,
      documents,
      getDocumentListQuery.debounceExecute,
      getTaskFeedbackQuery.execute,
      notificationModal,
      saveTaskFeedbackQuery.execute,
      taskId,
      uploadFeedbackDocumentsQuery.execute,
      uploadedDocList,
    ]
  );

  const handleFeedbackUpdate = useCallback(
    (data, feedback, cb) => {
      const feedbackId = feedback.id;
      const updatedDocList = uploadedDocList.filter(
        (doc) => doc?.feedback_id !== feedbackId
      );

      notificationModal.progress({
        heading: MESSAGES.INPROGRESS_MESSAGE,
      });

      const updatedDocuments = documents?.filter((doc) => !doc?.id);
      const updateFeedbackQueryExecute = updateFeedbackQuery.execute;
      const uploadFeedbackDocumentsQueryExecute =
        uploadFeedbackDocumentsQuery.execute;
      const getDocumentListQueryExecute = getDocumentListQuery.debounceExecute;

      updateFeedbackQueryExecute(
        {
          feedbackId: feedback.id,
          ...data,
        },
        {
          onSuccess: (res) => {
            const getTaskFeedbackQueryExecute = getTaskFeedbackQuery.execute;
            if (updatedDocuments.length) {
              uploadFeedbackDocumentsQueryExecute(
                {
                  feedbackId: feedbackId,
                  files: updatedDocuments,
                },
                {
                  onSuccess: (res) => {
                    notificationModal.success({
                      heading: MESSAGES.MEMBER_RESPONSE_UPDATED,
                    });
                    getDocumentListQueryExecute(
                      { feedbackId: feedbackId },
                      {
                        onSuccess: (res) => {
                          const updatedDocs = res?.data?.data;
                          updatedDocList.push(...updatedDocs);
                          setUplodedDocList(updatedDocList);
                        },
                      }
                    );
                    getTaskFeedbackQueryExecute();
                    getTaskFeedbackQuery();
                  },
                }
              );
            }
            getTaskFeedbackQueryExecute({});
            notificationModal.close();
            cb && cb();
          },
          onError: (err) => {
            notificationModal.error({
              heading: "Failed to update Feedback",
            });
          },
        }
      );
    },
    [
      documents,
      getDocumentListQuery.debounceExecute,
      getTaskFeedbackQuery,
      notificationModal,
      updateFeedbackQuery.execute,
      uploadFeedbackDocumentsQuery.execute,
      uploadedDocList,
    ]
  );

  const handleSubmit = useCallback(
    ({ payload, feedback, isEditMode }, cb) => {
      if (isEditMode) {
        return handleFeedbackUpdate(payload, feedback, cb);
      }
      return handleFeedbackSave(payload);
    },
    [handleFeedbackSave, handleFeedbackUpdate]
  );

  const handleDirectionChange = useCallback(
    (direction, e) => {
      setActiveDocIndex((index) => {
        if (direction === "up" && index > 0) {
          return index - 1;
        }
        if (
          direction === "down" &&
          index < getTaskQuery?.data?.data?.papers?.length - 1
        ) {
          return index + 1;
        }
        return index;
      });
    },
    [getTaskQuery?.data?.data?.papers?.length]
  );

  const handleFetchPaperDocument = useCallback(
    (paperId) => {
      const getPaperDocumentQueryCancel = getPaperDocumentQuery.cancel;
      getPaperDocumentQueryCancel();
      const getPaperDocumentQueryExecute = getPaperDocumentQuery.execute;
      getPaperDocumentQueryExecute({ paperId });
    },
    [getPaperDocumentQuery.cancel, getPaperDocumentQuery.execute]
  );

  const handleDocumentClick = useCallback((document, documentIndex, e) => {
    setActiveDocIndex(documentIndex);
  }, []);
  const handleStatusChange = useCallback(
    (e) => {
      const payload = { taskId };
      if (e.target.checked) {
        payload.status = Const.STATUS.COMPLETED;
      } else {
        payload.status = Const.STATUS.PENDING;
      }
      const updateTaskStatusQueryExecute = updateTaskStatusQuery.execute;
      updateTaskStatusQueryExecute(payload, {
        onSuccess: (res) => {
          notificationModal.close();
          const getTaskQueryExecute = getTaskQuery.execute;
          getTaskQueryExecute({ groupId, taskId });
        },
        onError: (err) => {
          notificationModal.error({
            heading: "Failed to update task status",
          });
        },
      });
    },
    [
      getTaskQuery.execute,
      groupId,
      notificationModal,
      taskId,
      updateTaskStatusQuery.execute,
    ]
  );

  const handleEditDeleteDocument = useCallback(
    (index, data) => {
      const updatedDocList = uploadedDocList.filter(
        (doc) => doc?.feedback_id !== data.feedback_id
      );
      if (data.id) {
        const getDocumentListQueryExecute =
          getDocumentListQuery.debounceExecute;
        notificationModal.warning({
          title: MESSAGES.ARE_YOU_SURE,
          heading: MESSAGES.DOCUMENT_WILL_BE_DELETED,
          onConfirm: () => {
            const deleteCommitteeDocumentQueryExecute =
              deleteCommitteeDocumentQuery.execute;
            const payload = {
              documentId: data.id,
            };
            deleteCommitteeDocumentQueryExecute(payload, {
              onSuccess: () => {
                notificationModal.success({
                  heading: MESSAGES.DOCUMENT_DELETED,
                });
                getDocumentListQueryExecute(
                  {
                    feedbackId: data.feedback_id,
                  },
                  {
                    onSuccess: (res) => {
                      const updatedDocs = res?.data?.data;
                      updatedDocList.push(...updatedDocs);
                      setUplodedDocList(updatedDocList);
                    },
                  }
                );
              },
              onError: () => {
                notificationModal.error({
                  heading: MESSAGES.FAILED_TO_DELETED_DOCUMENT,
                });
              },
            });
          },
        });
      } else {
        setDocuments((prevDocuments) => {
          const updatedDocuments = [...prevDocuments];
          updatedDocuments.splice(index, 1);
          return updatedDocuments;
        });
      }
    },
    [
      deleteCommitteeDocumentQuery.execute,
      getDocumentListQuery.debounceExecute,
      notificationModal,
      uploadedDocList,
    ]
  );

  const handleDownloadFile = useCallback(
    (doc) => () => {
      const payload = {
        feedbackId: doc?.group_feedback_id,
        documentId: doc?.id,
      };
      const downloadDocumetQueryExecute = downloadDocumetQuery.execute;
      notificationModal.progress({ heading: MESSAGES.INPROGRESS_MESSAGE });
      downloadDocumetQueryExecute(payload, {
        onSuccess: (res) => {
          const fileURL = generateFileUrl(
            res?.data,
            res.headers["content-type"]
          );
          downloadOrPreviewFileUrl(fileURL, `${doc?.name}`, {
            type: res.headers["content-type"],
          });
          notificationModal.close();
        },
        onError: () => {
          notificationModal.error({
            heading: MESSAGES.PREVIEW_FAILED,
          });
        },
      });
    },
    [downloadDocumetQuery.execute, notificationModal]
  );

  const handleAddNewFeedbackClick = useCallback(() => {
    setNewFeedback(true);
    setDocuments([]);
    setIsEditMode((prevIsEditMode) => {
      const updatedIsEditMode = prevIsEditMode.map((val) => ({
        ...val,
        showModel: false,
      }));
      setIsEditMode(updatedIsEditMode);
    });
  }, []);

  const handleDeleteDocument = useCallback(
    (data) => (doc) => {
      const updatedDocList = uploadedDocList.filter(
        (doc) => doc?.feedback_id !== data.feedback_id
      );
      const getDocumentListQueryExecute = getDocumentListQuery.debounceExecute;
      notificationModal.warning({
        title: MESSAGES.ARE_YOU_SURE,
        heading: MESSAGES.DOCUMENT_WILL_BE_DELETED,
        onConfirm: () => {
          const deleteCommitteeDocumentQueryExecute =
            deleteCommitteeDocumentQuery.execute;
          const payload = {
            documentId: data.id,
          };
          deleteCommitteeDocumentQueryExecute(payload, {
            onSuccess: () => {
              notificationModal.success({ heading: MESSAGES.DOCUMENT_DELETED });
              getDocumentListQueryExecute(
                {
                  feedbackId: data.feedback_id,
                },
                {
                  onSuccess: (res) => {
                    const updatedDocs = res?.data?.data;
                    updatedDocList.push(...updatedDocs);
                    setUplodedDocList(updatedDocList);
                  },
                }
              );
            },
            onError: () => {
              notificationModal.error({
                heading: MESSAGES.FAILED_TO_DELETED_DOCUMENT,
              });
            },
          });
        },
      });
    },
    [
      deleteCommitteeDocumentQuery.execute,
      getDocumentListQuery.debounceExecute,
      notificationModal,
      uploadedDocList,
    ]
  );

  useEffect(() => {
    if (getTaskFeedbackQuery?.data?.data) {
      setFeedbackList(getTaskFeedbackQuery?.data?.data?.data);
    }
    const feedbackModel = (getTaskFeedbackQuery?.data?.data?.data || []).map(
      (e) => {
        return { feedbackId: e.id, showModel: false };
      }
    );
    setIsEditMode(feedbackModel);
  }, [getTaskFeedbackQuery?.data?.data]);

  useEffect(() => {
    if (getTaskQuery?.data?.data?.papers?.length) {
      const { id } = getTaskQuery?.data?.data?.papers[activeDocIndex];
      handleFetchPaperDocument(id);
    }
    if (getTaskQuery?.data?.data?.status === Const.STATUS.COMPLETED) {
      setStatus(true);
    } else {
      setStatus(false);
    }
  }, [
    activeDocIndex,
    getTaskFeedbackQuery?.data?.data,
    getTaskQuery?.data?.data?.papers,
    getTaskQuery?.data?.data?.status,
    getTaskQuery?.data?.data.task_papers,
    handleFetchPaperDocument,
  ]);

  useEffect(() => {
    const getTaskQueryExecute = getTaskQuery.execute;
    getTaskQueryExecute(
      { groupId, taskId },
      {
        onSuccess: (res) => {
          if (res?.data?.task_papers?.[0]) {
            handleFetchPaperDocument(res.data.task_papers[0].paper);
          }
        },
      }
    );
  }, [getTaskQuery.execute, groupId, handleFetchPaperDocument, taskId]);

  return useMemo(() => {
    return {
      getTaskQuery,
      mainFormRef,
      handleSubmit,
      activeDocIndex,
      setActiveDocIndex,
      handleDirectionChange,
      handleDocumentClick,
      getPaperDocumentQuery,
      getTaskFeedbackQuery,
      handleStatusChange,
      status,
      setStatus,
      goBack,
      setDocuments,
      documents,
      uploadedDocList,
      setUplodedDocList,
      handleAddNewFeedbackClick,
      handleDownloadFile,
      handleDeleteDocument,
      handleCancel,
      handleEdit,
      newFeedback,
      setNewFeedback,
      feedbackList,
      handleEditDeleteDocument,
      isEditMode,
    };
  }, [
    activeDocIndex,
    getPaperDocumentQuery,
    getTaskFeedbackQuery,
    getTaskQuery,
    handleDirectionChange,
    handleDocumentClick,
    handleStatusChange,
    handleSubmit,
    status,
    goBack,
    setDocuments,
    documents,
    uploadedDocList,
    setUplodedDocList,
    handleAddNewFeedbackClick,
    handleDownloadFile,
    handleDeleteDocument,
    handleCancel,
    handleEdit,
    newFeedback,
    setNewFeedback,
    feedbackList,
    handleEditDeleteDocument,
    isEditMode,
  ]);
}
export const [MembersFeedbackPageProvider, useMembersFeedbackPageContext] =
  generateContext(useMembersFeedbackPage);
