import React from "react";
import cx from "../../../../utils/class-names";
import Accordion from "../../../../components/accordion";
import ArrowIcon from "../../../../components/vectors/arrow-icon";
import FolderOpenIcon from "../../../../components/vectors/folder-open-icon";
import { MeetingAccordion } from "./meeting-accordion";
import { useWorkingDocumentPageContext } from "../../provider";
import { MiscellaneousFolder } from "./miscellaneous-folder";
import FolderCloseIcon from "../../../../components/vectors/folder-close-icon";

export default function DocumentSection() {
  const {
    getMeetingDetailsQuery,
    getMembersQuery,
    handleDocumentUpload,
    handleOpenUpload,
    handleDownloadDocuments,
    isFolderOpen,
  } = useWorkingDocumentPageContext();
  const meetingDetails = getMeetingDetailsQuery?.data?.data?.data || [];
  const membersDetails = getMembersQuery?.data || [];
  return (
    <>
      <div className="flex overflow-y-scroll flex-col flex-1 h-full p-5 pt-0">
        <div className="w-full">
          <Accordion defaultValue={true} className="rounded w-full">
            {({ expanded }) => {
              const icon =
                meetingDetails.length > 0 ? (
                  <FolderOpenIcon className="text-secondary-550 opacity-50 ml-2 mr-2 h-5 w-6" />
                ) : (
                  <FolderCloseIcon className="text-secondary-550 opacity-50 ml-2 mr-2 h-5 w-6" />
                );
              return (
                <>
                  <div
                    className={cx(
                      "flex items-center justify-between p-1 hover:bg-blue-100 cursor-pointer"
                    )}
                  >
                    <Accordion.Header className="flex items-center">
                      <div className="ml-1 h-2.5 w-2.5">
                        <ArrowIcon
                          className={cx(
                            "text-primary-text items-center h-2.5 w-2.5 rotate-90",
                            expanded && "rotate-180"
                          )}
                        />
                      </div>
                      {icon}
                      <div className={cx("flex flex-1 pr-3.5 items-center")}>
                        <span className="break-all text-black-100 font-semibold text-sm font-sans leading-5 mb-1">
                          MEPC
                        </span>
                      </div>
                    </Accordion.Header>
                  </div>
                  <Accordion.Content
                    className={cx(
                      "h-0 overflow-hidden",
                      expanded && "overflow-visible ml-[52px] h-auto mt-1.5"
                    )}
                  >
                    {meetingDetails?.map((meeting, index) => {
                      const meetingName = meeting?.name;
                      const groups = meeting?.commitee?.groups;
                      const committeeDocuments =
                        meeting?.commitee?.commitee_documents;
                      const committeeId = meeting?.commitee?.id;
                      return (
                        <>
                          <MeetingAccordion
                            key={index}
                            name={meetingName}
                            groups={groups}
                            committeeDocuments={committeeDocuments}
                            membersDetails={membersDetails}
                            committeeId={committeeId}
                            handleDocumentUpload={handleDocumentUpload}
                            handleOpenUpload={handleOpenUpload}
                            handleDownloadDocuments={handleDownloadDocuments}
                            isFolderOpen={isFolderOpen}
                          />
                        </>
                      );
                    })}
                  </Accordion.Content>
                </>
              );
            }}
          </Accordion>
          <MiscellaneousFolder
            handleOpenUpload={handleOpenUpload}
            handleDocumentUpload={handleDocumentUpload}
            handleDownloadDocuments={handleDownloadDocuments}
            getMeetingDetailsQuery={getMeetingDetailsQuery}
            membersDetails={membersDetails}
            isFolderOpen={isFolderOpen}
          />
        </div>
      </div>
    </>
  );
}
