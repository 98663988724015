import withHOC from "../../utils/with-hoc";
import { WorkingDocumentPageProvider } from "./provider";
import { HeaderRight } from "../../layouts/dashboard/header";
import HeaderTitle from "../../components/header-title";
import HeaderSection from "./components/header-section";
import DocumentSection from "./components/document-section";

const WorkingDocumentPage = (props) => {
  return (
    <>
      <div className="flex flex-1 flex-col">
        <HeaderRight>
          <HeaderTitle>Working Documents</HeaderTitle>
        </HeaderRight>
        <HeaderSection />
        <DocumentSection />
      </div>
    </>
  );
};

export default withHOC(WorkingDocumentPageProvider, WorkingDocumentPage);
