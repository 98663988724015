import cx from "../../utils/class-names";
import { Close2Icon } from "../vectors/close2-icon";
export const SelectedOptions = ({ label, onClick, classNames }) => {
  return (
    <div
      className={cx(
        "py-[2.6px] px-2.5 bg-blue-400 flex items-center justify-between rounded mb-1",
        classNames?.root
      )}
    >
      <div
        className={cx(
          "text-xs leading-[18px] font-medium text-blue-300 max-w-[97%] font-sans",
          classNames?.label
        )}
      >
        {label}
      </div>

      <Close2Icon
        onClick={onClick}
        className={cx("w-3 h-3 cursor-pointer", classNames?.icon)}
      />
    </div>
  );
};
