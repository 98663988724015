import { FieldArray, useFormikContext } from "formik";
import Accordion from "../../../../components/accordion";
import Divider from "../../../../components/divider";
import FormControl from "../../../../components/form-control";
import { FormikInput } from "../../../../components/input";
import InputLabel from "../../../../components/input-label";
import ArrowIcon from "../../../../components/vectors/arrow-icon";
import BinIcon from "../../../../components/vectors/bin-icon";
import Section from "../../../../components/section";
import cx from "../../../../utils/class-names";
import { FN } from "./form-helper";
import { useManageAgendaPageContext } from "../../provider";
import Button from "../../../../components/button";
import { FormikTextarea } from "../../../../components/textarea";
import UploadIcon from "../../../../components/vectors/upload-icon";
import DownloadThinIcon from "../../../../components/vectors/download-thin-icon";
import { createRef, useCallback, useState } from "react";
import { useNotificationModalContext } from "../../../../components/notification-modal/provider";
import MESSAGES from "../../../../constants/messages";
import generateFileUrl from "../../../../utils/generate-file-url";
import { downloadOrPreviewFileUrl } from "../../../../utils/download-or-preview-file-url";

const SectionPapers = () => {
  const { values } = useFormikContext();
  const {
    handleAddPaper,
    handleRemovePaper,
    handleOpenUpload,
    setPaperDocument,
    getAgendaQuery,
    downloadPaperQuery,
    deletePaperDocumentQuery,
  } = useManageAgendaPageContext();

  return (
    <Section>
      <FieldArray
        name={FN.PAPER.ROOT}
        render={(arrayHelpers) => {
          const details = values?.[FN.PAPER.ROOT] || [];
          const accordionElements = details.map((detail, cdIndex) => (
            <Accordion
              key={detail.id}
              defaultValue={true}
              className="rounded bg-secondary-0 border border-secondary-400 mb-3"
            >
              {({ expanded }) => (
                <>
                  <Accordion.Header
                    className={cx("flex items-center p-2 py-1")}
                  >
                    <span className="input-label flex-1 inline-block text-primary-text text-xs font-semibold mb-1.5">
                      {`Paper ${cdIndex + 1}`}
                    </span>
                    <span className="flex items-center ">
                      {expanded ? (
                        <div
                          className="flex items-center cursor-pointer justify-center w-6 h-6 p-0 bg-transparent text-red-550"
                          onClick={handleRemovePaper({
                            paperId: detail?.paper_id,
                            arrayHelpers,
                            index: cdIndex,
                          })}
                        >
                          <BinIcon />
                        </div>
                      ) : null}
                      <div
                        className={cx(
                          "flex items-center justify-center cursor-pointer w-6 h-6 p-0 bg-transparent text-secondary-800 rotate-180",
                          expanded && "rotate-0"
                        )}
                      >
                        <ArrowIcon />
                      </div>
                    </span>
                  </Accordion.Header>
                  <Accordion.Content
                    className={cx(
                      "h-0 overflow-hidden",
                      expanded && "px-2 pb-2 pt-0 h-auto"
                    )}
                  >
                    <Divider className="divider w-[100%] h-[2px] bg-gray-600 bg-opacity-25 mb-2" />
                    <PaperForm
                      cdIndex={cdIndex}
                      handleOpenUpload={handleOpenUpload}
                      detail={detail}
                      setPaperDocument={setPaperDocument}
                      downloadPaperQuery={downloadPaperQuery}
                      deletePaperDocumentQuery={deletePaperDocumentQuery}
                      getAgendaQuery={getAgendaQuery}
                    />
                  </Accordion.Content>
                </>
              )}
            </Accordion>
          ));

          return (
            <>
              <Section.Title>Papers</Section.Title>
              <Section.Body>
                {accordionElements}
                <Button
                  variant="solid"
                  color="primary"
                  className="py-2 px-3.5 flex items-center text-xs font-semibold action-auditor-save"
                  icon="+"
                  onClick={handleAddPaper(arrayHelpers)}
                >
                  Add Paper
                </Button>
              </Section.Body>
            </>
          );
        }}
      />
    </Section>
  );
};
export default SectionPapers;

const PaperForm = ({
  cdIndex,
  handleOpenUpload,
  setPaperDocument,
  downloadPaperQuery,
  deletePaperDocumentQuery,
  getAgendaQuery,
}) => {
  let fileUploadRef = createRef();
  const formik = useFormikContext();
  const notificationModal = useNotificationModalContext();
  const fileDetails = formik.values?.[FN.PAPER.ROOT]?.[cdIndex];
  const [file, setFile] = useState(fileDetails);
  const paperName =
    formik.values?.[FN.PAPER.ROOT]?.[cdIndex]?.[FN.PAPER.PAPER_SYMBOL];
  const handleDocumentUpload = useCallback(
    (e) => {
      const data = e?.target?.files[0];
      setFile(data);
      setPaperDocument((prevDocuments) => {
        const updatedDocuments = [...prevDocuments];
        updatedDocuments[cdIndex] = {
          paperName: paperName,
          file: data,
        };
        return updatedDocuments;
      });
    },
    [cdIndex, paperName, setPaperDocument]
  );
  const handleDownloadFile = useCallback(
    (file) => () => {
      if (file && file.content) {
        // Create a Blob and initiate download
        const blob = new Blob([file.content], { type: file.type });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = file.name;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }
    },
    []
  );
  const handleDownloadPaper = useCallback(
    (file) => () => {
      const paperId = file.paper_id;
      const title = file.file_name;
      const downloadCommitteeDocumentQueryExecute = downloadPaperQuery.execute;
      notificationModal.progress({ heading: MESSAGES.INPROGRESS_MESSAGE });
      downloadCommitteeDocumentQueryExecute(
        { paperId },
        {
          onSuccess: (res) => {
            const fileURL = generateFileUrl(
              res?.data,
              res.headers["content-type"]
            );
            downloadOrPreviewFileUrl(fileURL, `${title}`, {
              type: res.headers["content-type"],
            });
            notificationModal.close();
          },
          onError: () => {
            notificationModal.error({
              heading: MESSAGES.PREVIEW_FAILED,
            });
          },
        }
      );
    },
    [downloadPaperQuery.execute, notificationModal]
  );
  const handleDeleteFile = useCallback(() => {
    setFile(null);
    setPaperDocument((prevDocuments) => {
      const updatedDocuments = [...prevDocuments];
      updatedDocuments[cdIndex] = {
        paperName: paperName,
        file: null,
      };
      return updatedDocuments;
    });
  }, [cdIndex, paperName, setPaperDocument]);

  const handleDeletePaper = useCallback(
    (file) => (e) => {
      const id = file.paper_id;
      e.stopPropagation();
      notificationModal.warning({
        title: MESSAGES.ARE_YOU_SURE,
        heading: MESSAGES.DOCUMENT_WILL_BE_DELETED,
        onConfirm: () => {
          const deleteCommitteeDocumentQueryExecute =
            deletePaperDocumentQuery.execute;
          const getAgendaQueryExecute = getAgendaQuery.execute;
          deleteCommitteeDocumentQueryExecute(
            { paperId: id },
            {
              onSuccess: () => {
                getAgendaQueryExecute();
                setFile(null);
                notificationModal.success({
                  heading: MESSAGES.DOCUMENT_DELETED,
                });
              },
              onError: () => {
                notificationModal.error({
                  heading: MESSAGES.FAILED_TO_DELETED_DOCUMENT,
                });
              },
            }
          );
        },
      });
    },
    [
      deletePaperDocumentQuery.execute,
      getAgendaQuery.execute,
      notificationModal,
    ]
  );

  return (
    <>
      <div className="grid grid-cols-1 gap-x-4">
        <FormControl className="col-span-">
          <InputLabel
            required
            htmlFor={`${FN.PAPER.ROOT}.[${cdIndex}].${FN.PAPER.PAPER_SYMBOL}`}
          >
            Paper
          </InputLabel>
          <FormikInput
            type="text"
            id={`${FN.PAPER.ROOT}.[${cdIndex}].${FN.PAPER.PAPER_SYMBOL}`}
            name={`${FN.PAPER.ROOT}.[${cdIndex}].${FN.PAPER.PAPER_SYMBOL}`}
          />
        </FormControl>
        <FormControl>
          <InputLabel
            htmlFor={`${FN.PAPER.ROOT}.[${cdIndex}].${FN.PAPER.PAPER_DESCRIPTION}`}
          >
            Description
          </InputLabel>
          <FormikTextarea
            id={`${FN.PAPER.ROOT}.[${cdIndex}].${FN.PAPER.PAPER_DESCRIPTION}`}
            name={`${FN.PAPER.ROOT}.[${cdIndex}].${FN.PAPER.PAPER_DESCRIPTION}`}
            minRows={3}
            classNames={{ root: "grid-cols-2 gap-x-4 mb-1" }}
          />
        </FormControl>
        <FormControl>
          <InputLabel
            htmlFor={`${FN.PAPER.ROOT}.[${cdIndex}].${FN.PAPER.PAPER_SUMMARY}`}
          >
            Summary
          </InputLabel>
          <FormikTextarea
            id={`${FN.PAPER.ROOT}.[${cdIndex}].${FN.PAPER.PAPER_SUMMARY}`}
            name={`${FN.PAPER.ROOT}.[${cdIndex}].${FN.PAPER.PAPER_SUMMARY}`}
            minRows={3}
            classNames={{ root: "grid-cols-2 gap-x-4 mb-1" }}
          />
        </FormControl>
        <FormControl>
          <InputLabel
            htmlFor={`${FN.PAPER.ROOT}.[${cdIndex}].${FN.PAPER.SUBMITTED_BY}`}
          >
            Submitted By
          </InputLabel>
          <FormikInput
            id={`${FN.PAPER.ROOT}.[${cdIndex}].${FN.PAPER.SUBMITTED_BY}`}
            name={`${FN.PAPER.ROOT}.[${cdIndex}].${FN.PAPER.SUBMITTED_BY}`}
            className="ring-secondary-400"
          />
        </FormControl>
      </div>
      <div className="flex items-center">
        {!file ? (
          <>
            <Button
              variant="link"
              color="primary"
              className={cx("font-semibold py-0 px-0 m-0 leading-none")}
              onClick={handleOpenUpload(fileUploadRef)}
            >
              <span className="flex items-center gap-2">
                <UploadIcon />
                <span className="text-xs text-primary-button font-semibold mt-1">
                  Upload
                </span>
              </span>
            </Button>
            <input
              type="file"
              id={`${FN.PAPER.ROOT}.[${cdIndex}].${"document"}`}
              name={`${FN.PAPER.ROOT}.[${cdIndex}].${"document"}`}
              ref={fileUploadRef}
              className="hidden"
              onChange={handleDocumentUpload}
            />
          </>
        ) : (
          !file.s3_key && (
            <>
              <Button
                variant="link"
                color="primary"
                className={cx("font-semibold py-0 px-0 m-0 leading-none")}
                onClick={handleOpenUpload(fileUploadRef)}
              >
                <span className="flex items-center gap-2">
                  <UploadIcon />
                  <span className="text-xs text-primary-button font-semibold mt-1">
                    Upload
                  </span>
                </span>
              </Button>
              <input
                type="file"
                id={`${FN.PAPER.ROOT}.[${cdIndex}].${"document"}`}
                name={`${FN.PAPER.ROOT}.[${cdIndex}].${"document"}`}
                ref={fileUploadRef}
                className="hidden"
                onChange={handleDocumentUpload}
              />
            </>
          )
        )}
      </div>
      {file?.name && (
        <div className="relative flex flex-col tab-sm:flex-1 mt-2">
          <div className="w-full p-1 rounded bg-[#F3F4F5] flex justify-between border-[2px] border-[#F8F8F8;]">
            <div className="flex gap-2 items-center max-w-[90%] ml-2">
              <div className="flex flex-col">
                <p className="font-normal text-black-100 text-xs leading-[13px] break-all">
                  {file?.name}
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <DownloadThinIcon
                className={cx(
                  "text-primary-button ml-1.5 w-4 h-4 cursor-pointer"
                )}
                onClick={handleDownloadFile(file)}
              />
              <Divider className="w-[2px] h-full mx-3.5" />
              <BinIcon
                className={cx("w-4 h-4 cursor-pointer self-center")}
                onClick={handleDeleteFile}
              />
            </div>
          </div>
        </div>
      )}
      {file?.s3_key && (
        <div className="relative flex flex-col tab-sm:flex-1 mt-2">
          <div className="w-full p-1 rounded bg-[#F3F4F5] flex justify-between border-[2px] border-[#F8F8F8;]">
            <div className="flex gap-2 items-center max-w-[90%] ml-2">
              <div className="flex flex-col">
                <p className="font-normal text-black-100 text-xs leading-[13px] break-all">
                  {file?.file_name || file?.description}
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <DownloadThinIcon
                className={cx(
                  "text-primary-button ml-1.5 w-4 h-4 cursor-pointer"
                )}
                onClick={handleDownloadPaper(file)}
              />
              <Divider className="w-[2px] h-full mx-3.5" />
              <BinIcon
                className={cx("w-4 h-4 cursor-pointer self-center")}
                onClick={handleDeletePaper(file)}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
