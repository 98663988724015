import { HeaderRight } from "../../layouts/dashboard/header";
import withHOC from "../../utils/with-hoc";
import HeaderTitle from "../../components/header-title";
import { CreateCommitteePageProvider } from "./provider";
import { CreateCommitteeForm } from "./components/create-committee-form";
import { useLocation } from "react-router-dom";

function CreateCommitteePage() {
  const location = useLocation();
  const isCreateCommitteePage = location.pathname === "/create-committee";
  return (
    <>
      <div className="flex flex-1 flex-col overflow-hidden">
        <HeaderRight>
          <HeaderTitle>
            {isCreateCommitteePage
              ? "Create Shadow Committee"
              : "Edit Shadow Committee"}
          </HeaderTitle>
        </HeaderRight>
        <CreateCommitteeForm />
      </div>
    </>
  );
}

export default withHOC(CreateCommitteePageProvider, CreateCommitteePage);
