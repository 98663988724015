import * as Yup from "yup";
import moment from "moment";
import getValueByStringPath from "./get-value-by-string-path";
import MESSAGES from "../constants/messages";

export const isRequiredEMailString = (config = {}) => {
  let { withRequired = true, fieldName = "This field", message = "" } = config;

  let typeErrorMsg = MESSAGES.VALIDATION_STRING_MSG;
  let requiredErrorMsg = `${fieldName} is required`;
  let emailErrorMsg = `Value of ${fieldName} must be a valid email`;

  if (withRequired) {
    return Yup.string()
      .typeError(typeErrorMsg)
      .email(emailErrorMsg)
      .required(message || requiredErrorMsg);
  }
  return Yup.string().typeError(typeErrorMsg);
};

export const isRequiredString = (config = {}) => {
  let { withRequired = true, fieldName = "This field", message = "" } = config;

  let typeErrorMsg = MESSAGES.VALIDATION_STRING_MSG;
  let requiredErrorMsg = `${fieldName} is required`;

  if (withRequired) {
    return Yup.string()
      .typeError(typeErrorMsg)
      .required(message || requiredErrorMsg);
  }
  return Yup.string().typeError(typeErrorMsg);
};
export const isRequiredStringWithNull = (config = {}) => {
  let { withRequired = true, fieldName = "This field", message = "" } = config;

  let typeErrorMsg = MESSAGES.VALIDATION_STRING_MSG;
  let requiredErrorMsg = `${fieldName} is required`;

  if (withRequired) {
    return Yup.string()
      .transform((currentValue, originalValue) => {
        // mock null as false boolean value to allow nullable values in boolean check
        return originalValue === null ? "" : currentValue;
      })
      .typeError(typeErrorMsg)
      .nullable()
      .required(message || requiredErrorMsg);
  }
  return Yup.string()
    .transform((currentValue, originalValue) => {
      // mock null as false boolean value to allow nullable values in boolean check
      return originalValue === null ? "" : currentValue;
    })
    .typeError(typeErrorMsg);
};
export const isRequiredNumber = (config = {}) => {
  let { withRequired = true, fieldName = "This field", message = "" } = config;

  let typeErrorMsg = MESSAGES.VALIDATION_NUMBER_MSG;
  let requiredErrorMsg = `${fieldName} is required`;
  let nagativeNumberErrorMsg = `${fieldName} must be of positive number`;

  if (withRequired) {
    return Yup.number()
      .typeError(typeErrorMsg)
      .required(message || requiredErrorMsg)
      .positive(nagativeNumberErrorMsg);
  }
  return Yup.number()
    .transform((val, ogVal) => (ogVal?.trim?.() === "" ? null : val))
    .nullable()
    .typeError(typeErrorMsg);
};

export const isRequiredBoolean = (config = {}) => {
  let { withRequired = true, fieldName = "This field", message = "" } = config;

  let typeErrorMsg = MESSAGES.VALIDATION_BOOL_MSG;
  let requiredErrorMsg = `${fieldName} is required`;

  if (withRequired) {
    return Yup.bool()
      .transform((currentValue, originalValue) => {
        // mock null as false boolean value to allow nullable values in boolean check
        return originalValue === null ? false : currentValue;
      })
      .typeError(typeErrorMsg)
      .required(message || requiredErrorMsg);
  }
  return Yup.bool().nullable().typeError(typeErrorMsg);
};

export const isRequiredDate = (config = {}) => {
  let { withRequired = true, fieldName = "This field", message = "" } = config;
  let typeErrorMsg = MESSAGES.VALIDATION_DATE_MSG;
  let requiredErrorMsg = `${fieldName} is required`;
  if (withRequired) {
    return Yup.array()
      .of(Yup.date().typeError(typeErrorMsg).nullable())
      .nullable()
      .min(1, message || requiredErrorMsg)
      .nullable()
      .required(typeErrorMsg);
  }
  return Yup.array().of(Yup.date().typeError(typeErrorMsg)).required(typeErrorMsg);
};

export const checkMinDate = (config = {}) => {
  let { withRequired = true, fieldName = "This field", message = "" } = config;
  let typeErrorMsg = `${fieldName} should be future date`;
  let requiredErrorMsg = `This field is required`;
  if (withRequired) {
    return Yup.array()
      .of(
        Yup.date()
          .min(moment().subtract(1, "days"), message || typeErrorMsg)
          .nullable()
      )
      .nullable()
      .min(1, message || requiredErrorMsg)
      .required(typeErrorMsg);
  }
  return Yup.array().of(Yup.date().typeError(typeErrorMsg)).required(typeErrorMsg);
};

export const checkMaxDate = (config = {}) => {
  let { withRequired = true, fieldName = "This field", message = "" } = config;
  let typeErrorMsg = `${fieldName} should be past date`;
  let requiredErrorMsg = `This field is required`;
  if (withRequired) {
    return Yup.array()
      .of(Yup.date().max(moment().subtract(1, "days"), message || typeErrorMsg))
      .min(1, message || requiredErrorMsg)
      .required(typeErrorMsg);
  }
  return Yup.array().of(Yup.date().typeError(typeErrorMsg)).required(typeErrorMsg);
};

export const isCharString = (config = {}) => {
  let { withRequired = true, fieldName = "This field", message = "" } = config;

  let typeErrorMsg = MESSAGES.VALIDATION_CHARACTER_MSG;
  let requiredErrorMsg = `${fieldName} is required`;

  if (withRequired) {
    return Yup.string()
      .matches(/^[a-zA-Z\s]*$/, typeErrorMsg)
      .typeError(typeErrorMsg)
      .required(message || requiredErrorMsg);
  }
  return Yup.string().typeError(typeErrorMsg);
};

export const isArrayObject = (config = {}) => {
  let { withRequired = true, fieldName = "This field", message = "" } = config;

  let typeErrorMsg = `Value of ${fieldName} mutli select`;
  let requiredErrorMsg = `${fieldName} is required`;

  if (withRequired) {
    return Yup.array()
      .min(1, message || requiredErrorMsg)
      .typeError(typeErrorMsg)
      .required(message || requiredErrorMsg);
  }
  return Yup.string().typeError(typeErrorMsg);
};

export function test(keys, errorMessageFun, compareFun) {
  const _compareFun = (_, context) => {
    let fieldsValues = context.from[context.from.length - 1].value;
    let currentFieldValue = getValueByStringPath(fieldsValues, context.path);
    let compareFieldValues = keys.map((key) => {
      return getValueByStringPath(fieldsValues, key, context.options.index);
    });
    return compareFun(currentFieldValue, compareFieldValues, context);
  };
  return Yup.mixed().test("conditional-test", errorMessageFun, _compareFun);
}

export function multipleEmailsString(config = {}) {
  let { withRequired = true, fieldName = "This field", message = "", domain } = config;
  let requiredErrorMsg = `${fieldName} is required`;
  const domainExp = `^[A-Za-z0-9._%+-]+@${domain}$`;

  const validationSchema = Yup.array()
    .transform(function (value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value;
      }
      return originalValue ? originalValue.split(/[\s,]+/) : [];
    })
    .of(Yup.string().email(({ value }) => `${value} is not a valid email`));

  if (withRequired) {
    if (domain) {
      const mailRegExp = new RegExp(domainExp);
      return validationSchema.of(Yup.string().matches(mailRegExp, `Only @${domain} domains are allowed. `)).required(message || requiredErrorMsg);
    }
    return validationSchema.required(message || requiredErrorMsg);
  }

  if (domain) {
    const mailRegExp = new RegExp(domainExp);
    return validationSchema.of(Yup.string().matches(mailRegExp, `Only @${domain} domains are allowed. `));
  }

  return validationSchema;
}
