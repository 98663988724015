import { withConfig, getApi } from "./index";
const api = getApi();

export const login = withConfig((data, conf) => {
  const { username, password } = data;

  const config = conf({
    url: "/auth/login",
    method: "POST",
    auth: {
      username,
      password,
    },
  });
  return api(config);
});

export const getUserInfo = withConfig((data, conf) => {
  let config = conf({
    url: "/members/me",
    method: "GET",
  });
  return api(config);
});

export const changePassword = withConfig((data, conf) => {
  const { memberId, ...payload } = data;
  let config = conf({
    url: `/auth/change-password`,
    method: "POST",
    data: payload,
  });
  return api(config);
});
