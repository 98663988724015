const MESSAGES = {
  THIS_FIELD_IS_REQUIRED: "This field is required.",
  REQUIRED_FIELD: "This field is required.",
  FORM_DATA_SUCCESS: "Form saved.",
  FORM_DATA_PROGRESS: "Saving Form.",
  FORM_DATA_FAILED: "Failed to save form.",
  NO_FORMS: "No forms found.",
  NO_FORM: "No form found.",
  NO_SURVEY: "No reports found.",
  SIGN_IN_WRONG_DATA:"You will be redirected to the login page. please try logging in again.",

  VALIDATION_NUMBER_MSG: "The value must be numeric.",
  VALIDATION_STRING_MSG: "The value must be string.",
  VALIDATION_DATE_MSG: "The value must be date.",
  VALIDATION_CHARACTER_MSG: "The value must be character.",
  VALIDATION_BOOL_MSG: "The value must be bool.",

  UPLOAD_DOC_PROGRESS: "Uploading document.",
  UPLOAD_DOC_SUCCESS: "Document uploaded.",
  UPLOAD_DOC_FAILED: "Document uploading failed.",

  PREVIEW_SUCCESS: "Preview success.",
  PREVIEW_PROGRESS: "Generating preview.",
  PREVIEW_FAILED: "Failed to preview.",

  DELETE_DOC_CONFIRMATION:
    "The document will be deleted and cannot be retrieved.",
  DELETE_DOC_PROGRESS: "Deleting document.",
  DELETE_DOC_SUCCESS: "Document deleted.",
  DELETE_DOC_FAILED: "Failed to delete document.",

  SAVE_DOC_PROGRESS: "Adding document.",
  SAVE_DOC_SUCCESS: "Document added.",
  SAVE_DOC_FAILED: "Failed to save document.",

  ARE_YOU_SURE: "Are You Sure?",

  KINDLY_REFINE_THE_SEARCH_CRITERIA:
    "No data found. Please try changing the search criteria.",
  NO_RECORDS_FOUND: "No records found.",

  SESSION_EXPIRED: {
    TITLE: "Session Expired.",
    HEADING: "You will be redirected to the Login page.",
  },
  SIGN_IN_PROGRESS: "Signing in",
  ACCESS_DENIED: {
    TITLE: "Access is Denied.",
    HEADING: (
      <>
        You do not have permission to access this resource. Please check with
        Support Team.
      </>
    ),
  },

  TECHNICAL_ERROR: {
    TITLE: "Technical Error",
    HEADING: <>Please try again later or check with Support Team.</>,
  },
  FORM_SUBMIT_SUCCESS: "Form Submitted.",
  FORM_SUBMIT_PROGRESS: "Submitting Form.",
  FORM_SUBMIT_ERROR: "Failed to Submit Form.",

  MEMBER_REGISTERED_SUCCESSFULLY: "Member registered successfully",
  FAILED_TO_REGISTER_MEMBER: "Failed to register member",


  MEMBER_DELETED_SUCCESSFULLY: "Member deleted successfully",
  FAILED_TO_DELETE_MEMBER: "Failed to delete member",

  AGENDA_DELETED_SUCCESSFULLY: "Agenda deleted successfully",
  FAILED_TO_DELETE_AGENDA: "Failed to delete agenda",

  GROUP_CREATED_SUCCESSFULLY: "Group created successfully",
  GROUP_UPDATED_SUCCESSFULLY: "Group updated successfully",
  GROUP_DELETED_SUCCESSFULLY: "Group deleted successfully",

  FAILED_TO_CREATE_GROUP: "Failed to create group",
  FAILED_TO_UPDATE_GROUP: "Failed to update group",
  FAILED_TO_DELETE_GROUP: "Failed to delete group",

  MEMBER_UPDATED_SUCCESSFULLY: "Member details updated successfully",
  FAILED_TO_UPDATE_MEMBER: "Failed to update member",
  AGENDA_WILL_BE_DELETED: "Once an agenda is deleted it cannot be retrieved",
  MEMBER_WILL_BE_DELETED:
    "Before deleting a member, it is crucial to check if they exist in any committee",
  MEMBER_PASSWORD_RESET:
    "This will reset the member's password and send an email with the new password.",

  PAPER_WILL_BE_DELETED:"Once an paper is deleted it cannot be retrieved",
  PAPER_DELETED_SUCCESSFULLY: "Paper deleted successfully",
  FAILED_TO_DELETE_PAPER:"Failed to delete paper",

  PASSWORD_RESET_INPROGRESS: "Password reset in progress",
  PASSWORD_RESET_SUCCESSFUL: "Password reset successful",
  PASSWORD_RESET_FAILED: "Password reset failed",

  GROUP_WILL_BE_DELETED: "Group will be deleted",
  DOCUMENT_WILL_BE_DELETED: "Document will be deleted",
  DOCUMENT_DELETED: "Document deleted",
  FAILED_TO_DELETED_DOCUMENT: "Failed to delete document",

  SUCCESSFULLY_CREATED_COMMITTEE: "Successfully created committee",
  SUCCESSFULLY_UPDATED_COMMITTEE: "Successfully updated committee",
  UPDATING_COMMITTEE: "Updating committee",
  FAILED_TO_CREATE_COMMITTEE: "Failed to create committee",
  FAILED_TO_UPDATE_COMMITTEE: "Failed to update committee",

  FAILED_TO_CREATE_POST_MEETING_REPORT: "Failed to create Post meeting report.",
  POST_MEETING_REPORT_CREATED: "Post meeting report Created.",
  POST_MEETING_REPORT_SAVED: "Post meeting report saved.",
  FAILED_TO_SAVE_POST_MEETING_REPORT: "Failed to Save Post meeting report.",
  CANNOT_BE_EDITED_MEETING_REPORT: "The submitted form cannot be edited.",
  FILL_REQUIRED_FIELDS: "Please fill required fields.",

  SUCCESSFULLY_CREATED_TASK: "Successfully created task",
  FAILED_TO_CREATE_TASK: "Failed to create task",
  FAILED_TO_UPDATE_TASK: "Failed to update task",

  GROUP_FEEDBACK_CREATED_SUCCESSFULLY: "Group feedback created successfully",
  FAILED_TO_CREATE_GROUP_FEEDBACK: "Failed to create group feedback",

  COMMITTEE_FEEDBACK_CREATED_SUCCESSFULLY:
    "Committee feedback created successfully",
  FAILED_TO_CREATE_COMMITTEE_FEEDBACK: "Failed to create Committee feedback",
  UPDATE_COMMITTEE_FEEDBACK_SUCCESSFULLY:
    "Committee feedback updated successfully",
  FAIELD_TO_UPDATE_COMMITTEE_FEEDBACK: "Faield to update committee feedback",

  TASK_UPDATED_SUCCESSFULLY: "Task updated successfully",
  TASK_DELETED_SUCCESSFULLY: "Task deleted successfully",
  FAILED_TO_DELETE_TASK: "Failed to delete task",
  TASK_WILL_BE_DELETED: "Task will be deleted",

  SUCCESSFULLY_UPDATED_AGENDA: "Agenda updated successfully",
  FAILED_TO_UPDATE_AGENDA: "Failed to update agenda",

  AGENDA_ADDED_SUCCESSFULLY: "Agenda added successfully",
  FAILED_TO_ADD_AGENDA: "Failed to add agenda",

  REGULATION_ADDED_SUCCESSFULLY: "Regulation added successfully",
  FAILED_TO_ADD_REGULATION: "Failed to add Regulation",

  FAILED_TO_UPDATE_STATUS: "Failed to update status",

  GROUP_RESPONSE_CREATED: "Group response created.",
  GROUP_RESPONSE_UPDATED: "Group response updated.",
  FAILED_TO_CREATE_GROUP_RESPONSE: "Failed to create group response.",
  FAILED_TO_UPDATE_GROUP_RESPONSE: "Failed to create group response.",

  MEMBER_RESPONSE_CREATED: "Member response created.",
  MEMBER_RESPONSE_UPDATED: "Member response updated.",
  FAILED_TO_CREATE_MEMBER_RESPONSE: "Failed to create member response.",
  FAILED_TO_UPDATE_MEMBER_RESPONSE: "Failed to update member response.",

  REGULATION_WILL_BE_DELETED: "Regulation will be deleted",
  REGULATION_DELETED_SUCCESSFULLY: "Regulation deleted successfully",
  FAILED_TO_DELETE_REGULATION: "Failed to delete Regulation",




  INPROGRESS_MESSAGE : "Please wait as we set up things for you... This might take a few seconds." 
};

export default MESSAGES;
