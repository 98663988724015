import cx from "../../utils/class-names";

const colors = (props) => {
  const { variant, disabled, color } = props;
  let isOutlined = variant === "outline";
  let isLinked = variant === "link";
  let isDisabled = disabled;
  return {
    default: `
    border-gray-200 
    bg-white text-black-500
    active:bg-gray-400 hover:bg-gray-200`,
    primary: `
    border-primary-button 
    ${isOutlined ? "bg-transparent text-primary-button hover:text-primary-text" : "bg-primary-button text-white"} 
    ${isLinked ? "text-primary-brand active:text-primary-brand-800 hover:text-primary-brand-700 border-0" : ""} 
    ${isDisabled || isLinked ? "" : "active:bg-primary-button-800 hover:bg-primary-button-700"}`,
    secondary: `
    border-gray-500 
    ${isOutlined ? "bg-transparent text-gray-500 hover:text-white" : "bg-gray-500 text-white"}
    ${isLinked ? "text-gray-500 active:text-gray-600 hover:text-gray-400 border-0" : ""} 
    ${isDisabled || isLinked ? "" : "active:bg-gray-600 hover:bg-gray-400"}`,
    success: `
    border-green-500
    ${isOutlined ? "bg-transparent text-green-500 hover:text-white" : "bg-green-500 text-white"}
    ${isLinked ? "text-green-500 active:text-green-600 hover:text-green-400 border-0" : ""} 
    ${isDisabled || isLinked ? "" : "active:bg-green-600 hover:bg-green-400"}`,
    info: `
    border-sky-500
    ${isOutlined ? "bg-transparent text-sky-500 hover:text-white" : "bg-sky-500 text-white"}
    ${isLinked ? "text-sky-500 active:text-sky-600 hover:text-sky-400 border-0" : ""} 
    ${isDisabled || isLinked ? "" : "active:bg-sky-600 hover:bg-sky-400"}`,
    warning: `
    border-yellow-500
    ${isOutlined ? "bg-transparent text-yellow-500 hover:text-white" : "bg-yellow-500 text-white"}
    ${isLinked ? "text-yellow-500 active:text-yellow-600 hover:text-yellow-400 border-0" : ""} 
    ${isDisabled || isLinked ? "" : "active:bg-yellow-600 hover:bg-yellow-400"}`,
    danger: `
    border-red-500
    ${isOutlined ? "bg-transparent text-red-500 hover:text-white" : "bg-red-500 text-white"}
    ${isLinked ? "text-red-500 active:text-red-600 hover:text-red-400 border-0" : ""} 
    ${isDisabled || isLinked ? "" : "active:bg-red-600 hover:bg-red-400"}`,
  }[color];
};

const sizes = {
  xs: "px-2 py-1 text-xs",
  sm: "px-3 py-1.5 text-sm",
  md: "px-4 py-2 text-base",
  lg: "px-5 py-2.5 text-lg",
  xl: "px-6 py-3 text-xl",
  "2xl": "px-7 py-3.5 text-2xl",
};

export const useButtonClass = (props) => {
  const { size, disabled, variant, className } = props;
  let commonClasses = `border transition ease-in-out 
    duration-300 shadow-sm rounded whitespace-nowrap text-center`;
  let sizeClasses = sizes[size];

  let disabledClasses =
    disabled &&
    "bg-disabled-bg text-disabled-text border-disabled-text hover:bg-disabled-bg hover:text-disabled-text hover:border-disabled-text cursor-not-allowed";
  let linkedClasses = variant === "link" && `border-0 bg-transparent hover:underline  hover:border-0 active:border:0 shadow-none`;
  let colorClass = colors(props);

  return cx(commonClasses, sizeClasses, colorClass, linkedClasses, disabledClasses, className);
};
