import EllipseArrowIcon from "../../../components/vectors/ellipse-arrow-icon";
import DocumentCard from "./document-card";

export default function DocumentNav(props) {
  const { documents = [], activeIndex, onDirectionChange, onClick } = props;

  const handleDirectionChange = (direction) => (e) => {
    onDirectionChange && onDirectionChange(direction, e);
  };

  const handleClick = (document, documentIndex) => (e) => {
    onClick && onClick(document, documentIndex, e);
  };

  return (
    <div className="document-nav w-28 bg-gray-50 flex flex-col border-r-2">
      <div className="document-nav_cards flex-1 overflow-y-scroll p-1 pl-2">
        {documents.map((document, documentIndex) => {
          return (
            <DocumentCard
              key={document.id}
              title={document.symbol}
              active={documentIndex === activeIndex}
              onClick={handleClick(document, documentIndex)}
            />
          );
        })}
      </div>
      <div className="document-nav_actions bg-white flex px-2 py-4">
        <div
          className="cursor-pointer document-action_up flex-1 flex justify-center items-center"
          onClick={handleDirectionChange("up")}
        >
          <EllipseArrowIcon />
        </div>
        <div
          className="cursor-pointer document-action_down flex-1 flex justify-center items-center rotate-180"
          onClick={handleDirectionChange("down")}
        >
          <EllipseArrowIcon />
        </div>
      </div>
    </div>
  );
}
