import { useLayoutEffect, useCallback, useRef } from "react";

//React hook to persist any value between renders, but keeps it up-to-date if it changes.
export default function useCallbackRef(fn = undefined, deps = []) {
  const ref = useRef(fn);

  useLayoutEffect(() => {
    ref.current = fn;
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback((...args) => ref.current?.(...args), deps);
}
