import { useCallback, useMemo, useRef, useState } from "react";
import generateContext from "../../utils/generate-context";
import useCustomNavigate from "../../hooks/use-custom-navigate";
import * as ListsApi from "../../apis/lists.api";
import * as CommitteeApi from "../../apis/committee.api";
import * as MemeberApi from "../../apis/members.api";
import * as GroupApi from "../../apis/groups.api";
import { useAsync } from "@react-org/hooks";
import {
  FN,
  generatePayload,
  getAgendaDetailsRow,
  getFormInitialValues,
  getReportValidationSchema,
} from "./components/form-helper";
import { useParams } from "react-router-dom";
import { useNotificationModalContext } from "../../components/notification-modal/provider";
import MESSAGES from "../../constants/messages";
import generateFileUrl from "../../utils/generate-file-url";
import { downloadOrPreviewFileUrl } from "../../utils/download-or-preview-file-url";
import { useUserFromStorage } from "../../contexts/user.context";
import useSneaky from "../../hooks/use-sneaky";

const useCreatePostMeetingPage = () => {
  const [isLeader, setIsLeader] = useState(false);
  const [isCoordinator, setIsCoordinator] = useState(false);
  const [isGroupLeader, setIsGroupLeader] = useState(false);
  const [groupId, setGroupId] = useState(null);

  const notificationModal = useNotificationModalContext();
  const { committeeId } = useParams();
  const { goBack } = useCustomNavigate();
  const formRef = useRef(null);
  const { user } = useUserFromStorage();
  const containerRef = useRef(null);
  const footerRef = useRef(null);
  useSneaky(containerRef, footerRef);


  const userId = user?.id;
  const [isAddTaskDrawerOpen, setIsAddTaskDrawerOpen] = useState(false);

  const getPostMeetingQuery = useAsync(
    useCallback(
      (payload) =>
        CommitteeApi.getPostMeeting({ committeeId: committeeId, ...payload }),
      [committeeId]
    ),
    {
      immediate: true,
    }
  );

  const createTaskQuery = useAsync(GroupApi.createTask, {
    immediate: false,
  });

  const getPostMeetingQueryData = getPostMeetingQuery?.data?.data?.data[0];

  const commiteeMembers = getPostMeetingQueryData?.commitee?.commitee_members;

  const [isSubmitted, setIsSubmitted] = useState(
    getPostMeetingQueryData?.is_submitted
  );

  const reportValidationSchema = useMemo(() => {
    return getReportValidationSchema();
  }, []);

  const validationSchemaOnSubmit =
    isSubmitted === true && reportValidationSchema;
  const getAgendaQuery = useAsync(
    useCallback(
      (payload) => ListsApi.getAgenda({ limit: 100, ...payload }),
      []
    ),
    { immediate: true }
  );

  const getMemberQuery = useAsync(
    useCallback((payload) => MemeberApi.getMembers(payload), []),
    { immediate: true }
  );

  const createPostReportQuery = useAsync(CommitteeApi.createPostMeetingReport, {
    immediate: false,
  });

  const previewPostReportQuery = useAsync(
    CommitteeApi.PreviewPostMeetigReport,
    {
      immediate: false,
    }
  );

  const checkUserRole = useCallback(
    (members, roleId, id) =>
      members?.some((member) => {
        const isLeader = member?.group_members?.["is_leader"] || false;
        return (
          (isLeader ? member?.group_members[roleId] : member[roleId]) ===
            true && member[id] === userId
        );
      }),
    [userId]
  );

  useMemo(() => {
    if (commiteeMembers && userId) {
      setIsLeader(checkUserRole(commiteeMembers, "is_leader", "member_id"));
      setIsCoordinator(
        checkUserRole(commiteeMembers, "is_coordinator", "member_id")
      );
      setIsGroupLeader(
        checkUserRole(
          getPostMeetingQueryData?.commitee?.groups?.flatMap(
            (group) => group?.members || []
          ),
          "is_leader",
          "id"
        )
      );
    }
  }, [
    checkUserRole,
    commiteeMembers,
    getPostMeetingQueryData?.commitee?.groups,
    userId,
  ]);

  const isFormSubmitted = getPostMeetingQueryData?.is_submitted;
  const isAdmin = user?.is_admin;

  const initialValues = useMemo(() => {
    const postMeetingReport = getPostMeetingQueryData;
    const agendaList = getAgendaQuery?.data?.data?.data;

    return getFormInitialValues(postMeetingReport, agendaList);
  }, [getAgendaQuery?.data?.data?.data, getPostMeetingQueryData]);

  const handleAddAgenda = useCallback(
    (arrayHelpers) => (tabId) => {
      arrayHelpers.push({ ...getAgendaDetailsRow() });
    },
    []
  );
  const handleAgendaDelete = useCallback(
    (arrayHelpers, itemIndex) => () => {
      arrayHelpers.remove(itemIndex);
    },
    []
  );

  const handleRemoveMember = useCallback(
    (id) => () => {
      const selectedMembers =
        formRef?.current?.values[FN.LIST_OF_INDIAN_DELEGATION];
      const setFieldValue = formRef?.current?.setFieldValue;
      const updatedMembers = selectedMembers?.filter((item) => item !== id);
      setFieldValue(FN.LIST_OF_INDIAN_DELEGATION, updatedMembers);
    },
    []
  );

  const handleSubmit = useCallback(
    (formData) => {
      const payload = generatePayload(formData);
      payload.committeeId = committeeId;
      payload.is_submitted = isSubmitted === true ? true : false;

      const createPostReportQueryExecute = createPostReportQuery.execute;
      notificationModal.progress({
            heading: MESSAGES.INPROGRESS_MESSAGE,
      })
      createPostReportQueryExecute(payload, {
        onSuccess: (res) => {
          isSubmitted
            ? notificationModal.success({
                heading: MESSAGES.POST_MEETING_REPORT_CREATED,
              })
            : notificationModal.success({
                heading: MESSAGES.POST_MEETING_REPORT_SAVED,
              });
          getPostMeetingQuery.execute();
        },
        onError: () => {
          isSubmitted
            ? notificationModal.error({
                heading: MESSAGES.FAILED_TO_CREATE_POST_MEETING_REPORT,
              })
            : notificationModal.error({
                heading: MESSAGES.FAILED_TO_SAVE_POST_MEETING_REPORT,
              });
        },
      });
    },
    [
      committeeId,
      createPostReportQuery.execute,
      getPostMeetingQuery,
      isSubmitted,
      notificationModal,
    ]
  );

  const handleSaveButton = useCallback(
    (formik) => (e) => {
      setIsSubmitted(false);
      formik.submitForm();
    },
    []
  );
  const handleAddTask = useCallback(
    (groupId) => () => {
      setIsAddTaskDrawerOpen(true);
      setGroupId(groupId);
    },
    []
  );
  const handleCreateTask = useCallback(
    (data) => {
      const { papers, due_date, task_detail } = data;
      const payload = {
        papers,
        timeline: due_date,
        id: groupId,
        details: task_detail,
      };
      const createTaskQueryExecute = createTaskQuery.execute;
      notificationModal.progress({
        heading: MESSAGES.INPROGRESS_MESSAGE,
      });
      createTaskQueryExecute(payload, {
        onSuccess: () => {
          setIsAddTaskDrawerOpen(false);
          notificationModal.close();
        },
        onError: () => {
          notificationModal.error({
            heading: MESSAGES.FAILED_TO_CREATE_TASK,
          });
        },
      });
    },
    [createTaskQuery.execute, groupId, notificationModal]
  );

  const handleSubmitButton = useCallback(
    (formik) => async (e) => {
      setIsSubmitted(true);
      await formik.validateForm();
      if (Object.keys(formik.errors).length === 0) {
        notificationModal.warning({
          title: MESSAGES.ARE_YOU_SURE,
          heading: MESSAGES.CANNOT_BE_EDITED_MEETING_REPORT,
          onConfirm: () => {
            formik.submitForm();
          },
        });
      } else {
        notificationModal.error({
          heading: MESSAGES.FILL_REQUIRED_FIELDS,
        });
      }
    },
    [notificationModal]
  );

  const handlePostMeetingPreview = useCallback(() => {
    const previewPostReportQueryExecute = previewPostReportQuery.execute;
    const payload = {};
    payload.committeeId = committeeId;
    payload.reportId = getPostMeetingQueryData?.id;

    notificationModal.progress({ heading: MESSAGES.INPROGRESS_MESSAGE });

    previewPostReportQueryExecute(payload, {
      onSuccess: (res) => {
        const fileURL = generateFileUrl(res?.data, res.headers["content-type"]);

        downloadOrPreviewFileUrl(fileURL, `post-meeting-report.pdf`, {
          type: res.headers["content-type"],
        });

        notificationModal.close();
      },
      onError: () => {
        notificationModal.error({ heading: MESSAGES.PREVIEW_FAILED });
      },
    });
  }, [
    committeeId,
    getPostMeetingQueryData?.id,
    notificationModal,
    previewPostReportQuery.execute,
  ]);

  const handleChangeAgenda = useCallback(
    (formik) => () => {
      formik.setFieldValue(FN.PAPER, []);
    },
    []
  );
  const handleAddTaskDrawerToggle = useCallback(() => {
    setIsAddTaskDrawerOpen((prevState) => !prevState);
  }, []);

  return useMemo(() => {
    return {
      goBack,
      getAgendaQuery,
      initialValues,
      handleAddAgenda,
      handleAgendaDelete,
      getPostMeetingQuery,
      getMemberQuery,
      handleRemoveMember,
      formRef,
      handleSubmit,
      reportValidationSchema,
      handleSaveButton,
      handleSubmitButton,
      setIsSubmitted,
      isSubmitted,
      validationSchemaOnSubmit,
      handlePostMeetingPreview,
      isLeader,
      isCoordinator,
      isGroupLeader,
      isFormSubmitted,
      isAdmin,
      isAddTaskDrawerOpen,
      handleAddTask,
      handleChangeAgenda,
      handleAddTaskDrawerToggle,
      handleCreateTask,
      containerRef,
      footerRef
    };
  }, [goBack, getAgendaQuery, initialValues, handleAddAgenda, handleAgendaDelete, getPostMeetingQuery, getMemberQuery, handleRemoveMember, handleSubmit, reportValidationSchema, handleSaveButton, handleSubmitButton, isSubmitted, validationSchemaOnSubmit, handlePostMeetingPreview, isLeader, isCoordinator, isGroupLeader, isFormSubmitted, isAdmin, isAddTaskDrawerOpen, handleAddTask, handleChangeAgenda, handleAddTaskDrawerToggle, handleCreateTask]);
};

export const [CreatePostMeetingPorvider, useCreatePostMeetingPageContext] =
  generateContext(useCreatePostMeetingPage);
