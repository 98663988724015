function PdfIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={35}
      height={38}
      viewBox="0 0 35 38"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_49_50)" fill="#CA2438">
        <path d="M5.024 0C3.695 0 2.42.5 1.48 1.391A4.627 4.627 0 00.012 4.75v28.5c0 1.26.528 2.468 1.468 3.359A5.157 5.157 0 005.024 38h25.064c1.33 0 2.604-.5 3.544-1.391a4.628 4.628 0 001.469-3.359V4.75c0-1.26-.529-2.468-1.468-3.359A5.157 5.157 0 0030.087 0H5.024zm0 2.375h25.064c.665 0 1.302.25 1.772.696.47.445.734 1.05.734 1.679v28.5c0 .63-.264 1.234-.734 1.68a2.578 2.578 0 01-1.772.695H5.024c-.664 0-1.302-.25-1.772-.696a2.314 2.314 0 01-.734-1.679V4.75c0-.63.264-1.234.734-1.68a2.578 2.578 0 011.772-.695z" />
        <path d="M8.42 29.256a1.987 1.987 0 01-1.098-.997c-.489-.922-.326-1.843.2-2.617.496-.73 1.319-1.35 2.248-1.87a19.817 19.817 0 013.715-1.531 45.754 45.754 0 002.662-5.29c-.46-.99-.821-2.02-1.078-3.075-.216-.95-.298-1.89-.116-2.698.189-.84.687-1.596 1.63-1.955.48-.183 1.002-.285 1.509-.183a1.8 1.8 0 01.7.306c.207.15.376.342.495.561.22.39.3.846.318 1.278.018.444-.03.938-.117 1.458-.211 1.212-.677 2.694-1.304 4.261a25.722 25.722 0 002.456 4.004 15.207 15.207 0 013.344.12c.912.154 1.84.462 2.406 1.104.3.341.484.76.501 1.23.018.456-.117.907-.346 1.337-.197.398-.504.74-.887.988a2.236 2.236 0 01-1.278.328c-.83-.034-1.64-.466-2.338-.99a13.928 13.928 0 01-2.284-2.257 30.61 30.61 0 00-5.005.964 26.823 26.823 0 01-2.559 3.587c-.727.83-1.524 1.555-2.32 1.869a2.09 2.09 0 01-1.455.069zm3.456-4.514c-.416.18-.802.37-1.15.565-.823.46-1.357.91-1.622 1.299-.236.344-.24.594-.1.857.025.053.05.086.065.105a.702.702 0 00.087-.029c.344-.133.89-.558 1.592-1.358.399-.463.775-.943 1.128-1.44zm4.11-3.16a33.24 33.24 0 012.532-.457 27.412 27.412 0 01-1.279-2.038c-.393.842-.81 1.674-1.253 2.494v.002zm6.13 1.07a9.65 9.65 0 001.091.973c.601.452 1.02.601 1.248.608a.281.281 0 00.176-.035.735.735 0 00.235-.297.996.996 0 00.148-.475.22.22 0 00-.065-.15c-.13-.147-.501-.36-1.298-.496-.507-.08-1.02-.123-1.534-.126v-.002zm-4.987-8.327c.211-.645.378-1.302.501-1.967a5.31 5.31 0 00.096-1.104c0-.16-.027-.319-.08-.47a1.355 1.355 0 00-.364.095c-.218.083-.396.252-.491.672-.1.456-.076 1.114.115 1.952.06.264.135.54.226.822h-.003z" />
      </g>
      <defs>
        <clipPath id="clip0_49_50">
          <path fill="#fff" d="M0 0H35V38H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default PdfIcon;
