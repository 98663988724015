import cx from "../../../utils/class-names";
import {} from "../../../utils/generate-names";

function HeaderSection(props) {
  return (
    <div
      className={cx(
        "p-2 shadow-none flex justify-between items-center tab-sm:flex tab-xs:block mt-2"
      )}
    >
      <div className="grid grid-cols-10 space-x-2 w-full items-center mb-1 text-xs bg-primary-text p-2">
        <div className="flex flex-col col-span-4">
          <label className="text-sm font-medium font-sans text-secondary-0 leading-[18px]">
            Task Details
          </label>
        </div>
        <div className="flex flex-col col-span-3">
          <label className="text-sm font-medium font-sans text-secondary-0 leading-[18px]">
            Agenda Item / Paper
          </label>
        </div>
        <div className="flex flex-col col-span-2">
          <label className="text-sm font-medium font-sans text-secondary-0 leading-[18px]">
            Assigned to
          </label>
        </div>
        <div className="flex flex-col col-span-1">
          <label className="text-sm font-medium font-sans text-secondary-0 leading-[18px]">
            Timeline
          </label>
        </div>
      </div>
    </div>
  );
}
export default HeaderSection;
