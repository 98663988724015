function FolderOpenIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={25} height={20} fill="none" {...props}>
      <path d="M21.6 2.5H10.8L8.4 0h-6C1.08 0 0 1.125 0 2.5v5h24V5c0-1.375-1.08-2.5-2.4-2.5Z" fill="#FFA000" />
      <path
        d="M2.3 4.5h19.2c.453 0 .948.286 1.354.796.402.505.646 1.148.646 1.704v10.5c0 1.118-.875 2-1.9 2H2.4c-1.025 0-1.9-.882-1.9-2V7c0-.58.21-1.23.563-1.726.355-.5.796-.774 1.237-.774Z"
        fill="#fff"
        stroke="#C48317"
      />
      <path
        d="M.6 8.359v-.006c0-.498.181-.806.452-1.006C1.345 7.13 1.802 7 2.4 7h19.2c.599 0 1.117.13 1.47.362.33.217.53.528.53.989v.002l-.1 9.306v.005c0 .996-.843 1.836-1.9 1.836H2.4c-1.056 0-1.899-.839-1.9-1.833l.1-9.308Z"
        fill="#fff"
        stroke="#C38317"
      />
      <path
        d="M21.6 9H2.4C1.08 9 0 9.798 0 10.774v7.452C0 19.202 1.08 20 2.4 20h19.2c1.32 0 2.4-.798 2.4-1.774v-7.452C24 9.798 22.92 9 21.6 9Z"
        fill="#FFCA28"
      />
    </svg>
  );
}

export default FolderOpenIcon;
