import is from "../../utils/is";
import Portal from "../portal";
import cx from "../../utils/class-names";
import useKeyPress from "../../hooks/use-key-press";
import { createContext, useContext, useMemo, useCallback } from "react";
import { useControllableState } from "../../hooks/use-controllable-state";
import PropTypes from "prop-types";

const DrawerContext = createContext({});

const Drawer = (props) => {
  const {
    children,
    open,
    onClose,
    onOverlayClick,
    onEscapeClick,
    classNames = {},
    className,
    closeOnOverlay = true,
    closeOnEscape = true,
    defaultOpen = false,
    ...restProps
  } = props;

  const [value, updateValue] = useControllableState({
    value: open,
    onChange: onClose,
    defaultValue: defaultOpen,
  });

  const handleClose = useCallback(() => {
    updateValue(() => false);
  }, [updateValue]);

  const handleOverlayClick = useCallback(() => {
    if (onOverlayClick) {
      onOverlayClick();
    } else {
      closeOnOverlay && updateValue(() => false);
    }
  }, [closeOnOverlay, onOverlayClick, updateValue]);

  useKeyPress(document, "Escape", (e) => {
    onEscapeClick && onEscapeClick(e);
    if (onEscapeClick) {
      onEscapeClick(e);
    } else {
      closeOnEscape && updateValue(() => false);
    }
  });

  const values = useMemo(() => {
    return {
      open: value,
      onClose: handleClose,
    };
  }, [handleClose, value]);

  return (
    <Portal>
      {value ? (
        <DrawerContext.Provider value={values}>
          <div className={cx("drawer z-[1000] fixed top-0 left-0 w-full h-full overflow-hidden", classNames.root, className)} {...restProps}>
            <div className={cx("h-full w-1/3 bg-secondary-0 float-right", classNames.content)}>{is.function(children) ? children(values) : children}</div>
            <div className={cx("z-[-1] h-full w-full bg-secondary-900 bg-opacity-25", classNames.overlay)} onClick={handleOverlayClick}></div>
          </div>
        </DrawerContext.Provider>
      ) : null}
    </Portal>
  );
};

export function useDrawer() {
  const context = useContext(DrawerContext);
  if (context === undefined) {
    throw new Error("useDrawer must be used within a Drawer");
  }
  return context;
}

Drawer.defaultProps = {
  open: false,
  classNames: {
    root: "",
    content: "",
    overlay: "",
  },
  closeOnOverlay: true,
  closeOnEscape: true,
  defaultOpen: false,
  onOverlayClick: null,
};

Drawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onOverlayClick: PropTypes.func,
  onEscapeClick: PropTypes.func,
  classNames: PropTypes.shape({
    root: PropTypes.string,
    content: PropTypes.string,
    overlay: PropTypes.string,
  }),
  closeOnOverlay: PropTypes.bool,
  closeOnEscape: PropTypes.bool,
  defaultOpen: PropTypes.bool,
};

export default Drawer;
