import { CellMeasurerCache } from "react-virtualized";
import { useCallback, useMemo, useEffect, useState } from "react";

import useRows from "./useRows";
import debounce from "../../utils/debounce";
import useWindowSize from "../../hooks/use-window-size";
import usePrevious from "../../hooks/use-previous";
import is from "../../utils/is";
import useForceUpdate from "../../hooks/use-force-update";
import Row from "./row";

export default function useList(props) {
  const {
    rows: _rows = [],
    renderRow,
    renderSkeletonRow,
    defaultHeight,
    loadMore: _loadMore,
    hasMore,
    threshold = 1,
    isLoading = false,
    loadingRowCount = 1,
    renderNoRows = () => null,
    isLoadMoreDirty,
    classNames,
    listRef,
  } = props;

  const forceUpdate = useForceUpdate();
  const { rows, rowCount, prevRowCount } = useRows(_rows, loadingRowCount);
  const [scrollTop, setScrollTop] = useState(0);
  const size = useWindowSize();
  const previousSize = usePrevious(size);
  const cache = useMemo(
    () =>
      new CellMeasurerCache({
        defaultHeight: defaultHeight,
        fixedWidth: true,
      }),
    [defaultHeight]
  );

  const isRowLoaded = useCallback(
    (index) => {
      return index < rowCount;
    },
    [rowCount]
  );

  const rowRenderer = useCallback(
    ({ index, key, style, parent }) => {
      let rowToRender;
      let row = rows[index];

      let isSkeletonRow = row.isSkeletonRow;
      if (isSkeletonRow) {
        rowToRender = renderSkeletonRow && renderSkeletonRow({ row, index, key, style: {}, rowCount });
      } else if (renderRow) {
        rowToRender = renderRow && renderRow({ row, index, key, style: {}, rowCount });
      }

      if (rowToRender === undefined) return null;

      return (
        <Row cache={cache} key={key} rowKey={key} parent={parent} rowIndex={index} style={style} listRef={listRef}>
          {rowToRender}
        </Row>
      );
    },
    [cache, listRef, renderRow, renderSkeletonRow, rowCount, rows]
  );

  const loadMore = debounce(function (pageNumber) {
    _loadMore && _loadMore(pageNumber);
  }, 150);

  const loadMoreRows = useCallback(
    ({ stopIndex }) => {
      if (stopIndex + 1 === rowCount && hasMore && !isLoading) {
        loadMore(Number(stopIndex) + 1);
      }
    },
    [rowCount, hasMore, isLoading, loadMore]
  );

  const scrollToTop = useCallback(() => {
    setScrollTop(0);
  }, []);

  const handleListScroll = useCallback(({ scrollToTop }) => {
    setScrollTop(scrollToTop);
  }, []);

  const isRenderNoRows = isLoadMoreDirty && !isLoading && rowCount - loadingRowCount === 0;

  const getInfiniteLoaderProps = useCallback(() => {
    return {
      rowCount,
      isRowLoaded,
      loadMoreRows,
      threshold,
    };
  }, [isRowLoaded, loadMoreRows, rowCount, threshold]);

  const getListProps = useCallback(() => {
    return {
      rowCount,
      rowHeight: cache.rowHeight,
      rowRenderer,
      deferredMeasurementCache: cache,
      scrollTop,
      onScroll: handleListScroll,
      scrollToTop,
    };
  }, [cache, handleListScroll, rowCount, rowRenderer, scrollToTop, scrollTop]);

  const clearCache = useCallback(
    (clearCacheStartIndex = 0) => {
      if (is.undefined(clearCacheStartIndex) && clearCacheStartIndex < 0) return;
      if (clearCacheStartIndex < 10) {
        clearCacheStartIndex = 0;
      }
      for (let i = clearCacheStartIndex; i < rowCount; i++) {
        cache.clear(i);
      }
      listRef?.current?.forceUpdateGrids && listRef?.current?.forceUpdateGrids();
      listRef?.current?.recomputeGridSize && listRef?.current?.recomputeGridSize();
      forceUpdate();
    },
    [cache, forceUpdate, listRef, rowCount]
  );

  useEffect(() => {
    // NOTE: check previous width and current width based on that clear cache
    let clearCacheStartIndex;

    if (previousSize?.width !== size?.width) {
      clearCacheStartIndex = 0;
    } else {
      clearCacheStartIndex = prevRowCount - loadingRowCount;
    }
    clearCache(clearCacheStartIndex);
  }, [clearCache, loadingRowCount, prevRowCount, previousSize?.width, size?.width]);

  useEffect(() => {
    let listRefCurrent = listRef?.current;
    if (listRefCurrent) {
      listRefCurrent.clearCache = clearCache;
    }
    return () => {
      if (listRefCurrent) {
        listRefCurrent.clearCache = () => {};
      }
    };
  }, [clearCache, listRef]);

  return {
    isRenderNoRows,
    renderNoRows,
    getInfiniteLoaderProps,
    getListProps,
    classNames,
  };
}
