const UploadIcon = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      fill="none"
      {...props}
    >
      <path
        stroke="#00B3B0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M16.5 11.468v3.325a1.668 1.668 0 0 1-1.667 1.662H3.167A1.669 1.669 0 0 1 1.5 14.794v-3.325m3.333-4.156L9 1.992m0 0 4.167 5.32M9 1.992v10.973"
      />
    </svg>
  )
  export default UploadIcon