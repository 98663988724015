const AcceptIcon2 = (props) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" />
      <rect x={4.4} y={7.4} width={12.1737} height={2.77895} rx={1.38947} fill="#DFF" stroke="#004676" strokeWidth={0.8} />
      <rect x={4.4} y={11.8737} width={12.1737} height={2.77895} rx={1.38947} fill="#DFF" stroke="#004676" strokeWidth={0.8} />
      <circle cx={15.4078} cy={15.2763} r={3.85} fill="#DDFEFE" stroke="#004676" strokeWidth={0.8} />
      <path d="M13.842 15.276l1.053 1.119 2.526-2.684" stroke="#004676" strokeWidth={0.8} strokeLinejoin="round" />
    </svg>
  );
};

export default AcceptIcon2;
