import Accordion from "../../../components/accordion";
import ArrowIcon from "../../../components/vectors/arrow-icon";
import DownloadThinIcon from "../../../components/vectors/download-thin-icon";
import PdfIcon from "../../../components/vectors/pdf-icon";
import cx from "../../../utils/class-names";
import { generateFullName } from "../../../utils/generate-names";
import { useGroupFeedbackPageContext } from "../provider";

export const TaskResponse = ({ item }) => {
  const feedbackDocuments = item.feedback_documents;
  const response = item?.response;
  const comments = item?.comment;
  const memberName = generateFullName(item?.member);
  const { handleDownloadDocument } = useGroupFeedbackPageContext();
  return (
    <Accordion defaultValue={true} className="w-full">
      {({ expanded }) => (
        <>
          <Accordion.Header
            className={cx(
              "bg-primary-text border-primary-text text-secondary-0 border-t-2 border-x-2 rounded-t-md p-1 flex flex-wrap justify-between"
            )}
          >
            <label className="text-black text-sm font-sans font-semibold ml-1">
              {memberName || "-"}
            </label>
            <span className="flex items-center gap-1">
              <label className="bg-secondary-0 text-primary-text text-sm font-sans font-semibold ml-1 px-1 rounded">
                {response}
              </label>
              <div
                className={cx(
                  "flex items-center justify-center cursor-pointer w-6 h-6 p-0 bg-transparent text-secondary-800 rotate-180",
                  expanded && "rotate-0"
                )}
              >
                <ArrowIcon />
              </div>
            </span>
          </Accordion.Header>
          <Accordion.Content
            className={cx(
              "overflow-hidden border-x-2 border-b-2 rounded-b-md h-0",
              expanded && "h-auto"
            )}
          >
            <div
              className="text-sm font-sans font-medium p-2"
              dangerouslySetInnerHTML={{ __html: comments }}
            />
            {Array.isArray(feedbackDocuments) &&
              feedbackDocuments.map((doc, i) => {
                return (
                  <div
                    className="relative flex flex-col tab-sm:flex-1 mb-2"
                    key={i}
                  >
                    <div className="w-full p-1 rounded bg-[#F3F4F5] flex justify-between border-[2px] border-[#F8F8F8;]">
                      <div className="flex gap-2 items-center max-w-[90%]">
                        <div>
                          <PdfIcon className="h-6 w-5" />
                        </div>
                        <div className="flex flex-col">
                          <p className="font-normal text-black-100 text-xs leading-[13px] break-all">
                            {doc?.name}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <DownloadThinIcon
                          className={cx(
                            "text-primary-button ml-1.5 w-4 h-4 cursor-pointer mr-1.5"
                          )}
                          onClick={handleDownloadDocument(doc)}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
          </Accordion.Content>
        </>
      )}
    </Accordion>
  );
};
