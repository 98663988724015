import cx from "../../utils/class-names";
import PropTypes from "prop-types";

function MessageText(props) {
  const { children, className } = props;
  return <div className={cx("message-text flex justify-center items-center w-full", className)}>{children}</div>;
}

MessageText.defaultProps = {
  className: "",
};

MessageText.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default MessageText;
