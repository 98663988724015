import Button from "../../components/button";
import HeaderTitle from "../../components/header-title";
import ToggleButton from "../../components/toggle-button";
import Arrow2Icon from "../../components/vectors/arrow2-icon";
import { HeaderLeft, HeaderRight } from "../../layouts/dashboard/header";
import withHOC from "../../utils/with-hoc";
import TaskDetails from "./components/task-details";
import { TaskResponse } from "./components/task-response";
import {
  GroupFeedbackPageProvider,
  useGroupFeedbackPageContext,
} from "./provider";

function GroupFeedbackPage() {
  const {
    goBack,
    handleSubmit,
    handleStatusChange,
    status,
    feedbackList,
    getTaskFeedbackQuery,
    getPaperDetailsQuery
  } = useGroupFeedbackPageContext();
  const feedbackDetails = getTaskFeedbackQuery?.data?.data?.data;
  const paperDetails = getPaperDetailsQuery?.data?.data;
  const pageTitle = `${paperDetails?.agenda?.title} - ${paperDetails?.symbol}`;

  return (
    <div className="w-full gap-4 flex">
      <HeaderRight>
        <Button
          variant="outline"
          color="primary"
          className="h-9 flex items-center gap-1 hover:bg-primary-button hover:text-secondary-0"
          onClick={goBack}
          icon={<Arrow2Icon className="rotate-180" />}
        >
          Back
        </Button>
        <HeaderTitle className="ml-2">{pageTitle}</HeaderTitle>
      </HeaderRight>
      <HeaderLeft className="">
        <ToggleButton onChange={handleStatusChange} checked={status}>
          Mark as complete
        </ToggleButton>
      </HeaderLeft>
      <div className="w-[60%] py-2 px-3 overflow-y-scroll">
        <div className="font-semibold text-black-100 text-base leading-5 my-2">
          Group Members Feedback
        </div>
        <div className="grid grid-cols-1 gap-3 mt-3">
          {(feedbackDetails || []).map((item) => {
            return <TaskResponse key={item.id} item={item} />;
          })}
        </div>
      </div>
      <div className="w-[40%] py-2 px-3 overflow-y-scroll bg-blue-800">
        <TaskDetails handleSubmit={handleSubmit} feedbackList={feedbackList} />
      </div>
    </div>
  );
}

export default withHOC(GroupFeedbackPageProvider, GroupFeedbackPage);
