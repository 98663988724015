import cx from "../../utils/class-names";

const BinIcon = (props) => {
  const { className, ...restProps } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      fill="none"
      className={cx("text-red-500", className)}
      {...restProps}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M3 5.25h12M7.5 8.25v4.5M10.5 8.25v4.5M3.75 5.25l.75 9a1.5 1.5 0 0 0 1.5 1.5h6a1.5 1.5 0 0 0 1.5-1.5l.75-9M6.75 5.25V3a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75v2.25"
      />
    </svg>
  );
};
export default BinIcon;
