import Const from "./index";

const Enums = {
  OPTIONS_YES_NO: [
    { label: Const.YES.LABEL, value: Const.YES.VALUE },
    { label: Const.NO.LABEL, value: Const.NO.VALUE },
  ],
  OPTIONS_YES_NO_NA: [
    { label: Const.YES.LABEL, value: Const.YES.VALUE },
    { label: Const.NO.LABEL, value: Const.NO.VALUE },
    { label: Const.NA.LABEL, value: Const.NA.VALUE },
  ],
  OPTIONS_TRUE_FALSE_NULL: [
    { label: Const.YES.LABEL, value: true },
    { label: Const.NO.LABEL, value: false },
    { label: Const.NA.LABEL, value: null },
  ],
  OPTIONS_TRUE_FALSE: [
    { label: Const.YES.LABEL, value: true },
    { label: Const.NO.LABEL, value: false },
  ],
  FEEDBACK_TTYPE: [
    "Feedback",
    "Error",
    "Observation",
    "Area of Improvement",
    "Analysis",
  ],
  ASSOCIATION_TYPES: {
    COMMITEE: "commitees",
    GROUP: "groups",
    ROOT: "root",
  },
};

export default Enums;
