import PdfViewer from "../../components/pdf-viewer";
import withHOC from "../../utils/with-hoc";
import TaskDetails from "./components/task-details";
import {
  MembersFeedbackPageProvider,
  useMembersFeedbackPageContext,
} from "./provider";

import DocumentNav from "./components/document-nav";
import { useEffect, useMemo } from "react";
import slateIconImg from "../../assets/slate.svg";
import generateFileUrl from "../../utils/generate-file-url";
import { HeaderLeft, HeaderRight } from "../../layouts/dashboard/header";
import HeaderTitle from "../../components/header-title";
import useLayout from "../../layouts/dashboard/use-layout";
import Button from "../../components/button";
import Arrow2Icon from "../../components/vectors/arrow2-icon";
import ToggleButton from "../../components/toggle-button";

function MembersFeedbackPage() {
  const {
    handleSubmit,
    getPaperDocumentQuery,
    getTaskQuery,
    activeDocIndex,
    handleDirectionChange,
    handleDocumentClick,
    goBack,
    handleStatusChange,
    status,
	feedbackList
  } = useMembersFeedbackPageContext();

  const { setShowMenu } = useLayout();
  const taskDetails = getTaskQuery?.data?.data?.details;
  const taskPapers = getTaskQuery?.data?.data?.papers || [];

  const pdf = useMemo(() => {
    return generateFileUrl(
      getPaperDocumentQuery?.data?.data,
      getPaperDocumentQuery?.data?.headers["content-type"]
    );
  }, [getPaperDocumentQuery?.data?.data, getPaperDocumentQuery?.data?.headers]);
  useEffect(() => {
    setShowMenu(false);
  }, [setShowMenu]);
  return (
    <div className="w-full flex flex-col" id="task-page">
      <HeaderRight>
        <Button
          variant="outline"
          color="primary"
          className="h-9 flex items-center gap-1 hover:bg-primary-button hover:text-secondary-0"
          onClick={goBack}
          icon={<Arrow2Icon className="rotate-180" />}
        >
          Back
        </Button>
        <HeaderTitle className="ml-2">Members Feedback</HeaderTitle>
      </HeaderRight>
      <HeaderLeft className="ml-36">
        <ToggleButton onChange={handleStatusChange} checked={status}>
          Mark as complete
        </ToggleButton>
      </HeaderLeft>
      <div className="content-header flex items-center">
        <div className="icon w-8 p-1 ml-1">
          <img src={slateIconImg} alt="" className="w-full h-auto" />
        </div>
        <div className="content-header_text text-xs font-semibold ml-1">
          {taskDetails}
        </div>
      </div>
      <div className="content-body flex-1 overflow-hidden gap-2 flex w-full justify-between">
        <div className="flex rounded">
          <DocumentNav
            documents={taskPapers}
            activeIndex={activeDocIndex}
            onDirectionChange={handleDirectionChange}
            onClick={handleDocumentClick}
          />
          <PdfViewer pdf={pdf} nav={false} />
        </div>
        <TaskDetails
          papers={taskPapers}
          className="w-[45%]"
          handleSubmit={handleSubmit}
          feedbackList={feedbackList}
        />
      </div>
    </div>
  );
}

export default withHOC(MembersFeedbackPageProvider, MembersFeedbackPage);
