import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import generateContext from "../../utils/generate-context";
import { useAsync } from "@react-org/hooks";
import * as GroupsApi from "../../apis/groups.api";
import * as GroupsFeedbackApi from "../../apis/group-feedback.api";
import * as ListsApi from "../../apis/lists.api";
import * as CommitteeApi from "../../apis/committee.api";
import * as TaskFeedbackApi from "../../apis/task-feedback.api";
import { useParams } from "react-router-dom";
import { useNotificationModalContext } from "../../components/notification-modal/provider";
import MESSAGES from "../../constants/messages";
import Const from "../../constants";
import useCustomNavigate from "../../hooks/use-custom-navigate";
import { generateInitialValues } from "./components/feedback-form/form-helper";
import generateFileUrl from "../../utils/generate-file-url";
import { downloadOrPreviewFileUrl } from "../../utils/download-or-preview-file-url";

const useGroupFeedbackPage = (props) => {
  const [newFeedback, setNewFeedback] = useState(null);
  const [isShowFeedbackDrawerOpen, setIsShowFeedbackDrawerOpen] =
    useState(false);
  const [status, setStatus] = useState();
  const [documents, setDocuments] = useState([]);
  const [feedbackList, setFeedbackList] = useState([]);
  const [uploadedDocList, setUplodedDocList] = useState([]);
  const notificationModal = useNotificationModalContext();
  const [isEditMode, setIsEditMode] = useState([]);

  const mainFormRef = useRef(null);
  const { committeeId, groupId, agendaId, paperId } = useParams();
  const { goBack } = useCustomNavigate();
  const getTaskFeedbackQuery = useAsync(
    useCallback(
      (payload) =>
        GroupsApi.getTaskFeedback({
          id: groupId,
          paper_id: paperId,
          ...payload,
        }),
      [groupId, paperId]
    ),
    {
      immediate: true,
    }
  );

  const getGroupPaperFeedbackQuery = useAsync(
    useCallback(
      (payload) =>
        GroupsApi.getGroupPaperFeedback({
          groupId,
          paperId,
          ...payload,
        }),
      [groupId, paperId]
    ),
    {
      immediate: true,
    }
  );
  const getGroupLeadersFeedbackQuery = useAsync(
    useCallback(
      (payload) =>
        GroupsApi.getGroupLeadersFeedback({
          groupId,
          paper_id: paperId,
          ...payload,
        }),
      [groupId, paperId]
    ),
    {
      select: (res) => {
        return { data: res.data.data, paginate: res.data.paging };
      },
      immediate: true,
    }
  );
  const getGroupDetailsQuery = useAsync(
    useCallback(
      (payload) =>
        CommitteeApi.getGroupDetails({
          committeeId,
          groupId,
          ...payload,
        }),
      [committeeId, groupId]
    ),
    {
      immediate: true,
    }
  );
  const getAgendaQuery = useAsync(
    useCallback(
      (payload) => ListsApi.getAgenda({ limit: 100, ...payload }),
      []
    ),
    {
      select: (res) => {
        return { data: res.data.data, paginate: res.data.paging };
      },
      immediate: true,
    }
  );
  const createGroupLeadersFeedback = useAsync(
    GroupsApi.createGroupLeadersFeedback,
    {
      immediate: false,
    }
  );
  const updateGroupPaperFeedbackQuery = useAsync(
    GroupsApi.updateGroupPaperFeedback,
    {
      immediate: false,
    }
  );
  const deleteGroupLeadersFeedback = useAsync(
    GroupsFeedbackApi.deleteGroupLeadersFeedback,
    {
      immediate: false,
    }
  );

  const uploadFeedbackDocumentsQuery = useAsync(
    GroupsFeedbackApi.uploaodFeedbackDocumments,
    { immediate: false }
  );

  const updateFeedbackQuery = useAsync(GroupsApi.updateFeedback, {
    immediate: false,
  });

  const deleteCommitteeDocumentQuery = useAsync(
    GroupsFeedbackApi.feedbackDocummentDelete,
    {
      immediate: false,
    }
  );

  const downloadDocumetQuery = useAsync(
    GroupsFeedbackApi.feedbackDocummentDocuments,
    { immediate: false }
  );
  const downloadFeedbackDocumentQuery = useAsync(
    TaskFeedbackApi.feedbackDocummentDocuments,
    {
      immediate: false,
    }
  );
  const getDocumentListQuery = useAsync(
    GroupsFeedbackApi.uploaodFeedbackDocummentsList,
    {
      immediate: true,
    }
  );
  const getPaperDetailsQuery = useAsync(
    useCallback(
      (payload) => ListsApi.getPaperDetails({ agendaId, paperId, ...payload }),
      [agendaId, paperId]
    ),
    {
      immediate: true,
    }
  );

  const handleShowFeedbackClick = useCallback(() => {
    setIsShowFeedbackDrawerOpen(true);
  }, []);
  const closeShowFeedbackDrawer = useCallback(() => {
    setIsShowFeedbackDrawerOpen(false);
  }, []);

  const handleAddNewFeedbackClick = useCallback(() => {
    setNewFeedback(true);
    setDocuments([]);
    setIsEditMode((prevIsEditMode) => {
      const updatedIsEditMode = prevIsEditMode.map((val) => ({
        ...val,
        showModel: false,
      }));
      setIsEditMode(updatedIsEditMode);
    });
  }, []);

  const Edit = useCallback(() => {
    setNewFeedback(false);
  }, []);

  const handleFeedbackSave = useCallback(
    (data) => {
      const payload = {
        response: data.response,
        response_type: data.comment,
      };
      payload.groupId = groupId;
      payload.paper_id = parseInt(paperId);
      notificationModal.progress({
        heading: MESSAGES.INPROGRESS_MESSAGE,
      });
      const createFeedbackExecute = createGroupLeadersFeedback.execute;
      const getGroupLeadersFeedbackQueryExecute =
        getGroupLeadersFeedbackQuery.execute;
      const uploadFeedbackDocumentsQueryExecute =
        uploadFeedbackDocumentsQuery.execute;
      const getDocumentListQueryExecute = getDocumentListQuery.debounceExecute;

      createFeedbackExecute(payload, {
        onSuccess: (res) => {
          getGroupLeadersFeedbackQueryExecute();
          const getTaskFeedbackQueryExecute =
            getTaskFeedbackQuery.debounceExecute;
          if (documents?.length) {
            const feedbackId = res?.data?.id;
            const updatedDocList = uploadedDocList.filter(
              (doc) => doc?.feedback_id !== feedbackId
            );
            const payload = {
              files: documents,
              feedbackId: res?.data?.id,
            };
            uploadFeedbackDocumentsQueryExecute(payload, {
              onSuccess: () => {
                setDocuments([]);
                getDocumentListQueryExecute(
                  { feedbackId: feedbackId },
                  {
                    onSuccess: (res) => {
                      const updatedDocs = res?.data?.data;
                      updatedDocList.push(...updatedDocs);
                      setUplodedDocList(updatedDocList);
                    },
                  }
                );
                notificationModal.success({
                  heading: MESSAGES.GROUP_RESPONSE_UPDATED,
                });
                getTaskFeedbackQueryExecute();
              },
              onError: () => {
                notificationModal.error({
                  heading: MESSAGES.FAILED_TO_UPDATE_GROUP_RESPONSE,
                });
              },
            });
          }
          Edit();
          notificationModal.close();
        },
        onError: (err) => {
          notificationModal.error({
            heading: "Failed to add Feedback",
          });
        },
      });
    },
    [
      groupId,
      paperId,
      notificationModal,
      createGroupLeadersFeedback.execute,
      getGroupLeadersFeedbackQuery.execute,
      uploadFeedbackDocumentsQuery.execute,
      getDocumentListQuery.debounceExecute,
      getTaskFeedbackQuery.debounceExecute,
      documents,
      Edit,
      uploadedDocList,
    ]
  );

  const handleFeedbackUpdate = useCallback(
    (data, feedback, cb) => {
      const feedbackId = feedback.id;
      const updatedDocList = uploadedDocList.filter(
        (doc) => doc?.group_feedback_id !== feedbackId
      );
      const payload = {
        response: data.response,
        response_type: data.comment,
        feedbackId: feedbackId,
        paper_id: feedback.paper_id,
      };
      notificationModal.progress({
        heading: MESSAGES.INPROGRESS_MESSAGE,
      });

      const updatedDocuments = documents?.filter((doc) => !doc?.id);
      const updateFeedbackQueryExecute = updateFeedbackQuery.execute;
      const getGroupLeadersFeedbackQueryExecute =
        getGroupLeadersFeedbackQuery.execute;
      const uploadFeedbackDocumentsQueryExecute =
        uploadFeedbackDocumentsQuery.execute;
      const getDocumentListQueryExecute = getDocumentListQuery.debounceExecute;

      updateFeedbackQueryExecute(payload, {
        onSuccess: (res) => {
          if (updatedDocuments.length) {
            uploadFeedbackDocumentsQueryExecute(
              {
                feedbackId: feedbackId,
                files: updatedDocuments,
              },
              {
                onSuccess: (res) => {
                  notificationModal.success({
                    heading: MESSAGES.GROUP_RESPONSE_CREATED,
                  });
                  getDocumentListQueryExecute(
                    { feedbackId: feedbackId },
                    {
                      onSuccess: (res) => {
                        const updatedDocs = res?.data?.data;
                        updatedDocList.push(...updatedDocs);
                        setUplodedDocList(updatedDocList);
                      },
                    }
                  );
                  getGroupLeadersFeedbackQueryExecute();
                  getTaskFeedbackQuery();
                },
              }
            );
          }
          getGroupLeadersFeedbackQueryExecute();
          notificationModal.close();
          cb && cb();
        },
        onError: (err) => {
          notificationModal.error({
            heading: "Failed to update Feedback",
          });
        },
      });
    },
    [
      documents,
      getDocumentListQuery.debounceExecute,
      getGroupLeadersFeedbackQuery.execute,
      getTaskFeedbackQuery,
      notificationModal,
      updateFeedbackQuery.execute,
      uploadFeedbackDocumentsQuery.execute,
      uploadedDocList,
    ]
  );

  const handleSubmit = useCallback(
    ({ payload, feedback, isEditMode }, cb) => {
      if (isEditMode) {
        return handleFeedbackUpdate(payload, feedback, cb);
      }
      return handleFeedbackSave(payload);
    },
    [handleFeedbackSave, handleFeedbackUpdate]
  );

  const handleDeleteFeedback = useCallback(
    (feedbackId) => () => {
      notificationModal.warning({
        title: MESSAGES.ARE_YOU_SURE,
        heading: MESSAGES.TASK_WILL_BE_DELETED,
        onConfirm: () => {
          const deleteGroupLeadersFeedbackExecute =
            deleteGroupLeadersFeedback.execute;
          const getGroupLeadersFeedbackQueryExecute =
            getGroupLeadersFeedbackQuery.execute;

          notificationModal.progress({
            heading: MESSAGES.INPROGRESS_MESSAGE,
          });
          deleteGroupLeadersFeedbackExecute(
            { feedbackId },
            {
              onSuccess: () => {
                const getTaskFeedbackQueryExecute =
                  getTaskFeedbackQuery.debounceExecute;
                getTaskFeedbackQueryExecute();

                getGroupLeadersFeedbackQueryExecute();
                notificationModal.success({
                  heading: MESSAGES.TASK_DELETED_SUCCESSFULLY,
                });
              },
              onError: () => {
                notificationModal.error({
                  heading: MESSAGES.FAILED_TO_DELETE_TASK,
                });
              },
            }
          );
        },
      });
    },
    [
      deleteGroupLeadersFeedback.execute,
      getGroupLeadersFeedbackQuery.execute,
      getTaskFeedbackQuery.debounceExecute,
      notificationModal,
    ]
  );

  const handleStatusChange = useCallback(
    (e) => {
      const payload = { groupId, paperId };
      if (e.target.checked) {
        payload.status = Const.STATUS.COMPLETED;
      } else {
        payload.status = Const.STATUS.PENDING;
      }
      const updateGroupPaperFeedbackQueryExecute =
        updateGroupPaperFeedbackQuery.execute;
      const getGroupPaperFeedbackQueryExecute =
        getGroupPaperFeedbackQuery.execute;
      updateGroupPaperFeedbackQueryExecute(payload, {
        onSuccess: (res) => {
          getGroupPaperFeedbackQueryExecute();
          notificationModal.close();
        },
        onError: (err) => {
          notificationModal.error({
            heading: "Failed to update task status",
          });
        },
      });
    },
    [
      getGroupPaperFeedbackQuery.execute,
      groupId,
      notificationModal,
      paperId,
      updateGroupPaperFeedbackQuery.execute,
    ]
  );

  const handleDeleteDocument = useCallback(
    (data) => (doc) => {
      const updatedDocList = uploadedDocList.filter(
        (doc) => doc?.group_feedback_id !== data.group_feedback_id
      );
      const getDocumentListQueryExecute = getDocumentListQuery.debounceExecute;
      notificationModal.warning({
        title: MESSAGES.ARE_YOU_SURE,
        heading: MESSAGES.DOCUMENT_WILL_BE_DELETED,
        onConfirm: () => {
          const deleteCommitteeDocumentQueryExecute =
            deleteCommitteeDocumentQuery.execute;
          const payload = {
            feedbackId: data.group_feedback_id,
            documentId: data.id,
          };
          deleteCommitteeDocumentQueryExecute(payload, {
            onSuccess: () => {
              notificationModal.success({ heading: MESSAGES.DOCUMENT_DELETED });
              getDocumentListQueryExecute(
                {
                  feedbackId: data.group_feedback_id,
                },
                {
                  onSuccess: (res) => {
                    const updatedDocs = res?.data?.data;
                    updatedDocList.push(...updatedDocs);
                    setUplodedDocList(updatedDocList);
                  },
                }
              );
            },
            onError: () => {
              notificationModal.error({
                heading: MESSAGES.FAILED_TO_DELETED_DOCUMENT,
              });
            },
          });
        },
      });
    },
    [
      deleteCommitteeDocumentQuery.execute,
      getDocumentListQuery.debounceExecute,
      notificationModal,
      uploadedDocList,
    ]
  );

  const initialValues = useMemo(() => {
    const initialValue = generateInitialValues();
    return initialValue;
  }, []);

  const handleDownloadFile = useCallback(
    (doc) => () => {
      const payload = {
        feedbackId: doc?.group_feedback_id,
        documentId: doc?.id,
      };
      const downloadDocumetQueryExecute = downloadDocumetQuery.execute;
      notificationModal.progress({ heading: MESSAGES.INPROGRESS_MESSAGE });
      downloadDocumetQueryExecute(payload, {
        onSuccess: (res) => {
          const fileURL = generateFileUrl(
            res?.data,
            res.headers["content-type"]
          );
          downloadOrPreviewFileUrl(fileURL, `${doc?.name}`, {
            type: res.headers["content-type"],
          });
          notificationModal.close();
        },
        onError: () => {
          notificationModal.error({
            heading: MESSAGES.PREVIEW_FAILED,
          });
        },
      });
    },
    [downloadDocumetQuery.execute, notificationModal]
  );

  const handleEditDeleteDocument = useCallback(
    (index, data) => {
      const updatedDocList = uploadedDocList.filter(
        (doc) => doc?.group_feedback_id !== data.group_feedback_id
      );
      if (data.id) {
        const getDocumentListQueryExecute =
          getDocumentListQuery.debounceExecute;
        notificationModal.warning({
          title: MESSAGES.ARE_YOU_SURE,
          heading: MESSAGES.DOCUMENT_WILL_BE_DELETED,
          onConfirm: () => {
            const deleteCommitteeDocumentQueryExecute =
              deleteCommitteeDocumentQuery.execute;
            const payload = {
              feedbackId: data.group_feedback_id,
              documentId: data.id,
            };
            deleteCommitteeDocumentQueryExecute(payload, {
              onSuccess: () => {
                notificationModal.success({
                  heading: MESSAGES.DOCUMENT_DELETED,
                });
                getDocumentListQueryExecute(
                  {
                    feedbackId: data.group_feedback_id,
                  },
                  {
                    onSuccess: (res) => {
                      const updatedDocs = res?.data?.data;
                      updatedDocList.push(...updatedDocs);
                      setUplodedDocList(updatedDocList);
                    },
                  }
                );
              },
              onError: () => {
                notificationModal.error({
                  heading: MESSAGES.FAILED_TO_DELETED_DOCUMENT,
                });
              },
            });
          },
        });
      } else {
        setDocuments((prevDocuments) => {
          const updatedDocuments = [...prevDocuments];
          updatedDocuments.splice(index, 1);
          return updatedDocuments;
        });
      }
    },
    [
      deleteCommitteeDocumentQuery.execute,
      getDocumentListQuery.debounceExecute,
      notificationModal,
      uploadedDocList,
    ]
  );

  const handleCancel = useCallback(
    (id) => {
      const newState = isEditMode?.map((e) => {
        if (e.feedbackId === id) {
          return { ...e, showModel: false };
        } else {
          return { ...e, showModel: false };
        }
      });
      if (!id) {
        setNewFeedback(false);
      }
      setIsEditMode(newState);
    },
    [isEditMode]
  );

  const handleEdit = useCallback(
    (id) => () => {
      setNewFeedback(false);
      const newState = isEditMode?.map((e) => {
        if (e.feedbackId === id) {
          return { ...e, showModel: true };
        } else {
          return { ...e, showModel: false };
        }
      });
      setIsEditMode(newState);
    },
    [isEditMode]
  );

  const handleDownloadDocument = useCallback(
    (doc) => () => {
      const payload = {
        documentId: doc?.id,
      };
      const downloadDocumetQueryExecute = downloadFeedbackDocumentQuery.execute;
      notificationModal.progress({ heading: MESSAGES.INPROGRESS_MESSAGE });
      downloadDocumetQueryExecute(payload, {
        onSuccess: (res) => {
          const fileURL = generateFileUrl(
            res?.data,
            res.headers["content-type"]
          );
          downloadOrPreviewFileUrl(fileURL, `${doc?.name}`, {
            type: res.headers["content-type"],
          });
          notificationModal.close();
        },
        onError: () => {
          notificationModal.error({
            heading: MESSAGES.PREVIEW_FAILED,
          });
        },
      });
    },
    [downloadFeedbackDocumentQuery.execute, notificationModal]
  );

  useEffect(() => {
    if (
      getGroupPaperFeedbackQuery?.data?.data?.status === Const.STATUS.COMPLETED
    ) {
      setStatus(true);
    } else {
      setStatus(false);
    }
  }, [getGroupPaperFeedbackQuery?.data?.data?.status]);

  useEffect(() => {
    if (getGroupLeadersFeedbackQuery?.data?.data) {
      const feedbackModel = (
        getGroupLeadersFeedbackQuery?.data?.data || []
      ).map((e) => {
        return { feedbackId: e.id, showModel: false };
      });
      setIsEditMode(feedbackModel);
      setFeedbackList(getGroupLeadersFeedbackQuery?.data?.data);
    }
  }, [getGroupLeadersFeedbackQuery?.data?.data]);

  return useMemo(() => {
    return {
      mainFormRef,
      handleSubmit,
      getTaskFeedbackQuery,
      getAgendaQuery,
      getGroupDetailsQuery,
      isShowFeedbackDrawerOpen,
      handleShowFeedbackClick,
      closeShowFeedbackDrawer,
      getGroupLeadersFeedbackQuery,
      handleDeleteFeedback,
      handleStatusChange,
      status,
      goBack,
      setNewFeedback,
      initialValues,
      newFeedback,
      documents,
      setDocuments,
      handleAddNewFeedbackClick,
      Edit,
      handleDeleteDocument,
      handleDownloadFile,
      getDocumentListQuery,
      deleteCommitteeDocumentQuery,
      feedbackList,
      uploadedDocList,
      handleEditDeleteDocument,
      setUplodedDocList,
      setIsEditMode,
      handleEdit,
      handleCancel,
      isEditMode,
      handleDownloadDocument,
      getPaperDetailsQuery,
    };
  }, [
    handleSubmit,
    getTaskFeedbackQuery,
    getAgendaQuery,
    getGroupDetailsQuery,
    isShowFeedbackDrawerOpen,
    handleShowFeedbackClick,
    closeShowFeedbackDrawer,
    getGroupLeadersFeedbackQuery,
    handleDeleteFeedback,
    handleStatusChange,
    status,
    goBack,
    initialValues,
    newFeedback,
    documents,
    handleAddNewFeedbackClick,
    Edit,
    handleDeleteDocument,
    handleDownloadFile,
    getDocumentListQuery,
    deleteCommitteeDocumentQuery,
    feedbackList,
    uploadedDocList,
    handleEditDeleteDocument,
    handleEdit,
    handleCancel,
    isEditMode,
    handleDownloadDocument,
    getPaperDetailsQuery,
  ]);
};
export const [GroupFeedbackPageProvider, useGroupFeedbackPageContext] =
  generateContext(useGroupFeedbackPage);
