import ReviewCard from "../../../components/review-card";
import cx from "../../../utils/class-names";
import SearchInput from "../../../components/search-input";
import CloseIcon from "../../../components/vectors/close-icon";
import { useWorkingDocumentPageContext } from "../provider";

export default function HeaderSection() {
  const {
    searchText,
    handleClearSearchFilter,
    handleEnterSearch,
    handleSearchChange,
  } = useWorkingDocumentPageContext();
  return (
    <ReviewCard
      className={cx(
        "p-2 mb-0 shadow-none flex flex-wrap justify-between items-center"
      )}
    >
      <div className="mt-2 mb-2">
        <SearchInput
          name="search"
          value={searchText}
          onChange={(e) => handleSearchChange(e)}
          classNames={{ root: "min-w-[350px]", input: "w-[350px]" }}
          onKeyPress={(event) => {
            if (event.key === "Enter") handleEnterSearch(event.target.value);
          }}
          placeholder="Search in List"
          iconRight={
            searchText !== "" ? (
              <div className="cursor-pointer" onClick={handleClearSearchFilter}>
                <CloseIcon className="w-4 h-4" />
              </div>
            ) : null
          }
        />
      </div>
    </ReviewCard>
  );
}
