import * as Yup from "yup";
import * as validation from "../../../utils/validation";
import uuid from "../../../utils/uuid";
import { getDateFromValue, getFormattedDateforAPI } from "../../../utils/date-and-time";

export const FN = {
  COMMITTEE_ID: "committee_id",
  NAME: "name",
  MEETING_DATE: "meeting_date",
  MEETING_ID:"meeting_id",
  DESCRIPTION: "description",
  LEADER_OF_DELEGATION: "leader_of_delegation",
  COORDINATOR_OF_COMMITTEE: "coordinator_of_the_committee",
  MEMBER_DETAILS: {
    ROOT: "member_details_root",
    MEMBER_NAME: "member_name",
    MEMBER_EMAIL: "member_email",
    MEMBER_REPRESENTATIVE_OF: "member_representative_of",
  },
};
export const getMembersDetailsRow = () => {
  return {
    id: uuid(),
    [FN.MEMBER_DETAILS.MEMBER_NAME]: "",
    [FN.MEMBER_DETAILS.MEMBER_EMAIL]: "",
    [FN.MEMBER_DETAILS.MEMBER_REPRESENTATIVE_OF]: "",
  };
};

export const getFormInitialValues = (data = {}) => {
  const { id, commitee_members, name, meeting, description } = data;
  const leader = commitee_members?.find((member) => member.is_leader === true);
  const coordinator = commitee_members?.find(
    (member) => member.is_coordinator === true
  );
  const members =
    commitee_members
      ?.filter((member) => !member.is_leader && !member.is_coordinator)
      .map(({ member_id, member }) => ({
        name: member_id,
        email: member.email,
        representative_of: member.representative_of,
      })) || [];
  const memberRowDetails = members.length
    ? members.map((item) => ({
        [FN.MEMBER_DETAILS.MEMBER_NAME]: item.name || "",
        [FN.MEMBER_DETAILS.MEMBER_EMAIL]: item.email || "",
        [FN.MEMBER_DETAILS.MEMBER_REPRESENTATIVE_OF]:
          item.representative_of || "",
      }))
    : [getMembersDetailsRow()];
  return {
    [FN.NAME]: name || "",
    [FN.MEETING_DATE]: getDateFromValue(meeting?.date) || "",
    [FN.DESCRIPTION]: description || "",
    [FN.LEADER_OF_DELEGATION]: leader?.member_id || "",
    [FN.COORDINATOR_OF_COMMITTEE]: coordinator?.member_id || "",
    [FN.MEMBER_DETAILS.ROOT]: memberRowDetails,
    [FN.COMMITTEE_ID]: id || "",
    [FN.MEETING_ID]: meeting?.id || "",
  };
};

export const getFormValidationSchema = (withRequired = true) => {
  return Yup.object().shape({
    [FN.NAME]: validation.isRequiredString({ withRequired }),
    [FN.COORDINATOR_OF_COMMITTEE]: validation.isRequiredString({
      withRequired,
    }),
    [FN.LEADER_OF_DELEGATION]: validation.isRequiredString({ withRequired }),
    [FN.MEMBER_DETAILS.ROOT]: Yup.array().of(
      Yup.object().shape({
        [FN.MEMBER_DETAILS.MEMBER_NAME]: validation.isRequiredString(),
      })
    ),
  });
};

export const generatePayload = (inputData) => {
  const {
    name,
    description,
    leader_of_delegation,
    coordinator_of_the_committee,
    member_details_root,
    meeting_date,
  } = inputData;
  const committeeMembers = [
    {
      is_leader: true,
      is_coordinator: false,
      member_id: parseInt(leader_of_delegation),
    },
    {
      is_leader: false,
      is_coordinator: true,
      member_id: parseInt(coordinator_of_the_committee),
    },
    ...member_details_root.map((member) => ({
      is_leader: false,
      is_coordinator: false,
      member_id: parseInt(member.member_name),
    })),
  ];

  const payload = {
    name,
    description,
    commitee_members: committeeMembers,
    meeting_date: getFormattedDateforAPI(meeting_date),
  };

  return payload;
};
