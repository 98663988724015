import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "../../utils/class-names";

const Tooltip = ({ text, children, className }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handleMouseEnter = () => {
    setIsTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setIsTooltipVisible(false);
  };

  return (
    <div
      className="relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="flex items-center justify-center ">{children}</div>
      {isTooltipVisible && (
        <div
          className={cx(
            "absolute bg-gray-800 text-white text-xs rounded py-1 px-2 mb-1 bottom-full left-1/2 transform -translate-x-1/2 opacity-100 pointer-events-none transition-opacity duration-300", className
          )}
        >
          {text}
        </div>
      )}
    </div>
  );
};

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Tooltip;
