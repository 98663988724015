export default function DateIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={17} viewBox="0 0 16 17" fill="none" {...props}>
      <path
        d="M12 3.834H4c-.737 0-1.334.597-1.334 1.333v8c0 .737.597 1.334 1.333 1.334h8c.737 0 1.334-.597 1.334-1.334v-8c0-.736-.597-1.333-1.334-1.333zM10.666 2.5v2.667M5.334 2.5v2.667M2.666 7.834h10.667"
        stroke="currentcolor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M6.667 10.5H5.334v1.333h1.333V10.5z" stroke="currentcolor" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
