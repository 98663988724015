import { withConfig, getApi } from "./index";
const api = getApi("/suggestion");

export const uploadSuggestionDocument = withConfig((data, conf) => {
  const { suggestionId, files = [] } = data;
  let formData = new FormData();
  files.forEach((item) => {
    formData.append("files", item);
  });

  const config = conf({
    url: `${suggestionId}/documents`,
    method: "POST",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return api(config);
});

export const getSuggestionDocuments = withConfig((data, conf) => {
  const { suggestionId, ...params } = data;
  const config = conf({
    url: `${suggestionId}/documents`,
    method: "GET",
    params: params,
  });
  return api(config);
});
export const previewSuggestionDocument = withConfig((data, conf) => {
  const { suggestionId, id, ...params } = data;
  const config = conf({
    url: `${suggestionId}/documents/${id}/preview`,
    method: "GET",
    params: params,
    responseType: "blob",
  });
  return api(config);
});
export const deleteSuggestionDocument = withConfig((data, conf) => {
  const { suggestionId, id, ...params } = data;
  const config = conf({
    url: `${suggestionId}/documents/${id}`,
    method: "DELETE",
    params: params,
  });
  return api(config);
});


