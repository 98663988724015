import { Form, Formik } from "formik";
import Button from "../../../../components/button";
import Drawer from "../../../../components/drawer";
import { useManageAgendaPageContext } from "../../provider";
import FormControl from "../../../../components/form-control";
import InputLabel from "../../../../components/input-label";
import { FN } from "./form-helper";
import { Close2Icon } from "../../../../components/vectors/close2-icon";
import SectionPapers from "./section-papers";
import { FormikTextarea } from "../../../../components/textarea";
import { FormikSelect } from "../../../../components/select";
import constants from "../../../../constants";

const AddAgendaDrawer = () => {
  const {
    initialValues,
    isAddAgendaDrawerOpen,
    handleCloseAgendaDrawer,
    agendaFormRef,
    getValidationSchema,
    handleUpdateAgenda,
    handleCreateAgenda,
    getMeetingDetailsQuery,
  } = useManageAgendaPageContext();
  const hasAgendaId = !!initialValues?.agenda_id;
  const handleSubmit = hasAgendaId
    ? handleUpdateAgenda(initialValues?.agenda_id)
    : handleCreateAgenda;
  return (
    <Drawer
      open={isAddAgendaDrawerOpen}
      classNames={{
        content:
          "w-[600px] flex overflow-hidden flex-col h-full tab-xs:w-[300px] drop-shadow",
      }}
    >
      <div className="bg-primary-text">
        <div className="w-full flex justify-end">
          <button className="mt-5 mr-5" onClick={handleCloseAgendaDrawer}>
            <Close2Icon className="h-5 w-5 text-secondary-0" />
          </button>
        </div>
        <div className="w-full">
          <div className="text-base m-5 font-medium leading-6 font-sans text-secondary-0">
            Agenda
          </div>
        </div>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={getValidationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => {
          agendaFormRef.current = formik;
          return (
            <Form className="flex flex-1 flex-col overflow-y-auto justify-between">
              <div className="flex-col p-4 gap-2">
                <FormControl>
                  <InputLabel className="flex-1" required htmlFor={FN.AGENDA}>
                    Agenda
                  </InputLabel>
                  <FormikTextarea id={FN.AGENDA} name={FN.AGENDA} minRows={3} />
                </FormControl>
                <FormControl>
                  <InputLabel
                    className="flex-1"
                    required
                    htmlFor={FN.MEETING_ID}
                  >
                    Meeting
                  </InputLabel>
                  <FormikSelect
                    data={getMeetingDetailsQuery?.data || []}
                    id={FN.MEETING_ID}
                    name={FN.MEETING_ID}
                    lableString="name"
                    valueString="id"
                    passValueInt={true}
                    virtualize={getMeetingDetailsQuery?.data?.length >= constants.VIRTUALIZED_ENABLE_LIMIT}
                    classNames={{
                      root: "mb-1",
                      input: "ring-black-100",
                      popperContent: "max-w-[560px]",
                    }}
                  />
                </FormControl>
                <SectionPapers />
              </div>
              <div className="flex items-end px-8 justify-end w-full my-4">
                <Button
                  type="submit"
                  variant="solid"
                  color="primary"
                  className="py-2 px-5 flex items-center text-xs font-semibold ml-5 action-auditor-save"
                >
                  {hasAgendaId ? "Update" : "Save"}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Drawer>
  );
};
export default AddAgendaDrawer;
