import { useState, useRef, useMemo, createContext, useContext, useEffect } from "react";
import { usePopper } from "react-popper";
import Portal from "../portal";
import cx from "../../utils/class-names";
import useOnClickOutside from "../../hooks/use-click-outside";
import useKeyPress from "../../hooks/use-key-press";
import PropTypes from "prop-types";

const PopperContext = createContext();

function Popper(props) {
  const {
    onOutsideClick,
    onEscapeClick,
    className,
    children,
    isOpen,
    setIsOpen,
    placement = "bottom-end",
    portal = false,
    setRef,
    inputRef,
    arrow = false,
  } = props;
  const [anchorElement, setAnchorElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes, state } = usePopper(anchorElement, popperElement, {
    placement: placement,
    modifiers: [{ name: "arrow", options: { element: arrowElement } }],
  });
  const visible = state?.modifiersData?.hide?.isReferenceHidden;
  let popperRef = useRef(null);

  useOnClickOutside(popperRef, (event) => {
    if (inputRef?.current) {
      if (inputRef.current.contains(event.target)) return;
    }
    onOutsideClick && onOutsideClick();
  });

  useKeyPress(document, "Escape", (e) => {
    onEscapeClick && onEscapeClick(e);
  });
  useEffect(() => {
    if (visible) {
      setIsOpen(false);
    }
    popperRef.current = popperElement;
    setRef && setRef(popperElement);
    return () => {
      popperRef.current = null;
      setRef && setRef(null);
    };
  }, [inputRef, popperElement, setIsOpen, setRef, visible]);

  const value = useMemo(() => {
    return {
      arrow,
      styles,
      isOpen,
      portal,
      popperRef,
      attributes,
      popperElement,
      setPopperElement,
      setAnchorElement,
      setArrowElement,
    };
  }, [arrow, attributes, isOpen, popperElement, portal, styles]);

  return (
    <PopperContext.Provider value={value}>
      <div className={cx("popper relative", className)}>{children}</div>
    </PopperContext.Provider>
  );
}

function PopperTrigger(props) {
  const { children } = props;
  const { setAnchorElement } = useContext(PopperContext);

  return <>{children({ setAnchorElement })}</>;
}

function PopperContent(props) {
  const { children, className } = props;
  const { arrow, portal, styles, attributes, isOpen, setPopperElement, setArrowElement } = useContext(PopperContext);

  let content = isOpen ? (
    <div ref={setPopperElement} style={styles.popper} {...attributes.popper} className={cx("popper-content", className)} tabIndex="-1" autoFocus>
      {arrow ? <div ref={(ref) => setArrowElement(ref)} style={styles.arrow} /> : null}
      {children}
    </div>
  ) : null;

  if (portal) {
    return <Portal>{content}</Portal>;
  }

  return <>{content}</>;
}

Popper.Trigger = PopperTrigger;
Popper.Content = PopperContent;

Popper.defaultProps = {
  className: "",
  isOpen: false,
  placement: "bottom-end",
  portal: false,
  arrow: false,
};

Popper.propTypes = {
  onOutsideClick: PropTypes.func,
  onEscapeClick: PropTypes.func,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  placement: PropTypes.string,
  portal: PropTypes.bool,
  arrow: PropTypes.bool,
  setIsOpen: PropTypes.func,
};

export default Popper;
