import { useFormikContext } from "formik";
import { useEffect } from "react";
import scrollToTheFirstErrorOnPage from "../../utils/scroll-to-first-error";
import PropTypes from "prop-types";

function ScrollToFormError(props) {
  const formik = useFormikContext();
  useEffect(() => {
    scrollToTheFirstErrorOnPage();
  }, [formik?.submitCount]);
  return <>{props.children}</>;
}

ScrollToFormError.defaultProps = {};

ScrollToFormError.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ScrollToFormError;
