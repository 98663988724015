import env from "../utils/env";

const constants = {
  ENV: env("NODE_ENV"),
  SENTRY: {
    ENABLE: env("SENTRY_ENABLE"),
    ENV: env("SENTRY_ENV"),
    INCLUDE: env("SENTRY_INCLUDE"),
    IGONRE_FILE: env("SENTRY_IGONRE_FILE"),
    IGONRE: env("SENTRY_IGONRE"),
    AUTHTOKEN: env("SENTRY_AUTHTOKEN"),
    ORG: env("SENTRY_ORG"),
    PROJECT: env("SENTRY_PROJECT"),
    CONFIG_FILE: env("SENTRY_CONFIG_FILE"),
    DSN: env("SENTRY_DSN"),
    TRACES_SAMPLE_RATE: env("SENTRY_TRACES_SAMPLE_RATE"),
  },
  API_URL: env("API_URL"),
  CONTEXT_TYPE: {
    USER: "user",
    TOKEN: "token",
  },
  TOKEN_TYPE: {
    ACCESS: "token",
    REFRESH: "refresh_token",
  },
  FROM: "From",
  TO: "To",
  DATE_TIME_FORMATS: {
    DATE_FLATPICKER: "d-m-Y",
    DATE_FLATPICKER_CERT: "d/m/Y",
    DATE_UI: "DD-MM-YYYY",
    DATE_API: "YYYY-MM-DD",
    DATE_FULL_MONTH: "DD-MMM-YYYY",
    DATE_DAY: "day",
    DATE_DAYS: "days",
    TIME_12_HR: "h:mma",
    TIME_24_HR: "HH:mm",
  },
  TOASTER_TYPES: {
    ERROR: "error",
    WARNING: "warn",
    SUCCESS: "success",
    LOG: "log",
  },
  DOT_TEXT: ".............",
  FILE_TYPE: {
    ZIP: "zip",
    CONTAINER: "container",
    OTHER_CONTAINER: "other-container",
  },
  ACCORDION_MIN_ROWS: {
    LIST_LIMIT: 5,
  },
  YES: {
    LABEL: "Yes",
    VALUE: "yes",
  },
  NO: {
    LABEL: "No",
    VALUE: "no",
  },
  NA: {
    LABEL: "NA",
    VALUE: "na",
  },
  NOTIFICATION_TYPE: {
    SUCCESS: "success",
    ERROR: "error",
    INFO: "info",
    WARNING: "warning",
    PROGRESS: "progress",
    CLOSE: "close",
    CONFIRM: "confirm",
  },
  COLOR: {
    PRIMARY_COLOR: "#00789A",
  },
  LIST_LIMIT: 50,
  DOC_TYPE: {
    REQUESTED: "Requested",
    UPLOADED: "Uploaded",
  },
  SHOW_FOOTER_SCROLL_POSITION: 100,
  VALIDATION_TYPE: {
    GREATER_THAN: "Greater than",
  },
  ENGLISH: "English",
  FILE_STATUS: {
    DELETED: "Deleted",
    UPLOADED: "Uploaded",
    REQUESTED: "Requested",
  },
  STATUS: {
    COMPLETED: "Completed",
    PENDING: "Pending",
    FEEDBACK: "feedback",
    GROUP_FEEDBACK_PENDING: "group_feedback_pending",
  },
  VIRTUALIZED_ENABLE_LIMIT: 5,
  VIRTUALIZED_ENABLE_LIMIT_FOR_MULTISELECT: 5,
  DOCUMENT: {
    MOVING: "Moving",
    COPYING: "Copying",
    MOVED: "Moved",
    COPIED: "Copied",
    MOVE: "Move",
    COPY: "Copy",
  },
  LIST_LIMIT_20: 20,
  GRID_LIMIT: 20,
  LIST_LIMIT_50: 50,
  ONE_DAY_SCHEDULE_TIME_LIMIT: 1440,
  TIME_STEP_INCREMENT_DECREMENT_BY: 5,
  SORT_DIRECTIONS: {
    ASC: "asc",
    DESC: "desc",
  },
  OS_TYPES: {
    WINDOWS: "windows",
    MAC: "mac",
    LINUX: "linux",
  },
  SKELETON_LOADING_ROWS: {
    DEFAULT: 5,
    PAGING: 2,
    NO_ROWS: 0,
  },
  COLORS: {
    DEFAULT: "default",
    PRIMARY: "primary",
    SECONDARY: "secondary",
    SUCCESS: "success",
    INFO: "info",
    WARNING: "warning",
    DANGER: "danger",
  },
  COMMITTEE_TAB: {
    VALUE: {
      GROUP: "group",
      MEMBERS: "member",
    },
    LABEL: {
      GROUP: "Groups",
      MEMBERS: "Member Details",
      ADEQUATE: "Adequate",
      EFFECTIVE: "Effective",
    },
  },
};

export default constants;
