import { withConfig, getApi } from "./index";
const api = getApi("/working-papers");

export const uploadWorkingPaper = withConfig((data, conf) => {
  const { files = [], associationName, associationId } = data;
  let formData = new FormData();
  files.forEach((item) => {
    formData.append("files", item);
  });
  formData.append("associationName", associationName);
  formData.append("associationId", associationId);
  const config = conf({
    url: `/documents`,
    method: "POST",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return api(config);
});

export const previewWorkingPaper = withConfig((data, conf) => {
  const {  id, ...params } = data;
  const config = conf({
    url: `documents/${id}/preview`,
    method: "GET",
    params: params,
    responseType: "blob",
  });
  return api(config);
});

