import moment from "moment";
import BinIcon from "../vectors/bin-icon";
import DownloadThinIcon from "../vectors/download-thin-icon";

const UploadedFile = (props) => {
  const {
    name,
    onDeleteClick,
    onDownloadClick,
    date,
    showDownloadIcon = false,
  } = props;
  const dateUploaded = moment(date).format("DD-MM-YYYY");
  return (
    <div className="w-full p-2 rounded bg-[#F3F4F5] flex justify-between items-center mb-1.5 border-[2px] border-[#F8F8F8;]">
      <div>
        <p className="text-xs text-black-100 font-semibold">{name}</p>
        <p className="text-[#667380] text-[0.625rem]">
          Date uploaded: {dateUploaded}
        </p>
      </div>
      <div className="flex gap-4 items-center">
        {showDownloadIcon && (
          <div onClick={onDownloadClick} className="cursor-pointer">
            <DownloadThinIcon className="text-primary-button w-5 h-5" />
          </div>
        )}
        <div onClick={onDeleteClick} className="w-4 h-4 cursor-pointer">
          <BinIcon />
        </div>
      </div>
    </div>
  );
};

export default UploadedFile;
