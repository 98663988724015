import moment from "moment";
import { generateFullName } from "../../../utils/generate-names";

function TaskItem(props) {
  const { row, handleViewTaskDetails } = props;
  const taskDetails = row.details;
  const members = row?.members;
  const memberName = members?.map((item) => generateFullName(item)).join(", ");
  const timeline = moment(row.timeline).format("DD-MM-YYYY");
  const agenda = row?.papers?.[0]?.agenda?.title;
  const papers = row?.papers?.map((e) => e.symbol)?.join(", ");

  return (
    <>
      <div
        className="grid grid-cols-10 space-x-2 w-full items-center p-2 drop-shadow-sm rounded-md bg-gray-50 mb-2 hover:bg-secondary-0 hover:border hover:border-primary-button min-h-[80px] cursor-pointer"
        onClick={handleViewTaskDetails(row.id, row.group_id)}
      >
        <div className="flex flex-col col-span-4 pr-2">
          <label className="text-xs font-medium font-sans text-black-100 leading-[18px]">
            {taskDetails}
          </label>
        </div>
        <div className="flex flex-col col-span-3">
          <label className="text-xs font-medium font-sans text-black-100 leading-[18px]">
            {agenda || "-"}/
          </label>
          <label className="text-xs font-medium font-sans text-black-100 leading-[18px]">
            {papers}
          </label>
        </div>
        <div className="flex flex-col col-span-2">
          <label className="text-xs font-semibold font-sans text-black-100 leading-[18px]">
            {memberName}
          </label>
        </div>
        <div className="flex flex-col col-span-1">
          <label className="text-xs font-medium font-sans text-black-100 leading-[18px]">
            {timeline}
          </label>
        </div>
      </div>
    </>
  );
}
export default TaskItem;
