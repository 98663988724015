import * as React from "react"

function PdfViewIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={64}
      height={92}
      viewBox="0 0 64 92"
      fill="none"
      {...props}
    >
      <rect
        x={1}
        y={1}
        width={62}
        height={90}
        rx={3}
        fill="#fff"
        stroke="#CA2438"
        strokeWidth={2}
      />
      <path
        d="M21.64 57.884a2.342 2.342 0 01-1.295-1.176c-.576-1.087-.384-2.173.236-3.086.585-.86 1.555-1.59 2.651-2.204a23.366 23.366 0 014.38-1.806 53.947 53.947 0 003.138-6.236 19.62 19.62 0 01-1.27-3.627c-.255-1.12-.352-2.229-.137-3.181.222-.991.81-1.882 1.921-2.305.567-.215 1.182-.336 1.78-.215.3.06.582.183.826.36.243.177.443.403.583.662.26.46.354.997.375 1.506.02.524-.035 1.106-.139 1.72-.248 1.428-.798 3.175-1.537 5.023a30.331 30.331 0 002.896 4.722 17.926 17.926 0 013.943.14c1.075.182 2.169.546 2.837 1.302.354.403.57.896.59 1.45.021.538-.138 1.07-.407 1.577a2.96 2.96 0 01-1.046 1.165 2.636 2.636 0 01-1.507.386c-.978-.039-1.933-.548-2.758-1.167a16.426 16.426 0 01-2.692-2.66 36.08 36.08 0 00-5.901 1.136A31.638 31.638 0 0126.09 55.6c-.857.98-1.797 1.834-2.737 2.204-.54.231-1.152.26-1.714.08zm4.074-5.324c-.49.213-.945.437-1.356.667-.97.543-1.599 1.072-1.912 1.532-.278.406-.284.7-.118 1.01.03.062.059.101.076.124a.82.82 0 00.104-.034c.405-.157 1.049-.658 1.877-1.602.47-.545.914-1.112 1.33-1.697zm4.847-3.724a39.265 39.265 0 012.984-.54 32.35 32.35 0 01-1.507-2.403 56.815 56.815 0 01-1.477 2.94v.003zm7.228 1.26c.443.454.875.84 1.286 1.148.709.532 1.202.709 1.471.717a.33.33 0 00.207-.042.865.865 0 00.278-.35c.105-.17.165-.363.174-.56a.259.259 0 00-.077-.176c-.153-.174-.59-.426-1.53-.586a12.084 12.084 0 00-1.809-.148v-.003zm-5.88-9.818c.248-.76.445-1.534.59-2.318.092-.527.127-.96.113-1.302 0-.189-.032-.376-.095-.555a1.594 1.594 0 00-.428.112c-.258.098-.467.297-.58.793-.118.537-.088 1.313.136 2.301.071.311.16.636.266.97h-.003z"
        fill="#CA2438"
      />
    </svg>
  )
}

export default PdfViewIcon
