import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import generateContext from "../../utils/generate-context";
import { useAsync } from "@react-org/hooks";
import * as ListsApi from "../../apis/lists.api";
import * as CommitteeApi from "../../apis/committee.api";
import { useNavigate, useParams } from "react-router-dom";
import { getFormInitialValues } from "./components/formhelper";
import generateFileUrl from "../../utils/generate-file-url";
import { downloadOrPreviewFileUrl } from "../../utils/download-or-preview-file-url";
import { useNotificationModalContext } from "../../components/notification-modal/provider";
import MESSAGES from "../../constants/messages";
import useCustomNavigate from "../../hooks/use-custom-navigate";
function useCommitteeFeedbackListingPage(props) {
  const notificationModal = useNotificationModalContext();
  const navigate = useNavigate();
  const mainFormRef = useRef(null);
  const { committeeId } = useParams();
  const [agenda, setAgenda] = useState("");
  const [paper, setPaper] = useState("");
  const { goBack } = useCustomNavigate();

  const getCommitteeQuery = useAsync(
    useCallback(
      (payload) =>
        CommitteeApi.getCommitteeDetails({
          id: committeeId,
          ...payload,
        }),
      [committeeId]
    ),
    {
      immediate: true,
    }
  );

  const getCommitteeFeedbackQuery = useAsync(
    useCallback(
      (payload) =>
        CommitteeApi.getCommitteeFeedback({
          id: committeeId,
          ...payload,
        }),
      [committeeId]
    ),
    {
      select: (res) => {
        return { data: res.data.data, paginate: res.data.paging };
      },
      immediate: true,
    }
  );

  const downloadCommitteeReportQuery = useAsync(CommitteeApi.downloadReport, {
    immediate: false,
  });

  const getAgendaQuery = useAsync(
    useCallback(
      (payload) => ListsApi.getAgenda({ limit: 100, ...payload }),
      []
    ),
    {
      select: (res) => {
        return { data: res.data.data, paginate: res.data.paging };
      },
      immediate: true,
    }
  );
  const getPaperForMeetingQuery = useAsync(ListsApi.getPaperForMeeting, {
    immediate: false,
  });

  const handleFeedbackClick = useCallback(
    (agendaId, paperId) => () => {
      navigate(
        `/committee/${committeeId}/agenda/${agendaId}/paper/${paperId}/feedback`
      );
    },
    [committeeId, navigate]
  );

  const initialValues = useCallback(() => {
    getFormInitialValues();
  }, []);

  const handleAgendaChange = useCallback((id) => {
    setAgenda(Number(id));
    setPaper(null);
  }, []);

  const handlePaperChange = useCallback((id) => {
    setPaper(Number(id));
  }, []);

  const handleDownloadReport = useCallback(() => {
    const downloadCommitteeReportQueryExecute =
      downloadCommitteeReportQuery.execute;
    downloadCommitteeReportQueryExecute(
      { committeeId },
      {
        onSuccess: (res) => {
          const fileURL = generateFileUrl(
            res?.data,
            res.headers["content-type"]
          );
          downloadOrPreviewFileUrl(fileURL, `IMO Report`, {
            type: res.headers["content-type"],
          });
          notificationModal.close();
        },
        onError: () => {
          notificationModal.error({
            heading: MESSAGES.PREVIEW_FAILED,
          });
        },
      }
    );
  }, [committeeId, downloadCommitteeReportQuery.execute, notificationModal]);

  const getCommitteeStatusQuery = useAsync(
    useCallback(
      (payload) => {
        return CommitteeApi.getMarkComplete({
          committeeId,
          paperId: payload?.paper_id,
          ...payload,
        });
      },
      [committeeId]
    ),
    {
      immediate: true,
    }
  );

  const agendaFeedback = useMemo(() => {
    let filteredAgenda = getAgendaQuery?.data?.data || [];
    if (agenda) {
      filteredAgenda = (getAgendaQuery?.data?.data || []).filter(
        (item) => item.id === parseInt(agenda, 10)
      );
    }
    const getCommitteeStatusQueryExecute = getCommitteeStatusQuery.execute;
    getCommitteeStatusQueryExecute();
    return filteredAgenda.reduce((acc, cur) => {
      let filteredPapers = cur.papers;
      if (paper) {
        filteredPapers = cur.papers.filter((item) => item.id === paper);
      }
      const formattedPapers = filteredPapers.map((item) => {
        const payload = {};
        payload.paper_id = item.id;
        payload.committeeId = committeeId;
        return {
          title: cur.title,
          paper: item.symbol,
          paper_id: item.id,
          agenda_id: cur.id,
          description: item.description,
        };
      });
      return [...acc, ...formattedPapers];
    }, []);
  }, [
    getAgendaQuery?.data?.data,
    agenda,
    getCommitteeStatusQuery.execute,
    paper,
    committeeId,
  ]);

  useEffect(() => {
    if (getCommitteeQuery?.data?.data?.meeting_id) {
      const payload ={
        limit : 200,
        meetingId: getCommitteeQuery?.data?.data?.meeting_id,
      }
      const getPaperForMeetingQueryExecute = getPaperForMeetingQuery.execute;
      getPaperForMeetingQueryExecute(payload);
    }
  }, [
    getCommitteeQuery?.data?.data?.meeting_id,
    getPaperForMeetingQuery.execute,
  ]);

  return useMemo(() => {
    return {
      mainFormRef,
      getAgendaQuery,
      getCommitteeFeedbackQuery,
      handleFeedbackClick,
      initialValues,
      agenda,
      paper,
      handleAgendaChange,
      handlePaperChange,
      handleDownloadReport,
      agendaFeedback,
      goBack,
      getPaperForMeetingQuery,
      getCommitteeQuery,
    };
  }, [
    getAgendaQuery,
    getCommitteeFeedbackQuery,
    handleFeedbackClick,
    initialValues,
    agenda,
    paper,
    handleAgendaChange,
    handlePaperChange,
    handleDownloadReport,
    agendaFeedback,
    goBack,
    getPaperForMeetingQuery,
    getCommitteeQuery,
  ]);
}
export const [
  CommitteeFeedbackListingPageProvider,
  useCommitteeFeedbackListingPageContext,
] = generateContext(useCommitteeFeedbackListingPage);
