import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import withLoadable from "../components/with-loadable";
import PrivateRoutes from "../components/routes-private";
import CreateCommitteePage from "../pages/create-committee.page";
import CommitteeFeedbackPage from "../pages/committee-feedback.page";
import MembersPage from "../pages/members.page";
import MyTaskPage from "../pages/my-task.page";
import GroupFeedbackPage from "../pages/group-feedback.page";
import CommitteeFeedbackListingPage from "../pages/committee-feedback-listing.page";
import MembersFeedbackPage from "../pages/members-feedback.page";
import GroupFeedbackListingPage from "../pages/group-feedback-listing.page";
import ReferenceDocumentsListinPage from "../pages/reference-documents-listing.page";
import PostMeetingReport from "../pages/post-meeting-report.page";
import PostMeetingReportV2 from "../pages/post-meeting-report_old.page";
import ManageAgendaPage from "../pages/manage-agenda.page";
import WorkingDocumentPage from "../pages/working-documents.page";
import UpcomingRegulationsPage from "../pages/upcoming-regulations.page";

const GlobalLayout = withLoadable(() => import("../layouts/global"));
const DashboardLayout = withLoadable(() => import("../layouts/dashboard"));

const LoginPage = withLoadable(() => import("../pages/auth/login.page"));
const NotFoundPage = withLoadable(() => import("../pages/not-found.page"));
const AuthLoginPage = withLoadable(() =>
  import("../pages/auth/auth-login.page")
);
const ManageCommitteePage = withLoadable(() =>
  import("../pages/manage-committee.page")
);
const ManageCommitteeDetailsPage = withLoadable(() =>
  import("../pages/manage-committee-details.page")
);
const GroupDetailsPage = withLoadable(() =>
  import("../pages/group-details.page")
);

function RouterContainer() {
  return (
    <Routes>
      <Route element={<GlobalLayout />}>
        <Route element={<PrivateRoutes />}>
          <Route element={<DashboardLayout />}>
            <Route path="/committee" element={<ManageCommitteePage />} />
            <Route path="/tasks" element={<MyTaskPage />} />
            <Route path="/agendas" element={<ManageAgendaPage />} />
            <Route path="/shadow-committee" element={<ManageCommitteePage />} />
            <Route path="/upcoming-regulations" element={<UpcomingRegulationsPage />} />
            <Route
              path="/committee/:committeeId"
              element={<ManageCommitteeDetailsPage />}
            />
            <Route
              path="/committee/:committeeId/groups/:groupId"
              element={<GroupDetailsPage />}
            />
            <Route
              path="/committee/:committeeId/feedback"
              element={<CommitteeFeedbackListingPage />}
            />
            <Route
              path="/committee/:committeeId/groups/:groupId/feedback"
              element={<GroupFeedbackListingPage />}
            />
            <Route
              path="/committee/:committeeId/agenda/:agendaId/paper/:paperId/feedback"
              element={<CommitteeFeedbackPage />}
            />
            <Route path="/create-committee" element={<CreateCommitteePage />} />
            <Route
              path="/edit-committee/:committeeId"
              element={<CreateCommitteePage />}
            />
            <Route
              path="/groups/:groupId/tasks/:taskId"
              element={<MembersFeedbackPage />}
            />
            <Route
              path="/committee/:committeeId/groups/:groupId/agenda/:agendaId/paper/:paperId/feedback"
              element={<GroupFeedbackPage />}
            />
            <Route path="/members" element={<MembersPage />} />
            <Route
              path="committee/reference-documents"
              element={<ReferenceDocumentsListinPage />}
            />
            <Route
              path="committee/:committeeId/prepare-post-meeting-report"
              element={<PostMeetingReport />}
            />
            <Route
              path="committee/:committeeId/prepare-post-meeting-report-v2"
              element={<PostMeetingReportV2 />}
            />
            <Route
              path="working-documents"
              element={<WorkingDocumentPage />}
            />
          </Route>
        </Route>

        <Route path="/login" element={<LoginPage />} />
        <Route path="/auth/login" element={<AuthLoginPage />} />
        <Route path="/" element={<Navigate to="login" />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
}

export default RouterContainer;
