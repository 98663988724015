import MessageText from "../message-text";
import cx from "../../utils/class-names";
import NotFoundIcon from "../vectors/not-found";
import PropTypes from "prop-types";

const EmptyListMessage = (props) => {
  const { classNames = {}, message, title, icon = true, iconVector, ...restProps } = props;
  return (
    <div className={cx("empty-list-message w-max h-max mx-auto flex flex-col justify-between items-center mb-[9%] self-center", classNames.root)}>
      {icon ? iconVector ? iconVector : <NotFoundIcon className={cx("mb-[9%] w-36 h-36", classNames.icon)} /> : null}
      <MessageText {...restProps} className="flex flex-col items-center text-[#09135A] font-normal">
        {title ? <div className="text-md mb-1">{title}</div> : null}
        {message ? <div className="text-sm opacity-[70%]">{message}</div> : null}
      </MessageText>
    </div>
  );
};

EmptyListMessage.defaultProps = {
  classNames: {},
  message: "",
  title: "",
  icon: true,
};

EmptyListMessage.propTypes = {
  classNames: PropTypes.shape({
    root: PropTypes.string,
    icon: PropTypes.string,
  }),
  message: PropTypes.string,
  title: PropTypes.string,
  iconVector: PropTypes.element,
  icon: PropTypes.bool,
};
export default EmptyListMessage;
