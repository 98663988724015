import Menu from "../../../components/menu";
import ReviewCard from "../../../components/review-card";
import { useUserFromStorage } from "../../../contexts/user.context";
import { useManageAgendaPageContext } from "../provider";

const AgendaItem = ({ row, style }) => {
  const { id, title, meeting, papers, meeting_id } = row;
  const {
    handleMenuOpen,
    handleMenuClose,
    setShowAction,
    showAction,
    handleEdit,
    handleDeleteAgenda,
    isAdmin,
    getMeetingDetailsQuery,
  } = useManageAgendaPageContext();
  const { user } = useUserFromStorage();
  const paperSymbols = (papers || []).map((e) => e.symbol).join(", ");
  const meetingDetails = getMeetingDetailsQuery?.data;
  const committeeMembers = meetingDetails
    ?.find((e) => e.id === meeting_id)
    ?.commitees?.flatMap((e) => e.commitee_members);
  const userInfo = committeeMembers?.find(
    (member) => member.member_id === user.id
  );
  const isShowButton =
    userInfo?.is_leader || userInfo?.is_coordinator || isAdmin;
  return (
    <div style={style}>
      <ReviewCard.Section className="relative flex flex-wrap tab-sm:flex-1 p-2 border rounded-md mb-3 hover:shadow-[-2px_2px_5px_1px_#e3e3e3,_2px_-2px_5px_1px_#e3e3e3,_2px_2px_5px_1px_#e3e3e3,_-2px_-2px_5px_1px_#e3e3e3]">
        <div className="grid grid-cols-9 space-x-4 w-full items-center justify-center my-1">
          <div className="flex flex-col flex-wrap col-span-3 ml-2">
            {title || ""}
          </div>
          <div className="flex flex-col flex-wrap col-span-1">
            <label className="text-sm font-normal font-sans text-black-100 leading-[18px]">
              {meeting?.name || ""}
            </label>
          </div>
          <div className="flex flex-col flex-wrap col-span-4 pl-2">
            <label className="text-sm font-normal font-sans text-gray-100 leading-[18px]">
              {paperSymbols || "-"}
            </label>
          </div>
          <div className="flex justify-end mt-2 col-span-1">
            {isShowButton && (
              <>
                <Menu
                  isOpen={showAction === id}
                  setOpen={setShowAction}
                  id={id}
                  handleClose={handleMenuClose}
                  handleOpen={handleMenuOpen}
                  setIsOpen={setShowAction}
                  classNames={{
                    content:
                      "py-2 shadow-[-2px_2px_5px_1px_#e3e3e3,_2px_-2px_5px_1px_#e3e3e3,_2px_2px_5px_1px_#e3e3e3,_-2px_-2px_5px_1px_#e3e3e3] rounded-md bg-white min-w-[200px] min-h-[30px] mt-1 mr-[-12px] space-y-2 ",
                  }}
                >
                  <Menu.Item
                    className="flex justify-between"
                    onClick={handleEdit(id)}
                  >
                    Edit
                  </Menu.Item>
                  <Menu.Item
                    className="flex justify-between rounded-b-md"
                    onClick={handleDeleteAgenda(id)}
                  >
                    Delete
                  </Menu.Item>
                </Menu>
              </>
            )}
          </div>
        </div>
      </ReviewCard.Section>
    </div>
  );
};

export default AgendaItem;
