import { getFormattedDate } from "../../../../utils/date-and-time";

export const generatePayload = (inputData) => {
  const {
    list_of_indian_delegation,
    from_meeting_date,
    to_meeting_date,
    nature_of_meeting,
    total_paper_submission,
    total_member_attended,
    total_member_attended_virtually,
    total_paper_under_submission,
    group_set_up,
    agendas,
    agenda_members,
  } = inputData;
  const indian_delegation_other_members = list_of_indian_delegation.map(
    ({ name, organisation, id }) => {
      const member = {
        name,
        organization: organisation,
      };
      if (id) {
        member.member_id = parseInt(id);
      }
      return member;
    }
  );

  const agendasData = agendas.map(
    ({
      id,
      total_paper_under_submission,
      total_paper_submission_by_india,
      total_paper_from_past_india,
      title,
    }) => {
      const agendaRow = {
        agenda_name: title,
        total_paper_under_submission: String(total_paper_under_submission),
        total_paper_submission_by_india: String(total_paper_submission_by_india),
        total_paper_from_past_india: String(total_paper_from_past_india),
      };
      if (id) {
        agendaRow.agenda_id = id;
      }
      return agendaRow;
    }
  );

  const nominated_agenda = agenda_members.map(
    ({ agenda_title, nominated_members, head_of_agenda, task, id }) => {
      const headOfAgenda = {
        name: head_of_agenda.head_of_agenda_name,
        organization: head_of_agenda.head_of_agenda_organisation,
        is_head: true,
      };
      if (head_of_agenda.id) {
        headOfAgenda.nominated_member_id = head_of_agenda.id;
      }
      const agendaMemberRow = {
        name: agenda_title,
        nominated_members: [
          ...nominated_members
            .filter((e) => !e.is_head)
            .map(({ name, organisation, id }) => {
              const nominatedMember = {
                name: name,
                organization: organisation,
                is_head: name === head_of_agenda.head_of_agenda_name,
              };
              if (id) {
                nominatedMember.nominated_member_id = id;
              }
              return nominatedMember;
            }),
        ],
        tasks: task.map(
          ({
            id,
            background,
            paper_under_consideration,
            india_submissions,
            india_cosponsored_papers,
            review_of_submissions,
            recommended_india_stand,
            shadow_committee_approved_stand,
            decision_of_imo_committee,
            areas_of_disagreement_of_india,
            achievement_of_india,
            to_do_list,
          }) => {
            const taskData = {
              background,
              paper_under_consideration,
              india_submissions,
              india_cosponsored_papers,
              review_of_submissions,
              recommended_india_stand,
              committee_approved_stand: shadow_committee_approved_stand,
              decision_of_committee: decision_of_imo_committee,
              areas_of_disagreement_of_india,
              achievement_of_india,
              to_do_list,
            };

            if (id) {
              taskData.task_id = id;
            }

            return taskData;
          }
        ),
      };
      if (id) {
        agendaMemberRow.nominated_agenda_id = id;
      }
      agendaMemberRow.nominated_members.push(headOfAgenda);
      return agendaMemberRow;
    }
  );

  const outputData = {
    indian_delegation_other_members: indian_delegation_other_members,
    from_meeting_date: getFormattedDate(from_meeting_date, "YYYY-MM-DD") || [],
    to_meeting_date: getFormattedDate(to_meeting_date, "YYYY-MM-DD") || [],
    nature_of_meeting: nature_of_meeting,
    total_paper_submission: total_paper_submission,
    total_member_attended: total_member_attended,
    total_member_attended_virtually: total_member_attended_virtually,
    total_paper_under_submission: total_paper_under_submission,
    group_set_up: group_set_up,
    agendas: agendasData,
    nominated_agenda: nominated_agenda,
  };
  return outputData;
};
