import Tooltip from "../../../components/tooltip";
import BinIcon from "../../../components/vectors/bin-icon";
import PenGreenIcon from "../../../components/vectors/pen-green-icon";
import cx from "../../../utils/class-names";

export const UpcomingRegulationItem = ({ row, handleRowEdit, handleDeleteRow }) => {
  const {
    approval_amendments,
    commitee,
    convention,
    entry_into_force,
    branch_to_issue_circular,
    circular_issued,
    target_date,
  } = row;

  return (
    <tr className="bg-gray-50 hover:bg-secondary-0 hover:border hover:border-primary-button mb-4">
      <td className={cx("px-2 py-1 text-xs font-medium font-sans text-black-100 leading-[18px] text-start", !commitee?.name && "text-center")}>
        {commitee?.name || "-"}
      </td>
      <td className={cx("px-2 py-1 text-xs font-medium font-sans text-black-100 leading-[18px] text-start", !approval_amendments && "text-center")}>
        {approval_amendments || "-"}
      </td>
      <td className={cx("px-2 py-1 text-xs font-medium font-sans text-black-100 leading-[18px] text-start", !convention && "text-center")}>
        {convention || "-"}
      </td>
      <td className={cx("px-2 py-1 text-xs font-medium font-sans text-black-100 leading-[18px] text-start", !entry_into_force && "text-center")}>
        {entry_into_force || "-"}
      </td>
      <td className={cx("px-2 py-1 text-xs font-medium font-sans text-black-100 leading-[18px] text-start", !branch_to_issue_circular && "text-center")}>
        {branch_to_issue_circular || "-"}
      </td>
      <td className={cx("px-2 py-1 text-xs font-medium font-sans text-black-100 leading-[18px] text-start min-w-[100px]", !target_date && "text-center")}>
        {target_date || "-"}
      </td>
      <td className={cx("px-2 py-1 text-xs font-medium font-sans text-black-100 leading-[18px] text-start", !circular_issued && "text-center")}>
        {circular_issued || "-"}
      </td>
      <td className="flex gap-x-2 mt-2 px-2 py-1 text-xs font-medium font-sans text-black-100 leading-[18px] text-start">
        <Tooltip className=" -translate-x-12 translate-y-5" text="Edit">
          <PenGreenIcon
            width={18}
            height={18}
            onClick={handleRowEdit(row)}
            className="cursor-pointer"
          />
        </Tooltip>
        <BinIcon
          onClick={handleDeleteRow(row?.id)}
          className="cursor-pointer"
        />
      </td>
    </tr>
  );
};
