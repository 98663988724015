import { forwardRef } from "react";
import { useField, useFormikContext } from "formik";
import cx from "../../utils/class-names";
import FormError from "../form-error";
import PropTypes from "prop-types";

const Input = forwardRef(function Input(props, ref) {
  const { classNames, className, error, iconLeft, iconRight, readOnly, disabled, ...restProps } = props;
  let inputClasses = cx(
    "border-0 outline-0 ring-1 ring-secondary-400 w-full border-secondary-200 rounded-[5px] bg-secondary-0 text-xs font-medium text-primary-text px-[10px] py-[6px] h-7",
    iconRight && "pr-15",
    iconLeft && "pl-15",
    (readOnly || disabled) && "bg-secondary-250",
    classNames.input || className
  );

  let iconClasses = cx("absolute top-1/2 -translate-y-1/2 h-7 flex items-center justify-center pb-0.5 mr-[1px]");

  let iconLeftClasses = cx(iconClasses, "left-0", classNames.iconLeft);
  let iconRightClasses = cx(iconClasses, "right-0", classNames.iconRight);
  let rootClasses = cx("input relative w-full", classNames.root);

  return (
    <div className={rootClasses}>
      {iconLeft && <div className={iconLeftClasses}>{iconLeft}</div>}
      <input ref={ref} type="text" className={inputClasses} {...restProps} readOnly={readOnly} disabled={disabled} />
      {iconRight && <div className={iconRightClasses}>{iconRight}</div>}
    </div>
  );
});

Input.defaultProps = {
  classNames: {
    input: "",
    iconLeft: "",
    iconRight: "",
    root: "",
  },
  className: "",
  iconLeft: null,
  iconRight: null,
  readOnly: false,
  disabled: false,
};

Input.propTypes = {
  classNames: PropTypes.shape({
    root: PropTypes.string,
    iconLeft: PropTypes.string,
    iconRight: PropTypes.string,
    input: PropTypes.string,
  }),
  className: PropTypes.string,
  iconLeft: PropTypes.element,
  iconRight: PropTypes.element,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
};

export function FormikInput(props) {
  const { name, classNames = {}, setRef = () => {} } = props;
  const [field, meta] = useField(name);
  const { submitCount } = useFormikContext();
  const isError = submitCount > 0 && !!meta.error;
  const helperText = (submitCount > 0 && meta.error) || "";
  return (
    <>
      <Input ref={setRef} error={isError} {...field} {...props} />
      <FormError show={isError} message={helperText} className={classNames.error} />
    </>
  );
}

export default Input;
