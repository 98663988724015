import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import generateContext from "../../utils/generate-context";
import useCustomNavigate from "../../hooks/use-custom-navigate";
import * as CommitteeApi from "../../apis/committee.api";
import * as MemberApi from "../../apis/members.api";
import * as PostMeetingApi from "../../apis/post-meeting-api";
import { useAsync } from "@react-org/hooks";
import { useParams } from "react-router-dom";
import { useNotificationModalContext } from "../../components/notification-modal/provider";
import MESSAGES from "../../constants/messages";
import generateFileUrl from "../../utils/generate-file-url";
import { downloadOrPreviewFileUrl } from "../../utils/download-or-preview-file-url";
import useSneaky from "../../hooks/use-sneaky";
import { getReportValidationSchema } from "./components/form-helper/validation-schema";
import { getFormInitialValues } from "./components/form-helper/initial-values";
import { FN } from "./components/form-helper/core";
import { generatePayload } from "./components/form-helper/payload";

const useCreatePostMeetingPage = () => {
  const [postMeetingId, setPostMeetingId] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isCreateGroupDrawerOpen, setIsCreateGroupDrawerOpen] = useState(false);
  const [isFormSave, setIsFormSave] = useState(false);

  const notificationModal = useNotificationModalContext();
  const { committeeId } = useParams();
  const { goBack } = useCustomNavigate();
  const formRef = useRef(null);
  const containerRef = useRef(null);
  const footerRef = useRef(null);

  useSneaky(containerRef, footerRef);

  const getPostMeetingQuery = useAsync(
    useCallback(
      (payload) =>
        CommitteeApi.getPostMeetingNew({
          committeeId: committeeId,
          ...payload,
        }),
      [committeeId]
    ),
    {
      immediate: true,
    }
  );

  const getMemberQuery = useAsync(
    useCallback((payload) => MemberApi.getMembers(payload), []),
    { immediate: true }
  );

  const createPostReportQuery = useAsync(
    CommitteeApi.createNewPostMeetingReport,
    {
      immediate: false,
    }
  );
  const deleteIndianDelegationMemberQuery = useAsync(
    PostMeetingApi.deleteIndianDelegationMember,
    {
      immediate: false,
    }
  );
  const deleteNominatedAgendaQuery = useAsync(
    PostMeetingApi.deleteNominatedAgenda,
    {
      immediate: false,
    }
  );
  const deleteNominatedAgendaMembersQuery = useAsync(
    PostMeetingApi.deleteNominatedAgendaMembers,
    {
      immediate: false,
    }
  );
  const deleteNominatedAgendaTasksQuery = useAsync(
    PostMeetingApi.deleteNominatedAgendaTasks,
    {
      immediate: false,
    }
  );

  const previewPostReportQuery = useAsync(
    CommitteeApi.PreviewNewPostMeetigReport,
    {
      immediate: false,
    }
  );

  const reportValidationSchema = useMemo(() => {
    return getReportValidationSchema();
  }, []);

  const initialValues = useMemo(() => {
    return getFormInitialValues(getPostMeetingQuery?.data?.data);
  }, [getPostMeetingQuery?.data]);

  const handleAddGroupClick = useCallback(() => {
    setIsCreateGroupDrawerOpen(true);
  }, []);

  const handleCreateGroupDrawerToggle = useCallback(() => {
    setIsCreateGroupDrawerOpen((prev) => !prev);
  }, []);

  const handleRemoveMember = useCallback(
    (id) => () => {
      const selectedMembers =
        formRef?.current?.values[FN.LIST_OF_INDIAN_DELEGATION];
      const setFieldValue = formRef?.current?.setFieldValue;
      const updatedMembers = selectedMembers?.filter((item) => item !== id);
      setFieldValue(FN.LIST_OF_INDIAN_DELEGATION, updatedMembers);
    },
    []
  );

  const handleSubmit = useCallback(
    (formData) => {
      const payload = generatePayload(formData);
      payload.committeeId = committeeId;
      payload.is_submitted = isFormSave === true ? false : true;
      const createPostReportQueryExecute = createPostReportQuery.execute;
      notificationModal.progress({
            heading: MESSAGES.INPROGRESS_MESSAGE,
      });
      createPostReportQueryExecute(payload, {
        onSuccess: (res) => {
          !isFormSave
            ? notificationModal.success({
                heading: MESSAGES.POST_MEETING_REPORT_CREATED,
              })
            : notificationModal.success({
                heading: MESSAGES.POST_MEETING_REPORT_SAVED,
              });
          getPostMeetingQuery.execute();
        },
        onError: () => {
          !isFormSave
            ? notificationModal.error({
                heading: MESSAGES.FAILED_TO_CREATE_POST_MEETING_REPORT,
              })
            : notificationModal.error({
                heading: MESSAGES.FAILED_TO_SAVE_POST_MEETING_REPORT,
              });
        },
      });
    },
    [
      committeeId,
      createPostReportQuery.execute,
      getPostMeetingQuery,
      isFormSave,
      notificationModal,
    ]
  );

  const handleSaveButton = useCallback(
    (formik) => (e) => {
      setIsFormSave(true);
      formik.submitForm();
    },
    []
  );

  const handleSubmitButton = useCallback(
    (formik) => async (e) => {
      setIsFormSave(false);
      await formik.validateForm();
      if (Object.keys(formik.errors).length === 0) {
        notificationModal.warning({
          title: MESSAGES.ARE_YOU_SURE,
          heading: MESSAGES.CANNOT_BE_EDITED_MEETING_REPORT,
          onConfirm: () => {
            formik.submitForm();
          },
        });
      } else {
        notificationModal.error({
          heading: MESSAGES.FILL_REQUIRED_FIELDS,
        });
      }
    },
    [notificationModal]
  );

  const handlePostMeetingPreview = useCallback(() => {
    const previewPostReportQueryExecute = previewPostReportQuery.execute;
    const payload = {};
    payload.committeeId = committeeId;
    payload.reportId = postMeetingId;
    notificationModal.progress({ heading: MESSAGES.INPROGRESS_MESSAGE });
    previewPostReportQueryExecute(payload, {
      onSuccess: (res) => {
        const fileURL = generateFileUrl(res?.data, res.headers["content-type"]);

        downloadOrPreviewFileUrl(fileURL, `post-meeting-report.pdf`, {
          type: res.headers["content-type"],
        });

        notificationModal.close();
      },
      onError: () => {
        notificationModal.error({ heading: MESSAGES.PREVIEW_FAILED });
      },
    });
  }, [
    committeeId,
    notificationModal,
    postMeetingId,
    previewPostReportQuery.execute,
  ]);

  const handleDeleteIndianDelegate = useCallback(
    (id, arrayHelpers, removeIndex) => (e) => {
      e.stopPropagation();
      if (id) {
        notificationModal.warning({
          title: MESSAGES.ARE_YOU_SURE,
          onConfirm: () => {
            const deleteMemberQueryExecute =
              deleteIndianDelegationMemberQuery.execute;
            const getPostMeetingQueryExecute = getPostMeetingQuery.execute;
            deleteMemberQueryExecute(
              { id, postMeetingId },
              {
                onSuccess: () => {
                  getPostMeetingQueryExecute();
                },
                onError: () => {
                  notificationModal.error({
                    heading: MESSAGES.FAILED_TO_DELETE_MEMBER,
                  });
                },
              }
            );
          },
        });
      } else {
        arrayHelpers.remove(removeIndex);
      }
    },
    [
      deleteIndianDelegationMemberQuery.execute,
      getPostMeetingQuery.execute,
      notificationModal,
      postMeetingId,
    ]
  );
  const handleDeleteNominatedAgenda = useCallback(
    (id, arrayHelpers, removeIndex) => (e) => {
      e.stopPropagation();
      if (id) {
        notificationModal.warning({
          title: MESSAGES.ARE_YOU_SURE,
          onConfirm: () => {
            const deleteNominatedAgendaQueryExecute =
              deleteNominatedAgendaQuery.execute;
            const getPostMeetingQueryExecute = getPostMeetingQuery.execute;
            deleteNominatedAgendaQueryExecute(
              { id, postMeetingId },
              {
                onSuccess: () => {
                  getPostMeetingQueryExecute();
                },
                onError: () => {
                  notificationModal.error({
                    heading: MESSAGES.FAILED_TO_DELETE_MEMBER,
                  });
                },
              }
            );
          },
        });
      } else {
        arrayHelpers.remove(removeIndex);
      }
    },
    [
      deleteNominatedAgendaQuery.execute,
      getPostMeetingQuery.execute,
      notificationModal,
      postMeetingId,
    ]
  );
  const handleDeleteNominatedAgendaMember = useCallback(
    (id, arrayHelpers, removeIndex) => (e) => {
      e.stopPropagation();
      if (id) {
        notificationModal.warning({
          title: MESSAGES.ARE_YOU_SURE,
          onConfirm: () => {
            const deleteNominatedAgendaMembersQueryExecute =
              deleteNominatedAgendaMembersQuery.execute;
            const getPostMeetingQueryExecute = getPostMeetingQuery.execute;
            deleteNominatedAgendaMembersQueryExecute(
              { id, postMeetingId },
              {
                onSuccess: () => {
                  getPostMeetingQueryExecute();
                },
                onError: () => {
                  notificationModal.error({
                    heading: MESSAGES.FAILED_TO_DELETE_MEMBER,
                  });
                },
              }
            );
          },
        });
      } else {
        arrayHelpers.remove(removeIndex);
      }
    },
    [
      deleteNominatedAgendaMembersQuery.execute,
      getPostMeetingQuery.execute,
      notificationModal,
      postMeetingId,
    ]
  );
  const handleDeleteNominatedAgendaTask = useCallback(
    (id, arrayHelpers, removeIndex) => (e) => {
      e.stopPropagation();
      if (id) {
        notificationModal.warning({
          title: MESSAGES.ARE_YOU_SURE,
          onConfirm: () => {
            const deleteNominatedAgendaTasksQueryExecute =
              deleteNominatedAgendaTasksQuery.execute;
            const getPostMeetingQueryExecute = getPostMeetingQuery.execute;
            deleteNominatedAgendaTasksQueryExecute(
              { id, postMeetingId },
              {
                onSuccess: () => {
                  getPostMeetingQueryExecute();
                },
                onError: () => {
                  notificationModal.error({
                    heading: MESSAGES.FAILED_TO_DELETE_MEMBER,
                  });
                },
              }
            );
          },
        });
      } else {
        arrayHelpers.remove(removeIndex);
      }
    },
    [
      deleteNominatedAgendaTasksQuery.execute,
      getPostMeetingQuery.execute,
      notificationModal,
      postMeetingId,
    ]
  );

  useEffect(() => {
    if (getPostMeetingQuery?.data?.data) {
      setPostMeetingId(getPostMeetingQuery?.data?.data?.id);
      setIsSubmitted(getPostMeetingQuery?.data?.data?.is_submitted);
    }
  }, [getPostMeetingQuery?.data?.data, postMeetingId]);

  return useMemo(() => {
    return {
      goBack,
      initialValues,
      getPostMeetingQuery,
      getMemberQuery,
      handleRemoveMember,
      formRef,
      handleSubmit,
      reportValidationSchema,
      handleSaveButton,
      handleSubmitButton,
      setIsSubmitted,
      isSubmitted,
      handlePostMeetingPreview,
      handleAddGroupClick,
      handleCreateGroupDrawerToggle,
      isCreateGroupDrawerOpen,
      containerRef,
      footerRef,
      handleDeleteIndianDelegate,
      handleDeleteNominatedAgenda,
      handleDeleteNominatedAgendaMember,
      handleDeleteNominatedAgendaTask,
      isFormSave,
    };
  }, [
    goBack,
    initialValues,
    getPostMeetingQuery,
    getMemberQuery,
    handleRemoveMember,
    handleSubmit,
    reportValidationSchema,
    handleSaveButton,
    handleSubmitButton,
    isSubmitted,
    handlePostMeetingPreview,
    handleAddGroupClick,
    handleCreateGroupDrawerToggle,
    isCreateGroupDrawerOpen,
    handleDeleteIndianDelegate,
    handleDeleteNominatedAgenda,
    handleDeleteNominatedAgendaMember,
    handleDeleteNominatedAgendaTask,
    isFormSave,
  ]);
};

export const [CreatePostMeetingProvider, useCreatePostMeetingPageContext] =
  generateContext(useCreatePostMeetingPage);
