import { useFormikContext } from "formik";
import React, { useMemo } from "react";
import { useCreateCommitteePageContext } from "../provider";
import FormControl from "../../../components/form-control";
import { FormikInput } from "../../../components/input";
import { FormikSelect } from "../../../components/select";
import { FN } from "./form-helper";
import BinIcon from "../../../components/vectors/bin-icon";
import { generateFullName } from "../../../utils/generate-names";
import constants from "../../../constants";

const MembersItem = ({ itemIndex, arrayHelpers }) => {
  const { handleMembersChange, getMembersQuery, handleMemberDelete } =
    useCreateCommitteePageContext();
  const formik = useFormikContext();
  const committeeLeader = formik.values[FN.LEADER_OF_DELEGATION] || "";
  const committeeCoordinator = formik.values[FN.COORDINATOR_OF_COMMITTEE] || "";
  const selectedMembers = formik.values.member_details_root?.map((e) =>
    parseInt(e.member_name)
  );
  const filteredMembers = useMemo(() => {
    let currentMemberRowCode = selectedMembers.filter(
      (_, _itemIndex) => _itemIndex === itemIndex
    );

    let otherMemberRowCode = selectedMembers.filter(
      (_, _itemIndex) => _itemIndex !== itemIndex
    );
    let membersToExclude = [...otherMemberRowCode];
    return (getMembersQuery?.data || [])
      .filter((current) => {
        let membersToRemove = membersToExclude.filter(
          (item) => !currentMemberRowCode.includes(item)
        );
        return (
          current.id !== parseInt(committeeLeader) &&
          current.id !== parseInt(committeeCoordinator) &&
          !membersToRemove.includes(current.id)
        );
      })
      ?.map((item) => {
        const full_name = generateFullName(item);
        return { full_name, ...item };
      });
  }, [
    committeeCoordinator,
    committeeLeader,
    getMembersQuery?.data,
    itemIndex,
    selectedMembers,
  ]);

  return (
    <>
      <div className="grid grid-cols-10 items-center gap-3">
        <FormControl className="col-span-3">
          <FormikSelect
            data={filteredMembers}
            id={`${FN.MEMBER_DETAILS.ROOT}.[${itemIndex}].${FN.MEMBER_DETAILS.MEMBER_NAME}`}
            name={`${FN.MEMBER_DETAILS.ROOT}.[${itemIndex}].${FN.MEMBER_DETAILS.MEMBER_NAME}`}
            lableString="full_name"
            valueString="id"
            passValueInt={true}
            placeholder="Name"
            virtualize={filteredMembers?.length >= constants.VIRTUALIZED_ENABLE_LIMIT}
            onChange={handleMembersChange(formik, itemIndex)}
            classNames={{
              root: "mb-1",
              input: "ring-black-100",
              popperContent: "max-w-[560px]",
            }}
          />
        </FormControl>
        <FormControl className="col-span-3">
          <FormikInput
            classNames={{
              root: "mb-1",
              input: "ring-black-100",
            }}
            id={`${FN.MEMBER_DETAILS.ROOT}.[${itemIndex}].${FN.MEMBER_DETAILS.MEMBER_EMAIL}`}
            name={`${FN.MEMBER_DETAILS.ROOT}.[${itemIndex}].${FN.MEMBER_DETAILS.MEMBER_EMAIL}`}
            placeholder="Email"
            disabled
          />
        </FormControl>
        <FormControl className="col-span-3">
          <FormikInput
            classNames={{
              root: "mb-1",
              input: "ring-black-100",
            }}
            id={`${FN.MEMBER_DETAILS.ROOT}.[${itemIndex}].${FN.MEMBER_DETAILS.MEMBER_REPRESENTATIVE_OF}`}
            name={`${FN.MEMBER_DETAILS.ROOT}.[${itemIndex}].${FN.MEMBER_DETAILS.MEMBER_REPRESENTATIVE_OF}`}
            placeholder="Representative of"
            disabled
          />
        </FormControl>
        <div className="flex items-center justify-center col-span-1 pr-2 mb-3">
          <BinIcon
            onClick={handleMemberDelete(arrayHelpers, itemIndex)}
            className="cursor-pointer"
          />
        </div>
      </div>
    </>
  );
};

export default MembersItem;
