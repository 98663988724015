import React, { useCallback } from "react";
import PenGreenIcon from "../../../components/vectors/pen-green-icon";
import { useUserFromStorage } from "../../../contexts/user.context";
import FeedbackForm from "./feedback-form";
import { useAsync } from "@react-org/hooks";
import * as GroupsFeedbackApi from "../../../apis/group-feedback.api";
import cx from "../../../utils/class-names";
import DownloadThinIcon from "../../../components/vectors/download-thin-icon";
import PdfIcon from "../../../components/vectors/pdf-icon";
import Divider from "../../../components/divider";
import BinIcon from "../../../components/vectors/bin-icon";
import { useGroupFeedbackPageContext } from "../provider";

const FeedbackList = (props) => {
  const { onSubmit, feedback, isEditMode, isModelOpen } = props;
  const { user } = useUserFromStorage();
  const {
    handleDownloadFile,
    handleDeleteDocument,
    setUplodedDocList,
    uploadedDocList,
    handleEdit,
    handleCancel,
  } = useGroupFeedbackPageContext();

  let feedbackId = feedback?.id;

  useAsync(
    useCallback(
      (payload) =>
        GroupsFeedbackApi.uploaodFeedbackDocummentsList({
          feedbackId: feedbackId,
          ...payload,
        }),
      [feedbackId]
    ),
    {
      onSuccess: (res) => {
        return setUplodedDocList((prevVal) =>
          [...prevVal, res?.data?.data].flat()
        );
      },
      immediate: true,
    }
  );

  const handleSubmit = (data) => {
    onSubmit && onSubmit(data, handleCancel);
  };

  const isEditable = user && user.id === feedback.member.id;

  const memberName = [
    feedback?.member?.first_name,
    feedback?.member?.last_name,
  ].join(" ");

  if (isModelOpen) {
    return (
      <FeedbackForm
        onSubmit={handleSubmit}
        data={feedback}
        onCancel={handleCancel}
        uploadedDocument={uploadedDocList}
        isEditMode={isEditMode}
      />
    );
  }
  return (
    <div className="relative text-xs feedback-box mt-2 bg-blue-800 border border-primary-button p-2 rounded">
      {isEditable && (
        <div
          className="w-6 h-6 p-1 absolute right-0 top-0 cursor-pointer"
          onClick={handleEdit(feedbackId)}
        >
          <PenGreenIcon className="w-full h-full" />
        </div>
      )}
      <div className="feedback-section mb-1">
        <div className="feedback-section_label text-black-100 font-semibold">
          {feedback.response_type} :
        </div>
        <div
          className="feedback-section_value"
          dangerouslySetInnerHTML={{ __html: feedback.response }}
        />
      </div>
      <div className="feedback-section mb-1">
        <div className="feedback-section_label text-black-100 font-semibold">
          Reported By:
        </div>
        <div className="feedback-section_value">{memberName}</div>
      </div>
      <div className="feedback-section mb-1"></div>
        {(uploadedDocList || []).map((doc, index) => {
        const name = doc.name;
        if (doc?.group_feedback_id === feedbackId) {
          return (
            <div
              className="relative flex flex-col tab-sm:flex-1 mb-2"
              key={index}
            >
              <div className="w-full p-1 rounded bg-[#F3F4F5] flex justify-between border-[2px] border-[#F8F8F8;]">
                <div className="flex gap-2 items-center max-w-[90%]">
                  <div>
                    <PdfIcon className="h-6 w-5" />
                  </div>
                  <div className="flex flex-col">
                    <p className="font-normal text-black-100 text-xs leading-[13px] break-all">
                      {name}
                    </p>
                  </div>
                </div>
                <div className="flex items-center">
                  <DownloadThinIcon
                    className={cx(
                      "text-primary-button ml-1.5 w-4 h-4 cursor-pointer"
                    )}
                    onClick={handleDownloadFile(doc)}
                  />
                  <Divider className="w-[2px] h-full mx-3.5" />
                  <BinIcon
                    className={cx("w-4 h-4 cursor-pointer self-center")}
                    onClick={handleDeleteDocument(doc)}
                  />
                </div>
              </div>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default FeedbackList;
