import FormControl from "../../../components/form-control";
import InputLabel from "../../../components/input-label";
import { FormikSelect } from "../../../components/select";
import { SelectedOptions } from "../../../components/selected-option.js";
import constants from "../../../constants";
import { useCreatePostMeetingPageContext } from "../provider";
import { FN } from "./form-helper";

const Agenda = (props) => {
  const { itemIndex } = props;
  const { initialValues } = useCreatePostMeetingPageContext();

  const agendaTitle = initialValues?.agenda_members[itemIndex].agenda_title;
  const headOfMemeber = initialValues?.agenda_members[itemIndex].head_of_agenda;
  const nomiatedMembers =
    initialValues?.agenda_members[itemIndex]?.nomiated_members;

  return (
    <>
      <div className="text-xs ml-4 mr-4 mb-3 bg-blue-800 border border-primary-button rounded">
        <div className="flex">
          <div className="ml-3 mr-3 mt-4 w-full">
            <FormControl className="">
              <InputLabel
                className="flex-1 text-label-100"
                htmlFor={`${FN.AGENDA_MEMBER_DETAILS.AGENDA_MEMBERS}[${itemIndex}].${FN.AGENDA_MEMBER_DETAILS.AGENDA_TITLE}[0].${FN.AGENDA_MEMBER_DETAILS.TITLE}`}
                disabled={true}
              >
                Agenda
              </InputLabel>
              <FormikSelect
                data={agendaTitle}
                id={`${FN.AGENDA_MEMBER_DETAILS.AGENDA_MEMBERS}[${itemIndex}].${FN.AGENDA_MEMBER_DETAILS.AGENDA_TITLE}[0].${FN.AGENDA_MEMBER_DETAILS.TITLE}`}
                name={`${FN.AGENDA_MEMBER_DETAILS.AGENDA_MEMBERS}[${itemIndex}].${FN.AGENDA_MEMBER_DETAILS.AGENDA_TITLE}[0].${FN.AGENDA_MEMBER_DETAILS.TITLE}`}
                lableString="title"
                valueString="title"
                virtualize={agendaTitle?.length >= constants.VIRTUALIZED_ENABLE_LIMIT}
                disabled={true}
                classNames={{
                  root: "mb-1",
                  input: "ring-black-100",
                  popperContent: "max-w-[220px]",
                  iconRight: "hidden",
                }}
                className="h-8"
                placeholder="Agenda"
              />
            </FormControl>
            <FormControl>
              <InputLabel
                className="flex-1 text-black-100"
                htmlFor={`${FN.AGENDA_MEMBER_DETAILS.AGENDA_MEMBERS}[${itemIndex}].${FN.AGENDA_MEMBER_DETAILS.NOMINATED_MEMEBERS}`}
                disabled={true}
              >
                Nominated person to work under this Agenda
              </InputLabel>
              <FormikSelect
                data={nomiatedMembers}
                id={`${FN.AGENDA_MEMBER_DETAILS.AGENDA_MEMBERS}[${itemIndex}].${FN.AGENDA_MEMBER_DETAILS.NOMINATED_MEMEBERS}`}
                name={`${FN.AGENDA_MEMBER_DETAILS.AGENDA_MEMBERS}[${itemIndex}].${FN.AGENDA_MEMBER_DETAILS.NOMINATED_MEMEBERS}`}
                lableString="full_name"
                valueString="id"
                placeholder="Select"
                multiSelect
                passValueInt={true}
                showSelectedLength={true}
                virtualize={nomiatedMembers?.length >= constants.VIRTUALIZED_ENABLE_LIMIT}
                classNames={{
                  root: "grid-cols-2 gap-x-4 mb-1 hidden",
                  input: "ring-black-100 h-8 hidden",
                }}
                disabled={true}
              />
            </FormControl>
            <div className="flex gap-2.5 flex-wrap">
              {nomiatedMembers.map((member,index) => {
                return (
                  <SelectedOptions
                    key={index}
                    label={member.full_name}
                    classNames={{
                      icon: "ml-2.5 hidden",
                      label: "max-w-[100%]",
                    }}
                  />
                );
              })}
            </div>
            <FormControl className="">
              <InputLabel
                className="flex-1 text-label-100"
                htmlFor={`${FN.AGENDA_MEMBER_DETAILS.AGENDA_MEMBERS}[${itemIndex}].${FN.AGENDA_MEMBER_DETAILS.HEAD_OF_AGENDA}[0].${FN.FULL_NAME}`}
              >
                Nominated Head of this Agenda
              </InputLabel>
              <FormikSelect
                data={headOfMemeber}
                id={`${FN.AGENDA_MEMBER_DETAILS.AGENDA_MEMBERS}[${itemIndex}].${FN.AGENDA_MEMBER_DETAILS.HEAD_OF_AGENDA}[0].${FN.FULL_NAME}`}
                name={`${FN.AGENDA_MEMBER_DETAILS.AGENDA_MEMBERS}[${itemIndex}].${FN.AGENDA_MEMBER_DETAILS.HEAD_OF_AGENDA}[0].${FN.FULL_NAME}`}
                lableString="full_name"
                valueString="full_name"
                virtualize={headOfMemeber?.length >= constants.VIRTUALIZED_ENABLE_LIMIT}
                classNames={{
                  root: "mb-1",
                  input: "ring-black-100",
                  popperContent: "max-w-[220px]",
                  iconRight: "hidden",
                }}
                className="h-8"
                placeholder="Agenda"
                disabled={true}
              />
            </FormControl>
          </div>
        </div>
      </div>
    </>
  );
};

export default Agenda;
