import { withConfig, getApi } from "./index";
const api = getApi();

export const createCommitte = withConfig((data, conf) => {
  const config = conf({
    url: "/commitees",
    method: "POST",
    data: data,
  });
  return api(config);
});

export const updateCommitte = withConfig((data, conf) => {
  const { id, meeting_id, ...payload } = data;
  const config = conf({
    url: `/commitees/${id}/meetings/${meeting_id}`,
    method: "PUT",
    data: payload,
  });
  return api(config);
});

export const uploadDocument = withConfig((data, conf) => {
  const { id, files = [], ...params } = data;
  const formData = new FormData();
  files.forEach((item) => {
    formData.append("files", item);
  });
  const config = conf({
    url: `/commitees/${id}/documents`,
    method: "POST",
    data: formData,
    params: params,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return api(config);
});
export const getDocuments = withConfig((data, conf) => {
  const { id, ...params } = data;
  const config = conf({
    url: `/commitees/${id}/documents`,
    method: "GET",
    params: params,
  });
  return api(config);
});
export const deleteDocument = withConfig((data, conf) => {
  const { id, ...params } = data;
  const config = conf({
    url: `/commitees/documents/${id}`,
    method: "DELETE",
    params: params,
  });
  return api(config);
});
export const downloadDocuments = withConfig((data, conf) => {
  const { id, ...params } = data;
  const config = conf({
    url: `/commitees/documents/${id}/preview`,
    method: "GET",
    params: params,
    responseType: "blob",
  });
  return api(config);
});
export const downloadReport = withConfig((data, conf) => {
  const { committeeId, ...params } = data;
  const config = conf({
    url: `/commitees/${committeeId}/feedback-report`,
    method: "GET",
    params: params,
    responseType: "blob",
  });
  return api(config);
});

export const getCommittees = withConfig((data, conf) => {
  const config = conf({
    url: "/commitees",
    method: "GET",
    params: data,
  });
  return api(config);
});

export const getCommitteeDetails = withConfig((data, conf) => {
  const { id, ...params } = data;
  const config = conf({
    url: `/commitees/${id}`,
    method: "GET",
    params: params,
  });
  return api(config);
});
export const createGroup = withConfig((data, conf) => {
  const { commiteeId, ...payload } = data;
  const config = conf({
    url: `/commitees/${commiteeId}/groups`,
    method: "POST",
    data: payload,
  });
  return api(config);
});
export const getGroupsList = withConfig((data, conf) => {
  const { committeeId, ...params } = data;
  const config = conf({
    url: `/commitees/${committeeId}/groups`,
    method: "GET",
    params: params,
  });
  return api(config);
});
export const getGroupDetails = withConfig((data, conf) => {
  const { committeeId, groupId, ...params } = data;
  const config = conf({
    url: `/commitees/${committeeId}/groups/${groupId}`,
    method: "GET",
    params: params,
  });
  return api(config);
});
export const getCommitteeFeedback = withConfig((data, conf) => {
  const { id, ...params } = data;
  const config = conf({
    url: `/commitees/${id}/feedback`,
    method: "GET",
    params: params,
  });
  return api(config);
});

export const createCommitteeFeedback = withConfig((data, conf) => {
  const { committeeId, ...payload } = data;
  const config = conf({
    url: `/commitees/${committeeId}/feedback`,
    method: "POST",
    data: payload,
  });
  return api(config);
});

export const changeMarkComplete = withConfig((data, conf) => {
  const { committeeId, paperId, ...payload } = data;
  const config = conf({
    url: `/commitees/${committeeId}/papers/${paperId}/status`,
    method: "POST",
    data: payload,
  });
  return api(config);
});

export const getCommitteeFeedbackDetails = withConfig((data, conf) => {
  const { committeeId, feedbackId, ...params } = data;
  const config = conf({
    url: `/commitees/${committeeId}/feedback/${feedbackId}`,
    method: "GET",
    params: params,
  });
  return api(config);
});

export const updateCommitteFeedbackDetails = withConfig((data, conf) => {
  const { feedbackId, ...payload } = data;
  const config = conf({
    url: `/commitee-feedback/${feedbackId}`,
    method: "PUT",
    data: payload,
  });
  return api(config);
});

export const getMarkComplete = withConfig((data, conf) => {
  const { committeeId, paperId, ...payload } = data;
  const config = conf({
    url: `/commitees/${committeeId}/papers/${paperId}/status`,
    method: "GET",
    data: payload,
  });
  return api(config);
});

export const getPostMeeting = withConfig((data, conf) => {
  const { committeeId, ...payload } = data;
  const config = conf({
    url: `/commitees/${committeeId}/post-meeting`,
    method: "GET",
    data: payload,
  });
  return api(config);
});
export const getPostMeetingNew = withConfig((data, conf) => {
  const { committeeId, ...payload } = data;
  const config = conf({
    url: `/commitees/${committeeId}/post-meeting-new`,
    method: "GET",
    data: payload,
  });
  return api(config);
});

export const createPostMeetingReport = withConfig((data, conf) => {
  const { committeeId, ...restData } = data;
  const config = conf({
    url: `/commitees/${committeeId}/post-meeting`,
    method: "POST",
    data: restData,
  });
  return api(config);
});
export const createNewPostMeetingReport = withConfig((data, conf) => {
  const { committeeId, ...restData } = data;
  const config = conf({
    url: `/commitees/${committeeId}/post-meeting-new`,
    method: "POST",
    data: restData,
  });
  return api(config);
});

export const PreviewPostMeetigReport = withConfig((data, conf) => {
  const { committeeId, reportId } = data;
  const config = conf({
    url: `/commitees/${committeeId}/post-meeting/${reportId}/preview`,
    method: "GET",
    responseType: "blob",
  });
  return api(config);
});

export const PreviewNewPostMeetigReport = withConfig((data, conf) => {
  const { committeeId, reportId } = data;
  const config = conf({
    url: `/commitees/${committeeId}/post-meeting-new/${reportId}/preview`,
    method: "GET",
    responseType: "blob",
  });
  return api(config);
});
