import { useState, createContext, useMemo, useContext, useCallback } from "react";

const LayoutContext = createContext();

function LayoutProvider(props) {
  const { children } = props;
  const [isShowMenu, setShowMenu] = useState(true);

  const closeOpenMenus = useCallback(() => {
    setShowMenu(!isShowMenu);
  }, [isShowMenu]);

  let values = useMemo(() => {
    return {
      isShowMenu,
      setShowMenu,
      closeOpenMenus,
    };
  }, [closeOpenMenus, isShowMenu]);

  return <LayoutContext.Provider value={values}>{children}</LayoutContext.Provider>;
}

export const withLayoutContext = (WrappedComponent) => {
  return (props) => {
    return (
      <LayoutProvider>
        <WrappedComponent {...props} />
      </LayoutProvider>
    );
  };
};

export default function useLayout() {
  const context = useContext(LayoutContext);
  if (context === undefined) {
    throw new Error("withLayoutContext must be used within a Layout");
  }
  return context;
}
