export default function Arrow2Icon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={15} height={12} viewBox="0 0 15 12" fill="none" {...props}>
      <path
        d="M1 5.25a.75.75 0 000 1.5v-1.5zm13.53 1.28a.75.75 0 000-1.06L9.757.697a.75.75 0 10-1.06 1.06L12.939 6l-4.242 4.243a.75.75 0 001.06 1.06L14.53 6.53zM1 6.75h13v-1.5H1v1.5z"
        fill="currentcolor"
      />
    </svg>
  );
}
