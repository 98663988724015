import * as Yup from "yup";
import * as validation from "../../../../utils/validation";

export const FN = {
  PAPER: "paper",
  FEEDBACK: "feedback",
  OBSERVATION: "observation",
};

export const generateInitialValues = (data = {}) => {
  return {
    [FN.PAPER]: (data?.feedback_papers || []).map(i => i.paper_id) || [],
    [FN.FEEDBACK]: data.response || "",
    [FN.OBSERVATION]: data.comment || "",
  };
};

export const generateValidationSchema = (withRequired = true) => {
  return Yup.object().shape({
    [FN.FEEDBACK]: validation.isRequiredString({ withRequired }),
    [FN.OBSERVATION]: validation.isRequiredString({ withRequired:false }),
  });
};

export const generatePayload = (data) => {
  return {
    papers: data[FN.PAPER],
    response: data[FN.FEEDBACK],
    comment: data[FN.OBSERVATION]
  }
}

export const Enums = {
  feedback: [
    { value: "Comment", label: "Comment" },
    { value: "No Comment", label: "No Comment" },
    { value: "Intervention", label: "Intervention" },
  ],
};
