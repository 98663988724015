import withHOC from "../../utils/with-hoc";
import { ManageAgendaPageProvider } from "./provider";
import HeaderSection from "./components/header-section";
import { HeaderRight } from "../../layouts/dashboard/header";
import HeaderTitle from "../../components/header-title";
import { AgendaList } from "./components/agenda-list";
import AddAgendaDrawer from "./components/add-agenda-drawer";

function ManageAgendaPage() {
  return (
    <>
      <div className="flex flex-1 flex-col overflow-hidden">
        <HeaderRight>
          <HeaderTitle>Manage Agenda</HeaderTitle>
        </HeaderRight>
        <HeaderSection />
        <AgendaList />
        <AddAgendaDrawer />
      </div>
    </>
  );
}

export default withHOC(ManageAgendaPageProvider, ManageAgendaPage);
