import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

import cx from "../../utils/class-names";
import { useWizard } from ".";

export default function WizardContentItem(props) {
  const { children, id, className, ...restProps } = props;
  const { contentContainerRef, updateInviewIds } = useWizard();

  const { ref, inView } = useInView({
    root: contentContainerRef.current,
    threshold: 0.2,
  });

  useEffect(() => {
    updateInviewIds(inView, id);
  }, [id, inView, updateInviewIds]);

  return (
    <div ref={ref} data-wizard-contentitem-id={id} className={cx("wizard_content-item", className)} {...restProps}>
      {children}
    </div>
  );
}
