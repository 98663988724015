import cx from "../../utils/class-names";
import Label from "../label";
import PropTypes from "prop-types";

const StatusBadges = (props) => {
  const { variant, ...restProps } = props;
  const classNames = {
    Pending: "border-orange-200 bg-orange-200 text-primary-brand",
    Completed: "border-green-200 bg-green-200 text-green-100",
  }[variant];
  const lable = {
    Pending: "Pending",
    Completed: "Completed",
  }[variant];

  return (
    <Label
      className={cx(
        "text-[10px] min-w-[5rem] font-semibold text-primary-text px-1 rounded border-[0.1px] text-center",
        classNames
      )}
      {...restProps}
    >
      {lable}
    </Label>
  );
};

StatusBadges.propTypes = {
  variant: PropTypes.oneOf(["Pending", "Completed"]),
  label: PropTypes.string,
};
export default StatusBadges;
