import Accordion from "../../../../components/accordion";
import Button from "../../../../components/button";
import ArrowIcon from "../../../../components/vectors/arrow-icon";
import ViewIcon from "../../../../components/vectors/view-icon";
import cx from "../../../../utils/class-names";
import { generateFullName } from "../../../../utils/generate-names";
import FeedbackForm from "./feedbackForm";
import { useCommitteeFeedbackPageContext } from "../../provider";
import TaskCommentDrawer from "../task-comment-drawer/Index";
import DownloadThinIcon from "../../../../components/vectors/download-thin-icon";
import PdfIcon from "../../../../components/vectors/pdf-icon";

export const TaskResponse = ({ item }) => {
  const { handleTaskCommentsDrawer, handleDownloadDocument } =
    useCommitteeFeedbackPageContext();
  const feedbackDocuments = item?.group_feedback_documents;
  const responseType = item?.response_type.toUpperCase();
  const groupName = item?.group?.name;
  const members = item?.member;
  const leaderName = generateFullName(members);
  const response = item?.response;
  const commentLength = item?.comments?.length;

  return (
    <main className="mb-2  border border-primary-button rounded-md">
      <header
        className={cx(
          "font-Poppins bg-primary-text border-primary-text text-secondary-0 border-t-2  rounded-t-md  flex justify-between items-center px-2 py-1 "
        )}
      >
        <aside className="">
          <label className="font-Poppins text-black text-md p-1 ">
            {groupName || "-"}
          </label>
          <label className="font-Poppins bg-secondary-0  text-primary-text text-md ml-1 px-1 rounded">
            {responseType}
          </label>
        </aside>
        <aside className="flex px-4 py-2">
          <ViewIcon color="white" className="mt-1" />
          <Button
            variant="link"
            color="primary"
            className="flex font-Poppins text-white text-md p-0"
            onClick={handleTaskCommentsDrawer(item)}
          >
            Task Comments
          </Button>
        </aside>
      </header>
      <nav className="bg-blue-800 text-sm px-3 py-2">
        <h1 className="font-Poppins">Group Leader - {leaderName}</h1>
      </nav>
      <div
        className="text-sm px-3 py-2 font-Poppins"
        dangerouslySetInnerHTML={{ __html: response }}
      />
      <section>
        {Array.isArray(feedbackDocuments) &&
          feedbackDocuments.map((doc, i) => {
            return (
              <div
                className="relative flex flex-col tab-sm:flex-1 mb-2"
                key={i}
              >
                <div className="w-full p-1 rounded bg-[#F3F4F5] flex justify-between border-[2px] border-[#F8F8F8;]">
                  <div className="flex gap-2 items-center max-w-[90%]">
                    <div>
                      <PdfIcon className="h-6 w-5 ml-2" />
                    </div>
                    <div className="flex flex-col">
                      <p className="font-Poppins font-normal text-black-100 text-xs leading-[13px] break-all">
                        {doc?.name}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <DownloadThinIcon
                      className={cx(
                        "text-primary-button ml-1.5 w-4 h-4 cursor-pointer mr-1.5"
                      )}
                      onClick={handleDownloadDocument(doc)}
                    />
                  </div>
                </div>
              </div>
            );
          })}
      </section>
      <section>
        <Accordion defaultValue={false} className="w-full">
          {({ expanded }) => {
            return (
              <>
                <Accordion.Header
                  className={cx(
                    "flex font-Poppins text-sm font-medium leading-6 tracking-normal text-left overflow-hidden bg-primary-button px-3 py-3"
                  )}
                >
                  <p className="font-Poppins flex items-center font-poppins text-xs font-medium leading-4 text-white tracking-normal text-left w-[100%]">
                    Comments {commentLength > 0 && `(${commentLength})`}
                    <ArrowIcon
                      className={cx(
                        "h-2 w-3 text-white ml-2",
                        expanded && "rotate-180"
                      )}
                    />
                  </p>
                </Accordion.Header>
                <Accordion.Content
                  className={cx(
                    "overflow-hidden h-0  px-3  ",
                    expanded && "h-auto"
                  )}
                >
                  <FeedbackForm item={item} />
                </Accordion.Content>
              </>
            );
          }}
        </Accordion>
        <TaskCommentDrawer item={item} />
      </section>
    </main>
  );
};
