import { withConfig, getApi } from "./index";
const api = getApi("/task-feedback");

export const updateFeedback = withConfig((data, conf) => {
  const { feedbackId, ...payload } = data;
  const config = conf({
    url: `${feedbackId}`,
    method: "PUT",
    data: payload,
  });
  return api(config);
});

export const uploaodFeedbackDocumments = withConfig((data, conf) => {
  const { feedbackId, files = [] } = data;
  let formData = new FormData();
  files.forEach((item) => {
    formData.append("files", item);
  });

  const config = conf({
    url: `/${feedbackId}/documents`,
    method: "POST",
    data: formData,
    responseType: "blob",
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return api(config);
});

export const uploaodFeedbackDocummentsList = withConfig((data, conf) => {
  const { feedbackId } = data;
  const config = conf({
    url: `/${feedbackId}/documents`,
    method: "GET",
  });
  return api(config);
});

export const feedbackDocummentDocuments = withConfig((data, conf) => {
  const { documentId, ...params } = data;
  const config = conf({
    url: `/documents/${documentId}/preview`,
    method: "GET",
    params: params,
    responseType: "blob",
  });
  return api(config);
});

export const feedbackDocummentDelete = withConfig((data, conf) => {
  const { documentId } = data;

  const config = conf({
    url: `/documents/${documentId}`,
    method: "DELETE",
  });
  return api(config);
});
