import React from "react";
import InputLabel from "../../../components/input-label";
import FormControl from "../../../components/form-control";
import { FormikDatepicker } from "../../../components/datepicker";
import { Enums, FN } from "./form-helper";
import { FormikSelect } from "../../../components/select";
import { SelectedOptions } from "../../../components/selected-option.js";
import { FormikInput } from "../../../components/input";
import Const from "../../../constants/index";
import { useCreatePostMeetingPageContext } from "../provider";
import { minDateForDatePicker } from "../../../utils/date-and-time";
import constants from "../../../constants/index";

const MeetingDetails = (props) => {
  const { memberList, selectedMembers, values, isDisabledForm } = props;
  const { handleRemoveMember, disabled } = useCreatePostMeetingPageContext();

  return (
    <>
      <div className="flex">
        <div className="ml-4  w-[22%]">
          <InputLabel className="flex-1 text-label-100">
            Date of meeting
          </InputLabel>
          <FormControl>
            <FormikDatepicker
              placeholder={Const.FROM}
              id={`${FN.FROM_MEETING_DATE}`}
              name={`${FN.FROM_MEETING_DATE}`}
              disabled={isDisabledForm}
              options={{
                minDate: minDateForDatePicker(0),
              }}
            />
          </FormControl>
        </div>
        <div className="ml-4  w-[22%]">
          <InputLabel className="flex-1 text-label-100 invisible">.</InputLabel>
          <FormControl>
            <FormikDatepicker
              placeholder={Const.TO}
              id={`${FN.TO_MEETING_DATE}`}
              name={`${FN.TO_MEETING_DATE}`}
              className="h-8"
              disabled={isDisabledForm}
              options={{
                minDate: minDateForDatePicker(0, values?.from_meeting_date),
              }}
            />
          </FormControl>
        </div>
        <div className="ml-4 mt-1 w-[50%]">
          <FormControl className="">
            <InputLabel
              className="flex-1 text-label-100"
              htmlFor={FN.NATURE_OF_MEETING}
            >
              Nature of Meeting
            </InputLabel>
            <FormikSelect
              data={Enums.NatureOfMeeting}
              id={FN.NATURE_OF_MEETING}
              name={FN.NATURE_OF_MEETING}
              lableString="label"
              valueString="nature_of_meeting"
              passValueInt={true}
              virtualize={Enums.NatureOfMeeting?.length >= constants.VIRTUALIZED_ENABLE_LIMIT}
              classNames={{
                root: "mb-1",
                input: "ring-black-100",
                popperContent: "max-w-[220px]",
              }}
              className="h-8"
              disabled={isDisabledForm}
            />
          </FormControl>
        </div>
      </div>
      <div className="ml-4 mt-1 w-[46%]">
        <FormControl>
          <InputLabel
            className="flex-1 text-black-100"
            htmlFor={FN.LIST_OF_INDIAN_DELEGATION}
          >
            List of Indian Delegation
          </InputLabel>
          <FormikSelect
            data={memberList}
            id={FN.LIST_OF_INDIAN_DELEGATION}
            name={FN.LIST_OF_INDIAN_DELEGATION}
            lableString="full_name"
            valueString="id"
            placeholder="Select"
            multiSelect
            passValueInt={true}
            showSelectedLength={true}
            virtualize
            classNames={{
              root: "grid-cols-2 gap-x-4 mb-1",
              input: "ring-black-100 h-8",
            }}
            disabled={isDisabledForm}
          />
        </FormControl>
        <div className="flex gap-2.5 flex-wrap">
          {memberList
            ?.filter((item) => selectedMembers.includes(item.id))
            .map((member) => {
              return (
                <SelectedOptions
                  key={member.id}
                  label={member.full_name}
                  onClick={handleRemoveMember(member.id)}
                  classNames={{
                    icon: `ml-2.5 ${disabled ? "hidden" : ""}`,
                    label: "max-w-[100%] ",
                  }}
                />
              );
            })}
        </div>
      </div>
      <div className="flex">
        <div className="ml-4  w-[22%]">
          <FormControl>
            <InputLabel
              className="flex-1 text-label-100"
              htmlFor={FN.TOTAL_PAPER_SUBMISSION}
            >
              Total Paper Submission at this session
            </InputLabel>
            <FormikInput
              classNames={{ root: "mb-1", input: "ring-black-100 h-8" }}
              id={FN.TOTAL_PAPER_SUBMISSION}
              name={FN.TOTAL_PAPER_SUBMISSION}
              disabled={isDisabledForm}
            />
          </FormControl>
        </div>
        <div className="ml-4  w-[22%]">
          <FormControl>
            <InputLabel
              className="flex-1 text-label-100"
              htmlFor={FN.TOTAL_MEMBER_ATTENDED}
            >
              Total who attended in person
            </InputLabel>
            <FormikInput
              classNames={{ root: "mb-1", input: "ring-black-100 h-8" }}
              id={FN.TOTAL_MEMBER_ATTENDED}
              name={FN.TOTAL_MEMBER_ATTENDED}
              disabled={isDisabledForm}
            />
          </FormControl>
        </div>
        <div className="ml-4 w-[22%]">
          <FormControl>
            <InputLabel
              className="flex-1 text-label-100"
              htmlFor={FN.TOTAL_MEMBER_ATTENDED_VIRTUALLY}
            >
              Total who attended virtually
            </InputLabel>
            <FormikInput
              classNames={{ root: "mb-1", input: "ring-black-100 h-8" }}
              id={FN.TOTAL_MEMBER_ATTENDED_VIRTUALLY}
              name={FN.TOTAL_MEMBER_ATTENDED_VIRTUALLY}
              disabled={isDisabledForm}
            />
          </FormControl>
        </div>
      </div>
      <div className="flex">
        <div className="ml-4  w-[73%]">
          <FormControl>
            <InputLabel
              className="flex-1 text-label-100"
              htmlFor={FN.TOTAL_PAPER_UNDER_SUBMISSION}
            >
              Total Paper Under Submission
            </InputLabel>
            <FormikInput
              classNames={{ root: "mb-1", input: "ring-black-100 h-8" }}
              id={FN.TOTAL_PAPER_UNDER_SUBMISSION}
              name={FN.TOTAL_PAPER_UNDER_SUBMISSION}
              disabled={isDisabledForm}
            />
          </FormControl>
        </div>
        <div className="ml-4  w-[22%]">
          <FormControl>
            <InputLabel
              className="flex-1 text-label-100"
              htmlFor={FN.GROUP_SET_UP}
            >
              Number of working group set-up
            </InputLabel>
            <FormikInput
              classNames={{ root: "mb-1", input: "ring-black-100 h-8" }}
              id={FN.GROUP_SET_UP}
              name={FN.GROUP_SET_UP}
              disabled={isDisabledForm}
            />
          </FormControl>
        </div>
      </div>
    </>
  );
};

export default MeetingDetails;
