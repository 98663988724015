const ViewIcon = ({ color = "#00B3B0", ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={19}
      height={12}
      fill="none"
      {...props}
    >
      <path
        stroke={color}
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M9.666 11C14.27 11 18 6 18 6s-3.731-5-8.334-5C5.064 1 1.333 6 1.333 6s3.73 5 8.333 5Z"
      />
      <path
        stroke={color}
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M9.666 8.083a2.083 2.083 0 1 0 0-4.166 2.083 2.083 0 0 0 0 4.166Z"
      />
    </svg>
  );
};
export default ViewIcon;
