import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import generateContext from "../../utils/generate-context";
import { useAsync } from "@react-org/hooks";
import * as CommitteeApi from "../../apis/committee.api";
import * as ListsApi from "../../apis/lists.api";
import * as PapersApi from "../../apis/papers.api";
import * as groupFeedbackApi from "../../apis/group-feedback.api";
import * as GroupsApi from "../../apis/groups.api";
import { useParams } from "react-router-dom";
import {
  generateFeedbackInitialValues,
  generateFeedbackValidationSchema,
  getFormInitialValuesTaskDetails,
} from "./components/consolidated-task-details/formhelper";
import {
  getFormInitialValuesPrepareFeedback,
  getFormValidationSchemaPrepareFeedback,
} from "./components/prepare-feedback/formhelper";
import { useNotificationModalContext } from "../../components/notification-modal/provider";
import MESSAGES from "../../constants/messages";
import { useUserFromStorage } from "../../contexts/user.context";
import Const from "../../constants";
import useCustomNavigate from "../../hooks/use-custom-navigate";
import generateFileUrl from "../../utils/generate-file-url";
import { downloadOrPreviewFileUrl } from "../../utils/download-or-preview-file-url";

function useCommitteeFeedbackPage(props) {
  const [status, setStatus] = useState();
  const [isTaskCommentDrawer, setIsTaskCommentDrawer] = useState(false);
  const [taskFeedback, setTaskFeedback] = useState([]);

  const notificationModal = useNotificationModalContext();
  const mainFormRef = useRef(null);
  const feedbackRef = useRef(null);
  const { committeeId, agendaId, paperId } = useParams();
  const { user } = useUserFromStorage();

  const { goBack, goTo } = useCustomNavigate();
  const getPaperFeedbackQuery = useAsync(
    useCallback(
      (payload) =>
        PapersApi.getPaperFeedback({
          commitee_id: committeeId,
          paperId,
          ...payload,
        }),
      [committeeId, paperId]
    ),
    {
      select: (res) => {
        return { data: res.data.data, paginate: res.data.paging };
      },
      immediate: true,
    }
  );
  const getCommitteeQuery = useAsync(
    useCallback(
      (payload) =>
        CommitteeApi.getCommitteeDetails({
          id: committeeId,
          ...payload,
        }),
      [committeeId]
    ),
    {
      immediate: true,
    }
  );

  const getCommitteeFeedbackQuery = useAsync(
    useCallback(
      (payload) =>
        CommitteeApi.getCommitteeFeedback({
          id: committeeId,
          paper_id: paperId,
          ...payload,
        }),
      [committeeId, paperId]
    )
  );

  const getAgendaQuery = useAsync(
    useCallback(
      (payload) => ListsApi.getAgenda({ limit: 100, ...payload }),
      []
    ),
    {
      select: (res) => {
        return { data: res.data.data, paginate: res.data.paging };
      },
      immediate: true,
    }
  );
  const getPaperDetailsQuery = useAsync(
    useCallback(
      (payload) => ListsApi.getPaperDetails({ agendaId, paperId, ...payload }),
      [agendaId, paperId]
    ),
    {
      immediate: true,
    }
  );
  const getGroupsListQuery = useAsync(
    useCallback(
      (payload) => CommitteeApi.getGroupsList({ committeeId, ...payload }),
      [committeeId]
    ),
    {
      select: (res) => {
        return { data: res.data.data, paginate: res.data.paging };
      },
      immediate: true,
    }
  );
  const createFeedback = useAsync(CommitteeApi.createCommitteeFeedback, {
    immediate: false,
  });

  const createGroupFeedback = useAsync(groupFeedbackApi.addFeedback, {
    immediate: false,
  });

  const changeCompleteMarkQuery = useAsync(CommitteeApi.changeMarkComplete, {
    immediate: false,
  });

  const updateFeedbackDetailsQuery = useAsync(
    CommitteeApi.updateCommitteFeedbackDetails,
    {
      immediate: false,
    }
  );
  const feedbackDetails = getPaperFeedbackQuery?.data?.data;
  const getMarkCompleteQuery = useAsync(
    useCallback(
      (payload) => CommitteeApi.getMarkComplete({ paperId, committeeId }),
      [paperId, committeeId]
    ),
    {
      immediate: true,
    }
  );

  const downloadFeedbackDocumentQuery = useAsync(
    groupFeedbackApi.feedbackDocummentDocuments,
    {
      immediate: false,
    }
  );

  const getTaskFeedbackQuery = useAsync(
    useCallback(
      (payload) =>
        GroupsApi.getTaskFeedback({
          id: payload.groupId,
          ...payload,
        }),
      []
    ),
    {
      immediate: false,
    }
  );
  const handleSubmit = useCallback(
    (data) => {
      const payload = {
        response: data.response,
        response_type: data.feedback_type,
        member_id: user?.id,
        paper_id: parseInt(paperId),
      };
      payload.committeeId = committeeId;
      const createFeedbackExecute = createFeedback.execute;
      notificationModal.progress({
        heading: MESSAGES.INPROGRESS_MESSAGE,
      });
      createFeedbackExecute(payload, {
        onSuccess: () => {
          notificationModal.success({
            heading: MESSAGES.COMMITTEE_FEEDBACK_CREATED_SUCCESSFULLY,
          });
          goTo(-1);
        },
        onError: () => {
          notificationModal.error({
            heading: MESSAGES.FAILED_TO_CREATE_COMMITTEE_FEEDBACK,
          });
        },
      });
    },
    [
      committeeId,
      createFeedback.execute,
      goTo,
      notificationModal,
      paperId,
      user?.id,
    ]
  );
  const initialValuesTaskDetails = useMemo(() => {
    return getFormInitialValuesTaskDetails();
  }, []);
  const validationSchemaPrepareFeedback = useMemo(() => {
    return getFormValidationSchemaPrepareFeedback();
  }, []);

  const initialValuesPrepareFeedback = useMemo(() => {
    return getFormInitialValuesPrepareFeedback(
      getCommitteeFeedbackQuery.data?.data?.data[0]
    );
  }, [getCommitteeFeedbackQuery.data?.data?.data]);

  const handleGroupChange = useCallback(
    (groupId) => {
      const payload = {};
      if (groupId) {
        payload.group_id = groupId;
      }
      const getPaperFeedbackQueryCancel = getPaperFeedbackQuery.cancel;
      const getPaperFeedbackQueryDebounceExecute =
        getPaperFeedbackQuery.debounceExecute;
      getPaperFeedbackQueryCancel(); //Canceled previous api call
      getPaperFeedbackQueryDebounceExecute(payload, {
        overwrite: true,
      });
    },
    [getPaperFeedbackQuery.cancel, getPaperFeedbackQuery.debounceExecute]
  );

  const handleCompleteMark = useCallback(
    (e) => {
      const checkedValue = e.target.checked;
      const payload = {};
      payload.committeeId = committeeId;
      payload.paperId = paperId;
      payload.status = checkedValue
        ? Const.STATUS.COMPLETED
        : Const.STATUS.PENDING;

      const changeCompleteMarkQueryExecute = changeCompleteMarkQuery.execute;
      const getMarkCompleteQueryExecute = getMarkCompleteQuery.execute;

      changeCompleteMarkQueryExecute(payload, {
        onSuccess: (res) => {
          getMarkCompleteQueryExecute();
        },
        onError: (res) => {
          notificationModal.error({
            heading: MESSAGES.FAILED_TO_UPDATE_STATUS,
          });
        },
      });
    },
    [
      changeCompleteMarkQuery.execute,
      committeeId,
      getMarkCompleteQuery.execute,
      notificationModal,
      paperId,
    ]
  );

  const handleUpdateFeedback = useCallback(
    (feedback_id) => (data) => {
      const { feedback_type, response } = data;
      const payload = {
        paper_id: parseInt(paperId),
        member_id: user?.id,
        response: response,
        response_type: feedback_type,
        feedbackId: feedback_id,
      };

      notificationModal.progress({
        heading: MESSAGES.INPROGRESS_MESSAGE,
      });
      const updateFeedbackDetailsQueryExecute =
        updateFeedbackDetailsQuery.execute;
      const getCommitteeFeedbackQueryExecute =
        getCommitteeFeedbackQuery.execute;
      updateFeedbackDetailsQueryExecute(payload, {
        onSuccess: () => {
          getCommitteeFeedbackQueryExecute();
          notificationModal.success({
            heading: MESSAGES.UPDATE_COMMITTEE_FEEDBACK_SUCCESSFULLY,
          });
        },
        onError: () => {
          notificationModal.error({
            heading: MESSAGES.FAIELD_TO_UPDATE_COMMITTEE_FEEDBACK,
          });
        },
      });
    },
    [
      getCommitteeFeedbackQuery.execute,
      notificationModal,
      paperId,
      updateFeedbackDetailsQuery.execute,
      user?.id,
    ]
  );

  const handleTaskCommentsDrawer = useCallback(
    (data) => () => {
      const payload = {
        id: data?.group_id,
        paperId: data?.paper_id,
      };
      const getTaskFeedbackQueryExecute = getTaskFeedbackQuery.debounceExecute;
      getTaskFeedbackQueryExecute(payload, {
        onSuccess: (res) => {
          setTaskFeedback(res?.data?.data);
        },
      });

      setIsTaskCommentDrawer(true);
    },
    [getTaskFeedbackQuery.debounceExecute]
  );

  const handleCloseCommentTaskDrawer = useCallback((e) => {
    setIsTaskCommentDrawer(false);
  }, []);

  const addFeedbackInitialValues = useMemo(() => {
    return generateFeedbackInitialValues();
  }, []);

  const addFeedbackSchema = useMemo(() => {
    return generateFeedbackValidationSchema();
  }, []);

  const handleAddFeedback = useCallback(
    (id) =>
      (data, { resetForm }) => {
        const payload = {
          feedbackId: id,
          comment: data?.comment,
        };
        const createGroupFeedbackExecute = createGroupFeedback.execute;
        notificationModal.progress({
          heading: MESSAGES.INPROGRESS_MESSAGE,
        });
        const getPaperFeedbackQueryExecute =
          getPaperFeedbackQuery.debounceExecute;
        createGroupFeedbackExecute(payload, {
          onSuccess: () => {
            notificationModal.close();
            feedbackRef.current.handleReset();
            resetForm();
            getPaperFeedbackQueryExecute();
          },
          onError: () => {
            notificationModal.error({
              heading: MESSAGES.FAILED_TO_CREATE_COMMITTEE_FEEDBACK,
            });
          },
        });
      },
    [
      createGroupFeedback.execute,
      getPaperFeedbackQuery.debounceExecute,
      notificationModal,
    ]
  );

  const handleDownloadDocument = useCallback(
    (doc) => () => {
      const payload = {
        documentId: doc?.id,
        feedbackId: doc?.group_feedback_id,
      };
      const downloadDocumetQueryExecute = downloadFeedbackDocumentQuery.execute;
      notificationModal.progress({ heading: MESSAGES.INPROGRESS_MESSAGE });
      downloadDocumetQueryExecute(payload, {
        onSuccess: (res) => {
          const fileURL = generateFileUrl(
            res?.data,
            res.headers["content-type"]
          );
          downloadOrPreviewFileUrl(fileURL, `${doc?.name}`, {
            type: res.headers["content-type"],
          });
          notificationModal.close();
        },
        onError: () => {
          notificationModal.error({
            heading: MESSAGES.PREVIEW_FAILED,
          });
        },
      });
    },
    [downloadFeedbackDocumentQuery.execute, notificationModal]
  );

  useEffect(() => {
    if (getMarkCompleteQuery?.data?.data?.status === Const.STATUS.COMPLETED) {
      setStatus(true);
    } else {
      setStatus(false);
    }
  }, [getMarkCompleteQuery?.data?.data?.status]);

  return useMemo(() => {
    return {
      mainFormRef,
      getPaperFeedbackQuery,
      getAgendaQuery,
      initialValuesTaskDetails,
      initialValuesPrepareFeedback,
      validationSchemaPrepareFeedback,
      getPaperDetailsQuery,
      getGroupsListQuery,
      handleGroupChange,
      handleSubmit,
      handleCompleteMark,
      getCommitteeFeedbackQuery,
      handleUpdateFeedback,
      status,
      goBack,
      handleTaskCommentsDrawer,
      isTaskCommentDrawer,
      handleCloseCommentTaskDrawer,
      handleAddFeedback,
      addFeedbackInitialValues,
      addFeedbackSchema,
      feedbackDetails,
      feedbackRef,
      handleDownloadDocument,
      taskFeedback,
      getCommitteeQuery,
    };
  }, [
    getPaperFeedbackQuery,
    getAgendaQuery,
    initialValuesTaskDetails,
    initialValuesPrepareFeedback,
    validationSchemaPrepareFeedback,
    getPaperDetailsQuery,
    getGroupsListQuery,
    handleGroupChange,
    handleSubmit,
    handleCompleteMark,
    getCommitteeFeedbackQuery,
    handleUpdateFeedback,
    status,
    goBack,
    handleTaskCommentsDrawer,
    isTaskCommentDrawer,
    handleCloseCommentTaskDrawer,
    handleAddFeedback,
    addFeedbackInitialValues,
    addFeedbackSchema,
    feedbackDetails,
    handleDownloadDocument,
    taskFeedback,
    getCommitteeQuery,
  ]);
}
export const [CommitteeFeedbackPageProvider, useCommitteeFeedbackPageContext] =
  generateContext(useCommitteeFeedbackPage);
