import { useEffect } from "react";
import Button from "../../../components/button";
import cx from "../../../utils/class-names";
import FeedbackForm from "./feedback-form";
import { useGroupFeedbackPageContext } from "../provider";
import FeedbackList from "./feedbackList";

function TaskDetails(props) {
  const { handleSubmit, className, feedbackList } = props;
  const {
    handleAddNewFeedbackClick,
    handleCancel,
    newFeedback,
    isEditMode,
    setNewFeedback,
  } = useGroupFeedbackPageContext();

  useEffect(() => {
    setNewFeedback(feedbackList?.length === 0);
  }, [feedbackList?.length, setNewFeedback]);

  return (
    <div className={cx("overflow-y-scroll", className)}>
      <div className="pb-0 flex flex-col">
        <div className="details-box">
          {newFeedback ? null : (
            <div className="details-header flex justify-end">
              <Button
                variant="link"
                color="primary"
                className="h-9 flex items-center gap-1 text-primary-button"
                onClick={handleAddNewFeedbackClick}
              >
                Add New Feedback
              </Button>
            </div>
          )}
          <div className="details-body">
            {newFeedback ? (
              <FeedbackForm onSubmit={handleSubmit} onCancel={handleCancel} />
            ) : null}
            {feedbackList?.map((feedback) => {
              const isModelOpen = isEditMode?.find(
                (e) => e?.feedbackId === feedback?.id && e?.showModel === true
              )?.showModel;

              return (
                <FeedbackList
                  key={feedback.id}
                  onSubmit={handleSubmit}
                  feedback={feedback}
                  isEditMode={isEditMode}
                  isModelOpen={isModelOpen}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TaskDetails;
