import cx from "../../utils/class-names";
import Modal from "../dialog";
import PropTypes from "prop-types";

const ConfirmationModal = (props) => {
  const { children, className, isOpen } = props;
  return (
    <Modal className="z-10" isOpen={isOpen}>
      <div className="min-w-[350px] text-xs flex flex-col">
        <div className={cx("confirmation-modal flex flex-col justify-between rounded-[inherit]", className)}>{children}</div>
      </div>
    </Modal>
  );
};

const ConfirmationModalTitle = (props) => {
  const { children, className, ...restProps } = props;
  return (
    <div className={cx("confirmation-modal-title text-md text-base text-primary-text font-semibold font-inter", className)} {...restProps}>
      {children}
    </div>
  );
};

const ConfirmationModalTitleBody = (props) => {
  const { children, className, ...restProps } = props;
  return (
    <div className={cx("confirmation-modal-body", className)} {...restProps}>
      {children}
    </div>
  );
};

ConfirmationModal.Title = ConfirmationModalTitle;
ConfirmationModal.Body = ConfirmationModalTitleBody;

ConfirmationModal.defaultProps = {
  isOpen: false,
  className: "",
};

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default ConfirmationModal;
