import * as Yup from "yup";
import * as validation from "../../../../utils/validation";

export const FN = {
  RESPONSE: "response",
  RESPONSE_TYPE: "response_type",
};

export const generateInitialValues = (data = {}) => {
  return {
    [FN.RESPONSE]: data.response || "",
    [FN.RESPONSE_TYPE]: data.response_type	|| "",
  };
};

export const generateValidationSchema = (withRequired = true) => {
  return Yup.object().shape({
    [FN.RESPONSE]: validation.isRequiredString({ withRequired:false }),
    [FN.RESPONSE_TYPE]: validation.isRequiredString({ withRequired }),
  });
};

export const generatePayload = (data) => {
  return {
    response: data[FN.RESPONSE],
    comment: data[FN.RESPONSE_TYPE]
  }
}

export const Enums = {
  feedback: [
    { value: "Comment", label: "Comment" },
    { value: "No Comment", label: "No Comment" },
    { value: "Intervention", label: "Intervention" },
  ],
};
