import { useNavigate } from "react-router-dom";

const useCustomNavigate = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };
  const goTo = (path) => {
    navigate(path);
  };
  const goForward = () => {
    navigate(+1);
  };
  return { goBack, goTo, goForward };
};

export default useCustomNavigate;
