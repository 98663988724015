import cx from "../../utils/class-names";
import PropTypes from "prop-types";

export default function Divider(props) {
  const { className, horizontal, vertical, ...restProps } = props;

  let classes = "bg-secondary-400";
  if (vertical) {
    classes += " w-[1px] h-full";
  } else if (horizontal) {
    classes += " h-[1px] w-full";
  }
  return (
    <div className={cx("divider", classes, className)} {...restProps}>
      &nbsp;
    </div>
  );
}

Divider.defaultProps = {
  className: "",
  horizontal: true,
  vertical: false,
};

Divider.propTypes = {
  className: PropTypes.string,
  horizontal: PropTypes.bool,
  vertical: PropTypes.bool,
};
