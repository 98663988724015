import cx from "../../utils/class-names";
import Input from "../../components/input";
import Popper from "../../components/popper";
import SearchIcon from "../../components/vectors/search-icon";
import useTypeAheadInput from "./use-type-ahead-input";
import useFormikTypeAheadInput from "./use-formik-type-ahead-input";
import CloseIcon from "../vectors/close-icon";
import is from "../../utils/is";
import FormError from "../form-error";
import ArrowIcon from "../vectors/arrow-icon";
import List from "../list";
import { useCallback } from "react";
import trimValue from "../../utils/trim-value";
import LoadingSpinner from "../loading-spinner";
import Checkbox from "../checkbox";
import Skeleton from "../skeleton";
import PropTypes from "prop-types";

const TypeAheadInput = (props) => {
  const {
    openOnFocus,
    closeOnSelect,
    isLoading = false,
    isDirty = false,
    data = [],
    value,
    id,
    iconRight,
    lableString,
    valueString,
    onChange,
    onSelect,
    onFocus,
    filter,
    limit,
    triggerIcon,
    placeholder,
    multiSelect = false,
    noDataComponent,
    classNames = {},
    className,
    onSearch,
    onBlur,
    setValue,
    inputLabelString,
    addNew = false,
    onAddNewClick,
    searchOnFocus = true,
    readOnly,
    disabled,
    hasMore,
    loadMore,
    loadingRowCount,
    readOnlyInput,
    passValueInt,
    virtualize = false,
    showCloseIcon = true,
    renderOption,
    showSelectedLength = false,
    ...restProps
  } = props;
  const {
    handleClear,
    isOpen,
    setIsOpen,
    inputRef,
    inputValue,
    handleBlur,
    containerRef,
    menuListRef,
    handleFocus,
    showReadonlyInput,
    handleInputChange,
    focusedOptionIndex,
    filteredData,
    handleOutSideClick,
    handleEscapeClick,
    handleAddNewClick,
    handleSetIsOpen,
    focusedOptionRef,
    selectedOption,
    handleOnSelect,
    virtualizeListRef,
  } = useTypeAheadInput({
    openOnFocus,
    closeOnSelect,
    data,
    value,
    lableString,
    valueString,
    onChange,
    onSelect,
    filter,
    noDataComponent,
    isDirty,
    isLoading,
    onFocus,
    onSearch,
    setValue,
    addNew,
    multiSelect,
    readOnlyInput,
    onAddNewClick,
    searchOnFocus,
    passValueInt,
    name: restProps.name,
    classNames,
  });

  const IconBox = (props) => {
    const { children, className, ...restProps } = props;
    return (
      <div
        className={cx(
          "cursor-pointer z-20 w-5 h-5 p-0.5 flex items-center justify-center hover:text-primary-brand text-primary-brand",
          className
        )}
        {...restProps}
      >
        {children}
      </div>
    );
  };

  const renderRequestRow = useCallback(
    (data) => {
      const { row, index, style = {} } = data;
      let optionLabel = "";
      if (inputLabelString) {
        optionLabel = trimValue(
          inputLabelString ? row?.[inputLabelString] : row
        );
      } else {
        optionLabel = trimValue(lableString ? row?.[lableString] : row);
      }
      let optionValue = trimValue(valueString ? row?.[valueString] : row);
      let isSelected = multiSelect
        ? value?.find((v) => {
            return trimValue(v) === String(optionValue);
          }) !== undefined
        : trimValue(value) === String(optionValue);

      let isFocused = index === focusedOptionIndex;

      let renderOptionConfig = {
        index,
        inputLabelString,
        lableString,
        valueString,
        multiSelect,
        value,
        focusedOptionIndex,
        focusedOptionRef,
        classNames,
        handleOnSelect,
        showSelectedLength,
      };
      let optionElement = null;
      if (multiSelect) {
        if (is.function(renderOption)) {
          optionElement = renderOption(row, renderOptionConfig);
        } else {
          optionElement = (
            <li
              ref={(ref) => {
                if (isFocused) {
                  focusedOptionRef.current = ref;
                }
              }}
              key={row.id}
              className={cx(
                "text-xs font-normal text-primary-text cursor-pointer hover:bg-primary-brand-100 flex pr-1.5 pt-1.5",
                isFocused && "bg-primary-brand-100",
                isSelected &&
                  cx(
                    "bg-primary-brand-100 font-semibold text-primary-brand active-option",
                    classNames.selected
                  )
              )}
              onClick={handleOnSelect(optionValue, optionLabel)}
            >
              <div>
                <Checkbox
                  checked={isSelected}
                  classNames={{
                    input: cx("w-4 h-4 bg-white rounded-md text-green-600"),
                    iconOuter: cx("w-4 h-4"),
                    iconInner: cx("w-4 h-4 text-opacity-40"),
                    checked: classNames.checked,
                    root: "pt-[2px]",
                  }}
                />
              </div>
              {optionLabel}
            </li>
          );
        }
      } else {
        if (is.function(renderOption)) {
          optionElement = renderOption(row, renderOptionConfig);
        } else {
          optionElement = (
            <li
              ref={(ref) => {
                if (isFocused) {
                  focusedOptionRef.current = ref;
                }
              }}
              key={row.id}
              className={cx(
                "p-2 text-xs font-normal text-primary-text cursor-pointer hover:bg-secondary-100",
                classNames.listItem,
                isFocused && "bg-secondary-100 ",
                isSelected &&
                  cx(
                    "bg-secondary-150 font-semibold active-option",
                    classNames.selectedRoot
                  )
              )}
              onClick={handleOnSelect(optionValue, optionLabel)}
            >
              {optionLabel}
            </li>
          );
        }
      }

      return (
        <div key={optionValue} style={style}>
          {optionElement}
        </div>
      );
    },
    [
      classNames,
      focusedOptionIndex,
      focusedOptionRef,
      handleOnSelect,
      inputLabelString,
      lableString,
      multiSelect,
      renderOption,
      showSelectedLength,
      value,
      valueString,
    ]
  );

  const renderSkeletonRow = useCallback(
    ({ key, style }) => {
      return isLoading ? (
        <div style={style} key={key}>
          <Skeleton className="w-100" />
        </div>
      ) : (
        []
      );
    },
    [isLoading]
  );

  let _iconRight = null;
  let closeIcon = null;
  if (isLoading) {
    closeIcon = (
      <>
        <div className="w-[1px] h-4 bg-secondary-200"></div>
        <IconBox>
          <LoadingSpinner color="text-blue-300" />
        </IconBox>
      </>
    );
  } else if (String(value).length > 0 && !(readOnly || disabled)) {
    closeIcon = (
      <>
        <div className="w-[1px] h-4 bg-secondary-200"></div>
        <IconBox
          onClick={handleClear}
          className="hover:text-red-200 text-red-200"
        >
          <CloseIcon className="h-2 w-3 text-blue-300" />
        </IconBox>
      </>
    );
  }

  if (!is.undefined(iconRight)) {
    _iconRight = (
      <div className={cx("flex items-center space-x-1 mr-1")}>
        {showCloseIcon && closeIcon}
        <div className="w-[1px] h-4 bg-secondary-200"></div>
        <IconBox
          className={cx(
            "rotate-180 hover:bg-secondary-100",
            isOpen && "rotate-0"
          )}
          onClick={(e) => !readOnly && !disabled && handleSetIsOpen(e)}
        >
          <ArrowIcon className="h-3 w-3 text-blue-300" />
        </IconBox>
        <div className="w-[1px] h-4 bg-secondary-200"></div>
        <div className="cursor-pointer z-20 w-7 h-5 p-0.5 flex items-center justify-center mr-1">
          {iconRight}
        </div>
      </div>
    );
  } else {
    _iconRight = (
      <div className={cx("flex items-center space-x-1 mr-1")}>
        {showCloseIcon && closeIcon}
        <div className="w-[1px] h-4 bg-secondary-200"></div>
        <IconBox
          className={cx(
            "rotate-180 hover:bg-secondary-100",
            isOpen && "rotate-0"
          )}
          onClick={(e) => !readOnly && !disabled && handleSetIsOpen(e)}
        >
          <ArrowIcon className="h-2 w-3 text-blue-300" />
        </IconBox>
      </div>
    );
  }
  let inputProps = {};

  if (showReadonlyInput) {
    inputProps.value = selectedOption?.[lableString] || value;
    inputProps.onChange = () => {};
    inputProps.readOnly = true;
  } else {
    inputProps.value = inputValue;
    if (
      !inputValue &&
      document.activeElement !== inputRef.current &&
      selectedOption
    ) {
      inputProps.value = selectedOption?.[lableString];
    }
    inputProps.onChange = handleInputChange;
  }

  if (readOnlyInput) {
    inputProps.value = "";
    inputProps.readOnly = true;

    if (multiSelect) {
      const selectedValues = selectedOption.map((item) => item?.[lableString]);
      const showLength = selectedOption.length > 0 && showSelectedLength;

      if (showLength) {
        inputProps.value = `${selectedValues.length} Selected`;
      } else {
        inputProps.value = selectedValues.join(",");
      }
    } else {
      const selectedValue =
        selectedOption?.[lableString] || selectedOption || inputValue;
      inputProps.value = selectedValue;
    }
  }

  if (readOnly || disabled) {
    inputProps.onFocus = () => {};
  }

  const _classNames = {
    root: classNames.root,
    input: cx(
      "bg-secondary-0 text-xs border-secondary-400 py-[6px] px-[10px]",
      className,
      isOpen && "rounded-bl-none rounded-br-none",
      (readOnly || disabled) && "bg-secondary-250",
      classNames.input
    ),
    iconLeft: cx("w-8 h-8 text-primary-brand", classNames.iconLeft),
    iconRight: classNames.iconRight,
  };
  const _iconLeft = <SearchIcon />;

  const setContainerRef = (ref) => {
    containerRef.current = ref;
  };
  const renderVirtualizeList = useCallback(() => {
    return (
      <List
        ref={virtualizeListRef}
        rows={filteredData || []}
        renderRow={renderRequestRow}
        renderSkeletonRow={renderSkeletonRow}
        hasMore={hasMore}
        loadMore={loadMore}
        isLoading={isLoading}
        loadingRowCount={loadingRowCount}
        isLoadMoreDirty={isDirty}
        id={id}
      />
    );
  }, [
    filteredData,
    hasMore,
    id,
    isDirty,
    isLoading,
    loadMore,
    loadingRowCount,
    renderRequestRow,
    renderSkeletonRow,
    virtualizeListRef,
  ]);

  const renderNormalList = useCallback(() => {
    return (
      <div className="flex flex-col flex-1">
        {filteredData?.map((row, index) => {
          return renderRequestRow({ row, index });
        })}
      </div>
    );
  }, [filteredData, renderRequestRow]);
  return (
    <Popper
      inputRef={inputRef}
      setRef={setContainerRef}
      portal={true}
      placement="bottom-start"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onOutsideClick={handleOutSideClick}
      onEscapeClick={handleEscapeClick}
      className="type-ahead-input"
    >
      <Popper.Trigger>
        {({ setAnchorElement }) => (
          <div ref={setAnchorElement}>
            <Input
              ref={inputRef}
              name={restProps.name}
              autoComplete="off"
              disabled={disabled}
              readOnly={readOnly}
              onBlur={handleBlur}
              onFocus={handleFocus}
              classNames={_classNames}
              iconLeft={_iconLeft}
              iconRight={_iconRight}
              placeholder={placeholder}
              {...inputProps}
              {...restProps}
            />
          </div>
        )}
      </Popper.Trigger>

      <Popper.Content
        className={cx(
          "type-ahead-input_content min-w-[350px] z-1000",
          classNames.popperContent
        )}
      >
        <ul
          ref={menuListRef}
          className={cx(
            "type-ahead-list bg-secondary-0 w-full max-h-[190px] overflow-x-hidden overflow-y-auto border border-secondary-400 shadow-[0px_2px_8px_0px_#0000000F] rounded-tl-none rounded-tr-none overflow-hidden",
            virtualize && "h-[190px]",
            classNames.options
          )}
        >
          {addNew && inputValue && !selectedOption && inputValue !== value ? (
            <li
              className={cx(
                "p-2 text-xs font-normal bg-green-100",
                classNames.addRoot
              )}
              onClick={handleAddNewClick}
            >
              <span
                className={cx("text-primary-text", classNames.addInputValue)}
              >
                Click here to add new:{" "}
                <span className="font-bold">{inputValue}</span>
              </span>
            </li>
          ) : null}
          <div className="flex-1 flex h-full">
            {virtualize ? renderVirtualizeList() : renderNormalList()}
          </div>
        </ul>
      </Popper.Content>
    </Popper>
  );
};

export function FormikTypeAheadInput(props) {
  const { handleChange, isError, helperText, field, classNames } =
    useFormikTypeAheadInput(props);

  return (
    <>
      <TypeAheadInput {...props} {...field} onChange={handleChange} />
      <FormError
        show={isError}
        message={helperText}
        className={classNames.error}
      />
    </>
  );
}

TypeAheadInput.defaultProps = {
  openOnFocus: false,
  isLoading: false,
  isDirty: false,
  data: [],
  value: "",
  id: "",
  lableString: "",
  valueString: "",
  onFocus: null,
  filter: [],
  limit: 0,
  triggerIcon: null,
  placeholder: "",
  multiSelect: false,
  noDataComponent: null,
  inputLabelString: "",
  addNew: false,
  searchOnFocus: true,
  readOnly: false,
  disabled: false,
  readOnlyInput: false,
  virtualize: false,
  showSelectedLength: false,
};

TypeAheadInput.propTypes = {
  openOnFocus: PropTypes.bool,
  closeOnSelect: PropTypes.func,
  isLoading: PropTypes.bool,
  isDirty: PropTypes.bool,
  data: PropTypes.array,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  id: PropTypes.string,
  iconRight: PropTypes.element,
  lableString: PropTypes.string,
  valueString: PropTypes.string,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  onFocus: PropTypes.func,
  filter: PropTypes.array,
  limit: PropTypes.number,
  triggerIcon: PropTypes.element,
  placeholder: PropTypes.string,
  multiSelect: PropTypes.bool,
  noDataComponent: PropTypes.element,
  classNames: PropTypes.shape({
    selected: PropTypes.string,
    checked: PropTypes.string,
    selectedRoot: PropTypes.string,
    iconLeft: PropTypes.string,
    iconRight: PropTypes.string,
    root: PropTypes.string,
    input: PropTypes.string,
    popperContent: PropTypes.string,
    listItem: PropTypes.string,
    options: PropTypes.string,
    addRoot: PropTypes.string,
    addInputValue: PropTypes.string,
    error: PropTypes.string,
  }),
  className: PropTypes.string,
  onSearch: PropTypes.func,
  onBlur: PropTypes.func,
  setValue: PropTypes.func,
  inputLabelString: PropTypes.string,
  addNew: PropTypes.bool,
  onAddNewClick: PropTypes.func,
  searchOnFocus: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  hasMore: PropTypes.func,
  loadMore: PropTypes.func,
  loadingRowCount: PropTypes.func,
  readOnlyInput: PropTypes.bool,
  virtualize: PropTypes.bool,
  showSelectedLength: PropTypes.bool,
};

export default TypeAheadInput;
