import cx from "../../utils/class-names";
import PropTypes from "prop-types";

function Section(props) {
  const { children, className, ...restProps } = props;
  return (
    <div className={cx("section bg-cyan-50 w-full p-2", className)} {...restProps}>
      {children}
    </div>
  );
}
function SectionTitle(props) {
  const { children, className, ...restProps } = props;
  return (
    <div className={cx("section_title text-primary-text text-sm font-bold mb-2", className)} {...restProps}>
      {children}
    </div>
  );
}

function SectionBody(props) {
  const { children, className, ...restProps } = props;
  return (
    <div className={cx("section_body", className)} {...restProps}>
      {children}
    </div>
  );
}

Section.Title = SectionTitle;
Section.Body = SectionBody;

Section.defaultProps = {
  children: [],
  className: "",
};

Section.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
export default Section;
