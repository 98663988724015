import * as React from "react"

function EllipseArrowIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={26}
      viewBox="0 0 26 26"
      fill="none"
      {...props}
    >
      <circle cx={13} cy={13} r={12.5} fill="#fff" stroke="#00B3B0" />
      <path
        d="M12.2 20a.8.8 0 001.6 0h-1.6zM13 7l-4.619 8h9.238L13 7zm.8 13v-5.8h-1.6V20h1.6z"
        fill="#00B3B0"
      />
    </svg>
  )
}

export default EllipseArrowIcon
