import { downloadOrPreviewFileUrl } from "../../utils/download-or-preview-file-url";
import ReviewCard from "../review-card";
import UploadIcon from "../vectors/upload-icon";
import UploadedFile from "./uploaded-file-item";

const DocumentUploader = ({ documents, setDocuments, onDelete ,onDownload, showDownloadIcon}) => {
  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    setDocuments((prevDocuments) => [...prevDocuments, ...files]);
  };

  const handleFileInputChange = (event) => {
    const files = Array.from(event.target.files);
    setDocuments((prevDocuments) => [...prevDocuments, ...files]);
  };

  const handleRemoveDocument = (index) => {
    setDocuments((prevDocuments) => {
      const updatedDocuments = [...prevDocuments];
      updatedDocuments.splice(index, 1);
      return updatedDocuments;
    });
  };

  const handleClick = () => {
    const fileInput = document.getElementById("file-input");
    if (fileInput && document.activeElement !== fileInput) {
      fileInput.click();
    }
  };

  const handleDelete = (index, document) => {
    if (onDelete) {
      onDelete(index, document);
    } else {
      handleRemoveDocument(index);
    }
  };

  const handleDownloadDocument = (document)=>{
    const url = URL.createObjectURL(document);
    downloadOrPreviewFileUrl(url, document.name);
  }

  const handleDownload = ( document) => {
    if (onDownload) {
      onDownload(document);
    } else {
      handleDownloadDocument(document);
    }
  };
  return (
    <div className="flex flex-col gap-3">
      <div
        className="bg-blue-100 p-4 border border-dotted border-blue-200 rounded-md  relative cursor-pointer"
        onDrop={handleDrop}
        onDragOver={(event) => event.preventDefault()}
        onClick={handleClick}
      >
        <input
          type="file"
          id="file-input"
          multiple
          className="absolute w-full h-full top-0 left-0 opacity-0"
          onChange={handleFileInputChange}
        />
        <div className="flex flex-wrap items-center justify-center gap-2">
          <span className="block text-xs text-center cursor-pointer text-gray-600">
            Drag and drop files or click to upload
          </span>
          <UploadIcon />
        </div>
      </div>
      {documents?.length > 0 && (
        <ReviewCard.Section className="relative flex flex-col tab-sm:flex-1 gap-2 py-2 border border-black-100 rounded-md m-0">
          <ul className="m-0">
            {documents.map((document, index) => {
              return (
                <UploadedFile
                  key={index}
                  name={document.name}
                  date={document.lastModifiedDate}
                  onDeleteClick={() => handleDelete(index, document)}
                  onDownloadClick={() => handleDownload(document)}
                  showDownloadIcon={showDownloadIcon}
                />
              );
            })}
          </ul>
        </ReviewCard.Section>
      )}
    </div>
  );
};
export default DocumentUploader;
