import cx from "../../utils/class-names";

export default function WizardNav(props) {
  const { children, className, ...restProps } = props;

  return (
    <div className={cx("wizard_nav w-[304px] p-6 bg-secondary-0 shadow-[0px_2px_8px_0px_#0000000F]", className)} {...restProps}>
      {children}
    </div>
  );
}
