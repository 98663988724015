import { useRef } from "react";
import Accordion from "../../../../components/accordion";
import cx from "../../../../utils/class-names";
import ArrowIcon from "../../../../components/vectors/arrow-icon";
import FolderOpenIcon from "../../../../components/vectors/folder-open-icon";
import FolderCloseIcon from "../../../../components/vectors/folder-close-icon";
import Button from "../../../../components/button";
import UploadIcon from "../../../../components/vectors/upload-icon";
import Enums from "../../../../constants/enums";
import { generateFullName } from "../../../../utils/generate-names";

export const MiscellaneousFolder = ({
  handleOpenUpload,
  handleDocumentUpload,
  getMeetingDetailsQuery,
  handleDownloadDocuments,
  membersDetails,
  isFolderOpen
}) => {
  const rootDocuments =
    getMeetingDetailsQuery?.data?.data?.root_documents || [];
  const miscellaneousFileUploadRef = useRef();
  return (
    <Accordion defaultValue={false} className="rounded w-full">
      {({ expanded }) => {
        const icon =
          rootDocuments.length > 0 ? (
            <FolderOpenIcon className="text-secondary-550 opacity-50 ml-2 mr-2 h-5 w-6" />
          ) : (
            <FolderCloseIcon className="text-secondary-550 opacity-50 ml-2 mr-2 h-5 w-6" />
          );
        return (
          <>
            <div
              className={cx(
                "flex items-center justify-between p-1 hover:bg-blue-100 cursor-pointer"
              )}
            >
              <Accordion.Header className="flex items-center">
                <div className="ml-1 h-2.5 w-2.5">
                  <ArrowIcon
                    className={cx(
                      "text-primary-text items-center h-2.5 w-2.5 rotate-90",
                      (isFolderOpen || expanded) && "rotate-180"
                    )}
                  />
                </div>
                {icon}
                <div className={cx("flex flex-1 pr-3.5 items-center")}>
                  <span className="mt-1 break-all text-black-100 font-semibold text-sm font-sans leading-5 mb-1">
                    Miscellaneous
                  </span>
                </div>
              </Accordion.Header>
              <div className="flex items-center mr-4">
                <Button
                  variant="link"
                  color="primary"
                  className={cx("font-semibold py-0 px-0 m-0 leading-none")}
                  onClick={handleOpenUpload(miscellaneousFileUploadRef)}
                >
                  <span className="flex items-center gap-2">
                    <UploadIcon className="ml-1.5" />
                    <span className="text-xs text-primary-button font-semibold mt-1">
                      Upload
                    </span>
                  </span>
                </Button>
                <input
                  type="file"
                  ref={miscellaneousFileUploadRef}
                  className="hidden"
                  multiple
                  onChange={handleDocumentUpload(
                    0,
                    Enums.ASSOCIATION_TYPES.ROOT
                  )}
                />
              </div>
            </div>
            <Accordion.Content
              className={cx(
                "h-0 overflow-hidden",
                (isFolderOpen || expanded) && "overflow-visible ml-[52px] h-auto mt-1.5"
              )}
            >
              {rootDocuments?.map((doc, index) => {
                const docName = doc?.document_name;
                const createdBy = membersDetails?.find(
                  (e) => e.id === doc.created_by
                );
                const membersName = generateFullName(createdBy);
                return (
                  <div
                    className="relative flex flex-col tab-sm:flex-1 mb-2"
                    key={index}
                    onClick={handleDownloadDocuments(doc.id, docName)}
                  >
                    <div className="flex items-center justify-between gap-4 p-2 rounded bg-[#F3F4F5] border-[2px] border-[#F8F8F8;] hover:bg-secondary-0 hover:border hover:border-primary-button cursor-pointer">
                      <div className="flex flex-col col-span-6 pr-2">
                        <label className="text-xs font-medium font-sans text-black-100 leading-[18px]">
                          {docName}
                        </label>
                      </div>
                      <div className="flex flex-col items-end col-span-2">
                        <label className="text-xs font-medium font-sans text-black-100 leading-[18px] mr-4">
                          {membersName}
                        </label>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Accordion.Content>
          </>
        );
      }}
    </Accordion>
  );
};
