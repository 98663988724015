import { isEmpty } from "lodash";
import { withConfig, getApi } from "./index";
const api = getApi("/upcoming-regulations");

export const addUpcomingRegulation = withConfig((data, conf) => {
    const { file , approval_amendments, committee_id, convention, circular_to_be_amended, adoption_amendments, entry_into_force, branch_to_issue_circular, circular_issued, target_date } = data;
    let formData = new FormData();
    if (!isEmpty(file)) {
        formData.append("file", file);
    }
    formData.append("committee_id", committee_id);
    formData.append("approval_amendments", approval_amendments);
    formData.append("convention", convention);
    formData.append("circular_to_be_amended", circular_to_be_amended);
    formData.append("adoption_amendments", adoption_amendments);
    formData.append("entry_into_force", entry_into_force);
    formData.append("branch_to_issue_circular", branch_to_issue_circular);
    formData.append("circular_issued", circular_issued);
    formData.append("target_date", target_date);
    const config = conf({
        url: `/`,
        method: "POST",
        data: formData,
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
    return api(config);
});

export const getUpcomingRegulations = withConfig((data, conf) => {
    const config = conf({
        url: "/",
        method: "GET",
        params: data,
    });
    return api(config);
});

export const getUpcomingRegulationsDetails = withConfig((data, conf) => {
    const { id, ...params } = data;
    const config = conf({
        url: `/${id}`,
        method: "GET",
        params: params,
    });
    return api(config);
});

export const updateUpcomingRegulation = withConfig((data, conf) => {
    const { id, file , approval_amendments, committee_id, convention, circular_to_be_amended, adoption_amendments, entry_into_force, branch_to_issue_circular, circular_issued, target_date } = data;
    let formData = new FormData();
    if (file?.name) {
        formData.append("file", file);
    }
    formData.append("committee_id", committee_id);
    formData.append("approval_amendments", approval_amendments);
    formData.append("convention", convention);
    formData.append("circular_to_be_amended", circular_to_be_amended);
    formData.append("adoption_amendments", adoption_amendments);
    formData.append("entry_into_force", entry_into_force);
    formData.append("branch_to_issue_circular", branch_to_issue_circular);
    formData.append("circular_issued", circular_issued);
    formData.append("target_date", target_date);
    const config = conf({
        url: `/${id}`,
        method: "PUT",
        data: formData,
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
    return api(config);
});

export const deleteUpcomingRegulation = withConfig((data, conf) => {
    const { id, ...params } = data;
    const config = conf({
        url: `/${id}`,
        method: "DELETE",
        params: params,
    });
    return api(config);
});


export const previewCircular = withConfig((data, conf) => {
    const { id, ...params } = data;
    const config = conf({
        url: `documents/${id}/preview`,
        method: "GET",
        params: params,
        responseType: "blob",
    });
    return api(config);
});

export const deleteCircular = withConfig((data, conf) => {
    const { id, ...params } = data;
    const config = conf({
        url: `documents/${id}`,
        method: "DELETE",
        params: params,
    });
    return api(config);
});