import * as Yup from "yup";
import * as validation from "../../../../utils/validation";
import { FN } from "./core";

const delegateSchema = Yup.object().shape({
  [FN.AGENDA_MEMBER_DETAILS.NOMINATED_MEMBERS.NAME]: validation.isRequiredString(),
  [FN.AGENDA_MEMBER_DETAILS.NOMINATED_MEMBERS.ORGANISATION]: validation.isRequiredString(),
});

const agendaTaskSchema = Yup.object().shape({
  [FN.AGENDA_MEMBER_DETAILS.TASKS.BACKGROUND]: validation.isRequiredString(),
  [FN.AGENDA_MEMBER_DETAILS.TASKS.INDIA_SUBMISSION]: validation.isRequiredString(),
  [FN.AGENDA_MEMBER_DETAILS.TASKS.INDIA_COSPONSORED_PAPERS]: validation.isRequiredString(),
  [FN.AGENDA_MEMBER_DETAILS.TASKS.REVIEW_OF_SUBMISSION]: validation.isRequiredString(),
  [FN.AGENDA_MEMBER_DETAILS.TASKS.RECOMMENDED_INDIA_STAND_TO_BE_TAKEN]: validation.isRequiredString(),
  [FN.AGENDA_MEMBER_DETAILS.TASKS.SHADOW_COMMITTEE_APPROVED_STAND]: validation.isRequiredString(),
  [FN.AGENDA_MEMBER_DETAILS.TASKS.DECISION_OF_IMO_COMMITTEE]: validation.isRequiredString(),
  [FN.AGENDA_MEMBER_DETAILS.TASKS.AREAS_OF_DISAGREEMENT_OF_INDIA]: validation.isRequiredString(),
  [FN.AGENDA_MEMBER_DETAILS.TASKS.ACHIEVEMENT_OF_INDIA]: validation.isRequiredString(),
  [FN.AGENDA_MEMBER_DETAILS.TASKS.TO_DO_LIST]: validation.isRequiredString(),
});

const agendaMembersSchema = Yup.object().shape({
  [FN.AGENDA_MEMBER_DETAILS.AGENDA_TITLE]: validation.isRequiredString(),
  [FN.AGENDA_MEMBER_DETAILS.HEAD_OF_AGENDA.ROOT]: Yup.object().shape({
    [FN.AGENDA_MEMBER_DETAILS.HEAD_OF_AGENDA.HEAD_OF_AGENDA_NAME]: validation.isRequiredString(),
    [FN.AGENDA_MEMBER_DETAILS.HEAD_OF_AGENDA.HEAD_OF_AGENDA_ORGANISATION]: validation.isRequiredString(),
  }),
  [FN.AGENDA_MEMBER_DETAILS.NOMINATED_MEMBERS.ROOT]: Yup.array().of(delegateSchema),
  [FN.AGENDA_MEMBER_DETAILS.TASKS.ROOT]: Yup.array().of(agendaTaskSchema),
});

const listOfIndianDelegationSchema = Yup.array().of(
  Yup.object().shape({
    [FN.LIST_OF_INDIAN_DELEGATION.NAME]: validation.isRequiredString(),
    [FN.LIST_OF_INDIAN_DELEGATION.ORGANISATION]: validation.isRequiredString(),
  })
);

const agendaDetailsSchema = Yup.array().of(
  Yup.object().shape({
    [FN.AGENDA_DETAILS.TOTAL_PAPER_UNDER_CONSIDERATION]: validation.isRequiredNumber(),
    [FN.AGENDA_DETAILS.TOTAL_PAPER_SUBMISSION_BY_INDIA]: validation.isRequiredNumber(),
    [FN.AGENDA_DETAILS.TOTAL_PAPER_FROM_PAST_INDIA]: validation.isRequiredNumber(),
  })
);

export const getReportValidationSchema = () => {
  return Yup.lazy(() => {
    return Yup.object().shape({
      [FN.FROM_MEETING_DATE]: validation.isRequiredDate(),
      [FN.TO_MEETING_DATE]: validation.isRequiredDate(),
      [FN.NATURE_OF_MEETING]: validation.isRequiredString(),
      [FN.LIST_OF_INDIAN_DELEGATION.ROOT]: listOfIndianDelegationSchema,
      [FN.TOTAL_PAPER_SUBMISSION]: validation.isRequiredNumber(),
      [FN.TOTAL_MEMBER_ATTENDED]: validation.isRequiredNumber(),
      [FN.TOTAL_MEMBER_ATTENDED_VIRTUALLY]: validation.isRequiredNumber(),
      [FN.TOTAL_PAPER_UNDER_SUBMISSION]: validation.isRequiredString(),
      [FN.GROUP_SET_UP]: validation.isRequiredNumber(),
      [FN.AGENDA_DETAILS.AGENDAS]: agendaDetailsSchema,
      [FN.AGENDA_MEMBER_DETAILS.ROOT]: Yup.array().of(agendaMembersSchema),
    });
  });
};
