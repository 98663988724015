import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import usePageVisibilityChange from "../../hooks/use-page-visibility-change";
import { useTokensFromStorage } from "../../contexts/token.context";
import CheckUserAccess from "../check-user-access";
import { isEmpty } from "lodash";

function NoAccess() {
  return <div>You dont have access to this page</div>;
}

export default function RoutesPrivate(props) {
  const navigate = useNavigate();
  const { tokens } = useTokensFromStorage();
  const isVisible = usePageVisibilityChange();
  const [isRender, setIsRender] = useState(false);

  useEffect(() => {
    if (isEmpty(tokens)) {
      navigate("/");
    } else {
      setIsRender(true);
    }
  }, [isVisible, navigate, tokens]);

  if (!isRender) {
    return null;
  }
  return (
    <CheckUserAccess fallback={<NoAccess />} {...props}>
      <Outlet />
    </CheckUserAccess>
  );
}
