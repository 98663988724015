import { useCreatePostMeetingPageContext } from "../provider";
import { useFormikContext } from "formik";
import Button from "../../../components/button";
import cx from "../../../utils/class-names";

export const FooterSection = () => {
  const {
    handleSaveButton,
    handleSubmitButton,
    handlePostMeetingPreview,
    isSubmitted,
    footerRef,
  } = useCreatePostMeetingPageContext();
  const formik = useFormikContext();

  return (
    <footer
      className="flex justify-end gap-3 p-6 sticky bottom-0 bg-secondary-0 transition duration-200 ease-in-out translate-y-full"
      ref={footerRef}
    >
      <Button
        variant="outline"
        color="primary"
        className={cx(
          "py-2 px-3.5 flex items-center text-xs font-semibold text-primary-button hover:bg-primary-button hover:text-secondary-0"
        )}
        onClick={handleSaveButton(formik)}
        disabled={isSubmitted}
      >
        Save
      </Button>
      <Button
        variant="solid"
        color="primary"
        className={cx(
          `y-2 px-3.5 w-20 flex items-center text-xs font-semibold`,
          isSubmitted ? "text-primary-button" : " text-white"
        )}
        onClick={handleSubmitButton(formik)}
        disabled={isSubmitted}
      >
        Submit
      </Button>
      <Button
        variant="solid"
        color="primary"
        className="py-2 px-3.5 flex items-center text-xs font-semibold action-auditor-save"
        onClick={handlePostMeetingPreview}
      >
        Prepare Meeting Report
      </Button>
    </footer>
  );
};
