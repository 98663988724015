import {
  useState,
  createContext,
  useContext,
  useMemo,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  clearTokenDataFromSession,
  useTokensFromStorage,
} from "../../../contexts/token.context";
import { getInitialValues } from "./change-password-model/form-helper";
import { useUserFromStorage } from "../../../contexts/user.context";
import { useAsync } from "@react-org/hooks";
import * as AuthApi from "../../../apis/auth.api";
import { useNotificationModalContext } from "../../../components/notification-modal/provider";
const HeaderContext = createContext();

function HeaderProvider(props) {
  const { children } = props;
  const navigate = useNavigate();
  const notificationModal = useNotificationModalContext();
  const { clearTokens } = useTokensFromStorage();
  const { user } = useUserFromStorage();
  const isForceChange = user?.force_change;
  const [isProfileTogglerOpen, setProfileTogglerOpen] = useState(false);
  const [isHelperMenuOpen, setHelperMenuOpen] = useState(false);
  const [isNotificationMenuOpen, setNotificationMenuOpen] = useState(false);
  const [isChangePasswordOpen, setIsChangePasswordModel] =
    useState(isForceChange);
  const initialValues = useCallback(() => {
    getInitialValues();
  }, []);

  const changePasswordQuery = useAsync(
    useCallback(
      (payload) =>
        AuthApi.changePassword({
          memberId: user.id,
          ...payload,
        }),
      [user.id]
    ),
    { immediate: false }
  );

  const handleLogout = useCallback(() => {
    clearTokenDataFromSession();
    clearTokens();
    navigate("/auth/login");
  }, [navigate, clearTokens]);

  const handleManageMemberClick = useCallback(() => {
    navigate("/members");
  }, [navigate]);

  const handleChangePasswordClick = useCallback(() => {
    setIsChangePasswordModel(true);
  }, []);
  const handleCancleChangePasswordModel = useCallback(() => {
    setIsChangePasswordModel(false);
  }, []);
  const handleChangePasswordSubmit = useCallback(
    (data) => {
      const payload = {
        current_password: data.current_password,
        new_password: data.new_password,
        new_password_repeat: data.confirm_new_password,
      };
      const changePasswordQueryExecute = changePasswordQuery.execute;
      notificationModal.progress({
        heading: "Change password inprogress",
      });
      changePasswordQueryExecute(payload, {
        onSuccess: (res) => {
          clearTokenDataFromSession();
          clearTokens();
          navigate("/auth/login");
          notificationModal.success({
            heading: "Password changed successfully",
          });
        },
        onError: () => {
          notificationModal.error({
            heading: "Failed to change password",
          });
        },
      });
    },
    [changePasswordQuery.execute, clearTokens, navigate, notificationModal]
  );

  const values = useMemo(() => {
    return {
      handleLogout,
      isProfileTogglerOpen,
      setProfileTogglerOpen,
      isHelperMenuOpen,
      setHelperMenuOpen,
      isNotificationMenuOpen,
      setNotificationMenuOpen,
      isChangePasswordOpen,
      handleChangePasswordClick,
      handleCancleChangePasswordModel,
      initialValues,
      handleChangePasswordSubmit,
      handleManageMemberClick,
    };
  }, [
    handleLogout,
    isProfileTogglerOpen,
    isHelperMenuOpen,
    isNotificationMenuOpen,
    isChangePasswordOpen,
    handleChangePasswordClick,
    handleCancleChangePasswordModel,
    initialValues,
    handleChangePasswordSubmit,
    handleManageMemberClick,
  ]);

  return (
    <HeaderContext.Provider value={values}>{children}</HeaderContext.Provider>
  );
}

export const withHeaderContext = (WrappedComponent) => {
  return (props) => {
    return (
      <HeaderProvider>
        <WrappedComponent {...props} />
      </HeaderProvider>
    );
  };
};

export default function useHeader() {
  const context = useContext(HeaderContext);
  if (context === undefined) {
    throw new Error("withHeaderContext must be used within a Header");
  }
  return context;
}
