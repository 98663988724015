import cx from "../../utils/class-names";
import Popper from "./../popper";
import VerticalDots from "./../vectors/vertical-dots";
import MenuItems from "./menu-items";
import PropTypes from "prop-types";

const Menu = (props) => {
  const { id, isOpen, classNames, children, handleClose, handleOpen, setIsOpen, disabled, ...restProps } = props;
  return (
    <Popper
      isOpen={isOpen}
      onOutsideClick={handleClose}
      onEscapeClick={handleClose}
      {...restProps}
      portal={true}
      setIsOpen={setIsOpen}
      className={cx("menu bottom-2.5", classNames.menu)}
    >
      <Popper.Trigger>
        {({ setAnchorElement }) => (
          <button
            className={cx("hover:bg-gray-300 flex justify-center items-center w-8 h-8 pt-1 -mt-1 -mr-1.5", classNames.iconContainer)}
            onClick={handleOpen(id)}
            disabled={disabled}
          >
            <VerticalDots ref={setAnchorElement} className={cx("m-auto", disabled && "text-gray-400", classNames.verticalDots)} />
          </button>
        )}
      </Popper.Trigger>
      <Popper.Content className={cx("z-10", classNames.content, "m-0")}>{children}</Popper.Content>
    </Popper>
  );
};

Menu.Item = MenuItems;

Menu.defaultProps = {
  id: "",
  isOpen: false,
  classNames: {
    menu: "",
    verticalDots: "",
    content: "",
  },
  disabled: false,
};

Menu.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isOpen: PropTypes.bool,
  classNames: PropTypes.shape({
    menu: PropTypes.string,
    verticalDots: PropTypes.string,
    content: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  handleClose: PropTypes.func,
  handleOpen: PropTypes.func,
  setIsOpen: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export default Menu;
