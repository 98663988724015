import ConfirmationModal from "../../../../components/confirmation-modal";
import InputLabel from "../../../../components/input-label";
import { Formik, Form } from "formik";
import Button from "../../../../components/button";
import FormControl from "../../../../components/form-control";
import { FN, getValidationSchema } from "./form-helper";
import AcceptIcon2 from "../../../../components/vectors/accept-icon2";
import { FormikInput } from "../../../../components/input";
import useHeader from "../provider";

function ChangePasswordModal(props) {
  const {
    isChangePasswordOpen,
    handleCancleChangePasswordModel,
    initialValues,
    handleChangePasswordSubmit,
  } = useHeader();
  return (
    <ConfirmationModal isOpen={isChangePasswordOpen} className="p-10 w-[27rem] drop-shadow">
      <ConfirmationModal.Title className="text-center">
        <div className="bg-primary-brand-300 h-[62px] rounded-full flex items-center justify-center">
          <label className="font-semibold text-primary-text mr-2">
            Change Password
          </label>
        </div>
      </ConfirmationModal.Title>
      <ConfirmationModal.Body className="border-dashed border-t-[1px] border-[#D2D6E0]">
        <Formik
          initialValues={initialValues}
          validationSchema={getValidationSchema}
          onSubmit={handleChangePasswordSubmit}
        >
          <Form>
            <div className="flex-col p-4 gap-3">
              <FormControl>
                <InputLabel
                  className="flex-1"
                  required
                  htmlFor={FN.CURRENT_PASSWORD}
                >
                  Current Password
                </InputLabel>
                <FormikInput
                  name={FN.CURRENT_PASSWORD}
                  id={FN.CURRENT_PASSWORD}
                  type="password"
                />
              </FormControl>
              <FormControl>
                <InputLabel
                  className="flex-1"
                  required
                  htmlFor={FN.NEW_PASSWORD}
                >
                  New Password
                </InputLabel>
                <FormikInput
                  name={FN.NEW_PASSWORD}
                  id={FN.NEW_PASSWORD}
                  type="password"
                />
              </FormControl>
              <FormControl>
                <InputLabel
                  className="flex-1"
                  required
                  htmlFor={FN.CONFIRM_NEW_PASSWORD}
                >
                  Confirm Password
                </InputLabel>
                <FormikInput
                  name={FN.CONFIRM_NEW_PASSWORD}
                  id={FN.CONFIRM_NEW_PASSWORD}
                  type="password"
                />
              </FormControl>
            </div>
            <div className="flex justify-end items-center space-x-2 self-end mt-5">
              <Button
                type="button"
                variant="link"
                color="secondary"
                className="py-2 px-4 flex items-center text-xs"
                onClick={handleCancleChangePasswordModel}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="solid"
                color="primary"
                className="py-2 px-3.5 flex items-center font-semibold text-xs"
                icon={<AcceptIcon2 />}
              >
                Confirm
              </Button>
            </div>
          </Form>
        </Formik>
      </ConfirmationModal.Body>
    </ConfirmationModal>
  );
}
export default ChangePasswordModal;
