import cx from "../../utils/class-names";

const ReviewCardSection = (props) => {
  const { children, className, ...restProps } = props;
  return (
    <div className={cx("review-card_section", className)} {...restProps}>
      {children}
    </div>
  );
};

export default ReviewCardSection;
