import { useMemo, useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

import Wizard from "../../components/wizard";
import Skeleton from "../../components/skeleton";
import cx from "../../utils/class-names";
import uuid from "../../utils/uuid";
import PropTypes from "prop-types";
import "./index.css";

const usePdfViewer = (props) => {
  const [numPages, setNumPages] = useState(null);
  const [loadingPdf, setLoadingPdf] = useState(true);
  const pdfPages = useMemo(() => {
    return Array.from({ length: numPages }).map((_, index) => {
      return {
        pageNumber: index + 1,
        id: uuid(),
      };
    });
  }, [numPages]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setLoadingPdf(false);
  }

  return useMemo(() => {
    return {
      pdfPages,
      numPages,
      loadingPdf,
      onDocumentLoadSuccess,
    };
  }, [loadingPdf, numPages, pdfPages]);
};

const PdfViewer = (props) => {
  const { onDocumentLoadSuccess, pdfPages, numPages } = usePdfViewer(props);

  return (
    <Document
      error="Only PDF files can be viewed (For Word, Excel, etc. please download the file)."
      file={props.pdf}
      onLoadSuccess={onDocumentLoadSuccess}
      className="pdf-viewer flex flex-1 max-w-[640px]"
      loading={<Skeleton className="pdf-viewer-skeleton w-full h-[445px]" />}
    >
      <Wizard className="w-full p-0 my-0  flex-1 ">
        {(wizard) => {
          return (
            <>
              {props.nav ? (
                <Wizard.Nav className="max-w-fit p-4 shadow-md overflow-x-hidden overflow-y-auto h-full bg-gray-50">
                  {pdfPages.map((page, pageIndex) => (
                    <Wizard.NavItem
                      key={page.id}
                      id={page.id}
                      className={cx(
                        "w-fit p-1 my-0 mx-auto mb-2 border-[1px bg-[#F3F4F5] hover:bg-[#F3F4F5]"
                      )}
                      activeBgClass="border-2 font-[800] bg-[#D9D9D9] text-black-100"
                    >
                      <Page
                        pageNumber={page.pageNumber}
                        height={115}
                        onClick={() => wizard.handleWizardNavItemClick(page.id)}
                        pageIndex={pageIndex}
                      >
                        <div className="text-center text-[10px] mt-2 text-inherit text-black font-semibold">
                          {page.pageNumber} / {numPages}
                        </div>
                      </Page>
                    </Wizard.NavItem>
                  ))}
                </Wizard.Nav>
              ) : null}

              <Wizard.Content className="overflow-x-auto overflow-y-auto p-2 align-middle bg-gray-50 shadow-none">
                {pdfPages.map((page, pageIndex) => (
                  <Wizard.ContentItem key={page.id} id={page.id}>
                    <Page
                      className="mb-2"
                      pageNumber={page.pageNumber}
                      pageIndex={pageIndex}
                      loading={<Skeleton className="w-[594px] h-[807px]" />}
                    />
                  </Wizard.ContentItem>
                ))}
              </Wizard.Content>
            </>
          );
        }}
      </Wizard>
    </Document>
  );
};

export default PdfViewer;

PdfViewer.defaultProps = {
  numPages: 1,
  pdfPages: [],
  pdf: {},
};

PdfViewer.propTypes = {
  onDocumentLoadSuccess: PropTypes.func,
  numPages: PropTypes.number,
  pdfPages: PropTypes.array,
  pdf: PropTypes.object,
};
