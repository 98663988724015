import { FieldArray, Form, Formik } from "formik";
import React from "react";
import { useCreateCommitteePageContext } from "../provider";
import FormControl from "../../../components/form-control";
import InputLabel from "../../../components/input-label";
import { FormikInput } from "../../../components/input";
import { FormikTextarea } from "../../../components/textarea";
import { FormikSelect } from "../../../components/select";
import Button from "../../../components/button";
import { FN } from "./form-helper";
import MembersItem from "./members-item";
import { generateFullName } from "../../../utils/generate-names";
import constants from "../../../constants";
import { FormikDatepicker } from "../../../components/datepicker";

export const CreateCommitteeForm = () => {
  const {
    initialValues,
    getValidationSchema,
    handleCreateCommittee,
    mainFormRef,
    handleCancelClick,
    getMembersQuery,
    handleAddMember,
    handleUpdateCommittee,
  } = useCreateCommitteePageContext();

  const members = (getMembersQuery?.data || []).map((item) => {
    const full_name = generateFullName(item);
    return { full_name, ...item };
  });

  const isCreateCommitteePage = initialValues.committee_id === "";
  const handleSubmit = isCreateCommitteePage
    ? handleCreateCommittee
    : handleUpdateCommittee(initialValues.committee_id, initialValues.meeting_id);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getValidationSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {(formik) => {
        mainFormRef.current = formik;
        const { values } = formik;
        const memberIds = (values?.member_details_root || []).map((item) =>
          parseInt(item.member_name)
        );
        const leader = parseInt(values.leader_of_delegation);
        const coordinator = parseInt(values.coordinator_of_the_committee);
        const membersForLeaderDelegation = members.filter(
          (member) => ![...memberIds, coordinator].includes(member.id)
        );
        const membersForCommiteeCordinator = members.filter(
          (member) => ![...memberIds, leader].includes(member.id)
        );

        return (
          <Form className="flex flex-1 flex-col overflow-y-auto justify-between">
            <div className="p-4">
              <div className="grid grid-cols-3 gap-3">
                <FormControl className="col-span-2">
                  <InputLabel className="flex-1" required htmlFor={FN.NAME}>
                    Name
                  </InputLabel>
                  <FormikInput
                    classNames={{ root: "mb-1", input: "ring-black-100" }}
                    id={FN.NAME}
                    name={FN.NAME}
                  />
                </FormControl>
                <FormControl>
                  <InputLabel
                    className="text-black-100"
                    htmlFor={FN.MEETING_DATE}
                    required
                  >
                    Meeting Date
                  </InputLabel>
                  <FormikDatepicker
                    className="ring-black-100"
                    id={FN.MEETING_DATE}
                    name={FN.MEETING_DATE}
                    options={{ minDate: "today" }}
                  />
                </FormControl>
              </div>
              <FormControl>
                <InputLabel className="flex-1" htmlFor={FN.DESCRIPTION}>
                  Description
                </InputLabel>
                <FormikTextarea
                  id={FN.DESCRIPTION}
                  name={FN.DESCRIPTION}
                  minRows={3}
                  className="grid-cols-2 gap-x-4 mb-1 ring-black-100"
                />
              </FormControl>
              <div className="grid grid-cols-3 gap-3">
                <FormControl>
                  <InputLabel
                    className="flex-1"
                    required
                    htmlFor={FN.LEADER_OF_DELEGATION}
                  >
                    Leader of the delegation
                  </InputLabel>
                  <FormikSelect
                    data={membersForLeaderDelegation}
                    id={FN.LEADER_OF_DELEGATION}
                    name={FN.LEADER_OF_DELEGATION}
                    lableString="full_name"
                    valueString="id"
                    passValueInt={true}
                    virtualize={
                      membersForLeaderDelegation?.length >=
                      constants.VIRTUALIZED_ENABLE_LIMIT
                    }
                    classNames={{
                      root: "mb-1",
                      input: "ring-black-100",
                      popperContent: "max-w-[560px]",
                    }}
                  />
                </FormControl>
                <FormControl>
                  <InputLabel
                    className="flex-1"
                    required
                    htmlFor={FN.COORDINATOR_OF_COMMITTEE}
                  >
                    Coordinator of the Committee
                  </InputLabel>
                  <FormikSelect
                    data={membersForCommiteeCordinator}
                    id={FN.COORDINATOR_OF_COMMITTEE}
                    name={FN.COORDINATOR_OF_COMMITTEE}
                    lableString="full_name"
                    valueString="id"
                    passValueInt={true}
                    virtualize={
                      membersForCommiteeCordinator?.length >=
                      constants.VIRTUALIZED_ENABLE_LIMIT
                    }
                    classNames={{
                      root: "mb-1",
                      input: "ring-black-100",
                      popperContent: "max-w-[560px]",
                    }}
                  />
                </FormControl>
              </div>
              <FieldArray
                name={FN.MEMBER_DETAILS.ROOT}
                render={(arrayHelpers, index) => {
                  return (
                    <div className="flex flex-col mb-2" key={index}>
                      <InputLabel
                        className="text-xs font-sans font-semibold"
                        htmlFor={FN.MEMBER_DETAILS.ROOT}
                      >
                        Members
                      </InputLabel>
                      {values?.[FN.MEMBER_DETAILS.ROOT]?.map(
                        (item, itemIndex) => {
                          return (
                            <MembersItem
                              arrayHelpers={arrayHelpers}
                              itemIndex={itemIndex}
                              key={itemIndex}
                            />
                          );
                        }
                      )}
                      <Button
                        variant="outline"
                        color="primary"
                        className="py-2 px-3.5 w-20 flex items-center text-xs font-semibold text-primary-button"
                        onClick={handleAddMember(arrayHelpers, values)}
                      >
                        + Add
                      </Button>
                    </div>
                  );
                }}
              />
            </div>
            <div className="flex items-end px-8 justify-end w-full my-4">
              <Button
                variant="outline"
                color="primary"
                className="py-2 px-3.5 flex items-center text-xs font-semibold ml-5 text-primary-button"
                onClick={handleCancelClick}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="solid"
                color="primary"
                className="py-2 px-3.5 flex items-center text-xs font-semibold ml-5 action-auditor-save"
              >
                {isCreateCommitteePage ? "Create" : "Update"}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
