import * as Yup from "yup";
import * as validation from "../../../../utils/validation";

export const FN = {
  FIRST_NAME: "first_name",
  LAST_NAME: "last_name",
  ADDRESS: "address",
  CONTACT_NUMBER: "contact_no",
  REPRESENTATIVE_OF: "representative_of",
  ORGANISATION: "organization",
  EMAIL: "email",
  ID: "id",
};

export const getFormInitialValues = (data = {}) => {
  return {
    [FN.FIRST_NAME]: data[FN.FIRST_NAME] || "",
    [FN.LAST_NAME]: data[FN.LAST_NAME] || "",
    [FN.ADDRESS]: data[FN.ADDRESS] || "",
    [FN.CONTACT_NUMBER]: data[FN.CONTACT_NUMBER] || "",
    [FN.REPRESENTATIVE_OF]: data[FN.REPRESENTATIVE_OF] || "",
    [FN.ORGANISATION]: data[FN.ORGANISATION] || "",
    [FN.EMAIL]: data[FN.EMAIL] || "",
    [FN.ID]: data[FN.ID] || null,
  };
};

export const getFormValidationSchema = (withRequired = true) => {
  return Yup.object().shape({
    [FN.FIRST_NAME]: validation.isRequiredString({ withRequired }),
    [FN.LAST_NAME]: validation.isRequiredString({ withRequired }),
    [FN.ADDRESS]: validation.isRequiredString({ withRequired: false }),
    [FN.CONTACT_NUMBER]: validation.isRequiredString({ withRequired }),
    [FN.REPRESENTATIVE_OF]: validation.isRequiredString({ withRequired }),
    [FN.ORGANISATION]: validation.isRequiredString({ withRequired: false }),
    [FN.EMAIL]: validation.isRequiredEMailString({ withRequired }),
  });
};

export const Enums = {
  representativeOf: [
    { label: "Govt of India", value: "Govt of India" },
    { label: "Recognized Organization", value: "Recognized Organization" },
    { label: "Ship Owners", value: "Ship Owners" },
    { label: "Ship Yards", value: "Ship Yards" },
    { label: "Ship Managers", value: "Ship Managers" },
    { label: "Ship Designers", value: "Ship Designers" },
    { label: "INSA", value: "INSA" },
    { label: "IMEI", value: "IMEI" },
    { label: "INA", value: "INA" },
    { label: "Indian Navy", value: "Indian Navy" },
    { label: "Coast Guard", value: "Coast Guard" },
    { label: "Others", value: "Others" },
  ],
};
