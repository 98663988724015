import Skeleton from "../../../components/skeleton";
import cx from "../../../utils/class-names";

const ReferenceDocumentsSkeleton = (props) => {
  return (
    <div
      className={cx(
        "px-2 py-0  m-0 shadow-none flex justify-between items-center tab-sm:flex tab-xs:block"
      )}
    >
      <div className="grid grid-cols-10 space-x-4 w-full items-center mb-2 text-sm p-2 drop-shadow-sm rounded-md bg-primary-row hover:bg-secondary-0 hover:border hover:border-primary-button">
        <div className="flex flex-col col-span-1">
          <Skeleton className="text-sm font-bold font-sans leading-[18px]" />
        </div>
        <div className="flex flex-col col-span-6">
          <Skeleton className="text-sm font-medium font-sans leading-[18px]" />
        </div>
      </div>
    </div>
  );
};
export default ReferenceDocumentsSkeleton;
