import React, { useRef } from "react";
import Accordion from "../../../../components/accordion";
import FolderOpenIcon from "../../../../components/vectors/folder-open-icon";
import FolderCloseIcon from "../../../../components/vectors/folder-close-icon";
import cx from "../../../../utils/class-names";
import ArrowIcon from "../../../../components/vectors/arrow-icon";
import { GroupAccordion } from "./group-accordian";
import { generateFullName } from "../../../../utils/generate-names";
import Button from "../../../../components/button";
import UploadIcon from "../../../../components/vectors/upload-icon";
import Enums from "../../../../constants/enums";
import { Document } from "../document";

export const MeetingAccordion = ({
  name,
  groups,
  committeeDocuments,
  membersDetails,
  committeeId,
  handleDocumentUpload,
  handleOpenUpload,
  handleDownloadDocuments,
  isFolderOpen,
}) => {
  const isGroup = groups?.length > 0 || committeeDocuments?.length > 0;
  const committeeFileUploadRef = useRef(null);
  return (
    <div className="w-full mb-2">
      <Accordion
        defaultValue={false}
        className="rounded w-full"
      >
        {({ expanded }) => {
          const icon = isGroup ? (
            <FolderOpenIcon className="text-secondary-550 opacity-50 ml-2 mr-2 h-5 w-6" />
          ) : (
            <FolderCloseIcon className="text-secondary-550 opacity-50 ml-2 mr-2 h-5 w-6" />
          );
          return (
            <>
              <div
                className={cx(
                  "flex items-center justify-between gap-4 w-[90%] p-1 hover:bg-blue-100 cursor-pointer"
                )}
              >
                <Accordion.Header className="flex items-center">
                  <div className="ml-1 h-2.5 w-2.5">
                    <ArrowIcon
                      className={cx(
                        "text-primary-text items-center h-2.5 w-2.5 rotate-90",
                        (isFolderOpen || expanded) && "rotate-180"
                      )}
                    />
                  </div>
                  {icon}
                  <div className={cx("flex flex-1 pr-3.5 items-center")}>
                    <span className="break-all text-black-100 font-semibold text-sm font-sans leading-5 mb-1">
                      {name}
                    </span>
                  </div>
                </Accordion.Header>
                <div className="flex items-center mr-4">
                  <Button
                    variant="link"
                    color="primary"
                    className={cx("font-semibold py-0 px-0 m-0 leading-none")}
                    onClick={handleOpenUpload(committeeFileUploadRef)}
                  >
                    <span className="flex items-center gap-2">
                      <UploadIcon className="ml-1.5" />
                      <span className="text-xs text-primary-button font-semibold mt-1">
                        Upload
                      </span>
                    </span>
                  </Button>
                  <input
                    type="file"
                    ref={committeeFileUploadRef}
                    className="hidden"
                    multiple
                    onChange={handleDocumentUpload(
                      committeeId,
                      Enums.ASSOCIATION_TYPES.COMMITEE
                    )}
                  />
                </div>
              </div>
              <Accordion.Content
                className={cx(
                  "h-0 overflow-hidden",
                  (isFolderOpen || expanded) && "overflow-visible ml-[52px] h-auto mt-1.5"
                )}
              >
                {groups?.map((group, index) => {
                  const groupName = group?.name;
                  return (
                    <GroupAccordion
                      key={index}
                      groupName={groupName}
                      group={group}
                    />
                  );
                })}
                {committeeDocuments?.map((doc, index) => {
                  const docName = doc?.document_name;
                  const createdBy = membersDetails?.find(
                    (e) => e.id === doc.created_by
                  );
                  const membersName = generateFullName(createdBy);
                  return (
                    <div
                      key={index}
                      className="relative flex flex-col tab-sm:flex-1 mb-2"
                      onClick={handleDownloadDocuments(doc.id, docName)}
                    >
                      <Document docName={docName} membersName={membersName} />
                    </div>
                  );
                })}
              </Accordion.Content>
            </>
          );
        }}
      </Accordion>
    </div>
  );
};
