import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { AxiosInterceptorProvider } from "./apis";
import { ErrorBoundary } from "react-error-boundary";
import ErrorBoundaryFallback from "./components/error-boundary-fallback";
import { NotificationModalProvider } from "./components/notification-modal/provider";
import NotificationModal from "./components/notification-modal";
import ToastContainer from "./components/toaster-container";
import TokenProvider from "./contexts/token.context";
import UserProvider from "./contexts/user.context";
import RouterContainer from "./routes";
import "./index.css";

const Root = (
  <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
    <BrowserRouter>
      <NotificationModalProvider>
        <NotificationModal />
        <AxiosInterceptorProvider>
          <UserProvider>
            <TokenProvider>
              <RouterContainer />
            </TokenProvider>
          </UserProvider>
        </AxiosInterceptorProvider>
      </NotificationModalProvider>
      <ToastContainer />
    </BrowserRouter>
  </ErrorBoundary>
);

createRoot(document.getElementById("root")).render(Root);
