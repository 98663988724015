import React, { useMemo } from "react";
import cx from "../../../../utils/class-names";
import PenGreenIcon from "../../../../components/vectors/pen-green-icon";
import Const from "../../../../constants";
import Label from "../../../../components/label";

export const FeedbackRow = ({ item, handleFeedbackClick }) => {
  const description = item?.description;
  const paper = item?.symbol;

  const checkStatus = (item, propertyName, targetStatus) => {
    const propertyValue = item?.[propertyName];
    const status = propertyValue?.[0]?.status;
    return status === targetStatus;
  };
  const committeeFeedbackStatus = checkStatus(
    item,
    "commitee_feedback_statuses",
    "Completed"
  );
  const groupFeedbackStatus =  checkStatus(
    item,
    "group_feedback_statuses",
    "Completed"
  );

  const StatusBadges = (props) => {
    const { variant, ...restProps } = props;
    const classNames = {
      group_feedback_pending: "border-gray-200 bg-gray-200 text-gray-100",
      feedback: "border-orange-200 bg-orange-200 text-primary-brand",
      Completed: "border-green-200 bg-green-200 text-green-100",
    }[variant];
    const label = {
      group_feedback_pending: "Group Feedback Pending",
      feedback: "Feedback",
      Completed: "Completed",
    }[variant];

    return (
      <Label
        className={cx(
          "text-[10px] min-w-[5rem] font-semibold text-primary-text px-1 rounded border-[0.1px] text-center",
          classNames
        )}
        {...restProps}
      >
        {label}
      </Label>
    );
  };

  const status = useMemo(() => {
    if (!groupFeedbackStatus && !committeeFeedbackStatus) {
      return Const.STATUS.GROUP_FEEDBACK_PENDING;
    } else if (groupFeedbackStatus && !committeeFeedbackStatus) {
      return Const.STATUS.FEEDBACK;
    } else {
      return Const.STATUS.COMPLETED;
    }
  }, [committeeFeedbackStatus, groupFeedbackStatus]);
  return (
    <div
      className={cx(
        "px-2 py-0  m-0 shadow-none flex justify-between items-center tab-sm:flex tab-xs:block"
      )}
    >
      <div className="grid grid-cols-10 space-x-4 w-full items-center mb-2 text-sm p-2 drop-shadow-sm rounded-md bg-gray-50 hover:bg-secondary-0 hover:border hover:border-primary-button">
        <div className="flex flex-col col-span-1">
          <label className="text-sm font-medium font-sans leading-[18px]">
            {paper}
          </label>
        </div>
        <div className="flex flex-col col-span-6">
          <label className="text-sm font-medium font-sans leading-[18px]">
            {description}
          </label>
        </div>
        <div className="flex flex-col col-span-1">
          <StatusBadges variant={status} />
        </div>
        <div className="flex col-span-2 justify-center">
          <PenGreenIcon
            width={18}
            height={18}
            onClick={handleFeedbackClick(item?.agenda_id, item?.id)}
            className="cursor-pointer ml-10"
          />
        </div>
      </div>
    </div>
  );
};
