export default function scrollToTheFirstErrorOnPage() {
  window.requestAnimationFrame(() => {
    let errorElement = document.getElementsByClassName("form-field-error");
    if (errorElement?.length) {
      let firstErrorEle = errorElement?.[0];
      let parentElement = firstErrorEle?.parentElement;
      parentElement.scrollIntoView(true);
    }
  });
}
