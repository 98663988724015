import { useCallback, useMemo, useRef, useState } from "react";
import { useAsync } from "@react-org/hooks";
import generateContext from "../../utils/generate-context";
import * as ListsApi from "../../apis/lists.api";
import * as AgendasApi from "../../apis/agendas.api";
import * as PapersApi from "../../apis/papers.api";
import Const from "../../constants";
import { useUserFromStorage } from "../../contexts/user.context";
import { useNotificationModalContext } from "../../components/notification-modal/provider";
import MESSAGES from "../../constants/messages";
import {
  generatePayload,
  getFormInitialValues,
  getFormValidationSchema,
  getPaperDetailsRow,
} from "./components/add-agenda-drawer/form-helper";
import { downloadOrPreviewFileUrl } from "../../utils/download-or-preview-file-url";
import generateFileUrl from "../../utils/generate-file-url";

function useManageAgendaPage(props) {
  const notificationModal = useNotificationModalContext();
  const agendaFormRef = useRef(null);
  const [showAction, setShowAction] = useState(null);
  const [isAddAgendaDrawerOpen, setIsAddAgendaDrawerOpen] = useState(false);
  const [agendaInitialValue, setAgendaInitialValue] = useState({});
  const [paperDocument, setPaperDocument] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [meetingId, setMeetingId] = useState(null);

  const getAgendaQuery = useAsync(
    useCallback((payload) => ListsApi.getAgenda({ meetingId, ...payload }), [meetingId]),
    {
      initialState: { data: [] },
      infinite: true,
      select: (res) => {
        return { data: res.data.data, paginate: res.data.paging };
      },
      immediate: true,
    }
  );
  const getMeetingDetailsQuery = useAsync(
    useCallback((payload) => ListsApi.getMeetingDetails({ ...payload }), []),
    {
      initialState: { data: [] },
      infinite: true,
      select: (res) => {
        return { data: res.data.data };
      },
      immediate: true,
    }
  );
  const createAgendaQuery = useAsync(AgendasApi.createAgenda, {
    immediate: false,
  });
  const updateAgendaQuery = useAsync(AgendasApi.editAgenda, {
    immediate: false,
  });
  const deleteAgendaQuery = useAsync(AgendasApi.deleteAgenda, {
    immediate: false,
  });
  const downloadPaperQuery = useAsync(PapersApi.getPaperDocument, {
    immediate: false,
  });
  const deletePaperQuery = useAsync(PapersApi.updatePaper, {
    immediate: false,
  });
  const deletePaperDocumentQuery = useAsync(PapersApi.deletePaperDocument, {
    immediate: false,
  });

  const { user } = useUserFromStorage();
  const isAdmin = useMemo(() => user?.is_admin, [user?.is_admin]);

  const isHasMorePages = useMemo(() => {
    return (
      getAgendaQuery?.paginate?.page_no * getAgendaQuery?.paginate?.page_limit <
      getAgendaQuery?.paginate?.total
    );
  }, [
    getAgendaQuery?.paginate?.page_limit,
    getAgendaQuery?.paginate?.page_no,
    getAgendaQuery?.paginate?.total,
  ]);

  const loadingRowCount = useMemo(() => {
    if (
      getAgendaQuery?.isIdle ||
      getAgendaQuery?.isPending ||
      getAgendaQuery?.data?.length === 0
    ) {
      return Const.SKELETON_LOADING_ROWS.DEFAULT;
    }
    return isHasMorePages
      ? Const.SKELETON_LOADING_ROWS.PAGING
      : Const.SKELETON_LOADING_ROWS.NO_ROWS;
  }, [
    getAgendaQuery?.data?.length,
    getAgendaQuery?.isIdle,
    getAgendaQuery?.isPending,
    isHasMorePages,
  ]);

  const handleLoadMore = useCallback(() => {
    if (getAgendaQuery?.isPending || getAgendaQuery?.isIdle || !isHasMorePages)
      return;
    let page = (getAgendaQuery?.paginate?.page_no || 0) + 1;
    let data = { page: page };
    let getAgendaQueryExecute = getAgendaQuery?.execute;
    getAgendaQueryExecute(data);
  }, [getAgendaQuery, isHasMorePages]);

  const initialValues = useMemo(() => {
    return getFormInitialValues(agendaInitialValue);
  }, [agendaInitialValue]);

  const getValidationSchema = useCallback(() => {
    return getFormValidationSchema();
  }, []);

  const handleMenuClose = useCallback(() => {
    setShowAction(false);
  }, [setShowAction]);

  const handleMenuOpen = useCallback(
    (id) => (e) => {
      e.stopPropagation();
      setShowAction(id);
    },
    [setShowAction]
  );

  const handleAddPaper = useCallback(
    (arrayHelpers) => () => {
      arrayHelpers.push({ ...getPaperDetailsRow() });
    },
    []
  );
  const handleRemovePaper = useCallback(
    ({ paperId, arrayHelpers, index }) =>
      (event) => {
        event.preventDefault();
        if (paperId) {
          notificationModal.warning({
            title: MESSAGES.ARE_YOU_SURE,
            heading: MESSAGES.PAPER_WILL_BE_DELETED,
            onConfirm: () => {
              const deletePaperQueryExecute = deletePaperQuery.execute;
              const getAgendaQueryExecute = getAgendaQuery.execute;
              const getAgendaQuerySetData = getAgendaQuery.setQueryData;
              notificationModal.progress({
                heading: MESSAGES.INPROGRESS_MESSAGE,
              });
              deletePaperQueryExecute(
                { paperId },
                {
                  onSuccess: () => {
                    getAgendaQuerySetData([]);
                    getAgendaQueryExecute();
                    setIsAddAgendaDrawerOpen(false);
                    notificationModal.success({
                      heading: MESSAGES.PAPER_DELETED_SUCCESSFULLY,
                    });
                  },
                  onError: () => {
                    notificationModal.error({
                      heading: MESSAGES.FAILED_TO_DELETE_PAPER,
                    });
                  },
                }
              );
            },
          });
        } else {
          arrayHelpers.remove(index);
          setPaperDocument((prevDocuments) => {
            const updatedDocuments = [...prevDocuments];
            updatedDocuments.splice(index, 1);
            return updatedDocuments;
          });
        }
      },
    [
      deletePaperQuery.execute,
      getAgendaQuery.execute,
      getAgendaQuery.setQueryData,
      notificationModal,
    ]
  );

  const handleEdit = useCallback(
    (id) => (e) => {
      e.stopPropagation();
      const agendaDetails = (getAgendaQuery?.data || []).find(
        (item) => item.id === id
      );
      setIsAddAgendaDrawerOpen(true);
      setAgendaInitialValue(agendaDetails);
    },
    [getAgendaQuery?.data]
  );
  const handleOpenAddAgendaDrawer = useCallback((e) => {
    setIsAddAgendaDrawerOpen(true);
    setAgendaInitialValue({});
    setPaperDocument([]);
  }, []);

  const handleCloseAgendaDrawer = useCallback(
    (e) => {
      setIsAddAgendaDrawerOpen(false);
      const getAgendaQuerySetData = getAgendaQuery.setQueryData;
      const getAgendaQueryExecute = getAgendaQuery.execute;
      getAgendaQuerySetData([]);
      getAgendaQueryExecute();
    },
    [getAgendaQuery.execute, getAgendaQuery.setQueryData]
  );

  const handleAgendaList = useCallback(
    (value) => {
      let payload = { limit: 10, page: 1 };
      getAgendaQuery.cancel();
      getAgendaQuery.debounceExecute(payload, {
        overwrite: true,
      });
    },
    [getAgendaQuery]
  );

  const handleCreateAgenda = useCallback(
    (data) => {
      let payload = generatePayload(data, paperDocument);
      const createAgendaQueryExecute = createAgendaQuery.execute;
      notificationModal.progress({
        heading: MESSAGES.INPROGRESS_MESSAGE,
      });
      createAgendaQueryExecute(payload, {
        onSuccess: (res) => {
          handleAgendaList();
          notificationModal.success({
            heading: MESSAGES.AGENDA_ADDED_SUCCESSFULLY,
          });
          setIsAddAgendaDrawerOpen(false);
        },
        onError: () => {
          notificationModal.error({
            heading: MESSAGES.FAILED_TO_ADD_AGENDA,
          });
        },
      });
    },
    [
      createAgendaQuery.execute,
      handleAgendaList,
      notificationModal,
      paperDocument,
    ]
  );
  const handleOpenUpload = useCallback(
    (fileUploadRef) => () => {
      fileUploadRef.current.click();
    },
    []
  );
  const handleDownloadFile = useCallback(
    (id, title) => () => {
      const downloadCommitteeDocumentQueryExecute = downloadPaperQuery.execute;
      notificationModal.progress({ heading: MESSAGES.INPROGRESS_MESSAGE });
      downloadCommitteeDocumentQueryExecute(
        { paperId: id },
        {
          onSuccess: (res) => {
            const fileURL = generateFileUrl(
              res?.data,
              res.headers["content-type"]
            );
            downloadOrPreviewFileUrl(fileURL, `${title}`, {
              type: res.headers["content-type"],
            });
            notificationModal.close();
          },
          onError: () => {
            notificationModal.error({
              heading: MESSAGES.PREVIEW_FAILED,
            });
          },
        }
      );
    },
    [downloadPaperQuery.execute, notificationModal]
  );
  const handleDeleteDocument = useCallback(
    (id) => () => {
      notificationModal.warning({
        title: MESSAGES.ARE_YOU_SURE,
        heading: MESSAGES.DOCUMENT_WILL_BE_DELETED,
        onConfirm: () => {
          const deleteCommitteeDocumentQueryExecute =
            deletePaperDocumentQuery.execute;
          deleteCommitteeDocumentQueryExecute(
            { paperId: id },
            {
              onSuccess: () => {
                handleAgendaList();
                notificationModal.success({
                  heading: MESSAGES.DOCUMENT_DELETED,
                });
              },
              onError: () => {
                notificationModal.error({
                  heading: MESSAGES.FAILED_TO_DELETED_DOCUMENT,
                });
              },
            }
          );
        },
      });
    },
    [deletePaperDocumentQuery.execute, handleAgendaList, notificationModal]
  );

  const handleUpdateAgenda = useCallback(
    (id) => (formData) => {
      const payload = generatePayload(formData, paperDocument);
      payload.id = id;
      const updateCommitteeQueryExecute = updateAgendaQuery.execute;
      notificationModal.progress({
        heading: MESSAGES.INPROGRESS_MESSAGE,
      });
      updateCommitteeQueryExecute(payload, {
        onSuccess: () => {
          handleAgendaList();
          notificationModal.success({
            heading: MESSAGES.SUCCESSFULLY_UPDATED_AGENDA,
          });
          setIsAddAgendaDrawerOpen(false);
        },
        onError: () => {
          notificationModal.error({
            heading: MESSAGES.FAILED_TO_UPDATE_AGENDA,
          });
        },
      });
    },
    [
      paperDocument,
      updateAgendaQuery.execute,
      notificationModal,
      handleAgendaList,
    ]
  );

  const handleDeleteAgenda = useCallback(
    (id) => (e) => {
      e.stopPropagation();
      notificationModal.warning({
        title: MESSAGES.ARE_YOU_SURE,
        heading: MESSAGES.AGENDA_WILL_BE_DELETED,
        onConfirm: () => {
          const deleteMemberQueryExecute = deleteAgendaQuery.execute;
          const getAgendaQueryExecute = getAgendaQuery.execute;
          const getAgendaQuerySetData = getAgendaQuery.setQueryData;
          notificationModal.progress({
            heading: MESSAGES.INPROGRESS_MESSAGE,
          });
          deleteMemberQueryExecute(
            { id },
            {
              onSuccess: () => {
                getAgendaQuerySetData([]);
                getAgendaQueryExecute();
                notificationModal.success({
                  heading: MESSAGES.AGENDA_DELETED_SUCCESSFULLY,
                });
              },
              onError: () => {
                notificationModal.error({
                  heading: MESSAGES.FAILED_TO_DELETE_AGENDA,
                });
              },
            }
          );
        },
      });
    },
    [
      deleteAgendaQuery.execute,
      getAgendaQuery.execute,
      getAgendaQuery.setQueryData,
      notificationModal,
    ]
  );
  const handleClearSearchFilter = useCallback(
    (e) => {
      setSearchText("");
      const getAgendaQueryExecute = getAgendaQuery.execute;
      getAgendaQueryExecute();
    },
    [getAgendaQuery.execute]
  );

  const handleSearchChange = useCallback(
    (e) => {
      e.persist();
      setSearchText(e.target.value);
      if (e.target.value.length === 0) handleClearSearchFilter();
    },
    [handleClearSearchFilter]
  );

  const handleEnterSearch = useCallback(
    (value) => {
      const payload = {};
      payload.term = value;
      const getAgendaQueryCancel = getAgendaQuery.cancel;
      const getAgendaQueryExecute = getAgendaQuery.execute;
      getAgendaQueryCancel();
      getAgendaQueryExecute(payload, {
        overwrite: true,
      });
    },
    [getAgendaQuery.cancel, getAgendaQuery.execute]
  );
  const handleMeetingChange = useCallback(
    (value) => {
      const payload = {}
      if (value) {
        payload.meetingId = value;
        setMeetingId(value);
      } else {
        payload.meetingId = null;
        setMeetingId(null)
      }
      const getAgendaQueryCancel = getAgendaQuery.cancel;
      const getAgendaQueryExecute = getAgendaQuery.execute;
      getAgendaQueryCancel();
      getAgendaQueryExecute(payload, {
        overwrite: true,
      });
    },
    [getAgendaQuery.cancel, getAgendaQuery.execute]
  );

  return useMemo(() => {
    return {
      getAgendaQuery,
      loadingRowCount,
      isHasMorePages,
      handleLoadMore,
      isAdmin,
      handleEdit,
      handleDeleteAgenda,
      handleMenuClose,
      handleMenuOpen,
      showAction,
      setShowAction,
      handleOpenAddAgendaDrawer,
      isAddAgendaDrawerOpen,
      handleCloseAgendaDrawer,
      getValidationSchema,
      initialValues,
      handleAddPaper,
      handleRemovePaper,
      getMeetingDetailsQuery,
      agendaFormRef,
      handleCreateAgenda,
      handleUpdateAgenda,
      handleOpenUpload,
      handleDownloadFile,
      handleDeleteDocument,
      paperDocument,
      setPaperDocument,
      searchText,
      handleSearchChange,
      handleEnterSearch,
      handleClearSearchFilter,
      downloadPaperQuery,
      deletePaperDocumentQuery,
      handleMeetingChange,
    };
  }, [deletePaperDocumentQuery, downloadPaperQuery, getAgendaQuery, getMeetingDetailsQuery, getValidationSchema, handleAddPaper, handleClearSearchFilter, handleCloseAgendaDrawer, handleCreateAgenda, handleDeleteAgenda, handleDeleteDocument, handleDownloadFile, handleEdit, handleEnterSearch, handleLoadMore, handleMeetingChange, handleMenuClose, handleMenuOpen, handleOpenAddAgendaDrawer, handleOpenUpload, handleRemovePaper, handleSearchChange, handleUpdateAgenda, initialValues, isAddAgendaDrawerOpen, isAdmin, isHasMorePages, loadingRowCount, paperDocument, searchText, showAction]);
}
export const [ManageAgendaPageProvider, useManageAgendaPageContext] =
  generateContext(useManageAgendaPage);
