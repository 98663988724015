import React from "react";
import FormControl from "../../../components/form-control";
import { FormikSelect } from "../../../components/select";
import { FormikInput } from "../../../components/input";
import BinIcon from "../../../components/vectors/bin-icon";
import { FN } from "./form-helper";
import { useCreatePostMeetingPageContext } from "../provider";
import constants from "../../../constants";

const AgendaConsideration = (props) => {
  const { itemIndex, arrayHelpers, isDisabledForm } = props;
  const { getAgendaQuery, handleAgendaDelete } =
    useCreatePostMeetingPageContext();
  const agendas = getAgendaQuery?.data?.data?.data;

  return (
    <>
      <div key={itemIndex} className="grid grid-cols-10 items-center gap-3">
        <FormControl className="col-span-7">
          <FormikSelect
            data={agendas}
            id={`${FN.AGENDA_DETAILS.AGENDAS}.[${itemIndex}].${FN.AGENDA_DETAILS.TITLE}`}
            name={`${FN.AGENDA_DETAILS.AGENDAS}.[${itemIndex}].${FN.AGENDA_DETAILS.TITLE}`}
            lableString="title"
            valueString="title"
            passValueInt={true}
            placeholder="Agenda"
            virtualize={agendas?.length >= constants.VIRTUALIZED_ENABLE_LIMIT}
            classNames={{
              root: "mb-1 ",
              input: "ring-black-100 h-10 bg-[#6673801A]",
              popperContent: "max-w-[560px]",
              iconRight: "hidden",
            }}
            disabled={true}
          />
        </FormControl>
        <FormControl className="col-span-1">
          <FormikInput
            classNames={{
              root: "mb-1 ",
              input: "ring-black-100 h-10 bg-[#6673801A]",
            }}
            id={`${FN.AGENDA_DETAILS.AGENDAS}.[${itemIndex}].${FN.AGENDA_DETAILS.TOTAL_PAPER_UNDER_CONSIDERATION}`}
            name={`${FN.AGENDA_DETAILS.AGENDAS}.[${itemIndex}].${FN.AGENDA_DETAILS.TOTAL_PAPER_UNDER_CONSIDERATION}`}
            placeholder="Total Number"
            disabled={true}
          />
        </FormControl>
        <FormControl className="col-span-1">
          <FormikInput
            classNames={{
              root: "mb-1",
              input: "ring-black-100 h-10",
            }}
            id={`${FN.AGENDA_DETAILS.AGENDAS}.[${itemIndex}].${FN.AGENDA_DETAILS.TOTAL_PAPER_SUBMISSION_BY_INDIA}`}
            name={`${FN.AGENDA_DETAILS.AGENDAS}.[${itemIndex}].${FN.AGENDA_DETAILS.TOTAL_PAPER_SUBMISSION_BY_INDIA}`}
            disabled={isDisabledForm}
          />
        </FormControl>
        <FormControl className="col-span-1">
          <FormikInput
            classNames={{
              root: "mb-1",
              input: "ring-black-100 h-10 ",
            }}
            id={`${FN.AGENDA_DETAILS.AGENDAS}.[${itemIndex}].${FN.AGENDA_DETAILS.TOTAL_PAPER_FROM_PAST_INDIA}`}
            name={`${FN.AGENDA_DETAILS.AGENDAS}.[${itemIndex}].${FN.AGENDA_DETAILS.TOTAL_PAPER_FROM_PAST_INDIA}`}
            disabled={isDisabledForm}
          />
        </FormControl>
        <div className="flex items-center justify-center ml-[-90%] mb-3 hidden">
          <BinIcon
            onClick={handleAgendaDelete(arrayHelpers, itemIndex)}
            className="cursor-pointer"
          />
        </div>
      </div>
    </>
  );
};

export default AgendaConsideration;
