import cx from "../../utils/class-names";
import PropTypes from "prop-types";

export default function InputLabel(props) {
  const { children, className, required, ...restProps } = props;
  return (
    <label
      className={cx(
        "input-label inline-block text-primary-text text-xs font-semibold mb-1.5",
        className
      )}
      {...restProps}
    >
      {required ? <span className="text-red-550">* </span> : null}
      <span>{children}</span>
    </label>
  );
}

InputLabel.defaultProps = {
  className: "",
};

InputLabel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
