import HeaderTitle from "../../components/header-title";
import { HeaderRight } from "../../layouts/dashboard/header";
import withHOC from "../../utils/with-hoc";
import HeaderSection from "./components/header-section";
import { MyTaskPageProvider, useMyTaskPageContext } from "./provider";
import TaskItem from "./components/task-item";

function MyTaskPage() {
  const { handleViewTaskDetails, getMyTaskDetailsQuery } =
    useMyTaskPageContext();
  const taskDetails = getMyTaskDetailsQuery?.data?.data || [];
  return (
    <div className="flex flex-1 flex-col">
      <HeaderRight>
        <HeaderTitle>Tasks</HeaderTitle>
      </HeaderRight>
      <HeaderSection />
      <div className="flex flex-col flex-1 gap-3 px-2">
        {taskDetails.map((task) => {
          return (
            <TaskItem
              row={task}
              key={task.id}
              handleViewTaskDetails={handleViewTaskDetails}
            />
          );
        })}
      </div>
    </div>
  );
}

export default withHOC(MyTaskPageProvider, MyTaskPage);
