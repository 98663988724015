import { Form, Formik } from "formik";
import * as Yup from "yup";
import * as validation from "../../../utils/validation";
import InputLabel from "../../../components/input-label";
import ScrollToFormError from "../../../components/scroll-to-form-error";
import { FN } from "../components/form-helper/core";
import { useCreatePostMeetingPageContext } from "../provider";
import AgendaConsideration from "./agenda-consideration";
import MeetingDetails from "./meeting-details";
import AgendaMembers from "./agenda-members";
import { FooterSection } from "./footer-section";
import { useCallback } from "react";
const ReportForm = () => {
  const {
    initialValues,
    formRef,
    handleSubmit,
    reportValidationSchema,
    isSubmitted,
    containerRef,
    getPostMeetingQuery,
    isFormSave,
  } = useCreatePostMeetingPageContext();
  const formSaveValidationSchema = useCallback(() => {
    return Yup.lazy(() => {
      return Yup.object().shape({
        [FN.FROM_MEETING_DATE]: validation.isRequiredDate(),
        [FN.TO_MEETING_DATE]: validation.isRequiredDate(),
      });
    });
  }, []);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={isFormSave ? formSaveValidationSchema : reportValidationSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {(formik) => {
        formRef.current = formik;
        const { values } = formik;
        return (
          <Form className=" overflow-y-auto px-4 pt-4" ref={containerRef}>
            <ScrollToFormError>
              <MeetingDetails values={values} isDisabledForm={isSubmitted} />
              <InputLabel
                className="flex-1 text-label-100 ml-4 mb-0"
                htmlFor={FN.AGENDA_DETAILS.AGENDAS}
              >
                Agenda under consideration
              </InputLabel>
              <div className="m-4 mt-1 border border-solid border-black-100 border-1 rounded-md overflow-auto">
                <div className="grid grid-cols-12  bg-[#0E4374] p-2 gap-3 items-center">
                  <div className="col-span-6 text-white text-xs">Agenda</div>
                  <div className="col-span-2 text-white text-xs">
                    Total Number of Paper under consideration
                  </div>
                  <div className="col-span-2 text-white text-xs">
                    Total Number of Paper Submission by India in this session
                  </div>
                  <div className="col-span-2 text-white text-xs">
                    Total number of Paper from India in Past Session brought
                    forward and Considered
                  </div>
                </div>
                <div className="flex flex-col p-2">
                  {(
                    getPostMeetingQuery?.data?.data?.post_meeting_agendas || []
                  ).map((_, itemIndex) => {
                    return (
                      <AgendaConsideration
                        itemIndex={itemIndex}
                        key={itemIndex}
                        isDisabledForm={isSubmitted}
                      />
                    );
                  })}
                </div>
              </div>
              <div className="m-4 mt-1 rounded-md">
                <AgendaMembers isDisabledForm={isSubmitted} />
              </div>
              <FooterSection />
            </ScrollToFormError>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ReportForm;
