import HeaderTitle from "../../components/header-title";
import { HeaderRight } from "../../layouts/dashboard/header";
import withHOC from "../../utils/with-hoc";
import { FeedbackRow } from "./components/feedback-row";
import { Filter } from "./components/filter";
import HeaderSection from "./components/header-section";
import {
  CommitteeFeedbackListingPageProvider,
  useCommitteeFeedbackListingPageContext,
} from "./provider";
import Button from "../../components/button";
import Arrow2Icon from "../../components/vectors/arrow2-icon";
import DownloadThinIcon from "../../components/vectors/download-thin-icon";

function CommitteeFeedbackListingPage() {
  const {
    handleFeedbackClick,
    handleDownloadReport,
    getPaperForMeetingQuery,
    goBack,
  } = useCommitteeFeedbackListingPageContext();
  const paperFeedbackStatus = getPaperForMeetingQuery?.data?.data?.data || [];
  return (
    <div className="flex flex-1 flex-col">
      <HeaderRight>
        <Button
          variant="outline"
          color="primary"
          className="py-2 px-3.5 mr-2 flex items-center text-xs font-semibold text-primary-button hover:bg-primary-button hover:text-secondary-0"
          icon={<Arrow2Icon className="rotate-180" />}
          onClick={goBack}
        >
          Back
        </Button>
        <HeaderTitle>Committee Feedback</HeaderTitle>
        <Button
          variant="solid"
          color="primary"
          className="h-9 flex items-center gap-1 text-sm ml-2"
          icon={<DownloadThinIcon />}
          onClick={handleDownloadReport}
        >
          Download Report
        </Button>
      </HeaderRight>
      <Filter />
      <HeaderSection />
      {Array.isArray(paperFeedbackStatus) &&
        paperFeedbackStatus?.map((item) => {
          return (
            <FeedbackRow
              item={item}
              handleFeedbackClick={handleFeedbackClick}
            />
          );
        })}
    </div>
  );
}

export default withHOC(
  CommitteeFeedbackListingPageProvider,
  CommitteeFeedbackListingPage
);
