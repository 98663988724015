function FolderCloseIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={20}
      fill="none"
      {...props}
    >
      <path
        d="M21.6 2.5H10.8L8.4 0h-6C1.08 0 0 1.125 0 2.5v5h24V5c0-1.375-1.08-2.5-2.4-2.5Z"
        fill="#FFA000"
      />
      <path
        d="M21.6 2.5H2.4C1.08 2.5 0 3.625 0 5v12.5C0 18.875 1.08 20 2.4 20h19.2c1.32 0 2.4-1.125 2.4-2.5V5c0-1.375-1.08-2.5-2.4-2.5Z"
        fill="#FFCA28"
      />
    </svg>
  );
}

export default FolderCloseIcon;
