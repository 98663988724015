import { withConfig, getApi } from "./index";
const api = getApi("/agendas");

export const createAgenda = withConfig((data, conf) => {
  const formData = new FormData();
  formData.append("title",data.title);
  formData.append('meeting_id',data.meeting_id);
  data.papers?.forEach((item, indx) => {
    if(item.upload){
      formData.append(`papers[${indx}].upload`,item.upload);
    }
    formData.append(`papers[${indx}].description`,item.description);
    formData.append(`papers[${indx}].symbol`,item.symbol);
    formData.append(`papers[${indx}].summary`,item.summary);
  });
  const config = conf({
    url: ``,
    method: "POST",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return api(config);
});

export const addPaper = withConfig((data, conf) => {
  const { id, ...payload } = data;
  const config = conf({
    url: `${id}/addPaper`,
    method: "PUT",
    data: payload,
  });
  return api(config);
});
export const editAgenda = withConfig((data, conf) => {
  const { id, ...payload } = data;
  const formData = new FormData();
  if(payload.title){
  formData.append("title",payload.title);
  }
  if(payload.meeting_id){
    formData.append('meeting_id',payload.meeting_id);
  }
  payload.papers?.forEach((item, indx) => {
    if(item.paper_id){
      formData.append(`papers[${indx}].paper_id`,item.paper_id);
    }
    if(item.upload){
    formData.append(`papers[${indx}].upload`,item.upload);
    }
    formData.append(`papers[${indx}].description`,item.description);
    formData.append(`papers[${indx}].symbol`,item.symbol);
    formData.append(`papers[${indx}].summary`,item.summary);
  });
  const config = conf({
    url: `${id}`,
    method: "PUT",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return api(config);
});

export const deleteAgenda = withConfig((data, conf) => {
  const { id, ...payload } = data;
  const config = conf({
    url: `${id}`,
    method: "DELETE",
    data: payload,
  });
  return api(config);
});
