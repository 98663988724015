import * as Yup from "yup";

export const FN = {
  CURRENT_PASSWORD: "current_password",
  NEW_PASSWORD: "new_password",
  CONFIRM_NEW_PASSWORD: "confirm_new_password",
};

export const getInitialValues = () => {
  return {
    [FN.CURRENT_PASSWORD]: "",
    [FN.NEW_PASSWORD]: "",
    [FN.CONFIRM_NEW_PASSWORD]: "",
  };
};

export const getValidationSchema = () => {
  return Yup.object().shape({
    [FN.CURRENT_PASSWORD]: Yup.string().required(
      "Current password is required"
    ),
    [FN.NEW_PASSWORD]: Yup.string().required("New password is required"),
    [FN.CONFIRM_NEW_PASSWORD]: Yup.string().required(
      "Confirm new password is required"
    ),
  });
};
