import { useMemo } from "react";
import { useEffect, useRef } from "react";
import Const from "../constants";
import debounce from "../utils/debounce";

const sneakyFooterTimeout = 5000; // number in ms

export default function useSneaky(containerRef, footerRef) {
  const bottomRef = useRef(null);
  let isFooterHover = false;

  let containerEle = useMemo(() => {
    return containerRef?.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef?.current]);

  let footerEle = useMemo(() => {
    return footerRef?.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [footerRef?.current]);

  useEffect(() => {
    if (!containerEle || !footerEle) {
      return;
    }
    let footerTime, atBottom;
    const setTimer = () => {
      if (!isFooterHover && !bottomRef.current) {
        footerEle?.classList.add("translate-y-full");
      }
    };

    const resetTimer = () => {
      footerTime && clearTimeout(footerTime);
      footerTime = null;
    };

    const handleScroll = debounce(() => {
      const { scrollTop, scrollHeight, offsetHeight } = containerRef?.current;
      atBottom = scrollTop + offsetHeight >= scrollHeight;
      const isScroll = scrollTop > Const.SHOW_FOOTER_SCROLL_POSITION;
      bottomRef.current = atBottom;

      if (isScroll) {
        resetTimer();
        footerEle?.classList.remove("translate-y-full");
        if (!atBottom) {
          footerTime = setTimeout(setTimer, sneakyFooterTimeout);
        }
      }
    }, 10);

    const handleMouseEnter = () => {
      resetTimer();
      footerEle?.classList.remove("translate-y-full");
    };

    containerEle?.addEventListener("scroll", handleScroll);
    footerEle?.addEventListener("mouseenter", handleMouseEnter);
    footerEle?.addEventListener("mouseleave", handleScroll);

    return () => {
      footerTime && clearTimeout(footerTime);
      footerTime = null;
      containerEle?.removeEventListener("scroll", handleScroll);
      footerEle?.removeEventListener("mouseenter", handleMouseEnter);
      footerEle?.removeEventListener("mouseleave", handleScroll);
    };
  }, [containerEle, footerEle, isFooterHover, containerRef]);
}
