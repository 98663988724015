import { getApi, withConfig } from ".";
const api = getApi("/group-feedback");

export const deleteGroupLeadersFeedback = withConfig((data, conf) => {
  const { feedbackId, ...payload } = data;
  const config = conf({
    url: `/${feedbackId}`,
    method: "DELETE",
    data: payload,
  });
  return api(config);
});

export const uploaodFeedbackDocumments = withConfig((data, conf) => {
  const { feedbackId, files = [] } = data;
  let formData = new FormData();
  files.forEach((item) => {
    formData.append("files", item);
  });

  const config = conf({
    url: `/${feedbackId}/documents`,
    method: "POST",
    data: formData,
    responseType: "blob",
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return api(config);
});

export const uploaodFeedbackDocummentsList = withConfig((data, conf) => {
  const { feedbackId } = data;
  const config = conf({
    url: `/${feedbackId}/documents`,
    method: "GET",
  });
  return api(config);
});

export const feedbackDocummentDelete = withConfig((data, conf) => {
  const { feedbackId, documentId } = data;

  const config = conf({
    url: `/${feedbackId}/documents/${documentId}`,
    method: "DELETE",
  });
  return api(config);
});

export const feedbackDocummentDocuments = withConfig((data, conf) => {
  const { feedbackId, documentId, ...params } = data;
  const config = conf({
    url: `/${feedbackId}/documents/${documentId}/preview`,
    method: "GET",
    params: params,
    responseType: "blob",
  });
  return api(config);
});

export const addFeedback = withConfig((data, conf) => {
  const { feedbackId, ...payload } = data;

  const config = conf({
    url: `/${feedbackId}/comments`,
    method: "POST",
    data: payload,
  });
  return api(config);
});

export const feedbackList = withConfig((data, conf) => {
  const { feedbackId, ...payload } = data;

  const config = conf({
    url: `/${feedbackId}/comments`,
    method: "GET",
    data: payload,
  });
  return api(config);
});
