import { withHeaderContext } from "./provider";
import Portal from "../../../components/portal";
import { useEffect, useState } from "react";
import CollapseIcon from "../../../components/vectors/collapse-icon";
import cx from "../../../utils/class-names";
import UserMenu from "./user-menu";
import ChangePasswordModal from "./change-password-model";

const HEADER_RIGHT = "header-right";
const HEADER_LEFT = "header-left";

function Header(props) {
  const { closeOpenMenus, isShowMenu } = props;
  return (
    <div
      id="page-header"
      className={cx(
        "p-4 flex items-center justify-between bg-secondary-0 drop-shadow border-b-2 z-1",
        !isShowMenu && "pl-8"
      )}
    >
      <div className="tab-md:flex hidden mr-5">
        <CollapseIcon
          onClick={closeOpenMenus}
          className="cursor-pointer tab-md:flex hidden"
        />
      </div>
      {/* Note: add dynamic title using portal */}
      <div id={HEADER_RIGHT} className="font-medium flex-1" />
      <div className="flex items-center justify-between flex-1 ml-4 z-10">
        <div id={HEADER_LEFT} className="flex items-center ml-4 z-10" />
        <UserMenu />
      </div>
      <ChangePasswordModal />
    </div>
  );
}

export const HeaderRight = (props) => {
  const { children, ...restPops } = props;
  const [element, setElement] = useState();
  useEffect(() => {
    let ele = document.getElementById(HEADER_RIGHT);
    setElement(ele);
  }, []);

  if (!element) return null;
  return (
    <Portal parent={element} {...restPops}>
      <div className="flex items-center">{children}</div>
    </Portal>
  );
};
export const HeaderLeft = (props) => {
  const { children, className, ...restPops } = props;
  const [element, setElement] = useState();
  useEffect(() => {
    let ele = document.getElementById(HEADER_LEFT);
    setElement(ele);
  }, []);

  if (!element) return null;
  return (
    <Portal parent={element} {...restPops}>
      <div className={cx("flex items-center", className)}>{children}</div>
    </Portal>
  );
};

export default withHeaderContext(Header);
