import React from "react";
import FormControl from "../../../components/form-control";
import { FormikInput } from "../../../components/input";
import { FN } from "./form-helper/core";

const AgendaConsideration = (props) => {
  const { itemIndex, isDisabledForm } = props;
  return (
    <>
      <div key={itemIndex} className="grid grid-cols-12 items-center gap-3">
        <FormControl className="col-span-6">
          <FormikInput
            id={`${FN.AGENDA_DETAILS.AGENDAS}.[${itemIndex}].${FN.AGENDA_DETAILS.TITLE}`}
            name={`${FN.AGENDA_DETAILS.AGENDAS}.[${itemIndex}].${FN.AGENDA_DETAILS.TITLE}`}
            placeholder="Agenda"
            classNames={{
              root: "mb-1 ",
              input: "ring-black-100 h-10",
            }}
            disabled={true}
          />
        </FormControl>
        <FormControl className="col-span-2">
          <FormikInput
            type="number"
            classNames={{
              root: "mb-1 ",
              input: "ring-black-100 h-10",
            }}
            id={`${FN.AGENDA_DETAILS.AGENDAS}.[${itemIndex}].${FN.AGENDA_DETAILS.TOTAL_PAPER_UNDER_CONSIDERATION}`}
            name={`${FN.AGENDA_DETAILS.AGENDAS}.[${itemIndex}].${FN.AGENDA_DETAILS.TOTAL_PAPER_UNDER_CONSIDERATION}`}
            disabled={isDisabledForm}
          />
        </FormControl>
        <FormControl className="col-span-2">
          <FormikInput
            type="number"
            classNames={{
              root: "mb-1",
              input: "ring-black-100 h-10",
            }}
            id={`${FN.AGENDA_DETAILS.AGENDAS}.[${itemIndex}].${FN.AGENDA_DETAILS.TOTAL_PAPER_SUBMISSION_BY_INDIA}`}
            name={`${FN.AGENDA_DETAILS.AGENDAS}.[${itemIndex}].${FN.AGENDA_DETAILS.TOTAL_PAPER_SUBMISSION_BY_INDIA}`}
            disabled={isDisabledForm}
          />
        </FormControl>
        <FormControl className="col-span-2">
          <FormikInput
            type="number"
            classNames={{
              root: "mb-1",
              input: "ring-black-100 h-10",
            }}
            id={`${FN.AGENDA_DETAILS.AGENDAS}.[${itemIndex}].${FN.AGENDA_DETAILS.TOTAL_PAPER_FROM_PAST_INDIA}`}
            name={`${FN.AGENDA_DETAILS.AGENDAS}.[${itemIndex}].${FN.AGENDA_DETAILS.TOTAL_PAPER_FROM_PAST_INDIA}`}
            disabled={isDisabledForm}
          />
        </FormControl>
      </div>
    </>
  );
};

export default AgendaConsideration;
