import { withConfig, getApi } from "./index";
const api = getApi("/post-meeting");

export const deleteIndianDelegationMember = withConfig((data, conf) => {
  const { postMeetingId, id, ...params } = data;
  const config = conf({
    url: `${postMeetingId}/indian-delegation/${id}`,
    method: "DELETE",
    params: params,
  });
  return api(config);
});
export const deleteNominatedAgenda = withConfig((data, conf) => {
  const { postMeetingId, id, ...params } = data;
  const config = conf({
    url: `${postMeetingId}/nominated-agenda/${id}`,
    method: "DELETE",
    params: params,
  });
  return api(config);
});
export const deleteNominatedAgendaMembers = withConfig((data, conf) => {
  const { postMeetingId, id, ...params } = data;
  const config = conf({
    url: `${postMeetingId}/nominated-member/${id}`,
    method: "DELETE",
    params: params,
  });
  return api(config);
});
export const deleteNominatedAgendaTasks = withConfig((data, conf) => {
  const { postMeetingId, id, ...params } = data;
  const config = conf({
    url: `${postMeetingId}/nominated-task/${id}`,
    method: "DELETE",
    params: params,
  });
  return api(config);
});
