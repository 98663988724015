import { useCallback } from "react";
import PenGreenIcon from "../../../components/vectors/pen-green-icon";
import { useUserFromStorage } from "../../../contexts/user.context";
import FeedbackForm from "./feedback-form";
import { useMembersFeedbackPageContext } from "../provider";
import DownloadThinIcon from "../../../components/vectors/download-thin-icon";
import Divider from "../../../components/divider";
import BinIcon from "../../../components/vectors/bin-icon";
import cx from "../../../utils/class-names";
import { useAsync } from "@react-org/hooks";
import * as TaskFeedbackApi from "../../../apis/task-feedback.api";
import { getFormattedDate } from "../../../utils/date-and-time";

function Feedback(props) {
  const { papers, onSubmit, feedback, key, isModelOpen } = props;
  const {
    uploadedDocList,
    setUplodedDocList,
    handleDownloadFile,
    handleDeleteDocument,
    isEditMode,
    handleCancel,
    handleEdit,
  } = useMembersFeedbackPageContext();

  const { user } = useUserFromStorage();

  let feedbackId = feedback?.id;

  useAsync(
    useCallback(
      (payload) =>
        TaskFeedbackApi.uploaodFeedbackDocummentsList({
          feedbackId: feedbackId,
          ...payload,
        }),
      [feedbackId]
    ),
    {
      onSuccess: (res) => {
        return setUplodedDocList((prevVal) =>
          [...prevVal, res?.data?.data].flat()
        );
      },
      immediate: true,
    }
  );

  const handleSubmit = (data) => {
    onSubmit && onSubmit(data, handleCancel);
  };

  const isEditable = user && user.id === feedback.member.id;
  const updatedDate = getFormattedDate(feedback?.updated_at,'lll')

  const feedbackPapers = feedback.feedback_papers
    .map((i) => i?.paper?.symbol)
    .join(", ");

  const memberName = [
    feedback?.member?.first_name,
    feedback?.member?.last_name,
  ].join(" ");

  if (isModelOpen) {
    return (
      <FeedbackForm
        onSubmit={handleSubmit}
        data={feedback}
        onCancel={handleCancel}
        papers={papers}
        uploadedDocument={uploadedDocList}
        isEditMode={isEditMode}
      />
    );
  }

  return (
    <div
      className="relative text-xs fedback-box mt-2 bg-blue-800 border border-primary-button p-2 rounded"
      key={key}
    >
      {isEditable ? (
        <div
          className="w-6 h-6 p-1 absolute right-0 top-0.5 cursor-pointer"
          onClick={handleEdit(feedbackId)}
        >
          <PenGreenIcon className="w-full h-full" />
        </div>
      ) : null}
        <div className={cx("feedback-section_value absolute right-2", isEditable && "right-6")}>
          {updatedDate}
        </div>
      <div className="feedback-section mb-1">
        <div className="feedback-section_label text-black-100 font-semibold">
          Paper :
        </div>
        <div className="feedback-section_value">{feedbackPapers}</div>
      </div>
      <div className="feedback-section mb-1">
        <div className="feedback-section_label text-black-100 font-semibold">
          {feedback.response} :
        </div>
        <div
          className="feedback-section_value"
          dangerouslySetInnerHTML={{ __html: feedback.comment }}
        />
      </div>
      <div className="feedback-section mb-1">
        <div className="feedback-section_label text-black-100 font-semibold">
          Reported by :
        </div>
        <div className="feedback-section_value">{memberName}</div>
      </div>
      {(uploadedDocList || []).map((doc, index) => {
        const name = doc.name;
        return doc?.feedback_id === feedbackId ? (
          <div
            className="relative flex flex-col tab-sm:flex-1 mb-2"
            key={index}
          >
            <div className="w-full p-1 rounded bg-[#F3F4F5] flex justify-between border-[2px] border-[#F8F8F8;]">
              <div className="flex gap-2 items-center max-w-[90%]">
                <div className="flex flex-col">
                  <p className="font-normal text-black-100 text-xs leading-[13px] break-all">
                    {name}
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <DownloadThinIcon
                  className={cx(
                    "text-primary-button ml-1.5 w-4 h-4 cursor-pointer"
                  )}
                  onClick={handleDownloadFile(doc)}
                />
                <Divider className="w-[2px] h-full mx-3.5" />
                <BinIcon
                  className={cx("w-4 h-4 cursor-pointer self-center")}
                  onClick={handleDeleteDocument(doc)}
                />
              </div>
            </div>
          </div>
        ) : null;
      })}
    </div>
  );
}

export default Feedback;
