import { withConfig, getApi } from "./index";
const api = getApi();

export const getAgenda = withConfig((data, conf) => {
  const config = conf({
    url: "/lists/agendas",
    method: "GET",
    params: data,
  });
  return api(config);
});

export const getPaper = withConfig((data, conf) => {
  const { id, ...params } = data;
  const config = conf({
    url: `/lists/agendas/${id}/papers`,
    method: "GET",
    params: params,
  });
  return api(config);
});

export const getPaperForMeeting = withConfig((data, conf) => {
  const { meetingId, ...params } = data;
  const config = conf({
    url: `/lists/papers/meeting/${meetingId}`,
    method: "GET",
    params: params,
  });
  return api(config);
});

export const getPaperDetails = withConfig((data, conf) => {
  const { agendaId, paperId, ...params } = data;
  const config = conf({
    url: `/lists/agendas/${agendaId}/papers/${paperId}`,
    method: "GET",
    params: params,
  });
  return api(config);
});

export const getMeetingDetails = withConfig((data, conf) => {
  const { agendaId, paperId, ...params } = data;
  const config = conf({
    url: `/lists/meetings`,
    method: "GET",
    params: params,
  });
  return api(config);
});
