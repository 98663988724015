import cx from "../../utils/class-names";

export const Close2Icon = (props) => {
  const { className, ...restProps } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      className={cx("text-[#CB2354]", className)}
      {...restProps}
    >
      <circle
        cx="6"
        cy="6.5"
        r="5.4"
        stroke="currentColor"
        strokeWidth="1.2"
      />
      <path
        d="M4.00195 4.49998L8.00195 8.49998"
        stroke="currentColor"
        strokeWidth="1.2"
      />
      <path
        d="M4.00195 8.49998L8.00195 4.49999"
        stroke="currentColor"
        strokeWidth="1.2"
      />
    </svg>
  );
};
