import SearchIcon from "../vectors/search-icon";
import Input from "../input";
import cx from "../../utils/class-names";
import PropTypes from "prop-types";

export default function SearchInput(props) {
  const { classNames = {}, ...restProps } = props;
  return (
    <Input
      autoComplete="off"
      placeholder="Search in List"
      classNames={{
        root: cx("search-input min-w-[250px] max-w-[300px]", classNames.root),
        input: cx("search-input_input bg-secondary-0 text-xs border-secondary-400 p-2 pl-8", classNames.input),
        iconLeft: cx("search-input_ico-left w-8 h-8 text-primary-brand", classNames.iconLeft),
        iconRight: cx("search-input_ico-right w-8 h-8 text-primary-brand cursor-pointer", classNames.iconRight),
      }}
      iconLeft={<SearchIcon />}
      {...restProps}
    />
  );
}

SearchInput.defaultProps = {
  iconLeft: <SearchIcon />,
  classNames: {
    root: "",
    input: "",
    iconLeft: "",
    iconRight: "",
  },
};

SearchInput.propTypes = {
  iconLeft: PropTypes.element,
  iconRight: PropTypes.element,
  classNames: PropTypes.shape({
    root: PropTypes.string,
    input: PropTypes.string,
    iconLeft: PropTypes.string,
    iconRight: PropTypes.string,
  }),
};
