import React from "react";
import PropTypes from "prop-types";
import cx from "../../utils/class-names";

const ToggleButton = (props) => {
  const { checked, onChange, children, className, ref, disabled } = props
	
  return (
    <div className="flex gap-2">
      <div className="ml-3 text-md font-medium font-sans">{children}</div>

      <label
        className={cx("relative inline-flex items-center cursor-pointer", className)}
      >
        <input
          type="checkbox"
          ref={ref}
          disabled={disabled}
          checked={checked}
          onChange={onChange}
          className="sr-only peer"
        />

        <div
          className={`w-11 h-6 bg-primary-brand peer-focus:outline-none dark:peer-focus:ring-blue-800 rounded-[1.375rem] peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:bg-primary-brand peer-checked:bg-primary-button`}
        ></div>
      </label>
    </div>
  );
};

ToggleButton.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  children: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ToggleButton;
