export const FN = {
  FROM_MEETING_DATE: "from_meeting_date",
  TO_MEETING_DATE: "to_meeting_date",
  NATURE_OF_MEETING: "nature_of_meeting",
  TOTAL_PAPER_SUBMISSION: "total_paper_submission",
  TOTAL_MEMBER_ATTENDED: "total_member_attended",
  TOTAL_MEMBER_ATTENDED_VIRTUALLY: "total_member_attended_virtually",
  TOTAL_PAPER_UNDER_SUBMISSION: "total_paper_under_submission",
  GROUP_SET_UP: "group_set_up",
  NUMBER_OF_WORKING_GROUP_SET_UP: "number_of_working_group_setup",
  LIST_OF_INDIAN_DELEGATION: {
    ROOT: "list_of_indian_delegation",
    NAME: "name",
    ORGANISATION: "organisation",
  },
  AGENDA_DETAILS: {
    AGENDAS: "agendas",
    TITLE: "title",
    TOTAL_PAPER_UNDER_CONSIDERATION: "total_paper_under_submission",
    TOTAL_PAPER_SUBMISSION_BY_INDIA: "total_paper_submission_by_india",
    TOTAL_PAPER_FROM_PAST_INDIA: "total_paper_from_past_india",
    AGENDA_ID: "agenda_id",
  },
  AGENDA_MEMBER_DETAILS: {
    ROOT: "agenda_members",
    AGENDA_TITLE: "agenda_title",
    HEAD_OF_AGENDA: {
      ROOT: "head_of_agenda",
      HEAD_OF_AGENDA_NAME: "head_of_agenda_name",
      HEAD_OF_AGENDA_ORGANISATION: "head_of_agenda_organisation",
    },
    NOMINATED_MEMBERS: {
      ROOT: "nominated_members",
      NAME: "name",
      ORGANISATION: "organisation",
    },
    TASKS: {
      ROOT: "task",
      BACKGROUND: "background",
      PAPER_UNDER_CONSIDERATION: "paper_under_consideration",
      INDIA_SUBMISSION: "india_submissions",
      INDIA_COSPONSORED_PAPERS: "india_cosponsored_papers",
      REVIEW_OF_SUBMISSION: "review_of_submissions",
      RECOMMENDED_INDIA_STAND_TO_BE_TAKEN: "recommended_india_stand",
      SHADOW_COMMITTEE_APPROVED_STAND: "shadow_committee_approved_stand",
      DECISION_OF_IMO_COMMITTEE: "decision_of_imo_committee",
      AREAS_OF_DISAGREEMENT_OF_INDIA: "areas_of_disagreement_of_india",
      ACHIEVEMENT_OF_INDIA: "achievement_of_india",
      TO_DO_LIST: "to_do_list",
    },
  },
};

export const Enums = {
  NatureOfMeeting: [
    { nature_of_meeting: "Hybrid", label: "Hybrid" },
    { nature_of_meeting: "InPerson", label: "In Person" },
    { nature_of_meeting: "Virtual", label: "Virtual" },
  ],
};
