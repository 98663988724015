import * as Yup from "yup";
import * as validation from "../../../../utils/validation";
import uuid from "../../../../utils/uuid";

export const FN = {
  AGENDA_ID: "agenda_id",
  AGENDA: "agenda",
  MEETING_ID: "meeting_id",
  PAPER: {
    ROOT: "papers",
    PAPER_ID: "paper_id",
    PAPER_SYMBOL: "symbol",
    PAPER_DESCRIPTION: "description",
    PAPER_SUMMARY: "summary",
    SUBMITTED_BY: "submitted_by",
    FILE_NAME: "file_name",
    S3_KEY: "s3_key"
  },
};

export const getPaperDetailsRow = () => {
  return {
    id: uuid(),
    [FN.PAPER.PAPER_SYMBOL]: "",
    [FN.PAPER.PAPER_DESCRIPTION]: "",
    [FN.PAPER.PAPER_SUMMARY]: "",
    [FN.PAPER.SUBMITTED_BY]: "",
  };
};

export const getFormInitialValues = (data = {}) => {
  const paperDetails = data?.papers?.length
    ? data.papers.map((item) => {
        return {
          [FN.PAPER.PAPER_ID]: item?.id || "",
          [FN.PAPER.PAPER_SYMBOL]: item?.symbol || "",
          [FN.PAPER.PAPER_DESCRIPTION]: item?.description || "",
          [FN.PAPER.PAPER_SUMMARY]: item?.summary || "",
          [FN.PAPER.SUBMITTED_BY]: item?.submitted_by || "",
          [FN.PAPER.FILE_NAME]: item?.file_name || "",
          [FN.PAPER.S3_KEY]: item?.s3_key || "",
        };
      })
    : [{ ...getPaperDetailsRow() }];
  return {
    [FN.AGENDA_ID]: data.id || "",
    [FN.AGENDA]: data.title || "",
    [FN.MEETING_ID]: data.meeting_id || "",
    [FN.PAPER.ROOT]: paperDetails,
  };
};

export const getFormValidationSchema = (withRequired = true) => {
  return Yup.object().shape({
    [FN.AGENDA]: validation.isRequiredString({ withRequired }),
    [FN.MEETING_ID]: validation.isRequiredString({ withRequired }),
    [FN.PAPER.ROOT]: getPaperValidationSchema(withRequired),
  });
};
function getPaperValidationSchema(withRequired) {
  return Yup.array().of(
    Yup.object().shape({
      [FN.PAPER.PAPER_SYMBOL]: validation.isRequiredString({
        withRequired: false,
      }),
      [FN.PAPER.PAPER_DESCRIPTION]: validation.isRequiredString({
        withRequired: false,
      }),
      [FN.PAPER.PAPER_SUMMARY]: validation.isRequiredString({
        withRequired: false,
      }),
      [FN.PAPER.SUBMITTED_BY]: validation.isRequiredString({
        withRequired: false,
      }),
    })
  );
}

export const generatePayload = (inputData, paperDocument) => {
  if (paperDocument?.length) {
    const fileMap = paperDocument.reduce((acc, item) => {
      acc[item?.paperName] = item.file;
      return acc;
    }, {});

    inputData.papers.forEach((item) => {
      item.file = fileMap[item.symbol];
    });
  }
  const outputData = {
    title: inputData.agenda,
    meeting_id: parseInt(inputData.meeting_id),
    papers: inputData.papers.map((paper) => {
      const paperDetails = {
        paper_id: paper.paper_id,
        description: paper.description,
        symbol: paper.symbol,
        summary: paper.summary,
        submitted_by: paper.submitted_by,
      };
      if (paper.file) {
        paperDetails.upload = paper.file;
      }
      return paperDetails;
    }),
  };
  return outputData;
};
