import React from "react";

export const Document = ({ docName, membersName }) => {
  return (
    <div className="flex items-center justify-between gap-4 w-[90%] p-2 rounded bg-[#F3F4F5] border-[2px] border-[#F8F8F8;] hover:bg-secondary-0 hover:border hover:border-primary-button cursor-pointer">
      <div className="flex flex-col col-span-6 pr-2">
        <label className="text-xs font-medium font-sans text-black-100 leading-[18px]">
          {docName}
        </label>
      </div>
      <div className="flex flex-col items-end col-span-2">
        <label className="text-xs font-medium font-sans text-black-100 leading-[18px] mr-4">
          {membersName}
        </label>
      </div>
    </div>
  );
};
