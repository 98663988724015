import cx from "../../utils/class-names";

export default function WizardContent(props) {
  const { children, className, contentContainerRef, ...restProps } = props;
  return (
    <div ref={contentContainerRef} className={cx("wizard_content flex-1 bg-secondary-0 shadow-[0px_2px_8px_0px_#0000000F]", className)} {...restProps}>
      {children}
    </div>
  );
}
