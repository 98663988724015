import React from "react";
import HeaderTitle from "../../components/header-title";
import { HeaderRight } from "../../layouts/dashboard/header";
import Button from "../../components/button";
import Arrow2Icon from "../../components/vectors/arrow2-icon";
import {
  CreatePostMeetingPorvider,
  useCreatePostMeetingPageContext,
} from "./provider";
import withHOC from "../../utils/with-hoc";
import ReportForm from "./components/report-form";

const PostMeetingReport = () => {
  const { goBack } = useCreatePostMeetingPageContext();

  return (
    <div className="flex flex-1 flex-col overflow-hidden">
      <HeaderRight>
        <Button
          variant="outline"
          color="primary"
          className="py-2 px-3.5 mr-2 flex items-center text-xs font-semibold text-primary-button hover:bg-primary-button hover:text-secondary-0"
          icon={<Arrow2Icon className="rotate-180" />}
          onClick={() => goBack()}
        >
          Back
        </Button>
        <HeaderTitle>Report</HeaderTitle>
      </HeaderRight>
      <ReportForm />
    </div>
  );
};

export default withHOC(CreatePostMeetingPorvider, PostMeetingReport);
