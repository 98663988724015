import { useCallback, useMemo, useState } from "react";
import generateContext from "../../utils/generate-context";
import { useAsync } from "@react-org/hooks";
import * as ListApi from "../../apis/lists.api";
import * as MembersApi from "../../apis/members.api";
import * as TaskFeedbackApi from "../../apis/task-feedback.api";
import * as WorkingPaperApi from "../../apis/working-paper.api";
import * as suggestionApi from "../../apis/suggestion.api";
import * as TasksApi from "../../apis/tasks.api";
import { useNotificationModalContext } from "../../components/notification-modal/provider";
import MESSAGES from "../../constants/messages";
import generateFileUrl from "../../utils/generate-file-url";
import { downloadOrPreviewFileUrl } from "../../utils/download-or-preview-file-url";

const useWorkingDocumentPage = () => {
  const notificationModal = useNotificationModalContext();
  const [searchText, setSearchText] = useState("");
  const [isFolderOpen, setIsFolderOpen] = useState(false);
  const getMeetingDetailsQuery = useAsync(
    useCallback(
      (payload) =>
        ListApi.getMeetingDetails({
          ...payload,
        }),
      []
    ),
    {
      immediate: true,
    }
  );
  const getMembersQuery = useAsync(
    useCallback(
      (payload) => MembersApi.getMembers({ limit: 1000, ...payload }),
      []
    ),
    {
      initialState: { data: [] },
      infinite: true,
      select: (res) => {
        return { data: res.data.data, paginate: res.data.paging };
      },
      immediate: true,
    }
  );
  const uploadWorkingPaperQuery = useAsync(WorkingPaperApi.uploadWorkingPaper, {
    immediate: false,
  });
  const previewWorkingPaperQuery = useAsync(
    WorkingPaperApi.previewWorkingPaper,
    { immediate: false }
  );
  const downloadFeedbackDocumentQuery = useAsync(
    TaskFeedbackApi.feedbackDocummentDocuments,
    { immediate: false }
  );
  const downloadSuggestionDocumentQuery = useAsync(
    suggestionApi.previewSuggestionDocument,
    {
      immediate: false,
    }
  );
  const downloadTaskDocumentQuery = useAsync(TasksApi.downloadTaskDocument, {
    immediate: false,
  });
  const getWorkingPaperListing = useCallback(
    (data) => {
      const tmpData = {
        ...data,
      };
      getMeetingDetailsQuery.cancel();
      getMeetingDetailsQuery.debounceExecute(tmpData, {
        overwrite: true,
      });
    },
    [getMeetingDetailsQuery]
  );

  const handleClearSearchFilter = useCallback(
    (e) => {
      setSearchText("");
      setIsFolderOpen(false);
      const getWorkingPaperListingQueryExecute = getMeetingDetailsQuery.execute;
      getWorkingPaperListingQueryExecute();
    },
    [getMeetingDetailsQuery.execute]
  );

  const handleSearchChange = useCallback(
    (e) => {
      e.persist();
      setSearchText(e.target.value);
      if (e.target.value.length === 0) handleClearSearchFilter();
    },
    [handleClearSearchFilter]
  );

  const handleEnterSearch = useCallback(
    (value) => {
      const payload = {};
      setIsFolderOpen(true);
      payload.term = value;
      getWorkingPaperListing(payload);
    },
    [getWorkingPaperListing]
  );

  const handleDownloadDocuments = useCallback(
    (id, docName) => () => {
      notificationModal.progress({ heading: MESSAGES.INPROGRESS_MESSAGE });
      const previewWorkingPaperQueryExecute = previewWorkingPaperQuery.execute;
      previewWorkingPaperQueryExecute(
        { id },
        {
          onSuccess: (res) => {
            const fileURL = generateFileUrl(
              res?.data,
              res?.headers["content-type"]
            );
            downloadOrPreviewFileUrl(fileURL, `${docName}`, {
              type: res?.headers["content-type"],
            });
            notificationModal.close();
          },
          onError: () => {
            notificationModal.error({ heading: MESSAGES.PREVIEW_FAILED });
          },
        }
      );
    },
    [notificationModal, previewWorkingPaperQuery.execute]
  );

  const handleDocumentUpload = useCallback(
    (id, associationName) => (event) => {
      let files = Array.from(event?.target?.files);
      const uploadWorkingPaperQueryExecute = uploadWorkingPaperQuery.execute;
      const getMeetingDetailsQueryExecute = getMeetingDetailsQuery.execute;
      notificationModal.progress({ heading: MESSAGES.INPROGRESS_MESSAGE });
      const documentPayload = {
        associationName: associationName,
        associationId: id,
        files: files,
      };
      uploadWorkingPaperQueryExecute(documentPayload, {
        onSuccess: (res) => {
          getMeetingDetailsQueryExecute();
          notificationModal.success({
            heading: MESSAGES.UPLOAD_DOC_SUCCESS,
          });
          event.target.value = null;
        },
        onError: () => {
          notificationModal.error({ heading: MESSAGES.UPLOAD_DOC_FAILED });
        },
      });
    },
    [
      getMeetingDetailsQuery.execute,
      notificationModal,
      uploadWorkingPaperQuery.execute,
    ]
  );
  const handleOpenUpload = useCallback(
    (fileUploadRef) => () => {
      fileUploadRef.current.click();
    },
    []
  );
  const handleDownloadCombinedDocuments = useCallback(
    (id, docName, docType, suggestionId) => () => {
      const getMeetingDetailsQueryExecute = getMeetingDetailsQuery.execute;
      notificationModal.progress({ heading: MESSAGES.INPROGRESS_MESSAGE });
      if (docType === "taskDocument") {
        const documentPayload = {
          documentId: id,
        };
        const downloadTaskDocumentQueryExecute =
          downloadTaskDocumentQuery.execute;
        downloadTaskDocumentQueryExecute(documentPayload, {
          onSuccess: (res) => {
            const fileURL = generateFileUrl(
              res?.data,
              res?.headers["content-type"]
            );
            downloadOrPreviewFileUrl(fileURL, `${docName}`, {
              type: res?.headers["content-type"],
            });
            getMeetingDetailsQueryExecute();
            notificationModal.close();
          },
          onError: () => {
            notificationModal.error({ heading: MESSAGES.PREVIEW_FAILED });
          },
        });
      } else if (docType === "suggestionDocument") {
        const documentPayload = {
          id: id,
          suggestionId,
        };
        const downloadSuggestionDocumentQueryExecute =
          downloadSuggestionDocumentQuery.execute;
        downloadSuggestionDocumentQueryExecute(documentPayload, {
          onSuccess: (res) => {
            const fileURL = generateFileUrl(
              res?.data,
              res?.headers["content-type"]
            );
            downloadOrPreviewFileUrl(fileURL, `${docName}`, {
              type: res?.headers["content-type"],
            });
            getMeetingDetailsQueryExecute();
            notificationModal.close();
          },
          onError: () => {
            notificationModal.error({ heading: MESSAGES.PREVIEW_FAILED });
          },
        });
      } else if (docType === "feedbackDocument") {
        const documentPayload = {
          documentId: id,
        };
        const downloadFeedbackDocumentQueryExecute =
          downloadFeedbackDocumentQuery.execute;
        downloadFeedbackDocumentQueryExecute(documentPayload, {
          onSuccess: (res) => {
            const fileURL = generateFileUrl(
              res?.data,
              res?.headers["content-type"]
            );
            downloadOrPreviewFileUrl(fileURL, `${docName}`, {
              type: res?.headers["content-type"],
            });
            getMeetingDetailsQueryExecute();
            notificationModal.close();
          },
          onError: () => {
            notificationModal.error({ heading: MESSAGES.PREVIEW_FAILED });
          },
        });
      }
    },
    [
      downloadFeedbackDocumentQuery.execute,
      downloadSuggestionDocumentQuery.execute,
      downloadTaskDocumentQuery.execute,
      getMeetingDetailsQuery.execute,
      notificationModal,
    ]
  );

  return useMemo(() => {
    return {
      getMeetingDetailsQuery,
      getMembersQuery,
      handleOpenUpload,
      handleDocumentUpload,
      handleDownloadDocuments,
      handleDownloadCombinedDocuments,
      searchText,
      handleSearchChange,
      handleClearSearchFilter,
      handleEnterSearch,
      isFolderOpen,
      setIsFolderOpen
    };
  }, [
    getMeetingDetailsQuery,
    getMembersQuery,
    handleClearSearchFilter,
    handleDocumentUpload,
    handleDownloadCombinedDocuments,
    handleDownloadDocuments,
    handleEnterSearch,
    handleOpenUpload,
    handleSearchChange,
    isFolderOpen,
    searchText,
  ]);
};

export const [WorkingDocumentPageProvider, useWorkingDocumentPageContext] =
  generateContext(useWorkingDocumentPage);
