import { useField, useFormikContext } from "formik";
import PropTypes from "prop-types";
import { forwardRef, useCallback, useEffect, useRef } from "react";
import Const from "../../constants";
import cx from "../../utils/class-names";
import is from "../../utils/is";
import mergeRefs from "../../utils/merge-refs";
import FormError from "../form-error";
import Input from "../input";
import CloseIcon from "../vectors/close-icon";
import DateIcon from "../vectors/date-icon";
import DateTimePicker from "./date-time-picker";
import "./index.css";

const Datepicker = forwardRef((props, ref) => {
  const {
    icon,
    options: _options = {},
    className,
    placeholder,
    value,
    children,
    readOnly,
    disabled,
    isShowInput = true,
    disableClose = false,
    ...restProps
  } = props;

  const datePicketRef = useRef(null);

  const handleClear = useCallback(() => {
    datePicketRef.current.flatpickr.clear();
  }, []);

  const IconBox = (props) => {
    const { children, className, ...restProps } = props;
    return (
      <div
        className={cx(
          "cursor-pointer z-20 w-5 h-5 p-0.5 flex items-center justify-center hover:text-primary-text text-primary-text",
          className
        )}
        {...restProps}
      >
        {children}
      </div>
    );
  };

  const getDatePickerIcon = useCallback(() => {
    let datePickerIcon = null;
    if (!is.undefined(icon)) {
      datePickerIcon = (
        <div className="flex items-center">
          {value?.length > 0 && !(readOnly || disabled) && (
            <>
              <div className="w-[1px] h-4 bg-secondary-200"></div>
              <IconBox onClick={handleClear} className>
                <CloseIcon color="text-blue-300" />
              </IconBox>
            </>
          )}
          <div className="w-[1px] h-4 bg-secondary-200"></div>
          <div className="cursor-pointer z-20 w-7 h-5 p-0.5 flex items-center justify-center mr-1">
            {icon}
          </div>
        </div>
      );
    } else {
      datePickerIcon = (
        <div className="flex items-center">
          {value?.length > 0 && !(readOnly || disabled || disableClose) && (
            <>
              <div className="w-[1px] h-4 bg-secondary-200"></div>
              <IconBox
                onClick={handleClear}
                className="hover:text-red-200 text-red-200"
              >
                <CloseIcon className="text-primary-text" />
              </IconBox>
            </>
          )}
          <div className="w-[1px] h-4 bg-secondary-200"></div>
          <div className="cursor-pointer z-20 w-7 h-5 p-0.5 flex items-center justify-center mr-1">
            <DateIcon
              className={cx(" w-4 h-5 text-primary-text")}
              onClick={() => {
                datePicketRef?.current?.flatpickr &&
                  datePicketRef.current.flatpickr.open();
              }}
            />
          </div>
        </div>
      );
    }
    return datePickerIcon;
  }, [disableClose, disabled, icon, handleClear, readOnly, value?.length]);

  let { dateFormat, ...restOptions } = _options;
  let options = {
    dateFormat: dateFormat || Const.DATE_TIME_FORMATS.DATE_FLATPICKER,
    altFormat: dateFormat || Const.DATE_TIME_FORMATS.DATE_FLATPICKER,
    altInput: true,
    allowInput: true,
    ...restOptions,
  };

  const readOnlyOption = {};
  if (options.allowInput) {
    readOnlyOption.readOnly = true;
  }

  useEffect(() => {
    if (datePicketRef?.current?.flatpickr?.altInput) {
      datePicketRef.current.flatpickr.altInput.disabled = readOnly || disabled;
    }
  }, [disabled, readOnly, ref]);

  return (
    <DateTimePicker
      ref={mergeRefs([datePicketRef, ref])}
      options={options}
      {...restProps}
      value={value}
      classNames={{
        input: cx(
          className,
          readOnly || disabled
            ? "datepicker bg-secondary-250"
            : "datepicker bg-secondary-0 ring-black-100"
        ),
        iconRight: "w-auto",
      }}
      className={cx(
        className,
        readOnly || disabled
          ? "datepicker bg-secondary-250"
          : "datepicker bg-secondary-0"
      )}
      placeholder={placeholder}
      iconRight={getDatePickerIcon()}
      render={(props, ref) => {
        return isShowInput ? (
          <Input ref={ref} {...props} {...readOnlyOption} />
        ) : (
          <div ref={ref} />
        );
      }}
      disabled={disabled}
    />
  );
});

export const FormikDatepicker = forwardRef((props, ref) => {
  const { name, classNames = {}, onChange, ...restProps } = props;
  const [field, meta, { setValue }] = useField(name);

  const { submitCount } = useFormikContext();
  const isError = submitCount > 0 && !!meta.error;
  const helperText = (submitCount > 0 && meta.error) || "";
  const handleChange = (value) => {
    setValue(value);
    onChange && onChange(value);
  };
  return (
    <>
      <Datepicker
        ref={ref}
        error={isError}
        {...field}
        {...restProps}
        onChange={handleChange}
      />
      <FormError
        show={isError}
        message={helperText}
        className={classNames.error}
      />
    </>
  );
});

Datepicker.defaultProps = {
  options: {},
  className: "",
  classNames: {},
  placeholder: "",
  value: [],
  readOnly: false,
  isShowInput: true,
  disableClose: false,
  disabled: false,
};

Datepicker.propTypes = {
  icon: PropTypes.element,
  className: PropTypes.string,
  classNames: PropTypes.shape({
    input: PropTypes.string,
    iconRight: PropTypes.string,
  }),
  options: PropTypes.object,
  placeholder: PropTypes.string,
  value: PropTypes.array,
  readOnly: PropTypes.bool,
  isShowInput: PropTypes.bool,
  disableClose: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Datepicker;
