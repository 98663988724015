import * as Yup from "yup";
import * as validation from "../../../../utils/validation";

export const FN = {
  FEEDBACK_TYPE: "feedback_type",
  RESPONSE: "response",
  FEEDBACK_ID:"feedback_id"
};

export const getFormInitialValuesPrepareFeedback = (data = {}) => {
  const feedbackType = data?.response_type;
  const response = data?.response;
  const feedbackId = data?.id
  return {
    [FN.FEEDBACK_TYPE]: feedbackType || "",
    [FN.RESPONSE]: response || "",
	[FN.FEEDBACK_ID] :feedbackId || ""
  };
};

export const getFormValidationSchemaPrepareFeedback = (withRequired = true) => {
  return Yup.object().shape({
    [FN.FEEDBACK_TYPE]: validation.isRequiredString({ withRequired }),
    [FN.RESPONSE]: validation.isRequiredString({ withRequired:false }),
  });
};

export const Enums = {
  feedback: [
    { value: "Comment", label: "Comment" },
    { value: "NO Comment", label: "No Comment" },
    { value: "Intervention", label: "Intervention" },
  ],
};
