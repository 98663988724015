import {  useTokensFromStorage } from "../../contexts/token.context";
import { useMemo } from "react";

export default function CheckUserAccess(props) {
  const { children, invert = false, fallback = null } = props;
  const { tokens } = useTokensFromStorage();

  const isHadAccess = useMemo(() => {
    const isTokenAvailable = !!tokens; // Check if token is available
    let _isHadAccess = isTokenAvailable;
    if (invert) {
      _isHadAccess = !_isHadAccess;
    }

    return _isHadAccess;
  }, [invert, tokens]);

  if (!isHadAccess) return fallback;

  return children;
}
