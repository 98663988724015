export default function DownloadThinIcon(props) {
  return (
    <svg width={14} height={13} viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7 8.33333V1M7 8.33333L4.6 5.88889M7 8.33333L9.4 5.88889M1 9.55556L1.3726 11.0742C1.43749 11.3386 1.58729 11.5733 1.7982 11.7411C2.0091 11.9088 2.26901 12 2.5366 12H11.4634C11.731 12 11.9909 11.9088 12.2018 11.7411C12.4127 11.5733 12.5625 11.3386 12.6274 11.0742L13 9.55556"
        stroke="currentcolor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
