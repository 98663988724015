import Drawer from "../../../../components/drawer";
import { useCommitteeFeedbackPageContext } from "../../provider";
import { Close2Icon } from "../../../../components/vectors/close2-icon";
import cx from "../../../../utils/class-names";
import {
  generateFullName,
  generateShortName,
} from "../../../../utils/generate-names";

const TaskCommentDrawer = ({ item }) => {
  const { isTaskCommentDrawer, handleCloseCommentTaskDrawer, taskFeedback } =
    useCommitteeFeedbackPageContext();
  return (
    <Drawer
      open={isTaskCommentDrawer}
      classNames={{
        content:
          "w-[600px] flex  flex-col h-full tab-xs:w-[300px] drop-shadow overflow-scroll",
      }}
    >
      <div className="bg-primary-text">
        <div className="w-full flex justify-end">
          <button className="mt-5 mr-5" onClick={handleCloseCommentTaskDrawer}>
            <Close2Icon className="h-5 w-5 text-secondary-0" />
          </button>
        </div>
        <div className="w-full">
          <div className="font-Poppins text-base m-5 font-medium leading-6 font-sans text-secondary-0">
            Task Comments
          </div>
        </div>
      </div>
      {Array.isArray(taskFeedback) &&
        taskFeedback?.map((feedback) => {
          const shortName = generateShortName(feedback?.member);
          const fullName = generateFullName(feedback?.member);

          return (
            <div className="mx-3 mt-3">
              <div className="flex align-middle">
                <div
                  className={cx(
                    "border-[#DB18BC] border border-opacity-25 flex items-center justify-center rounded-full cursor-pointer bg-orange-200 h-7 w-7 mr-2 "
                  )}
                >
                  <label
                    className={cx(
                      "font-Poppins font-medium tracking-normal leading-6 text-[10px] text-secondary-50 cursor-pointer"
                    )}
                  >
                    {shortName}
                  </label>
                </div>
                <h1 className="font-Poppins text-xs font-semibold leading-6 tracking-normal text-left">
                  {fullName}
                </h1>
              </div>
              <div
                className="font-Poppins text-xs font-normal leading-6 tracking-normal text-left ml-9"
                dangerouslySetInnerHTML={{ __html: feedback?.comment }}
              />
            </div>
          );
        })}
    </Drawer>
  );
};

export default TaskCommentDrawer;
