import { Form, Formik } from "formik";
import Button from "../../../../components/button";
import Drawer from "../../../../components/drawer";
import { useUpcomingRegulationPageContext } from "../../provider";
import FormControl from "../../../../components/form-control";
import InputLabel from "../../../../components/input-label";
import { FN } from "./form-helper";
import { Close2Icon } from "../../../../components/vectors/close2-icon";
import { FormikTextarea } from "../../../../components/textarea";
import { FormikSelect } from "../../../../components/select";
import constants from "../../../../constants";
import { FormikInput } from "../../../../components/input";
import { FormikDatepicker } from "../../../../components/datepicker";
import DownloadThinIcon from "../../../../components/vectors/download-thin-icon";
import cx from "../../../../utils/class-names";
import Divider from "../../../../components/divider";
import BinIcon from "../../../../components/vectors/bin-icon";
import UploadIcon from "../../../../components/vectors/upload-icon";

const AddRegulationDrawer = () => {
  const {
    initialValues,
    isRegulationDrawerOpen,
    getValidationSchema,
    getCommitteeQuery,
    closeRegulationDrawer,
    handleOpenUpload,
    handleDocumentUpload,
    fileUploadRef,
    file,
    handleDownloadFile,
    handleDeleteFile,
    handleAddRegulation,
    handleUpdateRegulation,
    handleDownloadCircular,
    handleDeleteCircular,
  } = useUpcomingRegulationPageContext();
  const hasRegulationId = !!initialValues?.upcoming_regulation_id;
  const handleSubmit = hasRegulationId
  ? handleUpdateRegulation(initialValues?.upcoming_regulation_id)
  : handleAddRegulation;
  return (
    <Drawer
      open={isRegulationDrawerOpen}
      classNames={{
        content:
          "w-[750px] flex overflow-hidden flex-col h-full tab-xs:w-[300px] drop-shadow",
      }}
    >
      <div className="bg-primary-text">
        <div className="w-full flex justify-end">
          <button className="mt-5 mr-5" onClick={closeRegulationDrawer}>
            <Close2Icon className="h-5 w-5 text-secondary-0" />
          </button>
        </div>
        <div className="w-full">
          <div className="text-base m-5 font-medium leading-6 font-sans text-secondary-0">
            Regulation
          </div>
        </div>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={getValidationSchema}
        onSubmit={handleSubmit}
      >
        {() => {
          return (
            <Form className="flex flex-1 flex-col overflow-y-auto justify-between">
              <div className="flex-col p-4 gap-2">
                <div className="grid grid-cols-2 gap-x-2">
                  <FormControl>
                    <InputLabel
                      className="flex-1"
                      required
                      htmlFor={FN.COMMITTEE}
                    >
                      Committee Session
                    </InputLabel>
                    <FormikSelect
                      data={getCommitteeQuery?.data || []}
                      id={FN.COMMITTEE}
                      name={FN.COMMITTEE}
                      lableString="name"
                      valueString="id"
                      passValueInt={true}
                      virtualize={
                        getCommitteeQuery?.data?.length >=
                        constants.VIRTUALIZED_ENABLE_LIMIT
                      }
                      classNames={{
                        root: "mb-1",
                        input: "ring-black-100",
                        popperContent: "max-w-[560px]",
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <InputLabel
                      className="flex-1"
                      required
                      htmlFor={FN.TARGET_DATE}
                    >
                      Target Date
                    </InputLabel>
                    <FormikDatepicker
                      id={FN.TARGET_DATE}
                      name={FN.TARGET_DATE}
                    />
                  </FormControl>
                </div>
                <FormControl>
                  <InputLabel
                    className="flex-1"
                    htmlFor={FN.IMO_RESOLUTION_REGARDING_APPROVAL_OF_AMENDMENTS}
                  >
                    IMO Resolution regarding Approval of amendments
                  </InputLabel>
                  <FormikTextarea
                    id={FN.IMO_RESOLUTION_REGARDING_APPROVAL_OF_AMENDMENTS}
                    name={FN.IMO_RESOLUTION_REGARDING_APPROVAL_OF_AMENDMENTS}
                    minRows={3}
                  />
                </FormControl>
                <div className="grid grid-cols-2 gap-x-2">
                  <FormControl>
                    <InputLabel
                      className="flex-1"
                      htmlFor={FN.CONVENTION}
                    >
                      Convention
                    </InputLabel>
                    <FormikInput id={FN.CONVENTION} name={FN.CONVENTION} />
                  </FormControl>
                  <FormControl>
                    <InputLabel
                      className="flex-1"
                      htmlFor={FN.CIRCULAR_TO_BE_AMENDED}
                    >
                      Regulation / Resolution / Circular to be amended
                    </InputLabel>
                    <FormikInput
                      id={FN.CIRCULAR_TO_BE_AMENDED}
                      name={FN.CIRCULAR_TO_BE_AMENDED}
                    />
                  </FormControl>
                </div>
                <div className="grid grid-cols-2 gap-x-2">
                  <FormControl>
                    <InputLabel
                      className="flex-1"
                      htmlFor={FN.ENTRY_INTO_FORCE}
                    >
                      Entry into Force
                    </InputLabel>
                    <FormikInput
                      id={FN.ENTRY_INTO_FORCE}
                      name={FN.ENTRY_INTO_FORCE}
                    />
                  </FormControl>
                  <FormControl>
                    <InputLabel
                      className="flex-1"
                      htmlFor={FN.BRANCH_OF_DGS_TO_ISSUE_CIRCULAR}
                    >
                      Wing / Branch of DGS to issue Circular / MSN / Order
                    </InputLabel>
                    <FormikInput
                      id={FN.BRANCH_OF_DGS_TO_ISSUE_CIRCULAR}
                      name={FN.BRANCH_OF_DGS_TO_ISSUE_CIRCULAR}
                    />
                  </FormControl>
                </div>
                <FormControl>
                  <InputLabel
                    className="flex-1"
                    htmlFor={FN.IMO_RESOLUTION_REGARDING_ADOPTION_OF_AMENDMENTS}
                  >
                    IMO Resolution regarding adoption of amendments
                  </InputLabel>
                  <FormikInput
                    id={FN.IMO_RESOLUTION_REGARDING_ADOPTION_OF_AMENDMENTS}
                    name={FN.IMO_RESOLUTION_REGARDING_ADOPTION_OF_AMENDMENTS}
                  />
                </FormControl>
                <FormControl>
                  <InputLabel
                    className="flex-1"
                    htmlFor={FN.DGS_CIRCULAR_ISSUED}
                  >
                    DGS Circular/MSN/Order Issued
                  </InputLabel>
                  <FormikInput
                    id={FN.DGS_CIRCULAR_ISSUED}
                    name={FN.DGS_CIRCULAR_ISSUED}
                  />
                </FormControl>
                <div className="flex items-center">
                  {!file ? (
                    <>
                      <Button
                        variant="link"
                        color="primary"
                        className={cx("font-semibold py-0 px-0 m-0 leading-none")}
                        onClick={handleOpenUpload(fileUploadRef)}
                      >
                        <span className="flex items-center gap-2">
                          <UploadIcon />
                          <span className="text-xs text-primary-button font-semibold mt-1">
                            Upload
                          </span>
                        </span>
                      </Button>
                      <input
                        type="file"
                        ref={fileUploadRef}
                        className="hidden"
                        onChange={handleDocumentUpload}
                      />
                    </>
                  ) : (
                    !file.s3_key && (
                      <>
                        <Button
                          variant="link"
                          color="primary"
                          className={cx("font-semibold py-0 px-0 m-0 leading-none")}
                          onClick={handleOpenUpload(fileUploadRef)}
                        >
                          <span className="flex items-center gap-2">
                            <UploadIcon />
                            <span className="text-xs text-primary-button font-semibold mt-1">
                              Upload
                            </span>
                          </span>
                        </Button>
                        <input
                          type="file"
                          ref={fileUploadRef}
                          className="hidden"
                          onChange={handleDocumentUpload}
                        />
                      </>
                    )
                  )}
                </div>
                {file?.name && (
                  <div className="relative flex flex-col tab-sm:flex-1 mt-2">
                    <div className="w-full p-1 rounded bg-[#F3F4F5] flex justify-between border-[2px] border-[#F8F8F8;]">
                      <div className="flex gap-2 items-center max-w-[90%] ml-2">
                        <div className="flex flex-col">
                          <p className="font-normal text-black-100 text-xs leading-[13px] break-all">
                            {file?.name}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <DownloadThinIcon
                          className={cx(
                            "text-primary-button ml-1.5 w-4 h-4 cursor-pointer"
                          )}
                          onClick={handleDownloadFile(file)}
                        />
                        <Divider className="w-[2px] h-full mx-3.5" />
                        <BinIcon
                          className={cx("w-4 h-4 cursor-pointer self-center")}
                          onClick={handleDeleteFile}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {file?.s3_key && (
                  <div className="relative flex flex-col tab-sm:flex-1 mt-2">
                    <div className="w-full p-1 rounded bg-[#F3F4F5] flex justify-between border-[2px] border-[#F8F8F8;]">
                      <div className="flex gap-2 items-center max-w-[90%] ml-2">
                        <div className="flex flex-col">
                          <p className="font-normal text-black-100 text-xs leading-[13px] break-all">
                            {file?.document_name}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <DownloadThinIcon
                          className={cx(
                            "text-primary-button ml-1.5 w-4 h-4 cursor-pointer"
                          )}
                          onClick={handleDownloadCircular(initialValues?.upcoming_regulation_id, file?.document_name)}
                        />
                        <Divider className="w-[2px] h-full mx-3.5" />
                        <BinIcon
                          className={cx("w-4 h-4 cursor-pointer self-center")}
                          onClick={handleDeleteCircular(initialValues?.upcoming_regulation_id)}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex items-end px-8 justify-end w-full my-4 gap-x-2">
                <Button
                  type="submit"
                  variant="solid"
                  color="primary"
                  className="py-2 px-5 flex items-center text-xs font-semibold ml-5 action-auditor-save"
                >
                  {hasRegulationId ? "Update" : "+ Add"}
                </Button>
                <Button
                  variant="outline"
                  color="primary"
                  className="py-2 px-5 flex items-center text-xs font-semibold ml-5 action-auditor-save"
                  onClick={closeRegulationDrawer}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Drawer>
  );
};
export default AddRegulationDrawer;
