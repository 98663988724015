import { createContext, useContext, useMemo, useCallback } from "react";
import cx from "../../utils/class-names";
import is from "../../utils/is";
import { useControllableState } from "../../hooks/use-controllable-state";
import PropTypes from "prop-types";

const AccordionContext = createContext({});

const Accordion = (props) => {
  const { children, expanded, defaultValue, onChange, className = "", ...restProps } = props;

  const [value, updateValue] = useControllableState({
    value: expanded,
    onChange: onChange,
    defaultValue: defaultValue,
  });

  const handleChange = useCallback(() => {
    updateValue((p) => !p);
  }, [updateValue]);

  const values = useMemo(() => {
    return {
      expanded: value,
      onChange: handleChange,
    };
  }, [handleChange, value]);

  return (
    <AccordionContext.Provider value={values}>
      <div {...restProps} className={cx("accordion", className)}>
        {is.function(children) ? children(values) : children}
      </div>
    </AccordionContext.Provider>
  );
};

const AccordionHeader = function Header(props) {
  const { children, className = "", ...restProps } = props;
  const { onChange } = useContext(AccordionContext);
  const handleClick = () => {
    onChange && onChange();
  };
  return (
    <div onClick={handleClick} {...restProps} className={cx("accordion-header", className)}>
      {children}
    </div>
  );
};

const AccordionContent = function Content(props) {
  const { children, className = "", ...restProps } = props;

  return (
    <div {...restProps} className={cx("accordion-content", className)}>
      {children}
    </div>
  );
};

Accordion.Header = AccordionHeader;
Accordion.Content = AccordionContent;

Accordion.defaultProps = {
  defaultValue: false,
  onChange: null,
};

Accordion.propTypes = {
  defaultValue: PropTypes.bool,
  onChange: PropTypes.func,
  expanded: PropTypes.bool,
};

export default Accordion;
