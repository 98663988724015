import Popper from "../popper";
import cx from "../../utils/class-names";
import PropTypes from "prop-types";

export default function Modal(props) {
  const { children, onOverlayClick, className, ...restProps } = props;
  return (
    <Popper portal={true} {...restProps}>
      <Popper.Content className={cx("relative z-50 w-full h-full", className)}>
        <div className="fixed inset-0 bg-black/30 -z-1" aria-hidden="true" onClick={onOverlayClick} />
        <div className="w-full h-full inline-flex items-center justify-center ">
          <div className="mx-auto bg-white shadow-[0px_4px_16px_0px_#00000014] rounded-md">{children}</div>
        </div>
      </Popper.Content>
    </Popper>
  );
}

Modal.defaultProps = {
  className: "",
};

Modal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
