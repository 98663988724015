import { FieldArray, Form, Formik } from "formik";
import React, { Fragment } from "react";
import InputLabel from "../../../components/input-label";
import { FN } from "../components/form-helper";
import Button from "../../../components/button";
import { useCreatePostMeetingPageContext } from "../provider";
import AgendaConsideration from "./agenda-consideration";
import Agenda from "./agenda";
import Group from "./group";
import MeetingDetails from "./meeting-details";
import { generateFullName } from "../../../utils/generate-names";
import cx from "../../../utils/class-names";
import ScrollToFormError from "../../../components/scroll-to-form-error";
const ReportForm = () => {
  const {
    initialValues,
    handleAddAgenda,
    getMemberQuery,
    formRef,
    handleSubmit,
    handleSaveButton,
    handleSubmitButton,
    handlePostMeetingPreview,
    reportValidationSchema,
    isSubmitted,
    isFormSubmitted,
    isLeader,
    isCoordinator,
    isGroupLeader,
    isAdmin,
    footerRef,
    containerRef
  } = useCreatePostMeetingPageContext();
  const isLeaderOrCoordinator = isCoordinator || isLeader || isAdmin ? false : true;
  const isDisabledForm = isFormSubmitted || isLeaderOrCoordinator;
  const isLeaderOrGroupLeader = isCoordinator || isLeader || isGroupLeader || isAdmin ? false : true;
  const isDisabledTask = isFormSubmitted || isLeaderOrGroupLeader;
  const isSave = isFormSubmitted || isLeaderOrGroupLeader? true : false;


  const isDisabledSubmitButton = isFormSubmitted === null || isLeaderOrCoordinator || isFormSubmitted 

  const members = getMemberQuery?.data?.data?.data;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={isSubmitted ? reportValidationSchema : null}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {(formik) => {
        formRef.current = formik;
        const { values } = formik;
        const memberList = members?.map((item) => {
          const full_name = generateFullName(item);
          return { full_name, ...item };
        });
        const selectedMembers = values[FN.LIST_OF_INDIAN_DELEGATION] ?? [];
        const filterMembers = memberList?.filter(
          (item) => item.member_id !== parseInt(selectedMembers)
        );

        return (
          <Form className=" overflow-y-auto px-4 pt-4" ref={containerRef}>
            <ScrollToFormError>
            <MeetingDetails
              memberList={filterMembers}
              selectedMembers={selectedMembers}
              values={values}
              isDisabledForm={isDisabledForm}
            />
            <InputLabel
              className="flex-1 text-label-100 ml-4 mb-0"
              htmlFor={FN.AGENDA_DETAILS.AGENDAS}
            >
              Agenda under consideration
            </InputLabel>
            <div className="m-4 mt-1 border border-solid border-black-100 border-1 rounded-md overflow-auto">
              <div className="grid grid-cols-10  bg-[#0E4374] p-2 gap-3">
                <div className="col-span-7 text-white text-xs">Agenda</div>
                <div className="col-span-1 text-white text-xs">
                  Total Number of Paper under consideration
                </div>
                <div className="col-span-1 text-white text-xs">
                  Total Number of Paper Submission by India in this session
                </div>
                <div className="col-span-1 text-white text-xs">
                  Total number of Paper from India in Past Session brought
                  forward and Considered
                </div>
              </div>
              <FieldArray
                name={FN.AGENDA_DETAILS.AGENDAS}
                render={(arrayHelpers, index) => {
                  return (
                    <div className="flex flex-col p-2" key={index}>
                      {initialValues?.[FN.AGENDA_DETAILS.AGENDAS]?.map(
                        (item, itemIndex) => {
                          return (
                            <AgendaConsideration
                              arrayHelpers={arrayHelpers}
                              itemIndex={itemIndex}
                              key={itemIndex}
                              isDisabledForm={isDisabledForm}
                            />
                          );
                        }
                      )}
                      <Button
                        variant="link"
                        color="primary"
                        className="py-2 px-3.5 w-20 flex items-center text-xs font-semibold text-primary-button hidden"
                        onClick={handleAddAgenda(arrayHelpers, values)}
                      >
                        + Add
                      </Button>
                    </div>
                  );
                }}
              />
            </div>
            <FieldArray
              name={FN.AGENDA_MEMBER_DETAILS.AGENDA_MEMBERS}
              render={(arrayHelpers, index) => {
                return (
                  <div className="flex flex-col mb-0" key={index}>
                    <div className="flex justify-between">
                      <InputLabel
                        className="flex-1 text-label-100 ml-4 mb-1"
                        htmlFor={FN.AGENDA_DETAILS.AGENDAS}
                      >
                        Agenda
                      </InputLabel>
                    </div>
                    {values?.[FN.AGENDA_MEMBER_DETAILS.AGENDA_MEMBERS]?.map(
                      (item, itemIndex) => {
                        return (
                          <Fragment key={itemIndex}>
                            <Agenda
                              arrayHelpers={arrayHelpers}
                              itemIndex={itemIndex}
                            />
                          </Fragment>
                        );
                      }
                    )}
                    <Button
                      variant="link"
                      color="primary"
                      className="py-2 px-3.5 w-20 items-center text-xs font-semibold text-primary-button hidden"
                      onClick={handleAddAgenda(arrayHelpers, values)}
                    >
                      + Add
                    </Button>
                  </div>
                );
              }}
            />

            <FieldArray
              name={FN.GROUPS.ISSUE}
              render={(arrayHelpers, index) => {
                const groupsWithTasks = values?.[FN.GROUPS.GROUP];
                return (
                  <div className="flex flex-col mb-2" key={index}>
                    {Array.isArray(groupsWithTasks) &&
                      groupsWithTasks?.map((group, itemIndex) => {
                        return (
                          <Group
                            arrayHelpers={arrayHelpers}
                            itemIndex={itemIndex}
                            key={itemIndex}
                            group={group}
                            isDisabledTask={isDisabledTask}
                          />
                        );
                      })}
                    <Button
                      variant="link"
                      color="primary"
                      className="py-2 px-3.5 w-20 flex items-center text-xs font-semibold text-primary-button hidden"
                      onClick={handleAddAgenda(arrayHelpers, values)}
                    >
                      + Add
                    </Button>
                  </div>
                );
              }}
            />
            <footer className="flex justify-end gap-3 p-6 sticky bottom-0 bg-secondary-0 transition duration-200 ease-in-out translate-y-full" ref={footerRef}>
              <Button
                variant="outline"
                color="primary"
                className={cx(
                  "py-2 px-3.5 flex items-center text-xs font-semibold text-primary-button hover:bg-primary-button hover:text-secondary-0"
                )}
                onClick={handleSaveButton(formik)}
                disabled={isSave}
              >
                Save
              </Button>
                <Button
                  variant="solid"
                  color="primary"
                  className={cx(
                    `y-2 px-3.5 w-20 flex items-center text-xs font-semibold`,
                    isDisabledSubmitButton ? "text-primary-button" : " text-white"
                  )}
                  onClick={handleSubmitButton(formik)}
                  disabled={isDisabledSubmitButton }
                >
                  Submit
                </Button>
                <Button
                  variant="solid"
                  color="primary"
                  className="py-2 px-3.5 flex items-center text-xs font-semibold action-auditor-save"
                  onClick={handlePostMeetingPreview}
                >
                  Prepare Meeting Report
                </Button>
            </footer>
            </ScrollToFormError>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ReportForm;
