import cx from "../../utils/class-names";
import PropTypes from "prop-types";

const Label = (props) => {
  const { children, className, ...restProps } = props;
  return (
    <div className={cx("label font-inter", className)} {...restProps}>
      {children}
    </div>
  );
};

Label.defaultProps = {
  className: "",
};

Label.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Label;
