import { withConfig, getApi } from "./index";
const api = getApi("/groups");

export const updateGroup = withConfig((data, conf) => {
  const { id, ...payload } = data;
  const config = conf({
    url: `${id}`,
    method: "PUT",
    data: payload,
  });
  return api(config);
});

export const createTask = withConfig((data, conf) => {
  const { id, ...payload } = data;
  const config = conf({
    url: `${id}/tasks`,
    method: "POST",
    data: payload,
  });
  return api(config);
});

export const deleteGroup = withConfig((data, conf) => {
  const { id, ...params } = data;
  const config = conf({
    url: `${id}`,
    method: "DELETE",
    params: params,
  });
  return api(config);
});

export const getTaskList = withConfig((data, conf) => {
  const { id, ...payload } = data;
  const config = conf({
    url: `${id}/tasks`,
    method: "GET",
    data: payload,
  });
  return api(config);
});

export const getTaskFeedback = withConfig((data, conf) => {
  const { id, ...params } = data;
  const config = conf({
    url: `${id}/tasks-feedback`,
    method: "GET",
    params: params,
  });
  return api(config);
});

export const createGroupLeadersFeedback = withConfig((data, conf) => {
  const { groupId, ...payload } = data;
  const config = conf({
    url: `${groupId}/feedback`,
    method: "POST",
    data: payload,
  });
  return api(config);
});

export const getTask = withConfig((data, conf) => {
  const { groupId, taskId, ...params } = data;
  const config = conf({
    url: `${groupId}/tasks/${taskId}`,
    method: "GET",
    params: params,
  });
  return api(config);
});

export const getGroupLeadersFeedback = withConfig((data, conf) => {
  const { groupId, taskId, ...params } = data;
  const config = conf({
    url: `${groupId}/feedback`,
    method: "GET",
    params: params,
  });
  return api(config);
});

export const updateGroupPaperFeedback = withConfig((data, conf) => {
  const { paperId, groupId, ...payload } = data;
  const config = conf({
    url: `${groupId}/papers/${paperId}/status`,
    method: "POST",
    data: payload,
  });
  return api(config);
});
export const getGroupPaperFeedback = withConfig((data, conf) => {
  const { paperId, groupId, ...payload } = data;
  const config = conf({
    url: `${groupId}/papers/${paperId}/status`,
    method: "GET",
    data: payload,
  });
  return api(config);
});

export const updateFeedback = withConfig((data, conf) => {
  const { feedbackId, ...payload } = data;
  const config = conf({
    url: `feedback/${feedbackId}`,
    method: "PUT",
    data: payload,
  });
  return api(config);
});
