import { Form, Formik } from "formik";
import React from "react";
import FormControl from "../../../../components/form-control";
import { FormikTextarea } from "../../../../components/textarea";
import Button from "../../../../components/button";
import { FN } from "./formhelper";
import { useCommitteeFeedbackPageContext } from "../../provider";
import cx from "../../../../utils/class-names";
import Divider from "../../../../components/divider";
import {
  generateFullName,
  generateShortName,
} from "../../../../utils/generate-names";

const FeedbackForm = ({ item }) => {
  const {
    addFeedbackInitialValues,
    addFeedbackSchema,
    handleAddFeedback,
    feedbackRef,
  } = useCommitteeFeedbackPageContext();

  const comments = item?.comments;

  return (
    <main>
      <Formik
        initialValues={addFeedbackInitialValues}
        validationSchema={addFeedbackSchema}
        onSubmit={handleAddFeedback(item.id)}
      >
        {(formik) => {
          feedbackRef.current = formik;
          return (
            <Form className="my-2">
              <FormControl>
                <FormikTextarea
                  id={FN.FEEDBACK}
                  name={FN.FEEDBACK}
                  minRows={2}
                  className="bg-blue-800"
                />
              </FormControl>
              <div className="flex justify-end">
                <Button
                  variant="outline"
                  color="primary"
                  className="py-2 px-3.5 flex items-center text-xs font-semibold  text-primary-button"
                  onClick={formik.handleReset}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="solid"
                  color="primary"
                  className="py-2 px-3.5 flex items-center text-xs font-semibold ml-5 action-auditor-save"
                >
                  Add
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
      <Divider className=" w-[100%] bg-gray-600 bg-opacity-20 mb-2" />
      {Array.isArray(comments) &&
        comments?.map((feedback) => {
          const shortName = generateShortName(feedback?.member);
          const fullName = generateFullName(feedback?.member);
          return (
            <div className="mb-3">
              <div className="flex align-middle">
                <div
                  className={cx(
                    "border-[#DB18BC] border border-opacity-25 flex items-center justify-center rounded-full cursor-pointer bg-orange-200 h-7 w-7 mr-2 "
                  )}
                >
                  <label
                    className={cx(
                      "font-Poppins font-medium tracking-normal leading-6 text-[10px] text-secondary-50 cursor-pointer"
                    )}
                  >
                    {shortName}
                  </label>
                </div>
                <h1 className="font-Poppins font-Poppins text-xs font-semibold leading-6 tracking-normal text-left">
                  {fullName}
                </h1>
              </div>
              <p className="font-Poppins text-xs font-normal leading-6 tracking-normal text-left ml-9">
                {feedback?.comment}
              </p>
            </div>
          );
        })}
    </main>
  );
};

export default FeedbackForm;
