import { useRef, useEffect, useState } from "react";

export default function useRows(data = [], loadingRowCount) {
  const [rows, setRows] = useState({});
  const rowRef = useRef({});

  useEffect(() => {
    rowRef.current = rows;
    let _rows = {};
    if (data.length > 0) {
      _rows = data.reduce((prev, current, currentIndex) => {
        prev[currentIndex] = current;
        return prev;
      }, {});
    }

    //NOTE:added to show skeleton _rows
    if (loadingRowCount > 0) {
      let rowLength = Object.keys(_rows).length;
      _rows = Array.from({ length: loadingRowCount }).reduce((prev, current, currentIndex) => {
        prev[rowLength + currentIndex] = { isSkeletonRow: true };
        return prev;
      }, _rows);
    }

    setRows(_rows);
    //NOTE: dont include 'rows' as a dependency or it will go in ifinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loadingRowCount]);

  const rowCount = Object.keys(rows).length;
  const prevRows = rowRef.current;
  const prevRowCount = Object.keys(rowRef.current).length;

  return {
    rows,
    rowCount,
    prevRows,
    prevRowCount,
  };
}
