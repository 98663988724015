import { useRef, useState, createContext, useCallback, useContext, useMemo, useEffect } from "react";
import cx from "../../utils/class-names";
import WizardNav from "./wizard-nav";
import WizardNavItem from "./wizard-nav-item";
import WizardContent from "./wizard-content";
import WizardContentItem from "./wizard-content-item";
import { useControllableState } from "../../hooks/use-controllable-state";
import PropTypes from "prop-types";

import is from "../../utils/is";

const WizardContext = createContext();

export const useWizard = function () {
  const context = useContext(WizardContext);
  if (context === undefined) {
    throw new Error("useWizard must be used within a Wizard");
  }
  return context;
};

function Wizard(props) {
  const { children, className, value, defaultValue = "", onChange, ...restProps } = props;

  const [ids, setIds] = useState([]);
  const [inViewIds, setInViewIds] = useState([]);

  const contentContainerRef = useRef(null);

  const [active, setActive] = useControllableState({
    value,
    defaultValue: defaultValue,
    onChange,
  });

  const registerId = useCallback((id) => {
    setIds((_ids) => [..._ids, id]);
  }, []);

  const updateInviewIds = useCallback((isInView, id) => {
    if (isInView) {
      setInViewIds((_ids) => [..._ids, id]);
    } else {
      setInViewIds((_ids) => _ids.filter((_id) => id !== _id));
    }
  }, []);

  const unregisterId = useCallback((id) => {
    setIds((_ids) => [..._ids, id]);
  }, []);

  const scrollToContentItem = useCallback((id) => {
    let element = document.querySelector(`[data-wizard-contentitem-id="${id}"]`);
    if (!element) return;
    element.scrollIntoView({
      block: "start",
    });
  }, []);

  const scrollToNavItem = useCallback((id) => {
    let element = document.querySelector(`[data-wizard-navitem-id="${id}"]`);
    if (!element) return;
    element.scrollIntoView({
      block: "start",
    });
  }, []);

  const handleWizardNavItemClick = useCallback(
    (id) => {
      scrollToContentItem(id);
      scrollToNavItem(id);
      setActive(id);
    },
    [scrollToContentItem, scrollToNavItem, setActive]
  );

  const isActive = useCallback(
    (id) => {
      return active === id;
    },
    [active]
  );

  useEffect(() => {
    if (defaultValue) {
      scrollToContentItem(defaultValue);
    }
    //needed contentContainerRef?.current for proper execution
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, scrollToContentItem, contentContainerRef?.current]);

  useEffect(() => {
    let idsInSequence = ids.filter((_id) => inViewIds.includes(_id));
    setActive(idsInSequence[0]);
    scrollToNavItem(idsInSequence[0]);
  }, [active, ids, inViewIds, scrollToNavItem, setActive]);

  const values = useMemo(() => {
    return {
      scrollToContentItem,
      active,
      setActive,
      handleWizardNavItemClick,
      isActive,
      registerId,
      unregisterId,
      contentContainerRef,
      updateInviewIds,
    };
  }, [active, handleWizardNavItemClick, isActive, registerId, scrollToContentItem, setActive, unregisterId, updateInviewIds]);

  return (
    <WizardContext.Provider value={values}>
      <div className={cx("wizard flex-1 flex pl-4 pr-7 my-2 overflow-auto", className)} {...restProps}>
        {is.function(children) ? children(values) : children}
      </div>
    </WizardContext.Provider>
  );
}

Wizard.Nav = WizardNav;
Wizard.NavItem = WizardNavItem;
Wizard.Content = WizardContent;
Wizard.ContentItem = WizardContentItem;

Wizard.defaultProps = {
  children: null,
  className: "",
  defaultValue: "",
};

Wizard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
};

export default Wizard;
