import withHOC from "../../utils/with-hoc";
import { MembersPageProvider } from "./provider";
import HeaderSection from "./components/header-section";
import RegisterMemberDrawer from "./components/register-member-drawer";
import { useUserFromStorage } from "../../contexts/user.context";
import { Members } from "./components/members";
import { HeaderRight } from "../../layouts/dashboard/header";
import HeaderTitle from "../../components/header-title";

function MembersPage() {
  const { user } = useUserFromStorage();
  const isAdmin = user?.is_admin;
  return (
    <>
      <div className="flex flex-1 flex-col overflow-hidden">
        <HeaderRight>
          <HeaderTitle>Members</HeaderTitle>
        </HeaderRight>
        <HeaderSection isAdmin={isAdmin} />
        <Members isAdmin={isAdmin} />
        <RegisterMemberDrawer isAdmin={isAdmin} />
      </div>
    </>
  );
}

export default withHOC(MembersPageProvider, MembersPage);
