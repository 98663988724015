import { useCallback, useMemo, useRef, useState } from "react";
import generateContext from "../../utils/generate-context";
import { useAsync } from "@react-org/hooks";
import * as ListsApi from "../../apis/lists.api";
import * as CommitteeApi from "../../apis/committee.api";
import { useParams } from "react-router-dom";
import { getFormInitialValues } from "./components/formhelper";
import useCustomNavigate from "../../hooks/use-custom-navigate";
function useGroupFeedbackListingPage(props) {
  const mainFormRef = useRef(null);
  const { committeeId, groupId } = useParams();
  const [agenda, setAgenda] = useState(null);
  const [paper, setPaper] = useState(null);
  const { goBack, goTo } = useCustomNavigate();
  const getGroupDetailsQuery = useAsync(
    useCallback(
      (payload) =>
        CommitteeApi.getGroupDetails({
          committeeId: committeeId,
          groupId: groupId,
          ...payload,
        }),
      [committeeId, groupId]
    ),
    {
      immediate: true,
    }
  );
  const getAgendaQuery = useAsync(
    useCallback(
      (payload) => ListsApi.getAgenda({ limit: 100, ...payload }),
      []
    ),
    {
      select: (res) => {
        return { data: res.data.data, paginate: res.data.paging };
      },
      immediate: true,
    }
  );

  const handleFeedbackClick = useCallback(
    (agendaId, paperId) => () => {
      goTo(
        `/committee/${committeeId}/agenda/${agendaId}/paper/${paperId}/feedback`
      );
    },
    [committeeId, goTo]
  );

  const initialValues = useCallback(() => {
    getFormInitialValues();
  }, []);

  const handlePrepareFeedbackClick = useCallback(
    (agendaId,paperId) => () => {
      goTo(
        `/committee/${committeeId}/groups/${groupId}/agenda/${agendaId}/paper/${paperId}/feedback`
      );
    },
    [committeeId, goTo, groupId]
  );

  const handleAgendaChange = useCallback((id) => {
    setAgenda(Number(id));
    setPaper(null);
  }, []);

  const handlePaperChange = useCallback((id) => {
    setPaper(Number(id));
  }, []);

  return useMemo(() => {
    return {
      mainFormRef,
      getAgendaQuery,
      handleFeedbackClick,
      initialValues,
      agenda,
      paper,
      handleAgendaChange,
      handlePaperChange,
      handlePrepareFeedbackClick,
      goBack,
      getGroupDetailsQuery,
    };
  }, [
    getAgendaQuery,
    handleFeedbackClick,
    initialValues,
    agenda,
    paper,
    handleAgendaChange,
    handlePaperChange,
    handlePrepareFeedbackClick,
    goBack,
    getGroupDetailsQuery,
  ]);
}
export const [
  GroupFeedbackListingPageProvider,
  useGroupFeedbackListingPageContext,
] = generateContext(useGroupFeedbackListingPage);
