import { useCallback, useMemo, useRef, useState } from "react";
import generateContext from "../../utils/generate-context";
import { useAsync } from "@react-org/hooks";
import * as PapersApi from "../../apis/papers.api";
import useCustomNavigate from "../../hooks/use-custom-navigate";
import Const from "../../constants/index";
import { useNotificationModalContext } from "../../components/notification-modal/provider";
import MESSAGES from "../../constants/messages";
import generateFileUrl from "../../utils/generate-file-url";
import { downloadOrPreviewFileUrl } from "../../utils/download-or-preview-file-url";

const useReferenceDocumentsLisitingPage = () => {
  const [searchText, setSearchText] = useState("");
  const { goBack } = useCustomNavigate();
  const listRef = useRef();
  const notificationModal = useNotificationModalContext();

  const getReferenceDocumentsListingQuery = useAsync(
    useCallback((payload) => {
      return PapersApi.getReferenceDocuments({ ...payload });
    }, []),
    {
      initialState: { data: [] },
      infinite: true,
      select: (res) => {
        listRef?.current?.props?.scrollToTop();
        return { data: res.data.data, paginate: res.data.paging };
      },
    },
    {
      immediate: true,
    }
  );

  const getReferenceDocumentPreviewQuery = useAsync(
    PapersApi.getReferenceDocumentPreview,
    { immediate: false }
  );

  const getReferenceDocumentsListing = useCallback(
    (data) => {
      const tmpData = {
        ...data,
        page: 1,
      };
      getReferenceDocumentsListingQuery.cancel();
      getReferenceDocumentsListingQuery.debounceExecute(tmpData, {
        overwrite: true,
      });
      listRef?.current?.props?.scrollToTop();
    },
    [getReferenceDocumentsListingQuery]
  );

  const handleClearSearchFilter = useCallback(
    (e) => {
      setSearchText("");
      const getReferenceDocumentsListingQueryExecute =
        getReferenceDocumentsListingQuery.execute;
      getReferenceDocumentsListingQueryExecute();
    },
    [getReferenceDocumentsListingQuery.execute]
  );

  const handleSearchChange = useCallback(
    (e) => {
      e.persist();
      setSearchText(e.target.value);
      if (e.target.value.length === 0) handleClearSearchFilter();
    },
    [handleClearSearchFilter]
  );

  const handleEnterSearch = useCallback(
    (value) => {
      const payload = {};
      payload.term = value;
      getReferenceDocumentsListing(payload);
    },
    [getReferenceDocumentsListing]
  );

  const isHasMorePages = useMemo(() => {
    return (
      getReferenceDocumentsListingQuery?.paginate?.page_no *
        getReferenceDocumentsListingQuery?.paginate?.page_limit <
      getReferenceDocumentsListingQuery?.paginate?.total
    );
  }, [
    getReferenceDocumentsListingQuery?.paginate?.page_limit,
    getReferenceDocumentsListingQuery?.paginate?.page_no,
    getReferenceDocumentsListingQuery?.paginate?.total,
  ]);

  const loadingRowCount = useMemo(() => {
    if (
      getReferenceDocumentsListingQuery?.isIdle ||
      getReferenceDocumentsListingQuery?.data?.length === 0
    ) {
      return 5;
    }
    return isHasMorePages
      ? Const.SKELETON_LOADING_ROWS.PAGING
      : Const.SKELETON_LOADING_ROWS.NO_ROWS;
  }, [
    getReferenceDocumentsListingQuery?.data?.length,
    isHasMorePages,
    getReferenceDocumentsListingQuery?.isIdle,
  ]);

  const handleLoadMore = useCallback(() => {
    if (
      getReferenceDocumentsListingQuery?.isPending ||
      getReferenceDocumentsListingQuery?.isIdle ||
      !isHasMorePages
    )
      return;
    let page = (getReferenceDocumentsListingQuery?.paginate?.page_no || 0) + 1;
    let data = { page: page };
    let getReferenceDocumentsListingQueryExecute =
      getReferenceDocumentsListingQuery?.execute;
    getReferenceDocumentsListingQueryExecute(data);
  }, [getReferenceDocumentsListingQuery, isHasMorePages]);

  const handlePreviewReference = useCallback(
    (id) => () => {
      notificationModal.progress({ heading: MESSAGES.INPROGRESS_MESSAGE });
      const getReferenceDocumentPreviewQueryExecute =
        getReferenceDocumentPreviewQuery.execute;
      getReferenceDocumentPreviewQueryExecute({id}, {
        onSuccess: (res) => {
          const fileURL = generateFileUrl(
            res?.data,
            res?.headers["content-type"]
          );
          downloadOrPreviewFileUrl(fileURL, `preview-${id}.pdf`, {
            type: res?.headers["content-type"],
          });
          notificationModal.close();
        },
        onError: () => {
          notificationModal.error({ heading: MESSAGES.PREVIEW_FAILED });
        },
      });
    },
    [getReferenceDocumentPreviewQuery.execute, notificationModal]
  );

  return useMemo(() => {
    return {
      getReferenceDocumentsListingQuery,
      goBack,
      handleSearchChange,
      searchText,
      handleEnterSearch,
      handleClearSearchFilter,
      loadingRowCount,
      isHasMorePages,
      handleLoadMore,
      listRef,
      handlePreviewReference,
    };
  }, [
    getReferenceDocumentsListingQuery,
    goBack,
    handleSearchChange,
    searchText,
    handleEnterSearch,
    handleClearSearchFilter,
    loadingRowCount,
    isHasMorePages,
    handleLoadMore,
    listRef,
    handlePreviewReference,
  ]);
};

export const [
  ReferenceDocumentsListingPageProvider,
  useReferenceDocumentsLisitingPageContext,
] = generateContext(useReferenceDocumentsLisitingPage);
