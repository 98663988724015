import * as Yup from "yup";
import * as validation from "../../../../utils/validation";

export const FN = {
  GROUP: "group",
  FEEDBACK: "comment",
};

export const getFormInitialValuesTaskDetails = (data = {}) => {
  return {
    [FN.GROUP]: "",
  };
};

export const getFormValidationSchemaTaskDetails = () => {
  return Yup.object().shape({
    [FN.GROUP]: validation.isRequiredString({ withRequired: false }),
  });
};

export const generateFeedbackInitialValues = (data = {}) => {
  return {
    [FN.FEEDBACK]: data[FN.FEEDBACK] || "",
  };
};

export const generateFeedbackValidationSchema = (withRequired = true) => {
  return Yup.object().shape({
    [FN.FEEDBACK]: validation.isRequiredString({ withRequired }),
  });
};
