import { useMemo } from "react";
import HeaderTitle from "../../components/header-title";
import { HeaderRight } from "../../layouts/dashboard/header";
import withHOC from "../../utils/with-hoc";
import { FeedbackRow } from "./components/feedback-row";
import { Filter } from "./components/filter";
import HeaderSection from "./components/header-section";
import {
  GroupFeedbackListingPageProvider,
  useGroupFeedbackListingPageContext,
} from "./provider";
import Button from "../../components/button";
import Arrow2Icon from "../../components/vectors/arrow2-icon";

function GroupFeedbackListingPage() {
  const {
    agenda,
    paper,
    getAgendaQuery,
    handlePrepareFeedbackClick,
    goBack,
    getGroupDetailsQuery,
  } = useGroupFeedbackListingPageContext();
  const groupAgenda = (getGroupDetailsQuery?.data?.data?.agendas || [])?.map(
    (e) => e.id
  );
  const agendaFeedback = useMemo(() => {
    let filteredAgenda = (getAgendaQuery?.data?.data || []).filter((e) => {
      return groupAgenda?.includes(e.id);
    });
    if (agenda) {
      filteredAgenda = (getAgendaQuery?.data?.data || []).filter(
        (item) => item.id === agenda
      );
    }
    return filteredAgenda.reduce((acc, cur) => {
      let filteredPapers = cur.papers;
      if (paper) {
        filteredPapers = cur.papers.filter((item) => item.id === paper);
      }
      const formattedPapers = filteredPapers.map((item) => {
        return {
          description: item.description,
          paper: item.symbol,
          paper_id: item.id,
          agenda_id: cur.id,
        };
      });

      return [...acc, ...formattedPapers];
    }, []);
  }, [agenda, getAgendaQuery?.data?.data, groupAgenda, paper]);

  return (
    <div className="flex flex-1 flex-col">
      <HeaderRight>
        <Button
          variant="outline"
          color="primary"
          className="h-9 flex items-center gap-1 hover:bg-primary-button hover:text-secondary-0"
          onClick={goBack}
          icon={<Arrow2Icon className="rotate-180" />}
        >
          Back
        </Button>
        <HeaderTitle className="ml-2">Prepare Feedback</HeaderTitle>
      </HeaderRight>
      <Filter />
      <HeaderSection />
      {agendaFeedback?.map((item) => {
        return (
          <FeedbackRow
            item={item}
            handlePrepareFeedbackClick={handlePrepareFeedbackClick}
          />
        );
      })}
    </div>
  );
}

export default withHOC(
  GroupFeedbackListingPageProvider,
  GroupFeedbackListingPage
);
