import React from "react";
import SearchInput from "../../../../components/search-input";
import { useReferenceDocumentsLisitingPageContext } from "../../provider";
import CloseIcon from "../../../../components/vectors/close-icon";
import { FN } from "./helper";

const Filter = () => {
  const {
    searchText,
    handleSearchChange,
    handleEnterSearch,
    handleClearSearchFilter,
  } = useReferenceDocumentsLisitingPageContext();
  return (
    <div className="mt-2 mb-2 ml-2">
      <SearchInput
        name={FN.SEARCH}
        value={searchText}
        onChange={(e) => handleSearchChange(e)}
        onKeyPress={(event) => {
          if (event.key === "Enter") handleEnterSearch(event.target.value);
        }}
        placeholder="Search in List"
        iconRight={
          searchText !== "" ? (
            <div className="cursor-pointer" onClick={handleClearSearchFilter}>
              <CloseIcon className="w-4 h-4" />
            </div>
          ) : null
        }
      />
    </div>
  );
};

export default Filter;
