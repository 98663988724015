export default function CheckboxOuterIcon2(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none" viewBox="0 0 22 22" {...props}>
      <path
        fill="#fff"
        stroke="currentColor"
        strokeWidth="0.8"
        d="M2.667.4h16a2.267 2.267 0 012.266 2.267v16a2.267 2.267 0 01-2.266 2.266h-16A2.267 2.267 0 01.4 18.667v-16A2.267 2.267 0 012.667.4z"
      ></path>
    </svg>
  );
}
