import { Form, Formik } from "formik";
import Button from "../../../../components/button";
import FormControl from "../../../../components/form-control";
import InputLabel from "../../../../components/input-label";
import { FormikSelect } from "../../../../components/select";
import {
  Enums,
  FN,
  generateInitialValues,
  generatePayload,
  generateValidationSchema,
} from "./form-helper";
import { FormikRichTextEditor } from "../../../../components/rich-text-editor";
import { useEffect, useMemo } from "react";
import { SelectedOptions } from "../../../../components/selected-option.js";
import DocumentUploader from "../../../../components/document-uploder";
import { useMembersFeedbackPageContext } from "../../provider";
import constants from "../../../../constants";

const FeedbackForm = (props) => {
  const {
    papers,
    onSubmit,
    onCancel,
    data = {},
    uploadedDocument,
    isEditMode,
  } = props;
  const { documents, setDocuments, handleEditDeleteDocument, newFeedback } =
    useMembersFeedbackPageContext();
  const intitialValues = useMemo(() => generateInitialValues(data), [data]);
  const validationSchema = useMemo(() => generateValidationSchema({}), []);

  const handleOnSubmit = (formData, formikContext) => {
    const payload = generatePayload(formData);
    const isEditMode = Object.keys(data).length > 0;
    onSubmit &&
      onSubmit({ payload, isEditMode, feedback: data, formikContext });
  };

  const handleCancelClick = (id) => {
    onCancel && onCancel(id);
  };

  const handlePaperItemRemove = (selectedPaperItems, formik, id) => {
    const updatedPaperItems = selectedPaperItems.filter((_id) => _id !== id);
    formik.setFieldValue(FN.PAPER, updatedPaperItems);
  };

  useEffect(() => {
    if (isEditMode && uploadedDocument) {
      const documents = uploadedDocument?.filter(
        (document) => document?.feedback_id === data?.id
      );
      setDocuments(documents);
    }
  }, [data.id, isEditMode, setDocuments, uploadedDocument]);

  return (
    <div className="bg-blue-800 border mt-2 border-primary-button p-2 rounded">
      <Formik
        initialValues={intitialValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        {(formik) => {
          const selectedPaperItems = (formik.values[FN.PAPER] ?? []).map((e) =>
            parseInt(e)
          );
          return (
            <Form className="flex flex-1 flex-col justify-between p-[1px]">
              <div className="flex-col gap-3">
                <FormControl className="flex-1">
                  <FormikSelect
                    data={papers}
                    id={FN.PAPER}
                    name={FN.PAPER}
                    lableString="symbol"
                    valueString="id"
                    placeholder="Select Paper"
                    passValueInt={true}
                    showSelectedLength={true}
                    multiSelect
                    virtualize={papers?.length >= constants.VIRTUALIZED_ENABLE_LIMIT}
                    classNames={{
                      root: "grid-cols-2 gap-x-4 mb-1",
                      input: "ring-black-100 ring-[0.5px] text-black-100",
                    }}
                  />
                </FormControl>
                <div className="flex gap-2.5 flex-wrap">
                  {papers
                    .filter((item) => selectedPaperItems.includes(item.id))
                    .map((paper) => (
                      <SelectedOptions
                        key={paper.id}
                        label={paper.symbol}
                        onClick={() =>
                          handlePaperItemRemove(
                            selectedPaperItems,
                            formik,
                            paper.id
                          )
                        }
                        classNames={{ icon: "ml-2.5" }}
                      />
                    ))}
                </div>
              </div>
              <div className="flex items-center gap-2.5">
                <FormControl className="flex-1">
                  <FormikSelect
                    data={Enums.feedback}
                    id={FN.FEEDBACK}
                    name={FN.FEEDBACK}
                    lableString="label"
                    valueString="value"
                    placeholder="Select Response Type"
                    virtualize={Enums.feedback?.length >= constants.VIRTUALIZED_ENABLE_LIMIT}
                    classNames={{
                      root: "grid-cols-2 gap-x-4 mb-1",
                      input: "ring-black-100 ring-[0.5px] text-black-100",
                    }}
                  />
                </FormControl>
              </div>
              <div className="grid grid-cols-1 bg-grey-100 mb-3 rounded">
                <InputLabel
                  className="text-xs font-semibold text-black-100"
                  htmlFor={`${FN.OBSERVATION}`}
                >
                  Add Comment
                </InputLabel>
                <FormikRichTextEditor
                  id={`${FN.OBSERVATION}`}
                  name={`${FN.OBSERVATION}`}
                  classNames="text-black-100"
                />
              </div>
              <div className="grid grid-cols-1 bg-grey-100 mb-3 rounded">
                <DocumentUploader
                  documents={documents}
                  setDocuments={setDocuments}
                  onDelete={newFeedback ? null : handleEditDeleteDocument}
                />
              </div>
              <div className="flex justify-end w-full mt-auto">
                <Button
                  type="button"
                  variant="outline"
                  color="primary"
                  className="py-2 px-3.5 ml-2 min-w-[100px] bg-white text-xs font-semibold action-auditor-save"
                  onClick={() => handleCancelClick(data?.id)}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="solid"
                  color="primary"
                  className="py-2 px-3.5 ml-2 min-w-[100px] flex items-center justify-center text-xs font-semibold action-auditor-save"
                >
                  Submit
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default FeedbackForm;
