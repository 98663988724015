import * as Yup from "yup";
import * as validation from "../../../../utils/validation";
import { getDateFromValue, getFormattedDateforAPI } from "../../../../utils/date-and-time";

export const FN = {
  COMMITTEE: "committee",
  IMO_RESOLUTION_REGARDING_APPROVAL_OF_AMENDMENTS: "imo_resolution_regarding_approval_of_amendments",
  CONVENTION: "convention",
  CIRCULAR_TO_BE_AMENDED: "circular_to_be_amended",
  IMO_RESOLUTION_REGARDING_ADOPTION_OF_AMENDMENTS: "imo_resolution_regarding_adoption_of_amendments",
  ENTRY_INTO_FORCE: "entry_into_force",
  BRANCH_OF_DGS_TO_ISSUE_CIRCULAR: "branch_of_dgs_to_issue_circular",
  TARGET_DATE: "target_date",
  DGS_CIRCULAR_ISSUED: "dgs_circular_issued",
  UPCOMING_REGULATION_ID: "upcoming_regulation_id",
  DOCUMENT_NAME: "document_name",
  S3_KEY: "s3_key"
};


export const getFormInitialValues = (data) => {
  const value = {
    [FN.COMMITTEE]: data?.committee_id || "",
    [FN.IMO_RESOLUTION_REGARDING_APPROVAL_OF_AMENDMENTS]: data?.approval_amendments || "",
    [FN.CONVENTION]: data?.convention || "",
    [FN.CIRCULAR_TO_BE_AMENDED]: data?.circular_to_be_amended || "",
    [FN.IMO_RESOLUTION_REGARDING_ADOPTION_OF_AMENDMENTS]: data?.adoption_amendments || "",
    [FN.ENTRY_INTO_FORCE]: data?.entry_into_force || "",
    [FN.BRANCH_OF_DGS_TO_ISSUE_CIRCULAR]: data?.branch_to_issue_circular || "",
    [FN.TARGET_DATE]: getDateFromValue(data?.target_date) || "",
    [FN.DGS_CIRCULAR_ISSUED]: data?.circular_issued || "",
  };
  if (data?.id) {
    value[FN.UPCOMING_REGULATION_ID] = data?.id
    value[FN.DOCUMENT_NAME] = data?.document_name
    value[FN.S3_KEY] = data?.s3_key
  }
  return value
};

export const getFormValidationSchema = () => {
  return Yup.object().shape({
    [FN.COMMITTEE]: validation.isRequiredString(),
    [FN.IMO_RESOLUTION_REGARDING_APPROVAL_OF_AMENDMENTS]: validation.isRequiredString({withRequired : false}),
    [FN.CONVENTION]: validation.isRequiredString({withRequired : false}),
    [FN.CIRCULAR_TO_BE_AMENDED]: validation.isRequiredString({withRequired : false}),
    [FN.IMO_RESOLUTION_REGARDING_ADOPTION_OF_AMENDMENTS]: validation.isRequiredString({withRequired : false}),
    [FN.ENTRY_INTO_FORCE]: validation.isRequiredString({withRequired : false}),
    [FN.BRANCH_OF_DGS_TO_ISSUE_CIRCULAR]: validation.isRequiredString({withRequired : false}),
    [FN.TARGET_DATE]: validation.isRequiredDate(),
    [FN.DGS_CIRCULAR_ISSUED]: validation.isRequiredString({withRequired : false}),
  });
};
export const generatePayload = (formData, file) => {
  const outputData = {
    approval_amendments: formData[FN.IMO_RESOLUTION_REGARDING_APPROVAL_OF_AMENDMENTS],
    committee_id: formData[FN.COMMITTEE],
    convention: formData[FN.CONVENTION],
    circular_to_be_amended: formData[FN.CIRCULAR_TO_BE_AMENDED],
    adoption_amendments: formData[FN.IMO_RESOLUTION_REGARDING_ADOPTION_OF_AMENDMENTS],
    entry_into_force: formData[FN.ENTRY_INTO_FORCE],
    branch_to_issue_circular: formData[FN.BRANCH_OF_DGS_TO_ISSUE_CIRCULAR],
    target_date: getFormattedDateforAPI(formData[FN.TARGET_DATE]),
    circular_issued: formData[FN.DGS_CIRCULAR_ISSUED],
  };
  if (file) {
    outputData.file = file;
  }
  return outputData;
};
