import moment from "moment";
import Const from "../constants";
import is from "../utils/is";

export const dateAndTime = (date, time) => {
  let hourminutes = time?.split(":");
  let hour = +hourminutes[0];
  let minutes = hourminutes[1] || "00";
  let mnt = !minutes?.toString().length || minutes?.toString().length === 0 ? `00` : minutes?.toString().length === 1 ? `0${minutes}` : minutes;
  let hrFormat = hour?.toString()?.length === 1 ? `0${hour}` : hour;
  let hoursAndMnt = hrFormat + ":" + mnt;
  let dateTime = `${date} ${hoursAndMnt}`;
  return moment(dateTime).format("YYYY-MM-DD[T]HH:mm");
};

export const getFormattedMinute = (minutes) => {
  if (!minutes?.toString().length || minutes?.toString().length === 0) {
    return "00";
  } else if (minutes?.toString().length === 1) {
    return `0${minutes}`;
  } else {
    return minutes;
  }
};

export const addHours = (time, value) => {
  let [hour, minutes = "00"] = time.split(":");
  hour = +hour + value;
  minutes = +minutes;
  minutes = getFormattedMinute(minutes);
  return hour + ":" + minutes;
};

export const addMinutes = (time, value) => {
  let [hour, minutes] = time.split(":");
  minutes = +minutes + value;
  hour = +hour + Math.floor(minutes / 60);
  minutes = minutes % 60;
  minutes = getFormattedMinute(minutes);
  return hour + ":" + minutes;
};

export const addTime = (time, value = 0, unit = Const.TIME_UNIT.HOUR) => {
  if (unit === Const.TIME_UNIT.MINUTE) {
    return addMinutes(time, value);
  }
  return addHours(time, value);
};

export const timeConvert24to12 = (timeString) => {
  let hourminutes = timeString?.split(":");
  let hour = +hourminutes[0];
  let minutes = hourminutes[1] || "00";
  let hr = hour % 12 || 12;
  let ampm = hour < 12 ? "AM" : "PM";
  let hours = hr?.toString()?.length === 1 ? `0${hr}` : hr;
  let mnt = !minutes?.toString().length || minutes?.toString().length === 0 ? `00` : minutes?.toString().length === 1 ? `0${minutes}` : minutes;
  return hours + ":" + mnt + " " + ampm;
};

export const getFormattedDate = (dateObj, format = Const.DATE_TIME_FORMATS.DATE_FULL_MONTH) => {
  if (is.array(dateObj)) {
    dateObj = dateObj?.[0];
  }
  if (!dateObj) {
    return "";
  }
  return moment(dateObj).format(format);
};

export const getCurrentDate = () => {
  return moment();
};

export const getFormattedDateforUI = (dateObj) => {
  return getFormattedDate(dateObj, Const.DATE_TIME_FORMATS.DATE_UI);
};

export const getFormattedDateforAPI = (dateObj) => {
  return getFormattedDate(dateObj, Const.DATE_TIME_FORMATS.DATE_API);
};

export const getDateFromValue = (value = "") => {
  return value ? [moment(value).toDate()] : [];
};

export const minDateForDatePicker = (days = 0, dateObj) => {
  if (is.array(dateObj)) dateObj = dateObj[0];
  if (!dateObj) dateObj = moment().toDate();
  return moment(dateObj).add(days, "days").set("hour", 0).set("minute", 0).set("second", 0).format(Const.DATE_TIME_FORMATS.DATE_UI);
};

export const maxDateForDatePicker = (days = 1, dateObj) => {
  if (is.array(dateObj)) dateObj = dateObj[0];
  if (!dateObj) dateObj = moment().toDate();
  return moment(dateObj).subtract(days, "days").set("hour", 0).set("minute", 0).set("second", 0).format(Const.DATE_TIME_FORMATS.DATE_UI);
};

export const getTimeDiff = (startDate, endDate) => {
  const start = moment(startDate, Const.DATE_TIME_FORMATS.TIME_24_HR);
  const end = moment(endDate, Const.DATE_TIME_FORMATS.TIME_24_HR);
  const duration = moment.duration(end.diff(start));
  return { hours: parseInt(duration.asHours()) || "00", minutes: parseInt(duration.asMinutes()) % 60 || "00" };
};

export const calculateBusinessDays = (firstDate, secondDate) => {
  let day1 = moment(firstDate).startOf("day");
  let day2 = moment(secondDate).startOf("day");

  let adjust = 1;

  if (day1.dayOfYear() === day2.dayOfYear() && day1.year() === day2.year()) {
    return 0;
  }

  if (day2.isBefore(day1)) {
    const temp = day1;
    day1 = day2;
    day2 = temp;
  }

  //Check if first date starts on weekends
  if (day1.day() === 6) {
    //Saturday
    //Move date to next week monday
    day1.day(8);
  } else if (day1.day() === 0) {
    //Sunday
    //Move date to current week monday
    day1.day(1);
  }

  //Check if second date starts on weekends
  if (day2.day() === 6) {
    //Saturday
    //Move date to current week friday
    day2.day(5);
  } else if (day2.day() === 0) {
    //Sunday
    //Move date to previous week friday
    day2.day(-2);
  }

  const day1Week = day1.week();
  let day2Week = day2.week();

  //Check if two dates are in different week of the year
  if (day1Week !== day2Week) {
    //Check if second date's year is different from first date's year
    if (day2Week < day1Week) {
      day2Week += day1Week;
    }
    //Calculate adjust value to be substracted from difference between two dates
    // EDIT: add rather than assign (+= rather than =)
    adjust += -2 * (day2Week - day1Week);
  }

  return day2.diff(day1, "days") + adjust;
};

export const getDateDiff = (firstDate, secondDate) => {
  const day1 = moment(firstDate);
  const day2 = moment(secondDate);
  return day1.diff(day2, "days");
};

export const getFormattedTime = (time) => {
  if (!time) {
    return "";
  }
  let _time = time.split(":");
  if (_time.length === 2) {
    return time;
  }
  if (_time.length === 3) {
    return _time[0] + ":" + _time[1];
  }
  return time;
};

export const getMinDate = (dates = []) => {
  return moment.min([...dates, moment()]);
};

export const checkDateInRange = (props) => {
  const { compareDate, startDate, endDate } = props;
  return moment(compareDate).isBetween(startDate, endDate, undefined, "[]");
};

export const addDays = (noOfDays, date) => {
  if (!date) return;
  return moment(date).add(noOfDays, "days");
};

export const addMonths = (noOfMonths, date) => {
  if (!date) return;
  return moment(date).add(noOfMonths, "months");
};

export const getHrsFromMins = (minutes) => {
  const hours = Math.floor(minutes / 60);
  let minute = minutes % 60;
  minute = minute?.toString()?.length === 1 ? `0${minute}` : minute;
  return minute > 0 ? hours + "." + minute : hours;
};

export const getMinutesFromHoursAndMinuts = (hours, minute) => {
  let minutes = hours * 60;
  if (minute > 0) {
    minutes = minutes + minute;
  }
  return minutes;
};

export const getFormattedTimeForComparision = (time) => {
  let hourminutes = time?.split(":");
  let hour = +hourminutes[0];
  let minutes = hourminutes[1] || "00";
  minutes = minutes?.toString()?.length === 1 ? `0${minutes}` : minutes;
  hour = hour?.toString()?.length === 1 ? `0${hour}` : hour;
  return hour + ":" + minutes;
};

export const checkIfDateIsFutureDate = (date) => {
  return moment(date, Const.DATE_TIME_FORMATS.DATE_UI).isAfter(moment(), "day");
};
