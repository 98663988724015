import { withConfig, getApi } from "./index";
const api = getApi();

export const getMembers = withConfig((data, conf) => {
  const config = conf({
    url: "/members",
    method: "GET",
    params: data,
  });
  return api(config);
});

export const registerMember = withConfig((data, conf) => {
  const config = conf({
    url: "/members",
    method: "POST",
    data: data,
  });
  return api(config);
});

export const updateMember = withConfig((data, conf) => {
  const { id, ...restData } = data;
  const config = conf({
    url: `/members/${id}`,
    method: "PUT",
    data: restData,
  });
  return api(config);
});

export const deleteMember = withConfig((data, conf) => {
  const { id, ...params } = data;
  const config = conf({
    url: `/members/${id}`,
    method: "DELETE",
    params: params,
  });
  return api(config);
});

export const resetPassword = withConfig((data, conf) => {
  const { id, ...payload } = data;
  const config = conf({
    url: `/members/${id}/reset-password`,
    method: "POST",
    data: payload,
  });
  return api(config);
});
