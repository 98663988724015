import { Form, Formik } from "formik";
import Button from "../../../../components/button";
import FormControl from "../../../../components/form-control";
import InputLabel from "../../../../components/input-label";
import { FormikRichTextEditor } from "../../../../components/rich-text-editor";
import { FormikSelect } from "../../../../components/select";
import { useCommitteeFeedbackPageContext } from "../../provider";
import { Enums, FN } from "./formhelper";
import constants from "../../../../constants";
import { useUserFromStorage } from "../../../../contexts/user.context";

export const PrepareFeedback = () => {
  const {
    initialValuesPrepareFeedback,
    validationSchemaPrepareFeedback,
    handleSubmit,
    mainFormRef,
    handleUpdateFeedback,
    getCommitteeQuery
  } = useCommitteeFeedbackPageContext();
  const committeeDetails = getCommitteeQuery?.data?.data;
  const { user } = useUserFromStorage();
  const userInfo = committeeDetails?.commitee_members?.find(
    (member) => member.member_id === user.id
  );
  const isShowButton =
    userInfo?.is_leader || userInfo?.is_coordinator || user?.is_admin;

  const isFeedbackId = initialValuesPrepareFeedback?.feedback_id !== "";
  const handleSubmitForm = isFeedbackId ? handleUpdateFeedback(initialValuesPrepareFeedback?.feedback_id ): handleSubmit ;

  return (
    <Formik
      initialValues={initialValuesPrepareFeedback}
      validationSchema={validationSchemaPrepareFeedback}
      onSubmit={handleSubmitForm}
      enableReinitialize={true}
    >
      {(formik) => {
        mainFormRef.current = formik;

        return (
          <Form className="grid grid-cols-1 grid-flow-row gap-3 justify-between p-[1px] w-full h-full drop-shadow">
            <div className="flex flex-col gap-2">
              <FormControl className="pr-2">
                <InputLabel
                  className="flex-1 text-black-100 mt-2 text-sm"
                  required
                  htmlFor={FN.FEEDBACK_TYPE}
                >
                  Prepare Feedback
                </InputLabel>
                <FormikSelect
                  data={Enums.feedback}
                  id={FN.FEEDBACK_TYPE}
                  name={FN.FEEDBACK_TYPE}
                  lableString="label"
                  valueString="value"
                  placeholder="Select Feedback Type"
                  virtualize={Enums.feedback?.length >= constants.VIRTUALIZED_ENABLE_LIMIT}
                  classNames={{
                    root: "grid-cols-2 gap-x-4 mb-1",
                    input: "ring-black-100 ring-[0.5px]",
                  }}
                />
              </FormControl>
              <div className="overflow-y-scroll">
                <FormikRichTextEditor id={FN.RESPONSE} name={FN.RESPONSE} />
              </div>
            </div>
            <div className="flex justify-end gap-2 w-full flex-1 my-4 mt-auto">
              <Button
                type="submit"
                variant="solid"
                color="primary"
                className="py-2 px-3 flex items-center justify-center flex-wrap text-xs font-semibold"
                disabled={!isShowButton}
              >
                {isFeedbackId ? "Update" : "Complete"}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
