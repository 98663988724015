import { withConfig, getApi } from "./index";
const api = getApi("/papers");

export const getPaperFeedback = withConfig((data, conf) => {
  const { paperId, ...params } = data;
  const config = conf({
    url: `${paperId}/group-feedback`,
    method: "GET",
    params: params,
  });
  return api(config);
});
export const updatePaper = withConfig((data, conf) => {
  const { paperId, ...params } = data;
  const config = conf({
    url: `${paperId}`,
    method: "PUT",
    params: params,
  });
  return api(config);
});

export const getPaperDocument = withConfig((data, conf) => {
  const { paperId } = data;
  const config = conf({
    url: `${paperId}/preview`,
    method: "GET",
    responseType: "blob",
  });
  return api(config);
});

export const uploadPaperDocument = withConfig((data, conf) => {
  const { paperId, files } = data;
  let formData = new FormData();
  formData.append("files", files);
  const config = conf({
    url: `/${paperId}/documents`,
    method: "PUT",
    data: formData,
    responseType: "blob",
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return api(config);
});

export const deletePaperDocument = withConfig((data, conf) => {
  const { paperId } = data;
  const config = conf({
    url: `${paperId}/documents`,
    method: "DELETE",
    responseType: "blob",
  });
  return api(config);
});

export const getReferenceDocuments = withConfig((data, conf) => {
  const config = conf({
    url: `/reference-documents`,
    method: "GET",
    params: data,
  });
  return api(config);
});

export const getReferenceDocumentPreview = withConfig((data, conf) => {
  const { id, ...params } = data;
  let config = conf({
    url: `/reference-documents/${id}/preview`,
    method: "GET",
    params: params,
    responseType: "blob",
  });
  return api(config);
});
