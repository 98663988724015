import { createRef, useCallback, useMemo, useState } from "react";
import generateContext from "../../utils/generate-context";
import { useLocation } from "react-router-dom";
import { useAsync } from "@react-org/hooks";
import * as CommitteeApi from "../../apis/committee.api";
import * as upcomingRegulationApi from "../../apis/upcoming-regulations.api";
import {
  generatePayload,
  getFormInitialValues,
  getFormValidationSchema,
} from "./components/add-regulation-drawer/form-helper";
import { useNotificationModalContext } from "../../components/notification-modal/provider";
import MESSAGES from "../../constants/messages";
import { downloadOrPreviewFileUrl } from "../../utils/download-or-preview-file-url";
import generateFileUrl from "../../utils/generate-file-url";

function useUpcomingRegulationPage(props) {
  const notificationModal = useNotificationModalContext();
  const location = useLocation();
  const fileUploadRef = createRef();
  const [isRegulationDrawerOpen, setIsRegulationDrawerOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [rowDetails, setRowDetails] = useState({});
  const getCommitteeQuery = useAsync(
    useCallback((payload) => CommitteeApi.getCommittees({ ...payload }), []),
    {
      intialState: { data: [] },
      infinite: true,
      select: (res) => {
        return { data: res.data.data, paginate: res.data.paging };
      },
      immediate: true,
    }
  );
  const getUpcomingRegulationsQuery = useAsync(
    useCallback(
      (payload) => upcomingRegulationApi.getUpcomingRegulations({ limit: 1000, ...payload }),
      []
    ),
    {
      intialState: { data: [] },
      infinite: true,
      select: (res) => {
        return { data: res.data.data, paginate: res.data.paging };
      },
      immediate: true,
    }
  );

  const addUpcomingRegulationQuery = useAsync(
    upcomingRegulationApi.addUpcomingRegulation,
    {
      immediate: false,
    }
  );
  const updateUpcomingRegulationQuery = useAsync(
    upcomingRegulationApi.updateUpcomingRegulation,
    {
      immediate: false,
    }
  );
  const deleteUpcomingRegulationQuery = useAsync(
    upcomingRegulationApi.deleteUpcomingRegulation,
    {
      immediate: false,
    }
  );
  const previewCircularQuery = useAsync(
    upcomingRegulationApi.previewCircular,
    {
      immediate: false,
    }
  );
  const deleteCircularQuery = useAsync(
    upcomingRegulationApi.deleteCircular,
    {
      immediate: false,
    }
  );

  const initialValues = useMemo(() => {
    return getFormInitialValues(rowDetails);
  }, [rowDetails]);

  const getValidationSchema = useMemo(() => {
    return getFormValidationSchema();
  }, []);

  const handleAddNewRegulations = useCallback(() => {
    setFile(null);
    setRowDetails({})
    setIsRegulationDrawerOpen(true);
  }, []);

  const closeRegulationDrawer = useCallback(() => {
    setIsRegulationDrawerOpen(false);
  }, []);

  const handleRowEdit = useCallback(
    (data) => (e) => {
      setFile(data);
      setRowDetails(data);
      setIsRegulationDrawerOpen(true);
    },
    []
  );

  const handleDeleteRow = useCallback(
    (id) => () => {
      notificationModal.warning({
        title: MESSAGES.ARE_YOU_SURE,
        heading: MESSAGES.REGULATION_WILL_BE_DELETED,
        onConfirm: () => {
          const deleteUpcomingRegulationQueryExecute =
            deleteUpcomingRegulationQuery.execute;
          const getUpcomingRegulationsQueryExecute =
            getUpcomingRegulationsQuery.execute;
          const getUpcomingRegulationsQuerySetData =
            getUpcomingRegulationsQuery.setQueryData;
          notificationModal.progress({
            heading: MESSAGES.INPROGRESS_MESSAGE,
          });
          deleteUpcomingRegulationQueryExecute(
            { id },
            {
              onSuccess: () => {
                getUpcomingRegulationsQuerySetData([]);
                getUpcomingRegulationsQueryExecute();
                notificationModal.success({
                  heading: MESSAGES.REGULATION_DELETED_SUCCESSFULLY,
                });
              },
              onError: () => {
                notificationModal.error({
                  heading: MESSAGES.FAILED_TO_DELETE_REGULATION,
                });
              },
            }
          );
        },
      });
    },
    [
      deleteUpcomingRegulationQuery.execute,
      getUpcomingRegulationsQuery.execute,
      getUpcomingRegulationsQuery.setQueryData,
      notificationModal,
    ]
  );

  const handleDocumentUpload = useCallback((e) => {
    const data = e?.target?.files[0];
    setFile(data);
  }, []);

  const handleOpenUpload = useCallback(
    (fileUploadRef) => () => {
      fileUploadRef.current.click();
    },
    []
  );

  const handleDownloadFile = useCallback(
    (Media) => () => {
      const url = URL.createObjectURL(Media);
      downloadOrPreviewFileUrl(url, Media.name);
    },
    []
  );

  const handleDownloadCircular = useCallback(
    (id, docName) => () => {
      const downloadTaskDocumentQueryExecute =
        previewCircularQuery.execute;
      downloadTaskDocumentQueryExecute({ id }, {
        onSuccess: (res) => {
          const fileURL = generateFileUrl(
            res?.data,
            res?.headers["content-type"]
          );
          downloadOrPreviewFileUrl(fileURL, `${docName}`, {
            type: res?.headers["content-type"],
          });
          notificationModal.close();
        },
        onError: () => {
          notificationModal.error({ heading: MESSAGES.PREVIEW_FAILED });
        },
      });
    },
    [notificationModal, previewCircularQuery.execute]
  );

  const handleDeleteCircular = useCallback(
    (id) => () => {
      notificationModal.warning({
        title: MESSAGES.ARE_YOU_SURE,
        heading: MESSAGES.TASK_WILL_BE_DELETED,
        onConfirm: () => {
          const deleteCircularQueryExecute =
            deleteCircularQuery.execute;
          const getUpcomingRegulationsQueryExecute =
            getUpcomingRegulationsQuery.execute;
          const getUpcomingRegulationsQuerySetData =
            getUpcomingRegulationsQuery.setQueryData;
          deleteCircularQueryExecute({ id }, {
            onSuccess: () => {
              getUpcomingRegulationsQuerySetData([]);
              getUpcomingRegulationsQueryExecute();
              setIsRegulationDrawerOpen(false);
              notificationModal.close();
            },
            onError: () => {
              notificationModal.error({ heading: MESSAGES.DELETE_DOC_FAILED });
            },
          });
        },
      });
    },
    [deleteCircularQuery.execute, getUpcomingRegulationsQuery.execute, getUpcomingRegulationsQuery.setQueryData, notificationModal]
  );

  const handleDeleteFile = useCallback(() => {
    setFile(null);
  }, []);

  const handleAddRegulation = useCallback(
    (data) => {
      let payload = generatePayload(data, file);
      const addUpcomingRegulationQueryExecute =
        addUpcomingRegulationQuery.execute;
      const getUpcomingRegulationsQueryExecute =
        getUpcomingRegulationsQuery.execute;
      const getUpcomingRegulationsQuerySetData =
        getUpcomingRegulationsQuery.setQueryData;
      notificationModal.progress({
        heading: MESSAGES.INPROGRESS_MESSAGE,
      });
      addUpcomingRegulationQueryExecute(payload, {
        onSuccess: (res) => {
          getUpcomingRegulationsQuerySetData([]);
          getUpcomingRegulationsQueryExecute();
          notificationModal.success({
            heading: MESSAGES.REGULATION_ADDED_SUCCESSFULLY,
          });
          setIsRegulationDrawerOpen(false);
        },
        onError: () => {
          notificationModal.error({
            heading: MESSAGES.FAILED_TO_ADD_REGULATION,
          });
        },
      });
    },
    [
      addUpcomingRegulationQuery.execute,
      file,
      getUpcomingRegulationsQuery.execute,
      getUpcomingRegulationsQuery.setQueryData,
      notificationModal,
    ]
  );

  const handleUpdateRegulation = useCallback(
    (id) => (data) => {
      let payload = generatePayload(data, file);
      payload.id = id;
      const updateUpcomingRegulationQueryExecute =
        updateUpcomingRegulationQuery.execute;
      const getUpcomingRegulationsQueryExecute =
        getUpcomingRegulationsQuery.execute;
      const getUpcomingRegulationsQuerySetData =
        getUpcomingRegulationsQuery.setQueryData;
      notificationModal.progress({
        heading: MESSAGES.INPROGRESS_MESSAGE,
      });
      updateUpcomingRegulationQueryExecute(payload, {
        onSuccess: (res) => {
          getUpcomingRegulationsQuerySetData([]);
          getUpcomingRegulationsQueryExecute();
          notificationModal.success({
            heading: MESSAGES.REGULATION_ADDED_SUCCESSFULLY,
          });
          setIsRegulationDrawerOpen(false);
        },
        onError: () => {
          notificationModal.error({
            heading: MESSAGES.FAILED_TO_ADD_REGULATION,
          });
        },
      });
    },
    [
      file,
      getUpcomingRegulationsQuery.execute,
      getUpcomingRegulationsQuery.setQueryData,
      notificationModal,
      updateUpcomingRegulationQuery.execute,
    ]
  );

  return useMemo(() => {
    return {
      getCommitteeQuery,
      location,
      isRegulationDrawerOpen,
      handleAddNewRegulations,
      initialValues,
      getValidationSchema,
      closeRegulationDrawer,
      getUpcomingRegulationsQuery,
      handleAddRegulation,
      handleDocumentUpload,
      handleOpenUpload,
      fileUploadRef,
      file,
      handleDeleteFile,
      handleDownloadFile,
      handleRowEdit,
      handleDeleteRow,
      handleUpdateRegulation,
      handleDownloadCircular,
      handleDeleteCircular
    };
  }, [
    closeRegulationDrawer,
    file,
    fileUploadRef,
    getCommitteeQuery,
    getUpcomingRegulationsQuery,
    getValidationSchema,
    handleAddNewRegulations,
    handleAddRegulation,
    handleDeleteCircular,
    handleDeleteFile,
    handleDeleteRow,
    handleDocumentUpload,
    handleDownloadCircular,
    handleDownloadFile,
    handleOpenUpload,
    handleRowEdit,
    handleUpdateRegulation,
    initialValues,
    isRegulationDrawerOpen,
    location,
  ]);
}
export const [
  UpcomingRegulationPageProvider,
  useUpcomingRegulationPageContext,
] = generateContext(useUpcomingRegulationPage);
