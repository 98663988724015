import ReviewCard from "../../../components/review-card";
import Skeleton from "../../../components/skeleton";
import cx from "../../../utils/class-names";

export function MembersSkeleton(props) {
  const { classNames = {} } = props;
  return (
    <ReviewCard.Section
      className={cx(
        "relative flex flex-wrap tab-sm:flex-1 p-2 border rounded-md mb-3 hover:shadow-[-2px_2px_5px_1px_#e3e3e3,_2px_-2px_5px_1px_#e3e3e3,_2px_2px_5px_1px_#e3e3e3,_-2px_-2px_5px_1px_#e3e3e3]",
        classNames.firstContainer
      )}
    >
      <div className="grid grid-cols-11 gap-2 w-full items-center space-x-4 my-1">
        <div className="flex col-span-3 ml-1">
          <Skeleton className="w-10 h-10 p-3 mr-2" variant="circular" />
          <div className="flex items-center col-span-4">
            <Skeleton className="w-40 h-6 tab-sm-min:w-30" />
          </div>
        </div>
        <div className="flex flex-col col-span-4">
          <Skeleton className="w-96 tab-sm-min:w-64" />
        </div>
        <div className="flex flex-col col-span-3">
          <Skeleton className="w-40" />
        </div>
        <div className="flex flex-col col-span-1"></div>
      </div>
    </ReviewCard.Section>
  );
}
