import Popper from "../../../components/popper";
import useHeader from "./provider";
import ArrowIcon from "../../../components/vectors/arrow-icon";
import RightUpIcon from "../../../components/vectors/right-up-arrow";
import cx from "../../../utils/class-names";
import { useUserFromStorage } from "../../../contexts/user.context";
import { generateNames } from "../../../utils/generate-names";

function UserMenu() {
  const {
    isProfileTogglerOpen,
    setProfileTogglerOpen,
    handleLogout,
    handleChangePasswordClick,
    handleManageMemberClick,
  } = useHeader();
  const { user } = useUserFromStorage();

  const [shortName, fullName] = generateNames(
    user?.first_name,
    user?.last_name
  );
  const isAdmin = user?.is_admin;

  const toggleUserMenu = (isOpen) => () => {
    setProfileTogglerOpen(isOpen);
  };

  return (
    <>
      <Popper
        isOpen={isProfileTogglerOpen}
        onOutsideClick={toggleUserMenu(false)}
      >
        <Popper.Trigger>
          {({ setAnchorElement }) => (
            <div
              ref={setAnchorElement}
              className="ml-2 px-2 rounded flex items-center cursor-pointer mr-3"
              onClick={toggleUserMenu(!isProfileTogglerOpen)}
            >
              <div className="flex items-center justify-center font-sans text-base min-w-[32px] px-2 h-9 w-9 rounded-full bg-secondary-50 text-secondary-0 ">
                {shortName}
              </div>
              <div className="flex items-center justify-center ml-2 py-2">
                <div className="tab-sm:hidden desk-sm:block text-primary-text text-sm font-normal">
                  {fullName}
                </div>
                <ArrowIcon
                  className={cx(
                    `w-3 h-3 ml-2 rotate-180`,
                    isProfileTogglerOpen && "rotate-270"
                  )}
                />
              </div>
            </div>
          )}
        </Popper.Trigger>
        <Popper.Content className="z-10 w-[250px]">
          <div className="flex items-center justify-end p-0 mb-[-6px]">
            <RightUpIcon className="w-5 h-5 ml-2 drop-shadow rotate-180 text-secondary-0" />
          </div>
          <div className="flex flex-col  bg-secondary-0 rounded-xl shadow-[0px_4px_16px_0px_#00000014]">
              <div className="w-full h-auto  transition-all  font-normal text-primary-text">
                <div
                  className="text-sm px-4 py-3 flex cursor-pointer items-center hover:bg-primary-brand-50 hover:rounded-b-lg"
                  onClick={handleChangePasswordClick}
                >
                  Change Password
                </div>
              </div>
            <div className="w-full h-auto  transition-all  font-normal text-primary-text">
              <div
                className="text-sm px-4 py-3 flex cursor-pointer items-center hover:bg-primary-brand-50 hover:rounded-b-lg"
                onClick={handleManageMemberClick}
              >
                {isAdmin ? "Manage Members" : "Members List"}
              </div>
            </div>
            <div className="w-full h-auto  transition-all  font-normal text-primary-text">
              <div
                className="text-sm px-4 py-3 flex cursor-pointer items-center hover:bg-primary-brand-50 hover:rounded-b-lg"
                onClick={handleLogout}
              >
                Logout
              </div>
            </div>
          </div>
        </Popper.Content>
      </Popper>
    </>
  );
}

export default UserMenu;
