import Menu from "../../../components/menu";
import ReviewCard from "../../../components/review-card";
import { useUserFromStorage } from "../../../contexts/user.context";
import { generateNames } from "../../../utils/generate-names";
import { useMembersPageContext } from "../provider";

const NameItem = (props) => {
  const { fistName, lastName } = props;
  const [shortName, fullName] = generateNames(fistName, lastName);
  return (
    <div className="flex flex-wrap items-center gap-2">
      <div className="flex items-center justify-center rounded-full cursor-pointer bg-primary-button h-9 w-9">
        <label className="font-medium text-base leading-[18px] text-secondary-0">
          {shortName}
        </label>
      </div>
      <div className="flex flex-col">
        <label className="text-sm font-normal font-sans text-black-100 leading-[18px]">
          {fullName}
        </label>
      </div>
    </div>
  );
};

const MemberItem = ({ row, style }) => {
  const {
    id,
    email,
    first_name,
    last_name,
    representative_of,
    commitee_members,
  } = row;
  const {
    handleMenuOpen,
    handleMenuClose,
    setShowAction,
    showAction,
    handleEdit,
    handleChangePassword,
    handleDelete,
  } = useMembersPageContext();
  const { user } = useUserFromStorage();
  const isAdmin = user?.is_admin;
  const committeeName = commitee_members?.map((e) => e.commitee);
  return (
    <div key={id} style={style}>
      <ReviewCard.Section className="relative flex flex-wrap tab-sm:flex-1 p-2 border rounded-md mb-3 hover:shadow-[-2px_2px_5px_1px_#e3e3e3,_2px_-2px_5px_1px_#e3e3e3,_2px_2px_5px_1px_#e3e3e3,_-2px_-2px_5px_1px_#e3e3e3]">
        <div className="grid grid-cols-9 space-x-4 w-full items-center justify-center my-1">
          <div className="flex flex-col flex-wrap col-span-2 ml-2">
            <NameItem fistName={first_name} lastName={last_name} />
          </div>
          <div className="flex flex-col flex-wrap col-span-2">
            <label className="text-sm font-normal font-sans text-black-100 leading-[18px]">
              {email}
            </label>
          </div>
          <div className="flex flex-col flex-wrap col-span-2 pl-2">
            <label className="text-sm font-normal font-sans text-gray-100 leading-[18px]">
              {representative_of || "-"}
            </label>
          </div>
          <div className="flex flex-col flex-wrap col-span-2 pl-3">
            {committeeName?.map((committee) => {
              return (
                <label
                  className="text-sm font-normal font-sans text-gray-100 leading-[18px]"
                  key={committee.id}
                >
                  {committee.name || "-"}
                </label>
              );
            })}
          </div>
          <div className="flex flex-col flex-wrap items-end mt-2 col-span-1">
            {isAdmin && (
              <Menu
                isOpen={showAction === id}
                setOpen={setShowAction}
                id={id}
                handleClose={handleMenuClose}
                handleOpen={handleMenuOpen}
                setIsOpen={setShowAction}
                classNames={{
                  content:
                    "py-2 shadow-[-2px_2px_5px_1px_#e3e3e3,_2px_-2px_5px_1px_#e3e3e3,_2px_2px_5px_1px_#e3e3e3,_-2px_-2px_5px_1px_#e3e3e3] rounded-md bg-white min-w-[200px] min-h-[30px] mt-1 mr-[-12px] space-y-2 ",
                }}
              >
                <Menu.Item
                  className="flex justify-between"
                  onClick={handleEdit(id)}
                >
                  Edit
                </Menu.Item>

                <Menu.Item
                  className="flex justify-between"
                  onClick={handleChangePassword(id)}
                >
                  Change Password
                </Menu.Item>

                <Menu.Item
                  className="flex justify-between rounded-b-md"
                  onClick={handleDelete(id)}
                >
                  Delete
                </Menu.Item>
              </Menu>
            )}
          </div>
        </div>
      </ReviewCard.Section>
    </div>
  );
};

export default MemberItem;
