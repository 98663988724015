import { useCallback, useEffect, useMemo, useState } from "react";
import generateContext from "../../utils/generate-context";
import { useNavigate, useParams } from "react-router-dom";
import { useRef } from "react";
import {
  FN,
  generatePayload,
  getFormInitialValues,
  getFormValidationSchema,
  getMembersDetailsRow,
} from "./components/form-helper";
import { useNotificationModalContext } from "../../components/notification-modal/provider";
import MESSAGES from "../../constants/messages";
import { useAsync } from "@react-org/hooks";
import * as MembersApi from "../../apis/members.api";
import * as CommitteeApi from "../../apis/committee.api";
import setFormikFieldValue from "../../utils/set-formik-field-value";

function useCreateCommitteePage(props) {
  const navigate = useNavigate();
  const mainFormRef = useRef(null);
  const { committeeId } = useParams();
  const notificationModal = useNotificationModalContext();
  const [committeeDetails, setCommitteeDetails] = useState([]);

  const getCommitteeDetailsQuery = useAsync(
    useCallback(
      (payload) =>
        CommitteeApi.getCommitteeDetails({
          id: committeeId,
          ...payload,
        }),
      [committeeId]
    ),
    {
      immediate: true,
    }
  );
  const createCommitteeQuery = useAsync(CommitteeApi.createCommitte, {
    immediate: false,
  });

  const updateCommitteeQuery = useAsync(CommitteeApi.updateCommitte, {
    immediate: false,
  });
  const getMembersQuery = useAsync(
    useCallback((payload) => MembersApi.getMembers({ limit: 200, ...payload }), []),
    {
      initialState: { data: [] },
      infinite: true,
      select: (res) => {
        return { data: res.data.data, paginate: res.data.paging };
      },
      immediate: true,
    }
  );

  const initialValues = useMemo(() => {
    return getFormInitialValues(committeeDetails);
  }, [committeeDetails]);

  const getValidationSchema = useCallback(() => {
    return getFormValidationSchema();
  }, []);

  const handleCancelClick = useCallback(
    (tabId) => {
      navigate("/committee");
    },
    [navigate]
  );
  const handleAddMember = useCallback(
    (arrayHelpers) => (tabId) => {
      arrayHelpers.push({ ...getMembersDetailsRow() });
    },
    []
  );
  const handleUpdateCommittee = useCallback(
    (committee_id, meeting_id) => (formData) => {
      const payload = generatePayload(formData);
      payload.id = committee_id;
      payload.meeting_id = meeting_id;
      const updateCommitteeQueryExecute = updateCommitteeQuery.execute;

      notificationModal.progress({
        heading: MESSAGES.INPROGRESS_MESSAGE,
      });
      updateCommitteeQueryExecute(payload, {
        onSuccess: () => {
          notificationModal.success({
            heading: MESSAGES.SUCCESSFULLY_UPDATED_COMMITTEE,
          });
          navigate("/committee");
        },
        onError: () => {
          notificationModal.error({
            heading: MESSAGES.FAILED_TO_UPDATE_COMMITTEE,
          });
        },
      });
    },
    [updateCommitteeQuery.execute, navigate, notificationModal]
  );
  const handleCreateCommittee = useCallback(
    (formData) => {
      const payload = generatePayload(formData);
      const createCommitteeQueryExecute = createCommitteeQuery.execute;
      notificationModal.progress({
        heading: MESSAGES.INPROGRESS_MESSAGE,
      });
      createCommitteeQueryExecute(payload, {
        onSuccess: (res) => {
          notificationModal.success({
            heading: MESSAGES.SUCCESSFULLY_CREATED_COMMITTEE,
          });
          navigate("/committee");
        },
        onError: () => {
          notificationModal.error({
            heading: MESSAGES.FAILED_TO_CREATE_COMMITTEE,
          });
        },
      });
    },
    [createCommitteeQuery.execute, navigate, notificationModal]
  );
  const handleMembersChange = useCallback(
    (formik, index) => (value) => {
      if (!value) {
        setFormikFieldValue(
          formik,
          `${FN.MEMBER_DETAILS.ROOT}.[${index}].${FN.MEMBER_DETAILS.MEMBER_EMAIL}`,
          ""
        );
        setFormikFieldValue(
          formik,
          `${FN.MEMBER_DETAILS.ROOT}.[${index}].${FN.MEMBER_DETAILS.MEMBER_REPRESENTATIVE_OF}`,
          ""
        );
        return;
      }
      let selectedOption = getMembersQuery?.data.find(
        (item) => item.id === parseInt(value)
      );
      if (selectedOption) {
        const email = selectedOption.email;
        const representativeOf = selectedOption.representative_of;
        setFormikFieldValue(
          formik,
          `${FN.MEMBER_DETAILS.ROOT}.[${index}].${FN.MEMBER_DETAILS.MEMBER_EMAIL}`,
          email
        );
        setFormikFieldValue(
          formik,
          `${FN.MEMBER_DETAILS.ROOT}.[${index}].${FN.MEMBER_DETAILS.MEMBER_REPRESENTATIVE_OF}`,
          representativeOf
        );
      }
    },
    [getMembersQuery?.data]
  );
  const handleMemberDelete = useCallback(
    (arrayHelpers, itemIndex) => () => {
      arrayHelpers.remove(itemIndex);
    },
    []
  );
  useEffect(() => {
    if (getCommitteeDetailsQuery?.data?.data) {
      setCommitteeDetails(getCommitteeDetailsQuery?.data?.data);
    }
  }, [getCommitteeDetailsQuery?.data?.data]);

  return useMemo(() => {
    return {
      mainFormRef,
      initialValues,
      getValidationSchema,
      handleCancelClick,
      getMembersQuery,
      handleCreateCommittee,
      handleAddMember,
      handleMembersChange,
      getCommitteeDetailsQuery,
      handleUpdateCommittee,
      handleMemberDelete,
    };
  }, [
    initialValues,
    getValidationSchema,
    handleCancelClick,
    getMembersQuery,
    handleCreateCommittee,
    handleAddMember,
    handleMembersChange,
    getCommitteeDetailsQuery,
    handleUpdateCommittee,
    handleMemberDelete,
  ]);
}
export const [CreateCommitteePageProvider, useCreateCommitteePageContext] =
  generateContext(useCreateCommitteePage);
