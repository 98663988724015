import React, { useCallback } from "react";
import InputLabel from "../../../components/input-label";
import FormControl from "../../../components/form-control";
import { FormikDatepicker } from "../../../components/datepicker";
import { FormikSelect } from "../../../components/select";
import { FormikInput } from "../../../components/input";
import Const from "../../../constants/index";
import { minDateForDatePicker } from "../../../utils/date-and-time";
import { FieldArray } from "formik";
import Button from "../../../components/button";
import BinIcon from "../../../components/vectors/bin-icon";
import { getListOfIndianDelegationRow } from "./form-helper/initial-values";
import { Enums, FN } from "./form-helper/core";
import { useCreatePostMeetingPageContext } from "../provider";
import constants from "../../../constants/index";

const MeetingDetails = (props) => {
  const { values, isDisabledForm } = props;
  const handleAddMember = useCallback(
    (arrayHelpers) => () => {
      arrayHelpers.push(getListOfIndianDelegationRow());
    },
    []
  );

  return (
    <>
      <div className="flex">
        <div className="ml-4  w-[22%]">
          <InputLabel className="flex-1 text-label-100">
            Date of meeting
          </InputLabel>
          <FormControl>
            <FormikDatepicker
              placeholder={Const.FROM}
              id={`${FN.FROM_MEETING_DATE}`}
              name={`${FN.FROM_MEETING_DATE}`}
              disabled={isDisabledForm}
              options={{
                minDate: minDateForDatePicker(0),
              }}
            />
          </FormControl>
        </div>
        <div className="ml-4  w-[22%]">
          <InputLabel className="flex-1 text-label-100 invisible">.</InputLabel>
          <FormControl>
            <FormikDatepicker
              placeholder={Const.TO}
              id={`${FN.TO_MEETING_DATE}`}
              name={`${FN.TO_MEETING_DATE}`}
              className="h-8"
              disabled={isDisabledForm}
              options={{
                minDate: minDateForDatePicker(0, values?.from_meeting_date),
              }}
            />
          </FormControl>
        </div>
        <div className="ml-4 mt-1 w-[50%]">
          <FormControl className="">
            <InputLabel
              className="flex-1 text-label-100"
              htmlFor={FN.NATURE_OF_MEETING}
            >
              Nature of Meeting
            </InputLabel>
            <FormikSelect
              data={Enums.NatureOfMeeting}
              id={FN.NATURE_OF_MEETING}
              name={FN.NATURE_OF_MEETING}
              lableString="label"
              valueString="nature_of_meeting"
              passValueInt={true}
              virtualize={Enums.NatureOfMeeting?.length >= constants.VIRTUALIZED_ENABLE_LIMIT}
              classNames={{
                root: "mb-1",
                input: "ring-black-100",
                popperContent: "max-w-[220px]",
              }}
              className="h-8"
              disabled={isDisabledForm}
            />
          </FormControl>
        </div>
      </div>
      <div className="flex">
        <div className="ml-4  w-[22%]">
          <FormControl>
            <InputLabel
              className="flex-1 text-label-100"
              htmlFor={FN.TOTAL_PAPER_SUBMISSION}
            >
              Total Paper Submission at this session
            </InputLabel>
            <FormikInput
              classNames={{ root: "mb-1", input: "ring-black-100 h-8" }}
              id={FN.TOTAL_PAPER_SUBMISSION}
              name={FN.TOTAL_PAPER_SUBMISSION}
              disabled={isDisabledForm}
            />
          </FormControl>
        </div>
        <div className="ml-4  w-[22%]">
          <FormControl>
            <InputLabel
              className="flex-1 text-label-100"
              htmlFor={FN.TOTAL_MEMBER_ATTENDED}
            >
              Total who attended in person
            </InputLabel>
            <FormikInput
              classNames={{ root: "mb-1", input: "ring-black-100 h-8" }}
              id={FN.TOTAL_MEMBER_ATTENDED}
              name={FN.TOTAL_MEMBER_ATTENDED}
              disabled={isDisabledForm}
            />
          </FormControl>
        </div>
        <div className="ml-4 w-[22%]">
          <FormControl>
            <InputLabel
              className="flex-1 text-label-100"
              htmlFor={FN.TOTAL_MEMBER_ATTENDED_VIRTUALLY}
            >
              Total who attended virtually
            </InputLabel>
            <FormikInput
              classNames={{ root: "mb-1", input: "ring-black-100 h-8" }}
              id={FN.TOTAL_MEMBER_ATTENDED_VIRTUALLY}
              name={FN.TOTAL_MEMBER_ATTENDED_VIRTUALLY}
              disabled={isDisabledForm}
            />
          </FormControl>
        </div>
      </div>
      <div className="flex">
        <div className="ml-4  w-[73%]">
          <FormControl>
            <InputLabel
              className="flex-1 text-label-100"
              htmlFor={FN.TOTAL_PAPER_UNDER_SUBMISSION}
            >
              Total Paper Under Submission
            </InputLabel>
            <FormikInput
              classNames={{ root: "mb-1", input: "ring-black-100 h-8" }}
              id={FN.TOTAL_PAPER_UNDER_SUBMISSION}
              name={FN.TOTAL_PAPER_UNDER_SUBMISSION}
              disabled={isDisabledForm}
            />
          </FormControl>
        </div>
        <div className="ml-4  w-[22%]">
          <FormControl>
            <InputLabel
              className="flex-1 text-label-100"
              htmlFor={FN.GROUP_SET_UP}
            >
              Number of working group set-up
            </InputLabel>
            <FormikInput
              classNames={{ root: "mb-1", input: "ring-black-100 h-8" }}
              id={FN.GROUP_SET_UP}
              name={FN.GROUP_SET_UP}
              disabled={isDisabledForm}
            />
          </FormControl>
        </div>
      </div>
      <FieldArray
        name={FN.LIST_OF_INDIAN_DELEGATION.ROOT}
        render={(arrayHelpers, index) => {
          return (
            <div className="flex flex-col mb-2 ml-4" key={index}>
              <InputLabel
                className="text-xs font-sans font-semibold text-label-100"
                htmlFor={FN.LIST_OF_INDIAN_DELEGATION.ROOT}
              >
                List of Indian Delegation
              </InputLabel>
              {values?.[FN.LIST_OF_INDIAN_DELEGATION.ROOT]?.map(
                (item, itemIndex) => {
                  return (
                    <MembersItem
                      id={item?.id}
                      arrayHelpers={arrayHelpers}
                      itemIndex={itemIndex}
                      key={itemIndex}
                      isDisabledForm={isDisabledForm}
                    />
                  );
                }
              )}
              {!isDisabledForm && (
                <Button
                  variant="outline"
                  color="primary"
                  className="py-2 px-3.5 w-20 flex items-center text-xs font-semibold text-primary-button"
                  onClick={handleAddMember(arrayHelpers)}
                >
                  + Add
                </Button>
              )}
            </div>
          );
        }}
      />
    </>
  );
};

export default MeetingDetails;

const MembersItem = ({ id, itemIndex, arrayHelpers, isDisabledForm }) => {
  const { handleDeleteIndianDelegate } = useCreatePostMeetingPageContext();

  return (
    <>
      <div className="grid grid-cols-10 items-center gap-3">
        <FormControl className="col-span-3">
          <FormikInput
            id={`${FN.LIST_OF_INDIAN_DELEGATION.ROOT}.[${itemIndex}].${FN.LIST_OF_INDIAN_DELEGATION.NAME}`}
            name={`${FN.LIST_OF_INDIAN_DELEGATION.ROOT}.[${itemIndex}].${FN.LIST_OF_INDIAN_DELEGATION.NAME}`}
            placeholder="Name"
            classNames={{
              root: "mb-1",
              input: "ring-black-100",
            }}
            disabled={isDisabledForm}
          />
        </FormControl>
        <FormControl className="col-span-3">
          <FormikInput
            classNames={{
              root: "mb-1",
              input: "ring-black-100",
            }}
            id={`${FN.LIST_OF_INDIAN_DELEGATION.ROOT}.[${itemIndex}].${FN.LIST_OF_INDIAN_DELEGATION.ORGANISATION}`}
            name={`${FN.LIST_OF_INDIAN_DELEGATION.ROOT}.[${itemIndex}].${FN.LIST_OF_INDIAN_DELEGATION.ORGANISATION}`}
            placeholder="Organisation"
            disabled={isDisabledForm}
          />
        </FormControl>
        {!isDisabledForm && (
          <div className="flex items-center justify-center col-span-1 pr-2 mb-3">
            <BinIcon
              onClick={handleDeleteIndianDelegate(id, arrayHelpers, itemIndex)}
              className="cursor-pointer"
            />
          </div>
        )}
      </div>
    </>
  );
};
