import { FieldArray, useFormikContext } from "formik";
import FormControl from "../../../components/form-control";
import { FormikInput } from "../../../components/input";
import InputLabel from "../../../components/input-label";
import { useCreatePostMeetingPageContext } from "../provider";
import { FN } from "./form-helper/core";
import Button from "../../../components/button";
import BinIcon from "../../../components/vectors/bin-icon";
import Accordion from "../../../components/accordion";
import cx from "../../../utils/class-names";
import ArrowIcon from "../../../components/vectors/arrow-icon";
import { useCallback } from "react";
import Divider from "../../../components/divider";
import { FormikTextarea } from "../../../components/textarea";
import {
  getAgendaMemberRow,
  getIssuesRow,
  getNominatedMemberRow,
} from "./form-helper/initial-values";

const AgendaMembers = ({ isDisabledForm }) => {
  const {
    handleDeleteNominatedAgenda,
    handleDeleteNominatedAgendaMember,
    handleDeleteNominatedAgendaTask,
  } = useCreatePostMeetingPageContext();
  const { values } = useFormikContext();
  const handleAddMember = useCallback(
    (innerHelper) => () => {
      innerHelper.push(getNominatedMemberRow());
    },
    []
  );
  const handleAddTask = useCallback(
    (taskHelpers) => () => {
      taskHelpers.push(getIssuesRow());
    },
    []
  );
  const handleAddAgenda = useCallback(
    (arrayHelpers) => () => {
      arrayHelpers.push(getAgendaMemberRow());
    },
    []
  );

  return (
    <FieldArray
      name={FN.AGENDA_MEMBER_DETAILS.ROOT}
      render={(arrayHelpers) => {
        const details = values?.[FN.AGENDA_MEMBER_DETAILS.ROOT] || [];
        const accordionElements = details.map((detail, agendaIndex) => (
          <Accordion key={detail.id} defaultValue={true} className="mb-3">
            {({ expanded }) => (
              <>
                <Accordion.Header
                  className={cx(
                    "flex items-center p-2 py-1 bg-primary-text rounded-t",
                    !expanded && "rounded"
                  )}
                >
                  <span className="input-label flex-1 inline-block text-secondary-0 text-xs font-semibold mb-1.5">
                    {`Agenda ${agendaIndex + 1}`}
                  </span>
                  <span className="flex items-center ">
                    {expanded && details.length > 1 && !isDisabledForm ? (
                      <div
                        className="flex items-center cursor-pointer justify-center w-6 h-6 p-0 bg-transparent text-red-550"
                        onClick={handleDeleteNominatedAgenda(
                          detail.id,
                          arrayHelpers,
                          agendaIndex
                        )}
                      >
                        <BinIcon className="text-white" />
                      </div>
                    ) : null}
                    <div
                      className={cx(
                        "flex items-center justify-center cursor-pointer w-6 h-6 p-0 bg-transparent text-secondary-800 rotate-180",
                        expanded && "rotate-0"
                      )}
                    >
                      <ArrowIcon className="text-white" />
                    </div>
                  </span>
                </Accordion.Header>
                <Accordion.Content
                  className={cx(
                    "h-0 overflow-hidden bg-blue-800 border-0 rounded-b",
                    expanded &&
                      "px-2 pb-2 pt-2 h-auto border border-primary-button"
                  )}
                >
                  <div className="grid grid-cols-1 gap-x-4">
                    <FormControl>
                      <InputLabel
                        className="flex-1 text-label-100"
                        htmlFor={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.AGENDA_TITLE}`}
                      >
                        Agenda
                      </InputLabel>
                      <FormikInput
                        id={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.AGENDA_TITLE}`}
                        name={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.AGENDA_TITLE}`}
                        classNames={{
                          root: "mb-1",
                          input: "ring-black-100 h-8",
                        }}
                        placeholder="Agenda"
                        disabled={isDisabledForm}
                      />
                    </FormControl>
                    <FormControl>
                      <InputLabel
                        className="flex-1 text-label-100"
                        htmlFor={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.HEAD_OF_AGENDA.ROOT}`}
                      >
                        Nominated Head of this Agenda
                      </InputLabel>
                      <div className="grid grid-cols-3 gap-3">
                        <div>
                          <FormikInput
                            id={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.HEAD_OF_AGENDA.ROOT}.${FN.AGENDA_MEMBER_DETAILS.HEAD_OF_AGENDA.HEAD_OF_AGENDA_NAME}`}
                            name={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.HEAD_OF_AGENDA.ROOT}.${FN.AGENDA_MEMBER_DETAILS.HEAD_OF_AGENDA.HEAD_OF_AGENDA_NAME}`}
                            classNames={{
                              root: "mb-1",
                              input: "ring-black-100 h-8",
                            }}
                            placeholder="Name"
                            disabled={isDisabledForm}
                          />
                        </div>
                        <div>
                          <FormikInput
                            id={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.HEAD_OF_AGENDA.ROOT}.${FN.AGENDA_MEMBER_DETAILS.HEAD_OF_AGENDA.HEAD_OF_AGENDA_ORGANISATION}`}
                            name={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.HEAD_OF_AGENDA.ROOT}.${FN.AGENDA_MEMBER_DETAILS.HEAD_OF_AGENDA.HEAD_OF_AGENDA_ORGANISATION}`}
                            classNames={{
                              root: "mb-1",
                              input: "ring-black-100 h-8",
                            }}
                            placeholder="Organisation"
                            disabled={isDisabledForm}
                          />
                        </div>
                      </div>
                    </FormControl>
                    <FieldArray
                      name={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.NOMINATED_MEMBERS.ROOT}`}
                      render={(innerHelper) => {
                        return (
                          <div className="flex flex-col mb-2" key={agendaIndex}>
                            <InputLabel className="text-xs font-sans font-semibold text-label-100">
                              Nominated person to work under this Agenda
                            </InputLabel>
                            {values?.[FN.AGENDA_MEMBER_DETAILS.ROOT][
                              agendaIndex
                            ][
                              FN.AGENDA_MEMBER_DETAILS.NOMINATED_MEMBERS.ROOT
                            ]?.map((item, index) => {
                              return (
                                <MembersItem
                                  id={item?.id}
                                  innerHelper={innerHelper}
                                  itemIndex={index}
                                  agendaIndex={agendaIndex}
                                  key={index}
                                  handleDeleteNominatedAgendaMember={
                                    handleDeleteNominatedAgendaMember
                                  }
                                  isDisabledForm={isDisabledForm}
                                />
                              );
                            })}
                            {!isDisabledForm && (
                              <Button
                                variant="outline"
                                color="primary"
                                className="py-2 px-3.5 w-20 flex items-center text-xs font-semibold text-primary-button"
                                onClick={handleAddMember(innerHelper)}
                              >
                                + Add
                              </Button>
                            )}
                          </div>
                        );
                      }}
                    />
                  </div>
                  <div className="grid grid-cols-1 gap-2.5">
                    <FieldArray
                      name={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.ROOT}`}
                      render={(taskHelpers) => {
                        const details =
                          values?.[FN.AGENDA_MEMBER_DETAILS.ROOT][agendaIndex][
                            FN.AGENDA_MEMBER_DETAILS.TASKS.ROOT
                          ] || [];
                        const accordionElements = details.map(
                          (detail, taskIndex) => (
                            <Accordion
                              key={detail.id}
                              defaultValue={true}
                              className="mb-3 bg-secondary-0 border border-primary-button rounded"
                            >
                              {({ expanded }) => (
                                <>
                                  <Accordion.Header
                                    className={cx("flex items-center p-2 py-1")}
                                  >
                                    <span className="input-label flex-1 inline-block text-primary-text text-xs font-semibold mb-1.5">
                                      {`Task ${taskIndex + 1}`}
                                    </span>
                                    <span className="flex items-center ">
                                      {expanded && details.length > 1 && !isDisabledForm ? (
                                        <div
                                          className="flex items-center cursor-pointer justify-center w-6 h-6 p-0 bg-transparent text-red-550"
                                          onClick={handleDeleteNominatedAgendaTask(
                                            detail.id,
                                            taskHelpers,
                                            taskIndex
                                          )}
                                        >
                                          <BinIcon />
                                        </div>
                                      ) : null}
                                      <div
                                        className={cx(
                                          "flex items-center justify-center cursor-pointer w-6 h-6 p-0 bg-transparent text-secondary-800 rotate-180",
                                          expanded && "rotate-0"
                                        )}
                                      >
                                        <ArrowIcon className="text-primary-brand" />
                                      </div>
                                    </span>
                                  </Accordion.Header>
                                  <Accordion.Content
                                    className={cx(
                                      "h-0 overflow-hidden",
                                      expanded && "px-2 pb-2 pt-0 h-auto"
                                    )}
                                  >
                                    <Divider className="divider w-[100%] h-[2px] bg-gray-600 bg-opacity-25 mb-2" />
                                    <TaskItem
                                      taskIndex={taskIndex}
                                      agendaIndex={agendaIndex}
                                      isDisabledForm={isDisabledForm}
                                    />
                                  </Accordion.Content>
                                </>
                              )}
                            </Accordion>
                          )
                        );
                        return (
                          <>
                            <div className="flex justify-between">
                              <InputLabel
                                className="flex-1 text-label-100 mb-2"
                                htmlFor={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.ROOT}`}
                              >
                                Tasks
                              </InputLabel>
                            </div>
                            {accordionElements}
                            {!isDisabledForm && (
                              <div>
                                <Button
                                  variant="solid"
                                  color="primary"
                                  className="py-2 px-3.5 flex items-center text-xs font-semibold m-1 action-auditor-save"
                                  icon="+"
                                  onClick={handleAddTask(taskHelpers)}
                                >
                                  Add Task
                                </Button>
                              </div>
                            )}
                          </>
                        );
                      }}
                    />
                  </div>
                </Accordion.Content>
              </>
            )}
          </Accordion>
        ));
        return (
          <>
            <div className="flex justify-between">
              <InputLabel
                className="flex-1 text-label-100 mb-2"
                htmlFor={FN.AGENDA_MEMBER_DETAILS.ROOT}
              >
                Agenda
              </InputLabel>
            </div>
            {accordionElements}
            {!isDisabledForm && (
              <Button
                variant="outline"
                color="primary"
                className="py-2 px-3.5 flex items-center text-xs font-semibold hover:bg-primary-button hover:text-secondary-0"
                icon="+"
                onClick={handleAddAgenda(arrayHelpers)}
              >
                Add
              </Button>
            )}
          </>
        );
      }}
    />
  );
};
export default AgendaMembers;

const MembersItem = ({
  id,
  itemIndex,
  agendaIndex,
  innerHelper,
  handleDeleteNominatedAgendaMember,
  isDisabledForm,
}) => {
  return (
    <>
      <div className="grid grid-cols-6 items-center gap-3">
        <FormControl className="col-span-2">
          <FormikInput
            id={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.NOMINATED_MEMBERS.ROOT}.${itemIndex}.${FN.AGENDA_MEMBER_DETAILS.NOMINATED_MEMBERS.NAME}`}
            name={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.NOMINATED_MEMBERS.ROOT}.${itemIndex}.${FN.AGENDA_MEMBER_DETAILS.NOMINATED_MEMBERS.NAME}`}
            classNames={{
              root: "mb-1",
              input: "ring-black-100 h-8",
            }}
            placeholder="Name"
            disabled={isDisabledForm}
          />
        </FormControl>
        <FormControl className="col-span-2">
          <FormikInput
            id={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.NOMINATED_MEMBERS.ROOT}.${itemIndex}.${FN.AGENDA_MEMBER_DETAILS.NOMINATED_MEMBERS.ORGANISATION}`}
            name={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.NOMINATED_MEMBERS.ROOT}.${itemIndex}.${FN.AGENDA_MEMBER_DETAILS.NOMINATED_MEMBERS.ORGANISATION}`}
            classNames={{
              root: "mb-1",
              input: "ring-black-100 h-8",
            }}
            placeholder="Organisation"
            disabled={isDisabledForm}
          />
        </FormControl>
        <div className="flex items-center justify-center col-span-1 pr-2 mb-3">
          <BinIcon
            onClick={handleDeleteNominatedAgendaMember(
              id,
              innerHelper,
              itemIndex
            )}
            className="cursor-pointer"
          />
        </div>
      </div>
    </>
  );
};

const TaskItem = ({ taskIndex, agendaIndex, isDisabledForm }) => {
  return (
    <div className="grid grid-cols-1 gap-x-4">
      <FormControl>
        <InputLabel
          className="flex-1 text-label-100"
          htmlFor={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.ROOT}.${taskIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.BACKGROUND}`}
        >
          Background
        </InputLabel>
        <FormikInput
          id={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.ROOT}.${taskIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.BACKGROUND}`}
          name={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.ROOT}.${taskIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.BACKGROUND}`}
          classNames={{
            root: "mb-1",
            input: "ring-black-100 h-8",
          }}
          disabled={isDisabledForm}
        />
      </FormControl>
      <FormControl>
        <InputLabel
          className="flex-1 text-black-100"
          htmlFor={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.ROOT}.${taskIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.PAPER_UNDER_CONSIDERATION}`}
        >
          Paper under consideration
        </InputLabel>
        <FormikInput
          id={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.ROOT}.${taskIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.PAPER_UNDER_CONSIDERATION}`}
          name={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.ROOT}.${taskIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.PAPER_UNDER_CONSIDERATION}`}
          classNames={{
            root: "grid-cols-2 gap-x-4 mb-1",
            input: "ring-black-100 h-8",
          }}
          disabled={isDisabledForm}
        />
      </FormControl>
      <FormControl>
        <InputLabel
          className="flex-1 text-black-100"
          htmlFor={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.ROOT}.${taskIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.INDIA_SUBMISSION}`}
        >
          India’s Submissions
        </InputLabel>
        <FormikTextarea
          id={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.ROOT}.${taskIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.INDIA_SUBMISSION}`}
          name={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.ROOT}.${taskIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.INDIA_SUBMISSION}`}
          minRows={3}
          className="ring-black-50"
          disabled={isDisabledForm}
        />
      </FormControl>

      <FormControl>
        <InputLabel
          className="flex-1 text-black-100"
          htmlFor={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.ROOT}.${taskIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.INDIA_COSPONSORED_PAPERS}`}
        >
          India’s co-sponsored papers
        </InputLabel>
        <FormikTextarea
          id={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.ROOT}.${taskIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.INDIA_COSPONSORED_PAPERS}`}
          name={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.ROOT}.${taskIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.INDIA_COSPONSORED_PAPERS}`}
          minRows={3}
          className="ring-black-50"
          disabled={isDisabledForm}
        />
      </FormControl>
      <FormControl>
        <InputLabel
          className="flex-1 text-black-100"
          htmlFor={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.ROOT}.${taskIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.REVIEW_OF_SUBMISSION}`}
        >
          Review of submissions
        </InputLabel>
        <FormikTextarea
          id={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.ROOT}.${taskIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.REVIEW_OF_SUBMISSION}`}
          name={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.ROOT}.${taskIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.REVIEW_OF_SUBMISSION}`}
          minRows={3}
          className="ring-black-50"
          disabled={isDisabledForm}
        />
      </FormControl>

      <FormControl>
        <InputLabel
          className="flex-1 text-black-100"
          htmlFor={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.ROOT}.${taskIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.RECOMMENDED_INDIA_STAND_TO_BE_TAKEN}`}
        >
          Recommended India’s stand to be taken
        </InputLabel>
        <FormikTextarea
          id={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.ROOT}.${taskIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.RECOMMENDED_INDIA_STAND_TO_BE_TAKEN}`}
          name={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.ROOT}.${taskIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.RECOMMENDED_INDIA_STAND_TO_BE_TAKEN}`}
          minRows={3}
          className="ring-black-50"
          disabled={isDisabledForm}
        />
      </FormControl>

      <FormControl>
        <InputLabel
          className="flex-1 text-black-100"
          htmlFor={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.ROOT}.${taskIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.SHADOW_COMMITTEE_APPROVED_STAND}`}
        >
          Shadow Committee approved stand
        </InputLabel>
        <FormikTextarea
          id={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.ROOT}.${taskIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.SHADOW_COMMITTEE_APPROVED_STAND}`}
          name={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.ROOT}.${taskIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.SHADOW_COMMITTEE_APPROVED_STAND}`}
          minRows={3}
          className="ring-black-50"
          disabled={isDisabledForm}
        />
      </FormControl>

      <FormControl>
        <InputLabel
          className="flex-1 text-black-100"
          htmlFor={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.ROOT}.${taskIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.DECISION_OF_IMO_COMMITTEE}`}
        >
          Decision of imo committee/subcommittee
        </InputLabel>
        <FormikTextarea
          id={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.ROOT}.${taskIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.DECISION_OF_IMO_COMMITTEE}`}
          name={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.ROOT}.${taskIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.DECISION_OF_IMO_COMMITTEE}`}
          minRows={3}
          className="ring-black-50"
          disabled={isDisabledForm}
        />
      </FormControl>

      <FormControl>
        <InputLabel
          className="flex-1 text-black-100"
          htmlFor={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.ROOT}.${taskIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.AREAS_OF_DISAGREEMENT_OF_INDIA}`}
        >
          Areas of disagreement of India by decision of imo
          committee/subcommittee
        </InputLabel>
        <FormikTextarea
          id={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.ROOT}.${taskIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.AREAS_OF_DISAGREEMENT_OF_INDIA}`}
          name={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.ROOT}.${taskIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.AREAS_OF_DISAGREEMENT_OF_INDIA}`}
          minRows={3}
          className="ring-black-50"
          disabled={isDisabledForm}
        />
      </FormControl>

      <FormControl>
        <InputLabel
          className="flex-1 text-black-100"
          htmlFor={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.ROOT}.${taskIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.ACHIEVEMENT_OF_INDIA}`}
        >
          Achievement of India under this issue
        </InputLabel>
        <FormikTextarea
          id={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.ROOT}.${taskIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.ACHIEVEMENT_OF_INDIA}`}
          name={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.ROOT}.${taskIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.ACHIEVEMENT_OF_INDIA}`}
          minRows={3}
          className="ring-black-50"
          disabled={isDisabledForm}
        />
      </FormControl>

      <FormControl>
        <InputLabel
          className="flex-1 text-black-100"
          htmlFor={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.ROOT}.${taskIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.TO_DO_LIST}`}
        >
          To do list
        </InputLabel>
        <FormikTextarea
          id={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.ROOT}.${taskIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.TO_DO_LIST}`}
          name={`${FN.AGENDA_MEMBER_DETAILS.ROOT}.${agendaIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.ROOT}.${taskIndex}.${FN.AGENDA_MEMBER_DETAILS.TASKS.TO_DO_LIST}`}
          minRows={3}
          className="ring-black-50"
          disabled={isDisabledForm}
        />
      </FormControl>
    </div>
  );
};
