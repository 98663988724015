import cx from "../../utils/class-names";

const MenuItems = (props) => {
  const { children, className, ...restProps } = props;

  return (
    <div className={cx("menu-items hover:bg-primary-brand-100 py-1.5 px-3 text-primary-text text-xs font-semibold cursor-pointer", className)} {...restProps}>
      {children}
    </div>
  );
};

export default MenuItems;
